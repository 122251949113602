import {
  CREATE_INVENTORIES_CHECK_START,
  CREATE_INVENTORIES_CHECK_SUCCESS,
  CREATE_INVENTORIES_CHECK_FAILED,
  UPDATE_INVENTORIES_CHECK_START,
  UPDATE_INVENTORIES_CHECK_SUCCESS,
  UPDATE_INVENTORIES_CHECK_FAILED,
  DELETE_INVENTORIES_CHECK_START,
  DELETE_INVENTORIES_CHECK_SUCCESS,
  DELETE_INVENTORIES_CHECK_FAILED,
  SEARCH_INVENTORIES_CHECK_START,
  SEARCH_INVENTORIES_CHECK_SUCCESS,
  SEARCH_INVENTORIES_CHECK_FAILED,
  GET_INVENTORIES_CHECK_DETAIL_START,
  GET_INVENTORIES_CHECK_DETAIL_SUCCESS,
  GET_INVENTORIES_CHECK_DETAIL_FAILED,
  CONFIRM_INVENTORIES_CHECK_START,
  CONFIRM_INVENTORIES_CHECK_SUCCESS,
  CONFIRM_INVENTORIES_CHECK_FAILED,
} from './inventories-check.action';

const initialState = {
  isLoading: false,
  inventoriesCheckList: [],
  inventoriesCheckDetail: {},
  total: 0,
};

/**
 * inventories-check reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function inventoriesCheck(state = initialState, action) {
  switch (action.type) {
    case CREATE_INVENTORIES_CHECK_START:
    case UPDATE_INVENTORIES_CHECK_START:
    case DELETE_INVENTORIES_CHECK_START:
    case SEARCH_INVENTORIES_CHECK_START:
    case GET_INVENTORIES_CHECK_DETAIL_START:
    case CONFIRM_INVENTORIES_CHECK_START:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_INVENTORIES_CHECK_SUCCESS:
    case UPDATE_INVENTORIES_CHECK_SUCCESS:
    case DELETE_INVENTORIES_CHECK_SUCCESS:
    case CONFIRM_INVENTORIES_CHECK_SUCCESS:
    case CREATE_INVENTORIES_CHECK_FAILED:
    case UPDATE_INVENTORIES_CHECK_FAILED:
    case DELETE_INVENTORIES_CHECK_FAILED:
    case CONFIRM_INVENTORIES_CHECK_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_INVENTORIES_CHECK_SUCCESS:
      return {
        ...state,
        inventoriesCheckList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case SEARCH_INVENTORIES_CHECK_FAILED:
      return {
        ...state,
        inventoriesCheckList: [],
        total: 0,
        isLoading: false,
      };
    case GET_INVENTORIES_CHECK_DETAIL_SUCCESS:
      return {
        ...state,
        inventoriesCheckDetail: action.payload,
        isLoading: false,
      };
    case GET_INVENTORIES_CHECK_DETAIL_FAILED:
      return {
        ...state,
        inventoriesCheckDetail: {},
        isLoading: false,
      };
    default:
      return state;
  }
}
