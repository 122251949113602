import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import { Form, Spin, Card, Row, Col, Input, Button, Upload } from 'antd';
import TopContent from 'components/common/top-content';
import {
  MODAL_MODE,
  TEXTFIELD_REQUIRED_LENGTH,
  CATEGORY_STATUS,
  CATEGORY_STATUS_MAP,
  IMAGE_STATUS,
  IMAGE_RELATION_TYPE,
  DESCRIPTION_ROWS,
  DEVICE_MIN_WIDTH,
} from 'common/constant';
import { formatDateTimeUtc, getImageUrl, redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import {
  getCategoryDetail,
  createCategory,
  updateCategory,
} from 'stores/category/category.action';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

function CategoryForm(props) {
  const { t, category } = props;
  const [form] = Form.useForm();
  const [mode, setMode] = useState(MODAL_MODE.CREATE);
  const [status, setStatus] = useState(0);
  const [title, setTitle] = useState('');
  const [createdAt, setCreatedAt] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      route: ROUTES.CATEGORY.PATH,
      title: ROUTES.CATEGORY.TITLE,
    },
    {
      title: ROUTES.CATEGORY_CREATE.TITLE,
    },
  ]);
  const [image, setImage] = useState(null);
  const path = props.match.path;
  const { id } = props.match.params;

  useEffect(() => {
    setMode(
      path.includes('/update/')
        ? MODAL_MODE.UPDATE
        : path.includes('/detail/')
        ? MODAL_MODE.DETAIL
        : MODAL_MODE.CREATE,
    );
  }, [path]);

  useEffect(() => {
    if (mode !== MODAL_MODE.CREATE) {
      const { name, description, created_at, status, image } =
        category.categoryDetail;

      if (name) {
        setStatus(status);
        setCreatedAt(moment(created_at));
        if (image)
          setImage({
            uid: 1,
            name: image,
            status: IMAGE_STATUS.DONE,
            url: getImageUrl(
              `${IMAGE_RELATION_TYPE.ITEM_GROUP}/${id}/${image}`,
            ),
          });

        form.setFieldsValue({
          name,
          description,
          imageList: image ? [image] : [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, form]);

  useEffect(() => {
    document.title = title;
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    setBreadcrumbs([
      {
        route: ROUTES.CATEGORY.PATH,
        title: ROUTES.CATEGORY.TITLE,
      },
      {
        title:
          mode === MODAL_MODE.CREATE
            ? ROUTES.CATEGORY_CREATE.TITLE
            : mode === MODAL_MODE.UPDATE
            ? ROUTES.CATEGORY_UPDATE.TITLE
            : ROUTES.CATEGORY_DETAIL.TITLE,
      },
    ]);

    setTitle(
      mode === MODAL_MODE.CREATE
        ? t('category.createCategory')
        : mode === MODAL_MODE.UPDATE
        ? t('category.editCategory')
        : t('category.detailCategory'),
    );
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const onFinish = (values) => {
    const { name, description } = values;
    const id = props.match.params.id;
    const imageName = image?.name;

    const params = new FormData();
    params.append('name', name);
    params.append('description', description || '');
    if (image) {
      params.append(
        'image',
        image?.status === IMAGE_STATUS.DONE
          ? null
          : JSON.stringify({
              ...image,
              name: imageName,
              url: `${IMAGE_RELATION_TYPE.ITEM_GROUP}/${id}/`,
            }),
      );
      if (image?.status !== IMAGE_STATUS.DONE)
        params.append('files[]', image.originFileObj || null);
    }

    if (mode === MODAL_MODE.CREATE)
      props.createCategory(params, () => {
        backToList();
      });
    else if (mode === MODAL_MODE.UPDATE) {
      props.updateCategory(params, id, () => {
        backToList();
      });
    }

    setImage(null);
  };

  const backToList = () => {
    redirectRouter(ROUTES.CATEGORY.PATH);
    setImage(null);
  };

  const getDetail = () => {
    const id = props.match.params.id;
    if (mode !== MODAL_MODE.CREATE) props.getCategoryDetail(id);
  };

  const onCancel = () => {
    if (mode === MODAL_MODE.CREATE) resetForm();
    else if (mode === MODAL_MODE.UPDATE) getDetail();
  };

  const resetForm = () => {
    form?.resetFields();
    setImage(null);
  };

  const renderActionButtons = () => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return (
          <div id="group-button-submit">
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="category-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.create')}
            </Button>
          </div>
        );
      case MODAL_MODE.UPDATE:
        return (
          <div id="group-button-submit">
            <Button onClick={() => backToList()} className="close-button">
              {t('common.close')}
            </Button>
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="category-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.save')}
            </Button>
          </div>
        );
      case MODAL_MODE.DETAIL:
        switch (status) {
          // PENDING
          case CATEGORY_STATUS.UNCONFIRM:
          case CATEGORY_STATUS.ACTIVE:
            return (
              <div id="group-button-submit">
                <Button onClick={() => backToList()} className="close-button">
                  {t('common.close')}
                </Button>
              </div>
            );
          // INACTIVE
          case CATEGORY_STATUS.INACTIVE:
            return (
              <Button onClick={() => backToList()} className="close-button">
                {t('common.close')}
              </Button>
            );
          default:
            return;
        }
      default:
        break;
    }
  };

  const handleChangeImage = ({ fileList: imageList }) => {
    if (image?.status === IMAGE_STATUS.REMOVED) {
      setImage(null);
    } else {
      setImage(imageList[0]);
    }
  };

  const isView = mode === MODAL_MODE.DETAIL;
  return (
    <div className="category-form">
      <Spin spinning={category?.isLoading}>
        <TopContent
          title={title}
          breadcrumbs={breadcrumbs}
          backToList={backToList}
        />
        <Card className="body-content">
          <Form
            {...layout}
            name="category-form"
            id="category-form"
            className="required-mark-after"
            form={form}
            onFinish={onFinish}
          >
            <div className="block-1">
              {isView && (
                <Row>
                  <Col
                    sm={12}
                    xs={24}
                    offset={window.innerWidth > DEVICE_MIN_WIDTH ? 4 : 0}
                  >
                    <Form.Item name="status" label={t('category.status')}>
                      <div
                        className={
                          [CATEGORY_STATUS.ACTIVE].includes(status)
                            ? 'activeStatusBox boxStatus'
                            : [CATEGORY_STATUS.UNCONFIRM].includes(status)
                            ? 'unconfirmedStatusBox boxStatus'
                            : 'inActiveStatusBox boxStatus'
                        }
                      >
                        {t(CATEGORY_STATUS_MAP[status])}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col
                  sm={12}
                  xs={24}
                  offset={window.innerWidth > DEVICE_MIN_WIDTH ? 4 : 0}
                >
                  <Form.Item
                    name="name"
                    label={t('category.name')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('category.placeholder.name')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('category.description')}
                    name="description"
                    rules={[
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input.TextArea
                      disabled={isView}
                      autoSize={{
                        minRows: isView ? 1 : DESCRIPTION_ROWS.MIN,
                        maxRows: DESCRIPTION_ROWS.MAX,
                      }}
                      placeholder={t('category.placeholder.description')}
                    />
                  </Form.Item>
                  {isView && (
                    <Form.Item label={t('category.createdAt')}>
                      <div className="ant-input-disabled">
                        {formatDateTimeUtc(createdAt)}
                      </div>
                    </Form.Item>
                  )}
                  <Form.Item
                    name="imageList"
                    label={t('category.image')}
                    rules={[]}
                  >
                    {isView ? (
                      image ? (
                        <img
                          width="100"
                          height="100"
                          className="image"
                          src={image?.url}
                          alt="img"
                        />
                      ) : (
                        <></>
                      )
                    ) : (
                      <Upload
                        action="/"
                        listType="picture-card"
                        fileList={image ? [image] : []}
                        beforeUpload={() => {
                          return false;
                        }}
                        maxCount={1}
                        onChange={handleChangeImage}
                      >
                        {image ? null : uploadButton}
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
        <Card style={{ marginTop: '32px' }}>
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderActionButtons()}
              </div>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
}

const mapDispatchToProps = {
  getCategoryDetail,
  createCategory,
  updateCategory,
};

const mapStateToProps = (state) => ({
  category: state.category,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CategoryForm),
);
