import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import LayoutWithSidebar from './layout-with-sidebar';
import { connect } from 'react-redux';
import Login from 'containers/login';
import ErrorPage from 'containers/internal-server-error';
import NotFoundPage from 'containers/404-not-found';
import routes from 'common/routes';
import { ROUTES } from 'common/routes';
import { redirectRouter, isAuth } from 'utils';
import { logout, refreshToken } from 'stores/auth/auth.action';
// import { Modal } from 'antd';
// import i18n from 'common/i18n';

class Layout extends Component {
  /**
   * Flatten array object
   * @param {array} arr
   * @returns
   */
  flatten = (arr) => {
    return arr
      ? arr.reduce((r, i) => [...r, i, ...this.flatten(i.subMenu)], [])
      : [];
  };

  getCurrentRoute = () => {
    const flattenRoutes = this.flatten(routes);
    const { pathname } = this.props.location;
    const currentRoute = flattenRoutes.find(
      (item) =>
        (pathname === item?.path && pathname === '/') ||
        (pathname.includes(item?.pathActive || item?.path) &&
          item?.path !== '/'),
    );

    return currentRoute || null;
  };

  renderRoutes = (inputRoutes, currentRoute) => {
    // authentication
    if (!isAuth() && currentRoute?.path !== ROUTES.ERROR_PAGE.PATH) {
      redirectRouter('/login');
    } else {
      return (
        <>
          {inputRoutes.map((route) => (
            <Route
              path={route.path}
              exact
              component={route.component}
              key={route.path}
            />
          ))}
        </>
      );
    }
  };

  // closeExpiredTokenDialog = () => {
  //   this.props.logout();
  // };

  refreshToken = () => {
    this.props.refreshToken(
      {
        refresh_token: window.localStorage.getItem('refreshToken'),
        remember_password: false,
      },
      () => {
        setTimeout(() => {
          window.location.reload();
        }, 400);
      },
    );
  };

  render() {
    const { expiredToken } = this.props;
    const currentRoute = this.getCurrentRoute();

    if (expiredToken) this.refreshToken();

    if (currentRoute?.path === ROUTES.LOGIN.PATH && isAuth())
      redirectRouter('/');

    return (
      <>
        {/* <Modal
          visible={expiredToken}
          title={i18n.t('auth.sessionExpiredTitle')}
          width={500}
          cancelText={i18n.t('common.no')}
          okText={i18n.t('common.yes')}
          onCancel={this.closeExpiredTokenDialog}
          onOk={this.refreshToken}
        >
          {i18n.t('auth.sessionExpired')}
        </Modal> */}
        <Switch>
          {currentRoute?.path === ROUTES.LOGIN.PATH ? (
            <Route
              path={ROUTES.LOGIN.PATH}
              exact
              component={Login}
              key={ROUTES.LOGIN.PATH}
            />
          ) : currentRoute?.path === ROUTES.ERROR_PAGE.PATH ? (
            <Route
              path={ROUTES.ERROR_PAGE.PATH}
              exact
              component={ErrorPage}
              key={ROUTES.ERROR_PAGE.PATH}
            />
          ) : currentRoute?.path ? (
            <LayoutWithSidebar>
              <Switch>{this.renderRoutes(routes, currentRoute)}</Switch>
            </LayoutWithSidebar>
          ) : (
            <Route path="*" exact component={NotFoundPage} key="not-found" />
          )}
        </Switch>
      </>
    );
  }
}

const mapDispatchToProps = {
  logout,
  refreshToken,
};

const mapStateToProps = (state) => ({
  expiredToken: state.auth.expiredToken,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
