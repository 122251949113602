export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const GET_CURRENCY_UNIT_START = 'GET_CURRENCY_UNIT_START';
export const GET_CURRENCY_UNIT_SUCCESS = 'GET_CURRENCY_UNIT_SUCCESS';
export const GET_CURRENCY_UNIT_FAILED = 'GET_CURRENCY_UNIT_FAILED';

export const SEARCH_INVENTORIES_START = 'SEARCH_INVENTORIES_START';
export const SEARCH_INVENTORIES_SUCCESS = 'SEARCH_INVENTORIES_SUCCESS';
export const SEARCH_INVENTORIES_FAILED = 'SEARCH_INVENTORIES_FAILED';

export const SEARCH_ORDERS_START = 'SEARCH_ORDERS_START';
export const SEARCH_ORDERS_SUCCESS = 'SEARCH_ORDERS_SUCCESS';
export const SEARCH_ORDERS_FAILED = 'SEARCH_ORDERS_FAILED';

export const GET_ORDER_DETAIL_START = 'GET_ORDER_DETAIL_START';
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS';
export const GET_ORDER_DETAIL_FAILED = 'GET_ORDER_DETAIL_FAILED';

export const REDIRECT_ERROR_PAGE_START = 'REDIRECT_ERROR_PAGE_START';
export const REDIRECT_ERROR_PAGE_SUCCESS = 'REDIRECT_ERROR_PAGE_SUCCESS';
export const REDIRECT_ERROR_PAGE_FAILED = 'REDIRECT_ERROR_PAGE_FAILED';

export const SEARCH_SHOP_OWNER_ORDERS_START = 'SEARCH_SHOP_OWNER_ORDERS_START';
export const SEARCH_SHOP_OWNER_ORDERS_SUCCESS =
  'SEARCH_SHOP_OWNER_ORDERS_SUCCESS';
export const SEARCH_SHOP_OWNER_ORDERS_FAILED =
  'SEARCH_SHOP_OWNER_ORDERS_FAILED';

export const SEARCH_SHOP_OWNER_BEST_SELLER_START =
  'SEARCH_SHOP_OWNER_BEST_SELLER_START';
export const SEARCH_SHOP_OWNER_BEST_SELLER_SUCCESS =
  'SEARCH_SHOP_OWNER_BEST_SELLER_SUCCESS';
export const SEARCH_SHOP_OWNER_BEST_SELLER_FAILED =
  'SEARCH_SHOP_OWNER_BEST_SELLER_FAILED';

export const SEARCH_ADMIN_TRANSACTIONS_START = 'SEARCH_ADMIN_TRANSACTIONS_START';
export const SEARCH_ADMIN_TRANSACTIONS_SUCCESS = 'SEARCH_ADMIN_TRANSACTIONS_SUCCESS';
export const SEARCH_ADMIN_TRANSACTIONS_FAILED = 'SEARCH_ADMIN_TRANSACTIONS_FAILED';

export const SEARCH_TRANSACTIONS_START = 'SEARCH_TRANSACTIONS_START';
export const SEARCH_TRANSACTIONS_SUCCESS = 'SEARCH_TRANSACTIONS_SUCCESS';
export const SEARCH_TRANSACTIONS_FAILED = 'SEARCH_TRANSACTIONS_FAILED';

export const CONFIRM_ORDER_START = 'CONFIRM_ORDER_START'
export const CONFIRM_ORDER_SUCCESS = 'CONFIRM_ORDER_SUCCESS'
export const CONFIRM_ORDER_FAILED = 'CONFIRM_ORDER_FAILED'

/**
 * Change website language
 */
export function handleChangeLanguage(lang) {
  return {
    type: CHANGE_LANGUAGE,
    payload: lang,
  };
}

export function getCurrencyUnit(payload, onSuccess, onError) {
  return {
    type: GET_CURRENCY_UNIT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function getCurrencyUnitSuccess(payload, onSuccess, onError) {
  return {
    type: GET_CURRENCY_UNIT_SUCCESS,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function getCurrencyUnitFailed(onSuccess, onError) {
  return {
    type: GET_CURRENCY_UNIT_FAILED,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchInventories(payload, onSuccess, onError) {
  return {
    type: SEARCH_INVENTORIES_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchInventoriesSuccess(payload, onSuccess, onError) {
  return {
    type: SEARCH_INVENTORIES_SUCCESS,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchInventoriesFailed(onSuccess, onError) {
  return {
    type: SEARCH_INVENTORIES_FAILED,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchOrders(payload, onSuccess, onError) {
  return {
    type: SEARCH_ORDERS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchOrdersSuccess(payload, onSuccess, onError) {
  return {
    type: SEARCH_ORDERS_SUCCESS,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchOrdersFailed(onSuccess, onError) {
  return {
    type: SEARCH_ORDERS_FAILED,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchShopOwnerOrders(payload, onSuccess, onError) {
  return {
    type: SEARCH_SHOP_OWNER_ORDERS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchShopOwnerOrdersSuccess(payload, onSuccess, onError) {
  return {
    type: SEARCH_SHOP_OWNER_ORDERS_SUCCESS,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchShopOwnerOrdersFailed(onSuccess, onError) {
  return {
    type: SEARCH_SHOP_OWNER_ORDERS_FAILED,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchShopOwnerBestSeller(payload, onSuccess, onError) {
  return {
    type: SEARCH_SHOP_OWNER_BEST_SELLER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchShopOwnerBestSellerSuccess(payload, onSuccess, onError) {
  return {
    type: SEARCH_SHOP_OWNER_BEST_SELLER_SUCCESS,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchShopOwnerBestSellerFailed(onSuccess, onError) {
  return {
    type: SEARCH_SHOP_OWNER_BEST_SELLER_FAILED,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function getOrderDetail(payload, onSuccess, onError) {
  return {
    type: GET_ORDER_DETAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function getOrderDetailSuccess(payload, onSuccess, onError) {
  return {
    type: GET_ORDER_DETAIL_SUCCESS,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function getOrderDetailFailed(onSuccess, onError) {
  return {
    type: GET_ORDER_DETAIL_FAILED,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Redirect error page
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function redirectErrorPage(callbackUrl, onSuccess, onError) {
  return {
    type: REDIRECT_ERROR_PAGE_START,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Redirect error page success action
 * @param {*} payload
 * @returns {object}
 */
export function redirectErrorPageSuccess(payload) {
  return {
    type: REDIRECT_ERROR_PAGE_SUCCESS,
    payload: payload,
  };
}

/**
 * Redirect error page failed action
 * @returns {object}
 */
export function redirectErrorPageFailed() {
  return {
    type: REDIRECT_ERROR_PAGE_FAILED,
  };
}

export function searchAdminTransactions(payload, onSuccess, onError) {
  return {
    type: SEARCH_ADMIN_TRANSACTIONS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchAdminTransactionsSuccess(payload, onSuccess, onError) {
  return {
    type: SEARCH_ADMIN_TRANSACTIONS_SUCCESS,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchAdminTransactionsFailed(onSuccess, onError) {
  return {
    type: SEARCH_ADMIN_TRANSACTIONS_FAILED,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchTransactions(payload, onSuccess, onError) {
  return {
    type: SEARCH_TRANSACTIONS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchTransactionsSuccess(payload, onSuccess, onError) {
  return {
    type: SEARCH_TRANSACTIONS_SUCCESS,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function searchTransactionsFailed(onSuccess, onError) {
  return {
    type: SEARCH_TRANSACTIONS_FAILED,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function confirmOrder(payload, onSuccess, onError) {
  return {
    type: CONFIRM_ORDER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function confirmOrderSuccess(payload, onSuccess, onError) {
  return {
    type: CONFIRM_ORDER_SUCCESS,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

export function confirmOrderFailed(onSuccess, onError) {
  return {
    type: CONFIRM_ORDER_FAILED,
    onSuccess: onSuccess,
    onError: onError,
  };
}
