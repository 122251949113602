import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import {
  Form,
  Spin,
  Card,
  Row,
  Col,
  Input,
  DatePicker,
  Button,
  Modal,
  Upload,
  Avatar,
} from 'antd';
import TopContent from 'components/common/top-content';
import {
  ADMIN_PHONE,
  DATE_FORMAT,
  IMAGE_RELATION_TYPE,
  IMAGE_STATUS,
  MODAL_MODE,
  PATTERN,
  TEXTFIELD_REQUIRED_LENGTH,
  TRANSACTION_STATUS_MAP,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_MAP,
  USER_TYPE,
  USER_TYPE_ENUM,
} from 'common/constant';
import moment from 'moment';
import { formatDateTimeUtc, getImageUrl } from 'utils';
import {
  updateUser,
  changePassword,
  getUserDetail,
  createUserBank,
  updateUserBank,
  searchUser,
} from 'stores/user/user.action';
import {
  searchAdminTransactions,
  searchTransactions,
} from 'stores/common/common.action';
import { PlusOutlined } from '@ant-design/icons';
import Coin from 'assets/images/coin.png';
import CustomTable from 'components/common/custom-table';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

function ShopOwnerProfile(props) {
  const { t, user, transactionList } = props;
  const [form] = Form.useForm();
  const [mode, setMode] = useState(MODAL_MODE.DETAIL);
  const [title] = useState(t('user.profile'));
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [image, setImage] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [userType, setUserType] = useState(null);
  const id = window.localStorage.getItem('userId');
  const isGoogleAccount = [
    USER_TYPE_ENUM.GOOGLE_USER,
    USER_TYPE_ENUM.GOOGLE_SHOP_OWNER,
  ].includes(JSON.parse(window.localStorage.getItem('user'))?.user_type);
  const [openBankModal, setOpenBankModal] = useState(false);
  const [openTransactionHistory, setOpenTransactionHistory] = useState(false);
  const isAdmin =
    JSON.parse(window.localStorage.getItem('user'))?.phone === ADMIN_PHONE;
  const [bankInfo, setBankInfo] = useState(null);
  const transactionHistoryColumns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('user.id'),
      width: '4vw',
    },
    {
      key: 'price',
      dataIndex: 'price',
      title: isAdmin ? t('user.coinReceived') : t('user.coinWithdraw'),
      align: 'center',
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('user.sentDate'),
      render: (params) => {
        return formatDateTimeUtc(params, DATE_FORMAT);
      },
    },
    {
      key: 'transaction_type',
      dataIndex: 'transaction_type',
      title: t('user.transactionType'),
      render: (params) => {
        return t(TRANSACTION_TYPE_MAP[params]);
      },
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('user.status'),
      render: (params) => {
        return t(TRANSACTION_STATUS_MAP[params]);
      },
    },
  ];

  if (isAdmin)
    transactionHistoryColumns.push({
      key: 'receiver_id',
      dataIndex: 'receiver_id',
      title: t('user.receiver'),
      render: (params) => {
        return user?.userList.find((user) => user.id === params)?.full_name;
      },
    });

  useEffect(() => {
    if (isAdmin) {
      props.searchUser();
      props.searchAdminTransactions();
    } else
      props.searchTransactions({
        filter: JSON.stringify([
          {
            column: 'user_id',
            value: window.localStorage.getItem('userId'),
          },
          {
            column: 'transaction_type',
            value: TRANSACTION_TYPE.SHOP,
          },
        ]),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      phone,
      email,
      full_name,
      date_of_birth,
      user_type,
      image,
      coin,
      bank,
    } = user.userDetail;

    if (phone) {
      form.setFieldsValue({
        phone,
        email,
        full_name,
        date_of_birth: date_of_birth ? moment(date_of_birth) : null,
        user_type,
        coin: +coin,
        bank_owner_name: bank?.bank_owner_name,
        bank_name: bank?.bank_name,
        bank_account_number: bank?.bank_account_number,
      });

      if (Object.keys(bank).length > 0) setBankInfo(bank);

      setUserType(user_type);

      if (image)
        setImage({
          uid: 1,
          name: image,
          status: IMAGE_STATUS.DONE,
          url: getImageUrl(`${IMAGE_RELATION_TYPE.USER}/${id}/${image}`),
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userDetail]);

  useEffect(() => {
    document.title = title;
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const onFinish = (values) => {
    const { phone, full_name, email, date_of_birth, user_type } = values;
    const imageName = image?.name;

    const params = new FormData();
    params.append('phone', phone);
    params.append('full_name', full_name);
    params.append('email', email);
    params.append(
      'date_of_birth',
      formatDateTimeUtc(date_of_birth, DATE_FORMAT),
    );
    params.append('user_type', user_type);
    if (image) {
      params.append(
        'image',
        image?.status === IMAGE_STATUS.DONE
          ? null
          : JSON.stringify({
              ...image,
              name: imageName,
              url: `${IMAGE_RELATION_TYPE.ITEM_GROUP}/${id}/`,
            }),
      );
      if (image?.status !== IMAGE_STATUS.DONE)
        params.append('files[]', image?.originFileObj || null);
    }

    props.updateUser(params, id, (response) => {
      setMode(MODAL_MODE.DETAIL);
      setImage(null);
      getDetail();
      window.localStorage.setItem('user', JSON.stringify(response));
    });
  };

  const submitChangePassword = () => {
    form?.validateFields();
    const password = form.getFieldValue('password_old');
    const new_password = form.getFieldValue('new_password');
    if (!password || !new_password) return;

    const params = {
      user_id: +id,
      password,
      new_password,
    };

    props.changePassword(params, () => {
      setOpenChangePassword(false);
    });
  };

  const submitBankInfo = () => {
    form?.validateFields();
    const bank_owner_name = form.getFieldValue('bank_owner_name');
    const bank_account_number = form.getFieldValue('bank_account_number');
    const bank_name = form.getFieldValue('bank_name');
    if (!bank_owner_name || !bank_account_number || !bank_name) return;

    const isUpdate = bankInfo !== null;

    const params = isUpdate
      ? {
          id: bankInfo.id,
          data: {
            user_id: +id,
            bank_owner_name,
            bank_account_number,
            bank_name,
          },
        }
      : {
          user_id: +id,
          bank_owner_name,
          bank_account_number,
          bank_name,
        };

    if (!isUpdate)
      props.createUserBank(params, () => {
        setOpenBankModal(false);
        getDetail();
      });
    else
      props.updateUserBank(params, () => {
        setOpenBankModal(false);
        getDetail();
      });
  };

  const disabledDate = (current) => {
    return moment().isBefore(current);
  };

  const getDetail = () => {
    props.getUserDetail(id);
  };

  const onCancel = () => {
    getDetail();
  };

  const openChangePasswordModal = () => {
    setOpenChangePassword(true);
  };

  const closeChangePasswordModal = () => {
    form.resetFields(['password_old', 'new_password']);
    setOpenChangePassword(false);
  };

  const closeBankModal = () => {
    form.resetFields(['bank_account_number', 'bank_owner_name', 'bank_name']);
    setOpenBankModal(false);
  };

  const closeTransactionHistoryModal = () => {
    setOpenTransactionHistory(false);
  };

  const renderActionButtons = () => {
    switch (mode) {
      case MODAL_MODE.DETAIL:
        return (
          <div id="group-button-submit">
            {isAdmin && (
              <>
                <Button
                  onClick={() => setOpenBankModal(true)}
                  className="mini-shop-button"
                  type="primary"
                >
                  {t('user.bankInfo')}
                </Button>
              </>
            )}
            <Button
              onClick={() => setOpenTransactionHistory(true)}
              className="mini-shop-button"
              type="primary"
            >
              {t('user.transactionHistory')}
            </Button>
            <Button
              onClick={openChangePasswordModal}
              className="mini-shop-button"
              type="primary"
            >
              {t('user.changePassword')}
            </Button>
            <Button
              onClick={() => setMode(MODAL_MODE.UPDATE)}
              className="mini-shop-button"
              type="primary"
            >
              {t('user.edit')}
            </Button>
          </div>
        );
      case MODAL_MODE.UPDATE:
        return (
          <div id="group-button-submit">
            <Button onClick={() => setMode(MODAL_MODE.DETAIL)}>
              {t('common.close')}
            </Button>
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="user-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.save')}
            </Button>
          </div>
        );
      default:
        break;
    }
  };

  const handleChangeImage = ({ fileList: imageList }) => {
    if (image?.status === IMAGE_STATUS.REMOVED) {
      setImage(null);
    } else {
      setImage(imageList[0]);
    }
  };

  const handlePreviewDetail = () => {
    setPreviewVisible(true);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const isView = mode === MODAL_MODE.DETAIL;

  return (
    <div className="user-form">
      <Modal
        visible={openChangePassword}
        title={t('user.changePassword')}
        width={720}
        cancelText={t('common.close')}
        okText={t('common.confirm')}
        onCancel={closeChangePasswordModal}
        onOk={submitChangePassword}
      >
        <Card>
          <Form
            {...layout}
            name="change-password-form"
            id="change-password-form"
            className="required-mark-after"
            form={form}
          >
            <Form.Item label={t('user.phoneNumber')} name="phone">
              <Input disabled placeholder={t('user.placeholder.phoneNumber')} />
            </Form.Item>
            <Form.Item label={t('user.fullName')} name="full_name">
              <Input disabled placeholder={t('user.placeholder.fullName')} />
            </Form.Item>
            <Form.Item
              label={t('user.oldPassword')}
              name="password_old"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t('form.required'),
                },
                {
                  max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  message: t('form.maxLength', {
                    max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }),
                },
                // {
                //   min: TEXTFIELD_REQUIRED_LENGTH.LENGTH_8.MAX,
                //   message: t('form.minLength', {
                //     min: TEXTFIELD_REQUIRED_LENGTH.LENGTH_8.MAX,
                //   }),
                // },
              ]}
            >
              <Input.Password placeholder={t('user.placeholder.oldPassword')} />
            </Form.Item>
            <Form.Item
              label={t('user.newPassword')}
              name="new_password"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t('form.required'),
                },
                {
                  max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  message: t('form.maxLength', {
                    max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }),
                },
                // {
                //   min: TEXTFIELD_REQUIRED_LENGTH.LENGTH_8.MAX,
                //   message: t('form.minLength', {
                //     min: TEXTFIELD_REQUIRED_LENGTH.LENGTH_8.MAX,
                //   }),
                // },
                // {
                //   pattern: PATTERN.password,
                //   message: t('form.validPassword'),
                // },
              ]}
            >
              <Input.Password placeholder={t('user.placeholder.newPassword')} />
            </Form.Item>
          </Form>
        </Card>
      </Modal>
      {openTransactionHistory && (
        <Modal
          visible={openTransactionHistory}
          title={t('user.transactionHistory')}
          width={1000}
          bodyStyle={{ height: 580 }}
          cancelText={t('common.close')}
          onCancel={closeTransactionHistoryModal}
          footer={[
            <Button
              key="close"
              onClick={closeTransactionHistoryModal}
              className="close-button-custom"
            >
              {t('common.close')}
            </Button>,
          ]}
        >
          <CustomTable
            className="transaction-table"
            columns={transactionHistoryColumns}
            rows={transactionList}
            topInfo={false}
            noPagination={true}
            sticky={true}
          />
        </Modal>
      )}
      <Modal
        visible={openBankModal}
        title={t('user.bankInfo')}
        width={720}
        cancelText={t('common.close')}
        okText={t('common.confirm')}
        onCancel={closeBankModal}
        onOk={submitBankInfo}
      >
        <Card>
          <Form
            {...layout}
            name="bank-info-form"
            id="bank-info-form"
            className="required-mark-after"
            form={form}
          >
            <Form.Item
              label={t('user.bankOwnerName')}
              name="bank_owner_name"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t('form.required'),
                },
                {
                  max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  message: t('form.maxLength', {
                    max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }),
                },
              ]}
            >
              <Input placeholder={t('user.placeholder.bankOwnerName')} />
            </Form.Item>
            <Form.Item
              label={t('user.bankName')}
              name="bank_name"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t('form.required'),
                },
                {
                  max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  message: t('form.maxLength', {
                    max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }),
                },
              ]}
            >
              <Input placeholder={t('user.placeholder.bankName')} />
            </Form.Item>
            <Form.Item
              label={t('user.bankAccountNumber')}
              name="bank_account_number"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t('form.required'),
                },
                {
                  max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  message: t('form.maxLength', {
                    max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }),
                },
              ]}
            >
              <Input placeholder={t('user.placeholder.bankAccountNumber')} />
            </Form.Item>
          </Form>
        </Card>
      </Modal>
      <Spin spinning={user?.isLoading}>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={() => {
            setPreviewVisible(false);
          }}
        >
          <img
            alt="preview"
            style={{
              width: '100%',
            }}
            src={image?.url || previewImage}
          />
        </Modal>
        <TopContent showSearch={false} />
        <Card className="body-content">
          <Row>
            <div className="shop-avatar-container">
              {isView ? (
                image && (
                  <Avatar
                    size={150}
                    className="avatar"
                    icon={<img src={image?.url} alt="profile" />}
                    onClick={() => handlePreviewDetail()}
                  />
                )
              ) : (
                <div className="upload-container">
                  <Upload
                    action="/"
                    listType="picture-card"
                    fileList={image ? [image] : []}
                    beforeUpload={() => {
                      return false;
                    }}
                    maxCount={1}
                    onPreview={handlePreview}
                    onChange={handleChangeImage}
                  >
                    {image ? null : uploadButton}
                  </Upload>
                </div>
              )}
            </div>
          </Row>
          {isView && (
            <Row>
              <div className="full-name-container">
                {form.getFieldValue('full_name')}
              </div>
            </Row>
          )}
          <Form
            {...layout}
            name="user-form"
            id="user-form"
            className="required-mark-after"
            form={form}
            onFinish={onFinish}
          >
            <div className="block-1">
              <Row>
                <Col xs={24} sm={8} offset={3}>
                  <Form.Item
                    name="phone"
                    label={t('user.phoneNumber')}
                    rules={[
                      {
                        required: false,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.LENGTH_10.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.LENGTH_10.MAX,
                        }),
                      },
                      {
                        pattern: PATTERN.phone,
                        message: t('form.validPhone'),
                      },
                    ]}
                  >
                    <Input
                      disabled
                      placeholder={t('user.placeholder.phoneNumber')}
                    />
                  </Form.Item>
                  {!isView && (
                    <Form.Item
                      label={t('user.fullName')}
                      name="full_name"
                      rules={[
                        {
                          whitespace: true,
                          required: true,
                          message: t('form.required'),
                        },
                        {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                          message: t('form.maxLength', {
                            max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                          }),
                        },
                      ]}
                    >
                      <Input placeholder={t('user.placeholder.fullName')} />
                    </Form.Item>
                  )}
                  <Form.Item label={t('user.birthday')} name="date_of_birth">
                    {isView ? (
                      <>
                        <div className="ant-input-disabled">
                          {formatDateTimeUtc(
                            form.getFieldValue('date_of_birth'),
                            DATE_FORMAT,
                          )}
                        </div>
                      </>
                    ) : (
                      <DatePicker
                        disabledDate={disabledDate}
                        placeholder={t('user.placeholder.birthday')}
                      />
                    )}
                  </Form.Item>
                  {isView && (
                    <Form.Item name="coin" label={t('user.coin')}>
                      <Input
                        disabled
                        prefix={
                          <img
                            src={Coin}
                            width="15px"
                            height="15px"
                            alt="coin-icon"
                          />
                        }
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col xs={24} sm={10} offset={3}>
                  <Form.Item
                    label={t('user.email')}
                    name="email"
                    rules={[
                      {
                        whitespace: true,
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                      // {
                      //   pattern: PATTERN.vti_email,
                      //   message: t('form.validVtiEmail'),
                      // },
                      {
                        pattern: PATTERN.email,
                        message: t('form.validEmail'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView || isGoogleAccount}
                      placeholder={t('user.placeholder.email')}
                    />
                  </Form.Item>
                  <Form.Item
                    name={`user_type`}
                    label={t('user.userType')}
                    rules={[
                      {
                        required: false,
                        message: t('form.required'),
                      },
                    ]}
                  >
                    <div className="ant-input ant-input-disabled">
                      {t(
                        `userType.${
                          USER_TYPE.find((type) => type.value === userType)?.key
                        }`,
                      )}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
        <Card style={{ marginTop: '32px' }}>
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderActionButtons()}
              </div>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
}

const mapDispatchToProps = {
  updateUser,
  changePassword,
  getUserDetail,
  createUserBank,
  updateUserBank,
  searchAdminTransactions,
  searchUser,
  searchTransactions,
};

const mapStateToProps = (state) => ({
  user: state.user,
  transactionList: state.common.transactionList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ShopOwnerProfile),
);
