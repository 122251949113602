import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Row, Col, Card, Form, Button, Input, Spin } from 'antd';
import ArrowLeft from 'assets/images/arrow-left.svg';
import './index.scss';
import { PATTERN } from 'common/constant';
import { generateCode } from 'stores/user/user.action';

function ForgotPassword(props) {
  const { t, toggleChangePassword, setRecoverPhone, user } = props;

  const onSendVerificationMail = (values) => {
    const { phone } = values;

    props.generateCode(phone, () => {
      props.openVerifyEmail();
    });
  };

  return (
    <Spin spinning={user?.isLoading}>
      <Card className="login-card">
        <Row>
          <Col span={6}>
            <Button
              className="btn-back"
              onClick={() => toggleChangePassword(false)}
            >
              <img src={ArrowLeft} alt="setting" />
            </Button>
          </Col>
          <Col span={12}>
            <center>
              <h2 className="login-title">{t('auth.forgotPassword')}</h2>
            </center>
          </Col>
        </Row>
        <div className="login-card-content">
          <br />
          <div className="width-100">
            <Form
              onFinish={onSendVerificationMail}
              size="large"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 8 }}
            >
              <div className="pb-8">
                <label className="login-label">{t('auth.phoneNumber')}</label>
              </div>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t('form.required'),
                  },
                  {
                    pattern: PATTERN.phone,
                    message: t('form.validPhone'),
                  },
                ]}
              >
                <Input
                  name="phoneNumber"
                  onChange={(event) => setRecoverPhone(event.target.value)}
                  placeholder={t('auth.placeholder.phoneNumber')}
                  className="input"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="btn-login mini-shop-button"
                >
                  {t('auth.continue')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Card>
    </Spin>
  );
}

const mapDispatchToProps = {
  generateCode,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword),
);
