import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TopContent from 'components/common/top-content';
import CustomTable from 'components/common/custom-table';
import { Button, Modal, Spin } from 'antd';
import { EyeFilled, EditFilled, DeleteFilled } from '@ant-design/icons';
import ReactAntSwitch from 'components/common/switch';
import './index.scss';
import { redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import {
  searchConversionRate,
  updateConversionRate,
  deleteConversionRate,
} from 'stores/conversion-rate/conversion-rate.action';
import {
  CONVERSION_RATE_STATUS,
  CONVERSION_RATE_TYPE_MAP,
} from 'common/constant';
import { CheckSquareFilled } from '@ant-design/icons';

const breadcrumbs = [
  {
    route: '/conversion-rate',
    title: 'conversionRate',
  },
];

function ConversionRate(props) {
  const { t, conversionRate } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState(null);
  const [keyword, setKeyWord] = useState('');
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmId, setConfirmId] = useState(null);

  useEffect(() => {
    document.title = t('conversionRate.title');
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, page, pageSize, filter, sort]);

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('conversionRate.id'),
    },
    {
      key: 'currency_unit',
      dataIndex: 'currency_unit',
      filterable: true,
      title: t('conversionRate.currencyUnit'),
      render: (params) => {
        return params?.name;
      },
    },
    {
      key: 'price',
      dataIndex: 'price',
      title: t('conversionRate.price'),
    },
    {
      key: 'conversion_rates_type',
      dataIndex: 'conversion_rates_type',
      title: t('conversionRate.type'),
      render: (params) => {
        return t(CONVERSION_RATE_TYPE_MAP[params]);
      },
    },
    {
      key: 'action',
      dataIndex: 'id',
      title: t('common.action'),
      disableClickEventBubbling: true,
      sorter: false,
      align: 'center',
      headerAlign: 'center',
      render: (params, row) => {
        const { status } = row;
        const isConfirmed = status !== CONVERSION_RATE_STATUS.UNCONFIRM;
        return (
          <div className="action-button">
            <Button
              type="text"
              shape="circle"
              icon={<EyeFilled />}
              onClick={() => onClickViewDetails(params)}
            />
            <Button
              type="text"
              shape="circle"
              icon={<EditFilled />}
              onClick={() => onClickEdit(params)}
            />
            {!isConfirmed && (
              <Button
                type="text"
                shape="circle"
                icon={<DeleteFilled />}
                onClick={() => onClickDelete(params)}
              />
            )}
            {!isConfirmed && (
              <Button
                type="text"
                shape="circle"
                icon={<CheckSquareFilled style={{ color: 'green' }} />}
                onClick={() => onClickConfirm(params)}
              />
            )}
            {isConfirmed && (
              <ReactAntSwitch
                onClick={() =>
                  changeStatusConversionRate(
                    params,
                    status === CONVERSION_RATE_STATUS.ACTIVE
                      ? CONVERSION_RATE_STATUS.INACTIVE
                      : CONVERSION_RATE_STATUS.ACTIVE,
                  )
                }
                value={status === CONVERSION_RATE_STATUS.ACTIVE}
              />
            )}
          </div>
        );
      },
    },
  ];

  const changeStatusConversionRate = (id, status) => {
    props.updateConversionRate(
      {
        id,
        status,
      },
      () => {
        refreshData();
        onCancelConfirm();
      },
    );
  };

  const handleCreate = () => {
    redirectRouter(ROUTES.CONVERSION_RATE_CREATE.PATH);
  };

  const refreshData = () => {
    props.searchConversionRate({
      keyword: keyword.trim(),
      offset: (page - 1) * pageSize,
      limit: pageSize,
      filter: JSON.stringify(filter),
      sort,
    });
  };

  const handleSearch = () => {
    refreshData();
  };

  const updateKeyword = (value) => {
    setKeyWord(value);
  };

  const onPageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
  };

  const onPageChange = ({ page }) => {
    setPage(page);
  };

  const onChangeFilter = (filter) => {
    setFilter(filter);
    setPage(1);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSort({
      column: sorter.field,
      value: sorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  const onClickViewDetails = (id) => {
    redirectRouter(
      ROUTES.CONVERSION_RATE_DETAIL.PATH.replace('/:id', `/${id}`),
    );
  };

  const onClickEdit = (id) => {
    redirectRouter(
      ROUTES.CONVERSION_RATE_UPDATE.PATH.replace('/:id', `/${id}`),
    );
  };

  const onClickDelete = (id) => {
    setDeletingId(id);
    setOpenConfirmDeleteModal(true);
  };

  const onCancelDelete = () => {
    setDeletingId(null);
    setOpenConfirmDeleteModal(false);
  };

  const onSubmitDelete = () => {
    props.deleteConversionRate(deletingId, () => {
      onCancelDelete();
      refreshData();
    });
  };

  const onClickConfirm = (id) => {
    setConfirmId(id);
    setOpenConfirmModal(true);
  };

  const onCancelConfirm = () => {
    setConfirmId(null);
    setOpenConfirmModal(false);
  };

  const onSubmitConfirm = () => {
    changeStatusConversionRate(confirmId, CONVERSION_RATE_STATUS.ACTIVE);
  };

  return (
    <Spin spinning={conversionRate?.isLoading}>
      <TopContent
        title={t('conversionRate.title')}
        breadcrumbs={breadcrumbs}
        displayButton={['create']}
        createButton={t('conversionRate.createButton')}
        handleCreateOpenModal={handleCreate}
        updateKeyword={updateKeyword}
        handleSearch={handleSearch}
      />
      <Modal
        visible={openConfirmDeleteModal}
        title={t('conversionRate.deleteModalTitle')}
        size="sm"
        onOk={onSubmitDelete}
        onCancel={onCancelDelete}
        okText={t('common.delete')}
        cancelText={t('common.cancel')}
        hideCancel
        width={719}
        okButtonProps={{
          id: 'delete-button',
        }}
        cancelButtonProps={{
          className: 'cancel-button-custom',
        }}
      >
        {t('conversionRate.deleteConfirm')}
      </Modal>
      <Modal
        visible={openConfirmModal}
        title={t('conversionRate.confirmModalTitle')}
        size="sm"
        onOk={onSubmitConfirm}
        onCancel={onCancelConfirm}
        okText={t('common.confirm')}
        cancelText={t('common.cancel')}
        hideCancel
        width={719}
        okButtonProps={{
          id: 'delete-button',
        }}
        cancelButtonProps={{
          className: 'cancel-button-custom',
        }}
      >
        {t('conversionRate.confirmMessage')}
      </Modal>
      <CustomTable
        columns={columns}
        rows={conversionRate?.conversionRateList}
        page={page}
        pageSize={pageSize}
        total={conversionRate?.total}
        topInfo={true}
        onChange={handleTableChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onChangeFilter={onChangeFilter}
      />
    </Spin>
  );
}

const mapDispatchToProps = {
  searchConversionRate,
  updateConversionRate,
  deleteConversionRate,
};

const mapStateToProps = (state) => ({
  conversionRate: state.conversionRate,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConversionRate),
);
