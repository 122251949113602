import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// import { INVENTORY_PIE_CHART_COLORS, INVENTORY_STATUS } from 'common/constant';
import { Component } from 'react';
// import { Pie } from '@ant-design/charts';
import { Column } from '@ant-design/charts';
import { Card, Row } from 'antd';
import Title from '../title';
import { CHART_MAX_COLUMN_WIDTH, INVENTORY_REPORT_TYPE } from 'common/constant';

class InventoriesReport extends Component {
  constructor(props) {
    super(props);
  }

  renderChartTitle = () => {
    const { type, t } = this.props;

    if (type === INVENTORY_REPORT_TYPE.EXPIRED)
      return t('dashboard.inventoriesReport.title.expired');
    return t('dashboard.inventoriesReport.title.unexpired');
  };

  render() {
    const { t, inventoriesList, storeList, type } = this.props;
    const isExpiredType = type === INVENTORY_REPORT_TYPE.EXPIRED;
    const shopIds = storeList?.map((store) => store.id);
    const filteredInventoriesList = inventoriesList?.filter(
      (inventory) =>
        shopIds?.includes(inventory.shop.id) &&
        inventory.status === type &&
        parseInt(inventory?.quantity) !== 0,
    );

    var config = {
      data: filteredInventoriesList.map((inventory) => ({
        name: inventory.item.name + `(${inventory.id})`,
        [t('common.quantity')]: parseInt(inventory.quantity),
      })),
      isGroup: true,
      xField: 'name',
      yField: t('common.quantity'),
      label: {
        position: 'middle',
        layout: [],
      },
      color: isExpiredType ? 'red' : 'lightGreen',
      legend: {
        layout: 'horizontal',
        position: 'bottom',
      },
      scrollbar: {
        type: 'horizontal',
      },
      maxColumnWidth: CHART_MAX_COLUMN_WIDTH,
      // slider: {
      //   start: 0.1,
      //   end: 0.2,
      // },
      // height: 350,
      // width: 250,
      // appendPadding: 10,
      // data: [
      //   {
      //     name: t('dashboard.inventoriesReport.expired'),
      //     value: filteredInventoriesList.filter(
      //       (inventory) => inventory?.status === INVENTORY_STATUS.EXPIRED,
      //     ).length,
      //   },
      //   {
      //     name: t('dashboard.inventoriesReport.unexpired'),
      //     value: filteredInventoriesList.filter(
      //       (inventory) => inventory?.status === INVENTORY_STATUS.UNEXPIRED,
      //     ).length,
      //   },
      // ],
      // angleField: 'value',
      // colorField: 'name',
      // color: INVENTORY_PIE_CHART_COLORS,
      // radius: 1,
      // label: {
      //   type: 'outer',
      //   style: { fontSize: 14, textOverflow: 'unset' },
      // },
      // statistic: {
      //   content: {
      //     style: {
      //       fontSize: 22,
      //     },
      //   },
      // },
      // innerRadius: 0.6,
      // interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    };
    return (
      <Card style={{ paddingBottom: '5px' }}>
        <Row className="justify-content-between">
          <Title
            style={{ lineHeight: '40px' }}
            className="mb-0"
            title={this.renderChartTitle()}
          />
        </Row>
        <Column {...config} />
        {/* <Pie {...config} /> */}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  inventoriesList: state.common.inventoriesList,
  storeList: state.store.storeList,
});

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(InventoriesReport),
);
