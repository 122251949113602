export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'danger',
  INFO: 'info',
  WARNING: 'warning',
};

export const LAYOUT_TYPE = {
  FULL_VIEW: 'FULL_VIEW',
  WITH_SIDEBAR: 'WITH_SIDEBAR',
};

export const DEFAULT_USER_AVARTAR =
  'https://png.pngtree.com/png-clipart/20200224/original/pngtree-businessman-avatar-cartoon-style-png-image_5234654.jpg';

export const DEFAULT_LANGUAGE = 0;

export const DATE_COLUMN_TYPE = 'categorical';

export const MODAL_MODE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DETAIL: 'DETAIL',
};

export const USER_STATUS = {
  ACTIVE: 0,
  INACTIVE: 2,
};

export const USER_STATUS_MAP = {
  0: 'status.active',
  2: 'status.inactive',
};

export const STORE_STATUS = {
  UNCONFIRM: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const STORE_STATUS_MAP = {
  0: 'status.unconfirm',
  1: 'status.active',
  2: 'status.inactive',
};

export const ITEM_UNIT_STATUS = {
  UNCONFIRM: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const ITEM_UNIT_STATUS_MAP = {
  0: 'status.unconfirm',
  1: 'status.active',
  2: 'status.inactive',
};

export const CATEGORY_STATUS = {
  UNCONFIRM: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const CATEGORY_STATUS_MAP = {
  0: 'status.unconfirm',
  1: 'status.active',
  2: 'status.inactive',
};

export const ITEM_STATUS = {
  UNCONFIRM: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const ITEM_STATUS_MAP = {
  0: 'status.unconfirm',
  1: 'status.active',
  2: 'status.inactive',
};

export const IMPORT_ITEM_TICKET_STATUS = {
  UNCONFIRM: 0,
  ACTIVE: 1,
};

export const IMPORT_ITEM_TICKET_STATUS_MAP = {
  0: 'status.unconfirm',
  1: 'status.confirm',
};

export const IMPORT_ITEM_TICKET_STATUS_LIST = [
  {
    id: 0,
    text: 'status.unconfirm',
  },
  {
    id: 1,
    text: 'status.confirm',
  },
];

export const INVENTORY_STATUS = {
  UNEXPIRED: 0,
  EXPIRED: 1,
};

export const INVENTORY_STATUS_MAP = {
  0: 'status.inventory.unexpired',
  1: 'status.inventory.expired',
};

export const INVENTORY_STATUS_LIST = [
  {
    id: 0,
    text: 'status.inventory.unexpired',
  },
  {
    id: 1,
    text: 'status.inventory.expired',
  },
];

export const ORDER_STATUS = {
  UNCONFIRM: 0,
  CONFIRM: 1,
  REJECT: 2,
};

export const ORDER_STATUS_MAP = {
  0: 'dashboard.billReport.billCreated',
  1: 'dashboard.billReport.paid',
  2: 'status.reject',
};

export const ORDER_STATUS_LIST = [
  {
    id: 0,
    text: 'dashboard.billReport.billCreated',
  },
  {
    id: 1,
    text: 'dashboard.billReport.paid',
  },
  {
    id: 2,
    text: 'status.reject',
  },
];

export const CURRENCY_UNIT_STATUS = {
  UNCONFIRM: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const CURRENCY_UNIT_STATUS_MAP = {
  0: 'status.unconfirm',
  1: 'status.active',
  2: 'status.inactive',
};

export const CONVERSION_RATE_STATUS = {
  UNCONFIRM: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const CONVERSION_RATE_STATUS_MAP = {
  0: 'status.unconfirm',
  1: 'status.active',
  2: 'status.inactive',
};

export const PAYMENT_TYPE_STATUS = {
  UNCONFIRM: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const PAYMENT_TYPE_STATUS_MAP = {
  0: 'status.unconfirm',
  1: 'status.active',
  2: 'status.inactive',
};

export const INVENTORIES_CHECK_STATUS = {
  UNCONFIRM: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const INVENTORIES_CHECK_STATUS_MAP = {
  0: 'status.unconfirm',
  1: 'status.active',
  2: 'status.inactive',
};

export const TRANSACTION_STATUS = {
  UNCONFIRM: 0,
  CONFIRMED: 1,
  REJECTED: 2,
  COMPLETE: 3,
};

export const TRANSACTION_STATUS_MAP = {
  0: 'status.unconfirm',
  1: 'status.confirm',
  2: 'status.failed',
  3: 'status.complete',
};

export const TRANSACTION_STATUS_LIST = [
  {
    id: 0,
    text: 'status.unconfirm',
  },
  {
    id: 1,
    text: 'status.confirm',
  },
  {
    id: 2,
    text: 'status.failed',
  },
  {
    id: 3,
    text: 'status.complete',
  },
];

export const NOTIFICATION_SETTING_STATUS = {
  DISABLED: 0,
  ACTIVE: 1,
};

export const NOTIFICATION_STATUS = {
  UNREAD: 0,
  READ: 1,
};

export const PATTERN = {
  vietnamese_alpha_numeric: new RegExp(
    /^([a-zA-Z0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]+)$/,
  ),
  special_characters: new RegExp(/[`!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/),
  alpha_numeric: new RegExp(/^([a-zA-Z0-9]+)$/),
  number_only: new RegExp(/^([0-9]+)$/),
  phone: new RegExp(/^0[0-9]{9,13}$/),
  positive_number: new RegExp(
    /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
  ),
  non_negative_number: new RegExp(
    /^(0*[0-9]*(\.[0-9]*)?|0+\.[0-9]*[1-9][0-9]*)$/,
  ),
  negative_integer: new RegExp(
    /^(0*[0-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
  ),
  // regex vti mail
  vti_email: new RegExp(
    /^(([^<>()[\]\\.,;:\s@!~{#}?'"$%^&*+=-]+(\.[^<>()[\]\\.,;:\s@!~{#}?'"$%^&*+=-]+)*)|(".+"))@vti.com.vn$/,
  ),
  // regex mail
  email: new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ),
  password: new RegExp(
    // regex doesn't require normal character without min
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&+=,.~*()|/<>:;'"{}?-]).{3,}$/,
    // regex requires normal character with min
    // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&+=,.~*()]).{8,}$/,
  ),
};

export const TEXTFIELD_REQUIRED_LENGTH = {
  COMMON: {
    MAX: 255,
  },
  LENGTH_50: {
    MAX: 50,
  },
  LENGTH_20: {
    MAX: 20,
  },
  LENGTH_10: {
    MAX: 10,
  },
  LENGTH_8: {
    MAX: 8,
  },
  BARCODE: {
    MAX: 13,
  },
};

export const API_URL = {
  CATEGORY: 'shops/item_group_settings',
  ITEM: 'shops/items',
  ITEM_UNIT: 'shops/item_unit_settings',
  STORE: 'shops',
  USER: 'users',
  PAYMENT: 'payments',
  USER_BANK: 'users/user_banks',
  IMPORT_ITEM_TICKET: 'shops/import_orders',
  BARCODE: 'shops/item_barcodes',
  CURRENCY_UNIT: '/payments/currency_units',
  INVENTORIES: '/shops/inventories',
  ORDERS: '/payments/orders',
  CONVERSION_RATE: '/payments/conversion_rates',
  PAYMENT_TYPE: '/payments/payment_type_settings',
  INVENTORIES_CHECK: 'shops/inventories_check',
  TRANSACTIONS: '/payments/transactions',
  STORE_TRANSACTION: '/shops/transaction',
  NOTIFICATION: 'notifications',
  NOTIFICATION_SETTING: '/notifications/notification_settings',
};

export const USER_TYPE = [
  {
    key: 'admin',
    value: 0,
  },
  {
    key: 'user',
    value: 1,
  },
  {
    key: 'shopOwner',
    value: 2,
  },
  {
    key: 'googleUser',
    value: 3,
  },
  {
    key: 'googleShopOwner',
    value: 4,
  },
];

export const USER_TYPE_ENUM = {
  ADMIN: 0,
  USER: 1,
  SHOPOWNER: 2,
  GOOGLE_USER: 3,
  GOOGLE_SHOP_OWNER: 4,
};

export const QUANTITY_MAX_LENGTH = 8;

export const PRICE_MAX_LENGTH = 14;

export const ITEM_PRICE_LENGTH = 12;

export const DEFAULT_DATE_TIME_FORMAT = 'yyy-MM-dd hh:mm a';

export const DATE_FORMAT = 'dd-MM-yyyy';

export const PIE_CHART_COLORS = ['#FF8042', '#0761AD'];

export const INVENTORY_PIE_CHART_COLORS = ['red', 'green'];

export const IMAGE_STATUS = {
  DONE: 'done',
  REMOVED: 'removed',
};

export const IMAGE_URL_PREFIX =
  process.env.REACT_APP_HOST + '/api/v1/notifications/images/show/';

export const IMAGE_RELATION_TYPE = {
  ITEM: 1,
  SHOP: 2,
  USER: 3,
  ITEM_GROUP: 4,
};

export const CALCULATE_TYPE_LIST = [
  {
    label: 'user.calculateType.add',
    value: 0,
  },
  {
    label: 'user.calculateType.sub',
    value: 1,
  },
  {
    label: 'user.calculateType.mul',
    value: 2,
  },
  {
    label: 'user.calculateType.div',
    value: 3,
  },
];

export const CALCULATE_TYPE_ENUM = {
  ADD: 0,
  SUB: 1,
  MUL: 2,
  DIV: 3,
};

export const CONVERSION_RATE_TYPE = {
  COIN_TO_MONEY: 0,
  MONEY_TO_COIN: 1,
  COIN_TO_COIN: 2,
};

export const CONVERSION_RATE_TYPE_MAP = {
  0: 'conversionType.coinToMoney',
  1: 'conversionType.moneyToCoin',
  2: 'conversionType.coinToCoin',
};

export const TRANSACTION_TYPE = {
  USER: 0,
  ADMIN: 1,
  SHOP: 2,
};

export const TRANSACTION_TYPE_MAP = {
  0: 'transactionType.user',
  1: 'transactionType.admin',
  2: 'transactionType.shop',
};

export const DEFAULT_ADMIN_PAYMENT_TYPE_ID = 1;

export const DEFAULT_PASSWORD = 'Vtipassword123.';

export const NULL_STRING = 'null';

export const ADMIN_PHONE = process.env.REACT_APP_ADMIN_PHONE;

export const COOLDOWN_TIME = 60;

export const DESCRIPTION_ROWS = {
  MIN: 3,
  MAX: 255,
};

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const POP_UP_CLOSED_ERROR = 'popup_closed_by_user';

export const INVENTORY_REPORT_TYPE = {
  UNEXPIRED: 0,
  EXPIRED: 1,
};

export const CHART_MAX_COLUMN_WIDTH = 60;

export const DEFAULT_CURRENCY_UNIT_NAME = ['VND', 'VNĐ'];

export const ACCEPT_UPLOAD_TYPE = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const TIME_TYPE_LIST = [
  {
    id: 3,
    text: 'dashboard.paymentReport.year',
  },
  {
    id: 2,
    text: 'dashboard.paymentReport.month',
  },
  {
    id: 1,
    text: 'dashboard.paymentReport.week',
  },
  {
    id: 0,
    text: 'dashboard.paymentReport.day',
  },
];

export const TIME_TYPE_ENUM = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3,
};

export const MIN_YEAR = 2022;

export const MONTH_FORMAT = '"YYYY-MM"';

export const WEEK_LIST = ['1', '2', '3', '4'];

export const MONTH_LIST = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

export const ITEM_TYPE_ENUM = {
  REGULAR: 0,
  NEW: 1,
  POPULAR: 2,
};

export const ITEM_TYPE_MAP = [
  {
    value: 0,
    text: 'item.type.regular',
  },
  {
    value: 1,
    text: 'item.type.new',
  },
  {
    value: 2,
    text: 'item.type.popular',
  },
];

export const ORDER_RECEIVE_TYPE = {
  DIRECT_BUY: 0,
  SHIP: 1,
  PRE_ORDER: 2,
};

export const DEVICE_MIN_WIDTH = 576;

export const CAN_PRE_ORDER_ITEM = {
  TRUE: 1,
  FALSE: 0,
};

export const CAN_PRE_ORDER_ITEM_FILTER_LIST = [
  {
    value: 1,
    text: 'common.yes',
  },
  {
    value: 0,
    text: 'common.no',
  },
];

export const TRANSACTION_TABS_LIST = [
  {
    value: '0',
    text: 'transaction.normal'
  },
  {
    value: '1',
    text: 'transaction.preOrder'
  },
]

export const FOUR_WEEKS_DAYS = 28;
export const NORMAL_WEEKS = 4;