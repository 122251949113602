/**
 * Check user is logged in
 * @returns {boolean}
 */
 import Cookies from 'universal-cookie';
 const cookies = new Cookies();
 
 export const isAuth = () => {
   const cookieToken = cookies.get('token');
   const localToken = localStorage.getItem('token');
 
   const userId = cookies.get('userId');
   let isAuth = false;
   if (cookieToken) {
     localStorage.setItem('token', 'Bearer ' + cookieToken);
     localStorage.setItem('userId', userId);
     isAuth = true;
   } else if (localToken && !cookieToken) {
     localStorage.removeItem('token');
     localStorage.removeItem('refreshToken');
   }
 
   return isAuth;
 };
 