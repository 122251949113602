export const CREATE_CURRENCY_UNIT_START = 'CREATE_CURRENCY_UNIT_START';
export const CREATE_CURRENCY_UNIT_SUCCESS = 'CREATE_CURRENCY_UNIT_SUCCESS';
export const CREATE_CURRENCY_UNIT_FAILED = 'CREATE_CURRENCY_UNIT_FAILED';

export const UPDATE_CURRENCY_UNIT_START = 'UPDATE_CURRENCY_UNIT_START';
export const UPDATE_CURRENCY_UNIT_SUCCESS = 'UPDATE_CURRENCY_UNIT_SUCCESS';
export const UPDATE_CURRENCY_UNIT_FAILED = 'UPDATE_CURRENCY_UNIT_FAILED';

export const DELETE_CURRENCY_UNIT_START = 'DELETE_CURRENCY_UNIT_START';
export const DELETE_CURRENCY_UNIT_SUCCESS = 'DELETE_CURRENCY_UNIT_SUCCESS';
export const DELETE_CURRENCY_UNIT_FAILED = 'DELETE_CURRENCY_UNIT_FAILED';

export const SEARCH_CURRENCY_UNIT_START = 'SEARCH_CURRENCY_UNIT_START';
export const SEARCH_CURRENCY_UNIT_SUCCESS = 'SEARCH_CURRENCY_UNIT_SUCCESS';
export const SEARCH_CURRENCY_UNIT_FAILED = 'SEARCH_CURRENCY_UNIT_FAILED';

export const GET_CURRENCY_UNIT_DETAIL_START = 'GET_CURRENCY_UNIT_DETAIL_START';
export const GET_CURRENCY_UNIT_DETAIL_SUCCESS = 'GET_CURRENCY_UNIT_DETAIL_SUCCESS';
export const GET_CURRENCY_UNIT_DETAIL_FAILED = 'GET_CURRENCY_UNIT_DETAIL_FAILED';

/**
 * Create payment-type
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createCurrencyUnit(payload, onSuccess, onError) {
  return {
    type: CREATE_CURRENCY_UNIT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create payment-type success action
 * @param {*} payload
 * @returns {object}
 */
export function createCurrencyUnitSuccess(payload) {
  return {
    type: CREATE_CURRENCY_UNIT_SUCCESS,
    payload: payload,
  };
}

/**
 * Create payment-type failed action
 * @returns {object}
 */
export function createCurrencyUnitFailed() {
  return {
    type: CREATE_CURRENCY_UNIT_FAILED,
  };
}

/**
 * Update payment-type
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateCurrencyUnit(payload, onSuccess, onError) {
  return {
    type: UPDATE_CURRENCY_UNIT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update payment-type success action
 * @param {*} payload
 * @returns {object}
 */
export function updateCurrencyUnitSuccess(payload) {
  return {
    type: UPDATE_CURRENCY_UNIT_SUCCESS,
    payload: payload,
  };
}

/**
 * Update payment-type failed action
 * @returns {object}
 */
export function updateCurrencyUnitFailed() {
  return {
    type: UPDATE_CURRENCY_UNIT_FAILED,
  };
}

/**
 * Delete payment-type
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function deleteCurrencyUnit(payload, onSuccess, onError) {
  return {
    type: DELETE_CURRENCY_UNIT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Delete payment-type success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteCurrencyUnitSuccess(payload) {
  return {
    type: DELETE_CURRENCY_UNIT_SUCCESS,
    payload: payload,
  };
}

/**
 * Delete payment-type failed action
 * @returns {object}
 */
export function deleteCurrencyUnitFailed() {
  return {
    type: DELETE_CURRENCY_UNIT_FAILED,
  };
}

/**
 * Search payment-type
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function searchCurrencyUnit(payload, onSuccess, onError) {
  return {
    type: SEARCH_CURRENCY_UNIT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Search payment-type success action
 * @param {*} payload
 * @returns {object}
 */
export function searchCurrencyUnitSuccess(payload) {
  return {
    type: SEARCH_CURRENCY_UNIT_SUCCESS,
    payload: payload,
  };
}

/**
 * Search payment-type failed action
 * @returns {object}
 */
export function searchCurrencyUnitFailed() {
  return {
    type: SEARCH_CURRENCY_UNIT_FAILED,
  };
}

/**
 * Get payment-type detail
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function getCurrencyUnitDetail(payload, onSuccess, onError) {
  return {
    type: GET_CURRENCY_UNIT_DETAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Get payment-type detail success action
 * @param {*} payload
 * @returns {object}
 */
export function getCurrencyUnitDetailSuccess(payload) {
  return {
    type: GET_CURRENCY_UNIT_DETAIL_SUCCESS,
    payload: payload,
  };
}

/**
 * Get payment-type detail failed action
 * @returns {object}
 */
export function getCurrencyUnitDetailFailed() {
  return {
    type: GET_CURRENCY_UNIT_DETAIL_FAILED,
  };
}
