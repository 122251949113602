import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TopContent from 'components/common/top-content';
import CustomTable from 'components/common/custom-table';
import TransactionForm from 'containers/transaction-statistics/form';
import { Spin, Button, Modal, Tabs } from 'antd';
import './index.scss';
import {
  searchShopOwnerOrders,
  confirmOrder,
} from 'stores/common/common.action';
import { searchUser } from 'stores/user/user.action';
import { searchItem } from 'stores/item/item.action';
import {
  ORDER_RECEIVE_TYPE,
  ORDER_STATUS,
  ORDER_STATUS_LIST,
  ORDER_STATUS_MAP,
  TRANSACTION_TABS_LIST,
} from 'common/constant';
import { EyeFilled, CheckSquareFilled } from '@ant-design/icons';
import { formatDateTimeUtc } from 'utils';
import { useLocation } from 'react-router-dom';

const breadcrumbs = [
  {
    route: '/transaction-statistics',
    title: 'transactionStatistics',
  },
];

const { TabPane } = Tabs;

function TransactionStatistics(props) {
  const { t, common, userList, itemList } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState(null);
  const [openTransactionDetail, setOpenTransactionDetail] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [activeTab, setActiveTab] = useState('0');
  const search = useLocation().search;

  useEffect(() => {
    document.title = t('transaction.title');
    props.searchUser();
    props.searchItem({
      filter: JSON.stringify([
        {
          column: 'user_id',
          value: window.localStorage.getItem('userId'),
        },
      ]),
    });
    setOrderId(new URLSearchParams(search).get('id'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, page, pageSize, filter, sort, activeTab]);

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('transaction.id'),
    },
    {
      key: 'user_id',
      dataIndex: 'user_id',
      title: t('transaction.userName'),
      render: (params) => {
        return userList.find((user) => user.id === params)?.full_name;
      },
    },
    {
      key: 'total_price',
      dataIndex: 'total_price',
      title: t('transaction.price'),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('transaction.status'),
      filterable: true,
      type: 'categorical',
      filterOptions: {
        options: ORDER_STATUS_LIST,
        getOptionValue: (option) => option?.id,
        getOptionLabel: (option) => t(option?.text),
      },
      render: (params) => {
        return t(ORDER_STATUS_MAP[params]);
      },
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('transaction.createdAt'),
      render: (params) => {
        return formatDateTimeUtc(params);
      },
    },
    {
      key: 'receiving_address',
      dataIndex: 'receiving_address',
      title: t('transaction.receivingAddress'),
    },
    {
      key: 'note',
      dataIndex: 'note',
      title: t('transaction.note'),
    },
    {
      key: 'action',
      dataIndex: 'id',
      title: t('common.action'),
      disableClickEventBubbling: true,
      sorter: false,
      align: 'center',
      headerAlign: 'center',
      render: (params, row) => {
        const { receive_type, status } = row;
        return (
          <div className="action-button">
            <Button
              type="text"
              shape="circle"
              icon={<EyeFilled />}
              onClick={() => onClickViewDetails(params)}
            />
            {receive_type === ORDER_RECEIVE_TYPE.PRE_ORDER &&
              status === ORDER_STATUS.UNCONFIRM && (
                <Button
                  type="text"
                  shape="circle"
                  icon={<CheckSquareFilled style={{ color: 'green' }} />}
                  onClick={() => onClickConfirm(params)}
                />
              )}
          </div>
        );
      },
    },
  ];

  const preOrderQuantityColumns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('transaction.id'),
      width: '1vw',
    },
    {
      key: 'item_id',
      dataIndex: 'item_id',
      title: t('transaction.itemName'),
      render: (params) => {
        return itemList.find((item) => item.id === params)?.name;
      },
    },
    {
      key: 'quantity',
      dataIndex: 'quantity',
      align: 'center',
      title: t('transaction.preOrderQuantity'),
    },
    {
      key: 'actual_quantity',
      dataIndex: 'actual_quantity',
      align: 'center',
      title: t('transaction.inventoryQuantity'),
    },
    {
      key: 'needBuyQuantity',
      dataIndex: 'needBuyQuantity',
      align: 'center',
      title: t('transaction.needBuyQuantity'),
      render: (params, row) => {
        const { quantity, actual_quantity } = row;
        const needBuy = quantity - actual_quantity;
        return needBuy < 0 ? 0 : needBuy;
      },
    },
  ];

  const onClickConfirm = (id) => {
    props.confirmOrder(id, () => {
      refreshData();
    });
  };

  const onClickViewDetails = (id) => {
    setOpenTransactionDetail(true);
    setTransactionId(id);
  };

  const refreshData = () => {
    const params =
      activeTab === '0'
        ? {
            user_id: window.localStorage.getItem('userId'),
            offset: (page - 1) * pageSize,
            limit: pageSize,
            filter: JSON.stringify(filter),
            sort,
          }
        : {
            user_id: window.localStorage.getItem('userId'),
            filter: JSON.stringify(
              filter.concat([
                {
                  column: 'receive_type',
                  value: ORDER_RECEIVE_TYPE.PRE_ORDER,
                },
              ]),
            ),
            sort,
          };

    props.searchShopOwnerOrders(params);
  };

  const onPageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
  };

  const onPageChange = ({ page }) => {
    setPage(page);
  };

  const onChangeFilter = (filter) => {
    setFilter(filter);
    setPage(1);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSort({
      column: sorter.field,
      value: sorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  const onCloseDetailModal = () => {
    setTransactionId(null);
    setOpenTransactionDetail(false);
    refreshData();
  };

  useEffect(() => {
    if (orderId) onClickViewDetails(orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const handleChangeTab = (key) => {
    setActiveTab(key);
  };

  const isFirstTab = activeTab === '0';

  return (
    <Spin spinning={common?.isLoading}>
      <TopContent
        title={`${t('transaction.title')}`}
        breadcrumbs={breadcrumbs}
        showSearch={false}
      />
      <Tabs defaultActiveKey="0" onChange={(key) => handleChangeTab(key)}>
        {TRANSACTION_TABS_LIST.map((tab) => (
          <TabPane tab={t(tab.text)} key={tab.value}>
            <>
              {activeTab === '1' && (
                <CustomTable
                  className="pre-order-table"
                  title={t('transaction.preOrderItemQuantity')}
                  columns={preOrderQuantityColumns}
                  rows={common.orderList
                    .filter((order) => order.status === ORDER_STATUS.UNCONFIRM)
                    .reduce((a, b) => a.concat(b.order_details), [])
                    .reduce((a, b, index, array) => {
                      const item = a.find((i) => i.item_id === b.item_id);
                      if (!item)
                        a.push({
                          id: a.length,
                          item_id: b.item_id,
                          quantity: array
                            .filter((detail) => detail.item_id === b.item_id)
                            .reduce((c, d) => (c += d.quantity), 0),
                          actual_quantity: itemList.find(
                            (item) => item.id === b.item_id,
                          )?.inventory[0]?.quantity,
                        });

                      return a;
                    }, [])}
                  noPagination={true}
                />
              )}
              <br />
              <CustomTable
                className="pre-order-table"
                title={
                  isFirstTab
                    ? t('transaction.normal')
                    : t('transaction.preOrder')
                }
                columns={columns}
                rows={
                  isFirstTab
                    ? common.orderList.filter(
                        (order) =>
                          order.receive_type !== ORDER_RECEIVE_TYPE.PRE_ORDER,
                      )
                    : common.orderList.filter(
                        (order) =>
                          order.receive_type === ORDER_RECEIVE_TYPE.PRE_ORDER,
                      )
                }
                page={page}
                pageSize={pageSize}
                total={
                  common?.total -
                  (isFirstTab
                    ? common.orderList.filter(
                        (order) =>
                          order.receive_type === ORDER_RECEIVE_TYPE.PRE_ORDER,
                      )?.length
                    : common.orderList.filter(
                        (order) =>
                          order.receive_type !== ORDER_RECEIVE_TYPE.PRE_ORDER,
                      )?.length)
                }
                topInfo={true}
                onChange={handleTableChange}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                onChangeFilter={onChangeFilter}
                noPagination={!isFirstTab}
              />
            </>
          </TabPane>
        ))}
      </Tabs>
      {openTransactionDetail && (
        <Modal
          visible={openTransactionDetail}
          title={t('transaction.detailModalTitle')}
          onCancel={onCloseDetailModal}
          width={1000}
          footer={[
            <Button
              key="close"
              onClick={onCloseDetailModal}
              className="close-button-custom"
            >
              {t('common.close')}
            </Button>,
          ]}
        >
          <TransactionForm id={transactionId} />
        </Modal>
      )}
    </Spin>
  );
}

const mapDispatchToProps = {
  searchShopOwnerOrders,
  searchUser,
  confirmOrder,
  searchItem,
};

const mapStateToProps = (state) => ({
  common: state.common,
  userList: state.user.userList,
  itemList: state.item.itemList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TransactionStatistics),
);
