import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  generateCodeFailed,
  generateCodeSuccess,
  GENERATE_CODE_START,
} from 'stores/user/user.action';

/**
 * Generate code API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const generateCodeApi = (params) => {
  const uri = `/${API_URL.USER}/generate_otp/${params}`;
  return api.get(uri);
};

/**
 * Handle generate code request and response
 * @param {object} action
 */
function* doGenerateCode(action) {
  try {
    const response = yield call(generateCodeApi, action?.payload);

    if (response?.status === 200) {
      yield put(generateCodeSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(generateCodeFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch change password
 */
export default function* watchGenerateCode() {
  yield takeLatest(GENERATE_CODE_START, doGenerateCode);
}
