import { API_URL, NOTIFICATION_TYPE } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  getCurrencyUnitFailed,
  getCurrencyUnitSuccess,
  GET_CURRENCY_UNIT_START,
} from 'stores/common/common.action';

/**
 * Search item API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getCurrencyUnitApi = (params) => {
  const uri = API_URL.CURRENCY_UNIT;
  return api.get(uri, params);
};

/**
 * Handle update request and response
 * @param {object} action
 */
function* doGetCurrencyUnit(action) {
  try {
    const response = yield call(getCurrencyUnitApi, action?.payload);

    if (response?.status === 200) {
      const payload = response.data.items;
      yield put(getCurrencyUnitSuccess(payload));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(getCurrencyUnitFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch search item
 */
export default function* watchGetCurrencyUnit() {
  yield takeLatest(GET_CURRENCY_UNIT_START, doGetCurrencyUnit);
}
