import React from 'react';
import Dashboard from 'containers/dashboard';
import Login from 'containers/login';
import Item from 'containers/item';
import Store from 'containers/store';
import User from 'containers/user';
import Category from 'containers/category';
import ItemUnit from 'containers/item-unit';
import ImportItemTicket from 'containers/import-item-ticket';
import BarCode from 'containers/barcode';
import InventoriesStatistics from 'containers/inventories-statistics';
import OutdatedStatistics from 'containers/outdated-statistics';
import TransactionStatistics from 'containers/transaction-statistics';
import CurrencyUnit from 'containers/currency-unit';
import ConversionRate from 'containers/conversion-rate';
import PaymentType from 'containers/payment-type';
import ShopOwnerProfile from 'containers/shop-owner-profile';
import InventoriesCheck from 'containers/inventories-check';
import WithdrawCoin from 'containers/withdraw-coin';
import UserForm from 'containers/user/form';
import ItemUnitForm from 'containers/item-unit/form';
import StoreForm from 'containers/store/form';
import ItemForm from 'containers/item/form';
import CategoryForm from 'containers/category/form';
import ImportItemTicketForm from 'containers/import-item-ticket/form';
import BarcodeForm from 'containers/barcode/form';
import CurrencyUnitForm from 'containers/currency-unit/form';
import ConversionRateForm from 'containers/conversion-rate/form';
import PaymentTypeForm from 'containers/payment-type/form';
import InventoriesCheckForm from 'containers/inventories-check/form';
import ErrorPage from 'containers/internal-server-error';
import {
  ShopOutlined,
  UserOutlined,
  AppstoreOutlined,
  HomeOutlined,
  CodeSandboxOutlined,
  DeploymentUnitOutlined,
  BarcodeOutlined,
  BankOutlined,
  FileSearchOutlined,
  ExceptionOutlined,
  LineChartOutlined,
  DollarCircleOutlined,
  TransactionOutlined,
  CreditCardFilled,
  UserAddOutlined,
  FileProtectOutlined,
  WalletOutlined,
} from '@ant-design/icons';

// define routes
const ROUTES = {
  DASHBOARD: {
    PATH: '/',
    TITLE: 'dashboard',
  },
  LOGIN: {
    PATH: '/login',
    TITLE: 'login',
  },
  CATEGORY: {
    PATH: '/category',
    TITLE: 'category',
  },
  USER: {
    PATH: '/user',
    TITLE: 'user',
  },
  STORE: {
    PATH: '/store',
    TITLE: 'store',
  },
  ITEM: {
    PATH: '/item',
    TITLE: 'item',
  },
  ITEM_UNIT: {
    PATH: '/item-unit',
    TITLE: 'itemUnit',
  },
  IMPORT_ITEM_TICKET: {
    PATH: '/import-item-ticket',
    TITLE: 'importItemTicket',
  },
  BARCODE: {
    PATH: '/barcode',
    TITLE: 'barcode',
  },
  BARCODE_CREATE: {
    PATH: '/barcode/create',
    TITLE: 'createBarcode',
  },
  INVENTORIES_STATISTICS: {
    PATH: '/inventories-statistics',
    TITLE: 'inventoriesStatistics',
  },
  OUTDATED_STATISTICS: {
    PATH: '/outdated-statistics',
    TITLE: 'outdatedStatistics',
  },
  TRANSACTION_STATISTICS: {
    PATH: '/transaction-statistics',
    TITLE: 'transactionStatistics',
  },
  CURRENCY_UNIT: {
    PATH: '/currency-unit',
    TITLE: 'currencyUnit',
  },
  CONVERSION_RATE: {
    PATH: '/conversion-rate',
    TITLE: 'conversionRate',
  },
  PAYMENT_TYPE: {
    PATH: '/payment-type',
    TITLE: 'paymentType',
  },
  SHOP_OWNER_PROFILE: {
    PATH: '/profile',
    TITLE: 'shopOwnerProfile',
  },
  ERROR_PAGE: {
    PATH: '/internal-server-error',
    TITLE: 'internalServerError',
  },
  INVENTORIES_CHECK: {
    PATH: '/inventories-check',
    TITLE: 'inventoriesCheck',
  },
  WITHDRAW_COIN: {
    PATH: '/withdraw-coin',
    TITLE: 'withdrawCoin',
  },
};

// add create, detail and update routes
const keys = Object.keys(ROUTES);
keys.forEach((key) => {
  if (
    [
      'CATEGORY',
      'USER',
      'STORE',
      'ITEM',
      'ITEM_UNIT',
      'IMPORT_ITEM_TICKET',
      'CURRENCY_UNIT',
      'CONVERSION_RATE',
      'PAYMENT_TYPE',
      'INVENTORIES_CHECK',
    ].includes(key)
  ) {
    ['/create', '/detail/:id', '/update/:id'].forEach((route) => {
      const newPath = ROUTES[key].PATH + route;
      const title =
        ROUTES[key].TITLE.charAt(0).toUpperCase() + ROUTES[key].TITLE.slice(1);
      let newTitle = 'detail' + title;
      let newKey = key + '_DETAIL';
      if (route === '/update/:id') {
        newTitle = 'edit' + title;
        newKey = key + '_UPDATE';
      } else if (route === '/create') {
        newTitle = 'create' + title;
        newKey = key + '_CREATE';
      }
      Object.assign(ROUTES, {
        [newKey]: {
          PATH: newPath,
          TITLE: newTitle,
        },
      });
    });
  }
});

export { ROUTES };

let routes = [];
routes = [
  {
    path: ROUTES.DASHBOARD.PATH,
    name: ROUTES.DASHBOARD.TITLE,
    isInSidebar: true,
    icon: <HomeOutlined />,
    component: Dashboard,
  },
  {
    path: ROUTES.LOGIN.PATH,
    name: ROUTES.LOGIN.TITLE,
    isInSidebar: false,
    icon: '',
    component: Login,
  },
  {
    path: ROUTES.SHOP_OWNER_PROFILE.PATH,
    name: ROUTES.SHOP_OWNER_PROFILE.TITLE,
    isInSidebar: true,
    icon: <UserOutlined />,
    component: ShopOwnerProfile,
  },
  {
    path: ROUTES.USER.PATH,
    name: ROUTES.USER.TITLE,
    isInSidebar: true,
    icon: <UserAddOutlined />,
    component: User,
    key: 'USER',
    adminOnly: true,
  },
  {
    path: ROUTES.STORE.PATH,
    name: ROUTES.STORE.TITLE,
    isInSidebar: true,
    icon: <ShopOutlined />,
    component: Store,
    key: 'STORE',
  },
  {
    path: ROUTES.CATEGORY.PATH,
    name: ROUTES.CATEGORY.TITLE,
    isInSidebar: true,
    icon: <CodeSandboxOutlined />,
    component: Category,
    key: 'CATEGORY',
    adminOnly: true,
  },
  {
    path: ROUTES.ITEM.PATH,
    name: ROUTES.ITEM.TITLE,
    isInSidebar: true,
    icon: <AppstoreOutlined />,
    component: Item,
    key: 'ITEM',
    shopOwnerOnly: true,
  },
  {
    path: ROUTES.ITEM_UNIT.PATH,
    name: ROUTES.ITEM_UNIT.TITLE,
    isInSidebar: true,
    icon: <DeploymentUnitOutlined />,
    component: ItemUnit,
    key: 'ITEM_UNIT',
    adminOnly: true,
  },
  {
    path: ROUTES.IMPORT_ITEM_TICKET.PATH,
    name: ROUTES.IMPORT_ITEM_TICKET.TITLE,
    isInSidebar: true,
    icon: <BankOutlined />,
    component: ImportItemTicket,
    key: 'IMPORT_ITEM_TICKET',
    shopOwnerOnly: true,
  },
  {
    path: ROUTES.BARCODE.PATH,
    name: ROUTES.BARCODE.TITLE,
    isInSidebar: true,
    icon: <BarcodeOutlined />,
    component: BarCode,
    key: 'BARCODE',
    shopOwnerOnly: true,
  },
  {
    path: ROUTES.BARCODE_CREATE.PATH,
    name: ROUTES.BARCODE_CREATE.TITLE,
    isInSidebar: false,
    icon: '',
    component: BarcodeForm,
    shopOwnerOnly: true,
  },
  {
    path: ROUTES.INVENTORIES_STATISTICS.PATH,
    name: ROUTES.INVENTORIES_STATISTICS.TITLE,
    isInSidebar: true,
    icon: <FileSearchOutlined />,
    component: InventoriesStatistics,
    shopOwnerOnly: true,
  },
  {
    path: ROUTES.OUTDATED_STATISTICS.PATH,
    name: ROUTES.OUTDATED_STATISTICS.TITLE,
    isInSidebar: true,
    icon: <ExceptionOutlined />,
    component: OutdatedStatistics,
    shopOwnerOnly: true,
  },
  {
    path: ROUTES.TRANSACTION_STATISTICS.PATH,
    name: ROUTES.TRANSACTION_STATISTICS.TITLE,
    isInSidebar: true,
    icon: <LineChartOutlined />,
    component: TransactionStatistics,
    shopOwnerOnly: true,
  },
  {
    path: ROUTES.CURRENCY_UNIT.PATH,
    name: ROUTES.CURRENCY_UNIT.TITLE,
    isInSidebar: true,
    icon: <DollarCircleOutlined />,
    component: CurrencyUnit,
    key: 'CURRENCY_UNIT',
    adminOnly: true,
  },
  {
    path: ROUTES.CONVERSION_RATE.PATH,
    name: ROUTES.CONVERSION_RATE.TITLE,
    isInSidebar: true,
    icon: <TransactionOutlined />,
    component: ConversionRate,
    key: 'CONVERSION_RATE',
    adminOnly: true,
  },
  {
    path: ROUTES.PAYMENT_TYPE.PATH,
    name: ROUTES.PAYMENT_TYPE.TITLE,
    isInSidebar: true,
    icon: <CreditCardFilled />,
    component: PaymentType,
    key: 'PAYMENT_TYPE',
    adminOnly: true,
  },
  {
    path: ROUTES.ERROR_PAGE.PATH,
    name: ROUTES.ERROR_PAGE.TITLE,
    isInSidebar: false,
    component: ErrorPage,
    key: 'ERROR_PAGE',
  },
  {
    path: ROUTES.INVENTORIES_CHECK.PATH,
    name: ROUTES.INVENTORIES_CHECK.TITLE,
    isInSidebar: true,
    icon: <FileProtectOutlined />,
    component: InventoriesCheck,
    key: 'INVENTORIES_CHECK',
    shopOwnerOnly: true,
  },
  {
    path: ROUTES.WITHDRAW_COIN.PATH,
    name: ROUTES.WITHDRAW_COIN.TITLE,
    isInSidebar: true,
    component: WithdrawCoin,
    icon: <WalletOutlined />,
    adminOnly: true,
  },
];

const formComponents = {
  USER: UserForm,
  ITEM_UNIT: ItemUnitForm,
  CATEGORY: CategoryForm,
  ITEM: ItemForm,
  STORE: StoreForm,
  IMPORT_ITEM_TICKET: ImportItemTicketForm,
  CURRENCY_UNIT: CurrencyUnitForm,
  CONVERSION_RATE: ConversionRateForm,
  PAYMENT_TYPE: PaymentTypeForm,
  INVENTORIES_CHECK: InventoriesCheckForm,
};

// add routes subMenu
routes.forEach((route) => {
  if (
    [
      ROUTES.USER.PATH,
      ROUTES.ITEM_UNIT.PATH,
      ROUTES.CATEGORY.PATH,
      ROUTES.ITEM.PATH,
      ROUTES.STORE.PATH,
      ROUTES.IMPORT_ITEM_TICKET.PATH,
      ROUTES.CURRENCY_UNIT.PATH,
      ROUTES.CONVERSION_RATE.PATH,
      ROUTES.PAYMENT_TYPE.PATH,
      ROUTES.INVENTORIES_CHECK.PATH,
    ].includes(route.path)
  ) {
    ['_DETAIL', '_UPDATE', '_CREATE'].forEach((key) => {
      const formKey = route.key + key;
      routes.push({
        path: ROUTES[formKey].PATH,
        pathActive:
          key !== '_CREATE'
            ? ROUTES[formKey].PATH.substring(0, ROUTES[formKey].PATH.length - 4)
            : '',
        name: ROUTES[formKey].TITLE,
        isInSidebar: false,
        icon: '',
        component: formComponents[route.key],
      });
    });
  }
});

export default routes;
