export const CREATE_ITEM_START = 'CREATE_ITEM_START';
export const CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS';
export const CREATE_ITEM_FAILED = 'CREATE_ITEM_FAILED';

export const UPDATE_ITEM_START = 'UPDATE_ITEM_START';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAILED = 'UPDATE_ITEM_FAILED';

export const DELETE_ITEM_START = 'DELETE_ITEM_START';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILED = 'DELETE_ITEM_FAILED';

export const SEARCH_ITEM_START = 'SEARCH_ITEM_START';
export const SEARCH_ITEM_SUCCESS = 'SEARCH_ITEM_SUCCESS';
export const SEARCH_ITEM_FAILED = 'SEARCH_ITEM_FAILED';

export const GET_ITEM_DETAIL_START = 'GET_ITEM_DETAIL_START';
export const GET_ITEM_DETAIL_SUCCESS = 'GET_ITEM_DETAIL_SUCCESS';
export const GET_ITEM_DETAIL_FAILED = 'GET_ITEM_DETAIL_FAILED';

export const IMPORT_ITEM_START = 'IMPORT_ITEM_START'
export const IMPORT_ITEM_SUCCESS = 'IMPORT_ITEM_SUCCESS'
export const IMPORT_ITEM_FAILED = 'IMPORT_ITEM_FAILED'

/**
 * Create item
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createItem(payload, onSuccess, onError) {
  return {
    type: CREATE_ITEM_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create item success action
 * @param {*} payload
 * @returns {object}
 */
export function createItemSuccess(payload) {
  return {
    type: CREATE_ITEM_SUCCESS,
    payload: payload,
  };
}

/**
 * Create item failed action
 * @returns {object}
 */
export function createItemFailed() {
  return {
    type: CREATE_ITEM_FAILED,
  };
}

/**
 * Update item
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateItem(payload, id, onSuccess, onError) {
  return {
    type: UPDATE_ITEM_START,
    payload: {
      data: payload,
      id
    },
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update item success action
 * @param {*} payload
 * @returns {object}
 */
export function updateItemSuccess(payload) {
  return {
    type: UPDATE_ITEM_SUCCESS,
    payload: payload,
  };
}

/**
 * Update item failed action
 * @returns {object}
 */
export function updateItemFailed() {
  return {
    type: UPDATE_ITEM_FAILED,
  };
}

/**
 * Delete item
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function deleteItem(payload, onSuccess, onError) {
  return {
    type: DELETE_ITEM_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Delete item success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteItemSuccess(payload) {
  return {
    type: DELETE_ITEM_SUCCESS,
    payload: payload,
  };
}

/**
 * Delete item failed action
 * @returns {object}
 */
export function deleteItemFailed() {
  return {
    type: DELETE_ITEM_FAILED,
  };
}

/**
 * Search item
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function searchItem(payload, onSuccess, onError) {
  return {
    type: SEARCH_ITEM_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Search item success action
 * @param {*} payload
 * @returns {object}
 */
export function searchItemSuccess(payload) {
  return {
    type: SEARCH_ITEM_SUCCESS,
    payload: payload,
  };
}

/**
 * Search item failed action
 * @returns {object}
 */
export function searchItemFailed() {
  return {
    type: SEARCH_ITEM_FAILED,
  };
}

/**
 * Get item detail
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function getItemDetail(payload, onSuccess, onError) {
  return {
    type: GET_ITEM_DETAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Get item detail success action
 * @param {*} payload
 * @returns {object}
 */
export function getItemDetailSuccess(payload) {
  return {
    type: GET_ITEM_DETAIL_SUCCESS,
    payload: payload,
  };
}

/**
 * Get item detail failed action
 * @returns {object}
 */
export function getItemDetailFailed() {
  return {
    type: GET_ITEM_DETAIL_FAILED,
  };
}

/**
 * Import item
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function importItem(payload, onSuccess, onError) {
  return {
    type: IMPORT_ITEM_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Import item success action
 * @param {*} payload
 * @returns {object}
 */
export function importItemSuccess(payload) {
  return {
    type: IMPORT_ITEM_SUCCESS,
    payload: payload,
  };
}

/**
 * Import item failed action
 * @returns {object}
 */
export function importItemFailed() {
  return {
    type: IMPORT_ITEM_FAILED,
  };
}
