import {
  CREATE_USER_START,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  SEARCH_USER_START,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILED,
  GET_USER_DETAIL_START,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAILED,
  CHANGE_PASSWORD_ADMIN_START,
  CHANGE_PASSWORD_ADMIN_SUCCESS,
  CHANGE_PASSWORD_ADMIN_FAILED,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  TRANSFER_COIN_START,
  TRANSFER_COIN_SUCCESS,
  TRANSFER_COIN_FAILED,
  FORGET_PASSWORD_START,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  GENERATE_CODE_START,
  GENERATE_CODE_SUCCESS,
  GENERATE_CODE_FAILED,
  VERIFY_CODE_START,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_FAILED,
  CREATE_USER_BANK_START,
  CREATE_USER_BANK_SUCCESS,
  CREATE_USER_BANK_FAILED,
  UPDATE_USER_BANK_START,
  UPDATE_USER_BANK_SUCCESS,
  UPDATE_USER_BANK_FAILED,
  CREATE_DEVICE_TOKEN_START,
  CREATE_DEVICE_TOKEN_SUCCESS,
  CREATE_DEVICE_TOKEN_FAILED,
} from './user.action';

const initialState = {
  isLoading: false,
  userList: [],
  userDetail: {},
  total: 0,
  recoverEmail: '',
};

/**
 * user reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function user(state = initialState, action) {
  switch (action.type) {
    case CREATE_USER_START:
    case UPDATE_USER_START:
    case DELETE_USER_START:
    case GET_USER_DETAIL_START:
    case CHANGE_PASSWORD_ADMIN_START:
    case CHANGE_PASSWORD_START:
    case TRANSFER_COIN_START:
    case FORGET_PASSWORD_START:
    case VERIFY_CODE_START:
    case CREATE_USER_BANK_START:
    case UPDATE_USER_BANK_START:
    case CREATE_DEVICE_TOKEN_START:
      return {
        ...state,
        isLoading: true,
      };
    case GENERATE_CODE_START:
      return {
        ...state,
        isLoading: true,
        recoverMail: '',
      };
    case SEARCH_USER_START:
      return {
        ...state,
        userDetail: {},
        isLoading: true,
      };
    case CREATE_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
    case DELETE_USER_SUCCESS:
    case CHANGE_PASSWORD_ADMIN_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
    case TRANSFER_COIN_SUCCESS:
    case FORGET_PASSWORD_SUCCESS:
    case VERIFY_CODE_SUCCESS:
    case CREATE_USER_BANK_SUCCESS:
    case UPDATE_USER_BANK_SUCCESS:
    case CREATE_DEVICE_TOKEN_SUCCESS:
    case CREATE_USER_BANK_FAILED:
    case UPDATE_USER_BANK_FAILED:
    case CREATE_USER_FAILED:
    case UPDATE_USER_FAILED:
    case DELETE_USER_FAILED:
    case CHANGE_PASSWORD_ADMIN_FAILED:
    case CHANGE_PASSWORD_FAILED:
    case TRANSFER_COIN_FAILED:
    case FORGET_PASSWORD_FAILED:
    case VERIFY_CODE_FAILED:
    case CREATE_DEVICE_TOKEN_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_USER_SUCCESS:
      return {
        ...state,
        userList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case SEARCH_USER_FAILED:
      return {
        ...state,
        userList: [],
        isLoading: false,
      };
    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
        isLoading: false,
      };
    case GET_USER_DETAIL_FAILED:
      return {
        ...state,
        userDetail: {},
        isLoading: false,
      };
    case GENERATE_CODE_SUCCESS:
      return {
        ...state,
        recoverEmail: action.payload.email,
        isLoading: false,
      };
    case GENERATE_CODE_FAILED:
      return {
        ...state,
        recoverEmail: '',
        isLoading: false,
      };
    default:
      return state;
  }
}
