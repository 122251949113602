import { USER_TYPE_ENUM } from 'common/constant';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  loginFailed,
  loginSuccess,
  LOGIN_START,
} from 'stores/auth/auth.action';
import i18n from 'common/i18n';
import { createToken } from 'firebase-config';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
/**
 * Login
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const loginApi = (params) => {
  const uri = `/users/login`;
  return api.post(uri, params);
};

/**
 * Handle login request and response
 * @param {object} action
 */
function* doLogin(action) {
  try {
    const response = yield call(loginApi, action?.payload);
    if (response?.status === 200) {
      const { data } = response;

      if (
        [USER_TYPE_ENUM.USER, USER_TYPE_ENUM.GOOGLE_USER].includes(
          data.user.user_type,
        )
      )
        throw new Error(i18n.t('auth.notPermitted'));
      // Save token info to cookies
      cookies.set('token', data.access_token);
      cookies.set('refreshToken', data.verify_token);
      cookies.set('userId', data?.user?.id);

      // Save token to local storage
      localStorage.setItem('token', 'Bearer ' + data.access_token);
      // Save refresh to ken to local storage
      localStorage.setItem('refreshToken', data.verify_token);
      // Save user infomation to local storage
      localStorage.setItem('user', JSON.stringify(data?.user));
      localStorage.setItem('userId', data?.user?.id);
      localStorage.setItem('userImage', data?.user?.image);

      if (action.payload.user_type) localStorage.setItem('googleLogin', 'true');
      yield put(loginSuccess(response.data.user));

      createToken(data?.user?.id);
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }
    } else {
      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(loginFailed(error?.message));
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch login
 */
export default function* watchLogin() {
  yield takeLatest(LOGIN_START, doLogin);
}
