import React from 'react';
import Sidebar from 'components/layout-router/sidebar';
import { Layout } from 'antd';
import './style.scss';

const LayoutWithSidebar = ({ children }) => {
  return (
    <Layout>
      <Sidebar />
      <Layout style={{ padding: 24 }}>
        {children}
      </Layout>
    </Layout>
  );
};

export default LayoutWithSidebar;
