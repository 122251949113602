import React from 'react';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './style.scss';

const Breadcrumb = (props) => {
  const { className, breadcrumbs = [], ...restProps } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const handleClick = (event, route) => {
    event.preventDefault();
    history.push(route);
  };
  return breadcrumbs.length ? (
    <div className={classNames('research-breadcrumb-wrapper', className)}>
      <AntdBreadcrumb
        separator="/"
        className="research-breadcrumb"
        {...restProps}
      >
        <AntdBreadcrumb.Item
          {...restProps}
          onClick={(event) => handleClick(event, '/')}
          className="mini-shop-breadcrumb"
        >
          <HomeOutlined /> <span>Mini Shop</span>
        </AntdBreadcrumb.Item>
        {breadcrumbs.map((breadcrumb, index) => {
          const { ...restProps } = breadcrumb;
          return (
            <AntdBreadcrumb.Item
              {...restProps}
              key={index}
              onClick={(event) => handleClick(event, breadcrumb.route)}
              className="mini-shop-breadcrumb"
            >
              {t(`menu.${breadcrumb.title}`)}
            </AntdBreadcrumb.Item>
          );
        })}
      </AntdBreadcrumb>
    </div>
  ) : null;
};

export default Breadcrumb;
