import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Menu, Dropdown, Modal, Badge, Popover, Spin } from 'antd';
import { NOTIFICATION_STATUS, NOTIFICATION_TYPE } from 'common/constant';
import addNotification from 'common/toast';
import { ReactComponent as More } from 'assets/images/more.svg';
import axios from 'axios';
import './index.scss';
import { useState } from 'react';
import BellImage from 'assets/images/notification.svg';
import {
  updateAllNotificationStatus,
  getNotificationSettingsList,
  getNotificationList,
} from 'stores/notification/notification.action';
import NotificationList from './notification-list';
import NotificationSettings from './notification-settings';
const SOCKET_DOMAIN = process.env.REACT_APP_SOCKET_DOMAIN;

function Notification(props) {
  const { t, notification } = props;
  const [openReadAllModal, setOpenReadAllModal] = useState(false);
  const [showNotiPopover, setShowNotiPopover] = useState(false);
  const [showNotifications, setShowNotifications] = useState(true);
  const [showNotificationSetting, setShowNotificationSetting] = useState(false);
  const [refreshSettings, setRefreshSettings] = useState(false);
  const [changeList, setChangeList] = useState([]);

  useEffect(() => {
    props.getNotificationSettingsList();
    props.getNotificationList(window.localStorage.getItem('userId'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSettings]);

  const handleShowMarkAllPopup = () => {
    props.updateAllNotificationStatus({}, () => {
      setRefreshSettings(!refreshSettings);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleReadAllNotification = () => {
    axios
      .put(`${SOCKET_DOMAIN}/api/v1/notifications/notification-users/seen/all`)
      .then(() => {
        this.setState({
          isReadAll: true,
          notices: [],
          isOpenReadAllModal: false,
        });
      })
      .catch(function (error) {
        addNotification(error, NOTIFICATION_TYPE.ERROR);
      });
  };

  const onCancelReadAllModal = () => {
    setOpenReadAllModal(false);
  };

  const handleShowNotificationSetting = () => {
    setShowNotificationSetting(!showNotificationSetting);
    setShowNotifications(!showNotifications);
  };

  const renderMoreOptions = () => {
    return (
      <Menu className="more-menu">
        <Menu.Item key="mark-all" onClick={handleShowMarkAllPopup}>
          {t('notification.isReadAll')}
        </Menu.Item>
        <Menu.Item
          key="setting"
          onClick={() => handleShowNotificationSetting()}
        >
          {showNotifications
            ? t('notification.setting')
            : t('notification.notificationList')}
        </Menu.Item>
      </Menu>
    );
  };

  const renderTitle = () => {
    return (
      <div className="popover-title-notify">
        <div className="noti-label-div">
          <h4 className="noti-label">
            {showNotifications
              ? t('notification.title')
              : t('notification.settingTitle')}
          </h4>
        </div>
        <Dropdown
          placement="bottomRight"
          arrow
          trigger={['click']}
          overlay={renderMoreOptions}
        >
          <Button className="button-ellipsis" icon={<More />}></Button>
        </Dropdown>
      </div>
    );
  };

  const handleShowNotifications = (show) => {
    setShowNotiPopover(show);
    if (!show) {
      setShowNotifications(true);
      setShowNotificationSetting(false);
    }
  };

  return (
    <Spin spinning={notification?.isLoading}>
      <div className="notification-container">
        <Modal
          visible={openReadAllModal}
          title={t('notification.readAllTitle')}
          width={720}
          cancelText={t('common.no')}
          okText={t('common.yes')}
          onCancel={onCancelReadAllModal}
          onOk={handleReadAllNotification}
        >
          {t('notification.confirmReadAll')}
        </Modal>
        <Popover
          content={
            <>
              {showNotifications ? (
                <NotificationList
                  setRefreshSettings={setRefreshSettings}
                  refreshSettings={refreshSettings}
                />
              ) : (
                <NotificationSettings
                  refreshSettings={refreshSettings}
                  setRefreshSettings={setRefreshSettings}
                  changeList={changeList}
                  setChangeList={setChangeList}
                />
              )}
            </>
          }
          title={renderTitle}
          trigger="click"
          visible={showNotiPopover}
          onVisibleChange={handleShowNotifications}
          placement="bottomRight"
          overlayClassName={
            showNotifications ? 'noti-popover' : 'noti-popover-setting'
          }
        >
          <Button className="btn-bell">
            <Badge
              count={
                notification?.notificationList?.filter(
                  (notification) =>
                    notification.status === NOTIFICATION_STATUS.UNREAD,
                )?.length || 0
              }
              size="small"
              showZero={false}
            >
              <img src={BellImage} alt="bell" />
            </Badge>
          </Button>
        </Popover>
      </div>
    </Spin>
  );
}

const mapDispatchToProps = {
  updateAllNotificationStatus,
  getNotificationSettingsList,
  getNotificationList,
};

const mapStateToProps = (state) => ({
  notification: state.notification,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Notification),
);
