import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  updateUserFailed,
  updateUserSuccess,
  UPDATE_USER_START,
} from 'stores/user/user.action';

/**
 * Update user API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateUserApi = (params) => {
  const uri = `/${API_URL.USER}/${params.id}`;
  return api.put(uri, params.data);
};

/**
 * Handle update request and response
 * @param {object} action
 */
function* doUpdateUser(action) {
  try {
    const response = yield call(updateUserApi, action?.payload);

    if (response?.status === 200) {
      yield put(updateUserSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response.data);
      }

      if (!Object.keys(action?.payload).includes('status'))
        addNotification('user.updateUserSuccess', NOTIFICATION_TYPE.SUCCESS);
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(updateUserFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch update user
 */
export default function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER_START, doUpdateUser);
}
