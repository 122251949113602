import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  getInventoriesCheckDetailFailed,
  getInventoriesCheckDetailSuccess,
  GET_INVENTORIES_CHECK_DETAIL_START,
} from 'stores/inventories-check/inventories-check.action';

/**
 * Get inventories-check detail API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getInventoriesCheckDetailApi = (params) => {
  const uri = `/${API_URL.INVENTORIES_CHECK}/${params}`;
  return api.get(uri);
};

/**
 * Handle get detail request and response
 * @param {object} action
 */
function* doGetInventoriesCheckDetail(action) {
  try {
    const response = yield call(getInventoriesCheckDetailApi, action?.payload);

    if (response?.status === 200) {
      yield put(getInventoriesCheckDetailSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(getInventoriesCheckDetailFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch update inventories-check
 */
export default function* watchGetInventoriesCheckDetail() {
  yield takeLatest(GET_INVENTORIES_CHECK_DETAIL_START, doGetInventoriesCheckDetail);
}
