import { format } from 'date-fns';
import { DEFAULT_DATE_TIME_FORMAT, IMAGE_URL_PREFIX } from 'common/constant';

/**
 * Filter autocomplete
 */
 export const filterAutoComplete = (inputValue, option) => {
  return option.children.toLowerCase().includes(inputValue.toLowerCase());
};

/**
 * Scroll to bottom
 */
 export const scrollToBottom = () => {
  window.scroll({
    top: document.body.offsetHeight,
    left: 0,
    behavior: 'smooth',
  });
};

/**
 * Scroll to top
 */
export const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

/**
 * Convert date (string or Date) into string with input format
 * @param {Date|String} dateTime
 * @param {String} formatPattern default yyyy-MM-dd hh:mm
 * @return {String}
 */
 export const formatDateTimeUtc = (
  dateTime,
  formatPattern = DEFAULT_DATE_TIME_FORMAT,
) => {
  return dateTime ? format(new Date(dateTime), formatPattern) : '';
};

export const getImageUrl = (part) => {
  return IMAGE_URL_PREFIX + part
}

export const currentTime = () => {
  var date = new Date();
  return (
    date.getFullYear().toString() +
    pad2(date.getMonth() + 1) +
    pad2(date.getDate()) +
    pad2(date.getHours()) +
    pad2(date.getMinutes()) +
    pad2(date.getSeconds())
  );
};

function pad2(n) {
  return n < 10 ? '0' + n : n;
}
