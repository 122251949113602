import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  searchConversionRateFailed,
  searchConversionRateSuccess,
  SEARCH_CONVERSION_RATE_START,
} from 'stores/conversion-rate/conversion-rate.action';

/**
 * Search conversion-rate API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const searchConversionRateApi = (params) => {
  const uri = `${API_URL.CONVERSION_RATE}`;
  return api.get(uri, params);
};

/**
 * Handle update request and response
 * @param {object} action
 */
function* doSearchConversionRate(action) {
  try {
    const response = yield call(searchConversionRateApi, action?.payload);

    if (response?.status === 200) {
      const payload = {
        list: response.data.items,
        total: response.data.total,
      }
      yield put(searchConversionRateSuccess(payload));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(searchConversionRateFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch search conversion-rate
 */
export default function* watchSearchConversionRate() {
  yield takeLatest(SEARCH_CONVERSION_RATE_START, doSearchConversionRate);
}
