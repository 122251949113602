import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  updateStoreFailed,
  updateStoreSuccess,
  UPDATE_STORE_START,
} from 'stores/store/store.action';

/**
 * Update store API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateStoreApi = (params) => {
  const uri = `/${API_URL.STORE}/${params.id}`;
  return api.put(uri, params.data);
};

/**
 * Handle update request and response
 * @param {object} action
 */
function* doUpdateStore(action) {
  try {
    const response = yield call(updateStoreApi, action?.payload);

    if (response?.status === 200) {
      yield put(updateStoreSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification('store.updateStoreSuccess', NOTIFICATION_TYPE.SUCCESS);
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(updateStoreFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch update store
 */
export default function* watchUpdateStore() {
  yield takeLatest(UPDATE_STORE_START, doUpdateStore);
}
