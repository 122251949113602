import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import { Form, Spin, Card, Row, Col, Input, Button, Select } from 'antd';
import TopContent from 'components/common/top-content';
import {
  MODAL_MODE,
  CONVERSION_RATE_STATUS,
  CONVERSION_RATE_STATUS_MAP,
  CONVERSION_RATE_TYPE_MAP,
  CURRENCY_UNIT_STATUS,
} from 'common/constant';
import { redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import {
  getConversionRateDetail,
  createConversionRate,
  updateConversionRate,
} from 'stores/conversion-rate/conversion-rate.action';
import { searchCurrencyUnit } from 'stores/currency-unit/currency-unit.action';
import Coin from 'assets/images/coin.png';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

function ConversionRateForm(props) {
  const { t, conversionRate, currencyUnitList } = props;
  const [form] = Form.useForm();
  const [mode, setMode] = useState(MODAL_MODE.CREATE);
  const [status, setStatus] = useState(0);
  const [title, setTitle] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      route: ROUTES.CONVERSION_RATE.PATH,
      title: ROUTES.CONVERSION_RATE.TITLE,
    },
    {
      title: ROUTES.CONVERSION_RATE_CREATE.TITLE,
    },
  ]);
  const [conversionType, setConversionType] = useState(null);
  const path = props.match.path;

  useEffect(() => {
    props.searchCurrencyUnit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMode(
      path.includes('/update/')
        ? MODAL_MODE.UPDATE
        : path.includes('/detail/')
        ? MODAL_MODE.DETAIL
        : MODAL_MODE.CREATE,
    );
  }, [path]);

  useEffect(() => {
    if (mode !== MODAL_MODE.CREATE) {
      const {
        min,
        max,
        price,
        conversion_rates_type,
        currency_unit_id,
        status,
      } = conversionRate.conversionRateDetail;
      setStatus(status);
      setConversionType(conversion_rates_type);
      form.setFieldsValue({
        min,
        max,
        price,
        conversion_rates_type: conversion_rates_type?.toString(),
        currency_unit_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionRate, form]);

  useEffect(() => {
    document.title = title;
    return () => {
      form.resetFields();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    setBreadcrumbs([
      {
        route: ROUTES.CONVERSION_RATE.PATH,
        title: ROUTES.CONVERSION_RATE.TITLE,
      },
      {
        title:
          mode === MODAL_MODE.CREATE
            ? ROUTES.CONVERSION_RATE_CREATE.TITLE
            : mode === MODAL_MODE.UPDATE
            ? ROUTES.CONVERSION_RATE_UPDATE.TITLE
            : ROUTES.CONVERSION_RATE_DETAIL.TITLE,
      },
    ]);

    setTitle(
      mode === MODAL_MODE.CREATE
        ? t('conversionRate.createConversionRate')
        : mode === MODAL_MODE.UPDATE
        ? t('conversionRate.editConversionRate')
        : t('conversionRate.detailConversionRate'),
    );
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const onFinish = (values) => {
    const { price, conversion_rates_type, currency_unit_id } = values;
    const id = props.match.params.id;
    const params = {
      min: 0,
      max: 0,
      price: +price,
      conversion_rates_type: +conversion_rates_type,
      currency_unit_id: +currency_unit_id,
    };
    if (mode === MODAL_MODE.CREATE)
      props.createConversionRate(params, () => {
        backToList();
      });
    else if (mode === MODAL_MODE.UPDATE) {
      params.id = id;
      props.updateConversionRate(params, () => {
        backToList();
      });
    }
  };

  const backToList = () => {
    redirectRouter(ROUTES.CONVERSION_RATE.PATH);
  };

  const getDetail = () => {
    const id = props.match.params.id;
    if (mode !== MODAL_MODE.CREATE) props.getConversionRateDetail(id);
  };

  const onCancel = () => {
    if (mode === MODAL_MODE.CREATE) resetForm();
    else if (mode === MODAL_MODE.UPDATE) getDetail();
  };

  const resetForm = () => {
    form?.resetFields();
  };

  const renderActionButtons = () => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return (
          <div id="group-button-submit">
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="conversion-rate-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.create')}
            </Button>
          </div>
        );
      case MODAL_MODE.UPDATE:
        return (
          <div id="group-button-submit">
            <Button onClick={() => backToList()} className="close-button">
              {t('common.close')}
            </Button>
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="conversion-rate-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.save')}
            </Button>
          </div>
        );
      case MODAL_MODE.DETAIL:
        switch (status) {
          // PENDING
          case CONVERSION_RATE_STATUS.UNCONFIRM:
          case CONVERSION_RATE_STATUS.ACTIVE:
            return (
              <div id="group-button-submit">
                <Button onClick={backToList} className="close-button">
                  {t('common.close')}
                </Button>
              </div>
            );
          // INACTIVE
          case CONVERSION_RATE_STATUS.INACTIVE:
            return (
              <Button onClick={() => backToList()} className="close-button">
                {t('common.close')}
              </Button>
            );
          default:
            return;
        }
      default:
        break;
    }
  };

  const onChangeConversionType = (value) => {
    form.resetFields(['price', 'currency_unit_id']);

    setConversionType(value);
  };

  const isView = mode === MODAL_MODE.DETAIL;
  const isUpdate = mode === MODAL_MODE.UPDATE;
  return (
    <div className="conversion-rate-form">
      <Spin spinning={conversionRate?.isLoading}>
        <TopContent
          title={title}
          breadcrumbs={breadcrumbs}
          backToList={backToList}
        />
        <Card className="body-content">
          <Form
            {...layout}
            name="conversion-rate-form"
            id="conversion-rate-form"
            className="required-mark-after"
            form={form}
            onFinish={onFinish}
          >
            <div className="block-1">
              {isView && (
                <Row>
                  <Col sm={10} xs={24}>
                    <Form.Item name="status" label={t('conversionRate.status')}>
                      <div
                        className={
                          [CONVERSION_RATE_STATUS.ACTIVE].includes(status)
                            ? 'activeStatusBox boxStatus'
                            : [CONVERSION_RATE_STATUS.UNCONFIRM].includes(
                                status,
                              )
                            ? 'unconfirmedStatusBox boxStatus'
                            : 'inActiveStatusBox boxStatus'
                        }
                      >
                        {t(CONVERSION_RATE_STATUS_MAP[status])}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={10} xs={24}>
                  <Form.Item
                    name="conversion_rates_type"
                    label={t('conversionRate.type')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                    ]}
                  >
                    {isView ? (
                      <div className="ant-input ant-input-disabled">
                        {t(
                          CONVERSION_RATE_TYPE_MAP[
                            Object.keys(CONVERSION_RATE_TYPE_MAP).find(
                              (value) =>
                                value ===
                                form.getFieldValue('conversion_rates_type'),
                            )
                          ],
                        )}
                      </div>
                    ) : (
                      <Select
                        disabled={isUpdate}
                        placeholder={t('conversionRate.placeholder.type')}
                        onChange={(value) => onChangeConversionType(value)}
                      >
                        {Object.keys(CONVERSION_RATE_TYPE_MAP).map((value) => (
                          <Select.Option key={value} value={value}>
                            {t(CONVERSION_RATE_TYPE_MAP[value])}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  {/* <Form.Item
                    name="min"
                    label={t('conversionRate.min')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      type="number"
                      placeholder={t('conversionRate.placeholder.min')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="max"
                    label={t('conversionRate.max')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      type="number"
                      placeholder={t('conversionRate.placeholder.max')}
                    />
                  </Form.Item> */}
                  <Form.Item
                    name="price"
                    label={t('conversionRate.price')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                    ]}
                  >
                    {isView ? (
                      <>
                        {form?.getFieldValue('price')} / 1 &nbsp;
                        <img
                          src={Coin}
                          width="15px"
                          height="15px"
                          alt="coin-icon"
                        />
                      </>
                    ) : (
                      <Input
                        disabled={isView}
                        type="number"
                        placeholder={t('conversionRate.placeholder.price')}
                        suffix={
                          <>
                            / 1 &nbsp;
                            <img
                              src={Coin}
                              width="15px"
                              height="15px"
                              alt="coin-icon"
                            />
                          </>
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col sm={10} xs={24}>
                  <Form.Item
                    name="currency_unit_id"
                    label={t('conversionRate.currencyUnit')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                    ]}
                  >
                    {isView ? (
                      <div className="ant-input ant-input-disabled">
                        {
                          currencyUnitList.find(
                            (currencyUnit) =>
                              currencyUnit.id ===
                              form.getFieldValue('currency_unit_id'),
                          )?.name
                        }
                      </div>
                    ) : (
                      <Select
                        disabled={isUpdate}
                        placeholder={t(
                          'conversionRate.placeholder.currencyUnit',
                        )}
                      >
                        {currencyUnitList
                          .filter(
                            (currencyUnit) =>
                              (currencyUnit.status ===
                                CURRENCY_UNIT_STATUS.ACTIVE &&
                                +conversionType === 2 &&
                                currencyUnit.name.toUpperCase() === 'COIN') ||
                              (+conversionType !== 2 &&
                                currencyUnit.name.toUpperCase() !== 'COIN'),
                          )
                          .map((currencyUnit) => (
                            <Select.Option
                              key={currencyUnit.id}
                              value={currencyUnit.id}
                            >
                              {currencyUnit.name}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
        <Card style={{ marginTop: '32px' }}>
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderActionButtons()}
              </div>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
}

const mapDispatchToProps = {
  getConversionRateDetail,
  createConversionRate,
  updateConversionRate,
  searchCurrencyUnit,
};

const mapStateToProps = (state) => ({
  conversionRate: state.conversionRate,
  currencyUnitList: state.currencyUnit.currencyUnitList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ConversionRateForm),
);
