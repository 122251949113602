import { withTranslation } from 'react-i18next';
import BarChartReport from '../barchart';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Row, Card } from 'antd';
import Title from '../title';

class BestSellerReport extends Component {
  constructor(props) {
    super(props);
  }

  formatData = (dataList) => {
    const { t, bestSeller, itemList } = this.props;
    const newData = [];

    for (let i = 0; i < bestSeller.length; i++) {
      const item = bestSeller[i];
      newData.push({
        name:
          itemList.find((it) => it.id === item?.item_id)?.name +
          `(${item?.item_id})`,
        [t('dashboard.bestSeller.sold')]: item?.orders_count || 0,
        // [t('dashboard.bestSeller.proportion')]: Math.round(
        //   (item?.orders_count / orderList.length) * 100,
        // ),
      });
    }

    return newData;
  };

  handleChangeGroupBy = (id) => {
    this.setState({
      groupBy: id,
    });
  };

  handleChangeSelect = (value) => {
    this.setState({
      filterBy: value,
    });
  };

  handleChangeDate = (unit, { start, end }) => {
    this.setState({
      groupBy: unit.value,
      startDate: start.utc().toISOString(),
      endDate: end.utc().toISOString(),
    });
  };

  render() {
    const { t } = this.props;
    const data = this.formatData([]);
    const color = ['#7F9EF4', '#0FA44A'];

    return (
      <>
        <Card>
          <Row className="justify-content-between">
            <Title
              style={{ lineHeight: '40px' }}
              className="mb-0"
              title={t('dashboard.bestSeller.title')}
            />
          </Row>
          <BarChartReport
            data={data}
            xField="name"
            color={color}
            yField={t('dashboard.bestSeller.sold')}
          />
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bestSeller: state.common.bestSeller,
  orderList: state.common.orderList,
  itemList: state.item.itemList,
});

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(BestSellerReport),
);
