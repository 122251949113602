import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  updateCurrencyUnitFailed,
  updateCurrencyUnitSuccess,
  UPDATE_CURRENCY_UNIT_START,
} from 'stores/currency-unit/currency-unit.action';

/**
 * Update currency-unit API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateCurrencyUnitApi = (params) => {
  const id = params.id;
  delete params.id;
  const uri = `${API_URL.CURRENCY_UNIT}/${id}`;
  return api.put(uri, params);
};

/**
 * Handle update request and response
 * @param {object} action
 */
function* doUpdateCurrencyUnit(action) {
  try {
    const response = yield call(updateCurrencyUnitApi, action?.payload);

    if (response?.status === 200) {
      yield put(updateCurrencyUnitSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'currencyUnit.updateCurrencyUnitSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(updateCurrencyUnitFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch update currency-unit
 */
export default function* watchUpdateCurrencyUnit() {
  yield takeLatest(UPDATE_CURRENCY_UNIT_START, doUpdateCurrencyUnit);
}
