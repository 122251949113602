import { combineReducers } from 'redux';
import auth from './auth/auth.reducer';
import common from './common/common.reducer';
import user from './user/user.reducer';
import itemUnit from './item-unit/item-unit.reducer';
import category from './category/category.reducer';
import store from './store/store.reducer';
import item from './item/item.reducer';
import importItemTicket from './import-item-ticket/import-item-ticket.reducer';
import barcode from './barcode/barcode.reducer';
import currencyUnit from './currency-unit/currency-unit.reducer';
import conversionRate from './conversion-rate/conversion-rate.reducer';
import paymentType from './payment-type/payment-type.reducer';
import inventoriesCheck from './inventories-check/inventories-check.reducer';
import notification from './notification/notification.reducer';
import { LOGOUT_SUCCESS } from './auth/auth.action';

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) return appReducer(undefined, action);
  return appReducer(state, action);
};

const appReducer = combineReducers({
  auth,
  common,
  user,
  itemUnit,
  category,
  store,
  item,
  importItemTicket,
  barcode,
  currencyUnit,
  conversionRate,
  paymentType,
  inventoriesCheck,
  notification,
});

export default rootReducer;
