import { API_URL, NOTIFICATION_TYPE } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  getNotificationSettingsListFailed,
  getNotificationSettingsListSuccess,
  GET_NOTIFICATION_SETTINGS_LIST_START,
} from 'stores/notification/notification.action';

/**
 * Get notification setting API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getNotificationSettingsListApi = (params) => {
  const uri = `${API_URL.NOTIFICATION_SETTING}`;
  return api.get(uri);
};

/**
 * Handle get notification settings list request and response
 * @param {object} action
 */
function* doGetNotificationSettingsList(action) {
  try {
    const response = yield call(getNotificationSettingsListApi, action?.payload);

    if (response?.status === 200) {
      const payload = response.data;
      yield put(getNotificationSettingsListSuccess(payload));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(getNotificationSettingsListFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch get notification settings list
 */
export default function* watchGetNotificationSettingsList() {
  yield takeLatest(GET_NOTIFICATION_SETTINGS_LIST_START, doGetNotificationSettingsList);
}
