import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  createItemFailed,
  createItemSuccess,
  CREATE_ITEM_START,
} from 'stores/item/item.action';

/**
 * Create item API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const createItemApi = (params) => {
  const uri = `/${API_URL.ITEM}`;
  return api.post(uri, params);
};

/**
 * Handle create request and response
 * @param {object} action
 */
function* doCreateItem(action) {
  try {
    const response = yield call(createItemApi, action?.payload);

    if (response?.status === 200) {
      yield put(createItemSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'item.createItemSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(createItemFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch create item
 */
export default function* watchCreateItem() {
  yield takeLatest(CREATE_ITEM_START, doCreateItem);
}
