import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import { Form, Spin, Card, Row, Col, Button, Input, Select } from 'antd';
import TopContent from 'components/common/top-content';
import {
  MODAL_MODE,
  TEXTFIELD_REQUIRED_LENGTH,
  IMPORT_ITEM_TICKET_STATUS,
  IMPORT_ITEM_TICKET_STATUS_MAP,
  STORE_STATUS,
  ITEM_STATUS,
  DESCRIPTION_ROWS,
  CONVERSION_RATE_TYPE,
} from 'common/constant';
import { redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import ItemsSettingTable from './items-setting-table';
import {
  updateImportItemTicket,
  getImportItemTicketDetail,
  createImportItemTicket,
} from 'stores/import-item-ticket/import-item-ticket.action';
import { searchConversionRate } from 'stores/conversion-rate/conversion-rate.action';
import { searchStore } from 'stores/store/store.action';
import { searchItem } from 'stores/item/item.action';
import { getCurrencyUnit } from 'stores/common/common.action';
import moment from 'moment';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const DEFAULT_ITEM = {
  id: 0,
  item_id: null,
  currency_unit_id: null,
  price: 0,
  quantity: 1,
  date_of_manufacture: moment(),
  filterValue: '',
};

function ImportItemTicketForm(props) {
  const { t, importItemTicket, storeList, itemList, conversionRateList } =
    props;
  const [form] = Form.useForm();
  const [mode, setMode] = useState(MODAL_MODE.CREATE);
  const [items, setItems] = useState([{ ...DEFAULT_ITEM }]);
  const [status, setStatus] = useState(0);
  const [title, setTitle] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      route: ROUTES.IMPORT_ITEM_TICKET.PATH,
      title: ROUTES.IMPORT_ITEM_TICKET.TITLE,
    },
    {
      title: ROUTES.IMPORT_ITEM_TICKET_CREATE.TITLE,
    },
  ]);
  const path = props.match.path;
  const { id } = props.match.params;

  useEffect(() => {
    let tempMode = path.includes('/update/')
      ? MODAL_MODE.UPDATE
      : path.includes('/detail/')
      ? MODAL_MODE.DETAIL
      : MODAL_MODE.CREATE;
    setMode(tempMode);

    props.searchStore({
      filter: JSON.stringify([
        {
          column: 'status',
          value: STORE_STATUS.ACTIVE,
        },
      ]),
    });
    props.searchItem({
      filter: JSON.stringify([
        {
          column: 'user_id',
          value: window.localStorage.getItem('userId'),
        },
        {
          column: 'status',
          value: ITEM_STATUS.ACTIVE,
        },
      ]),
    });

    props.getCurrencyUnit();
    props.searchConversionRate();
    if (tempMode === MODAL_MODE.CREATE) form.resetFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (mode !== MODAL_MODE.CREATE) {
      const { name, code, description, shop, status, import_order_details } =
        importItemTicket.importItemTicketDetail;
      setStatus(status);

      let fields = {
        name,
        code,
        description,
        shop_id: mode === MODAL_MODE.DETAIL ? shop?.name : shop?.id,
      };

      import_order_details?.forEach((item, index) => {
        import_order_details[index].id = index;
        Object.assign(fields, {
          [`item_id${index}`]: itemList.find((i) => i.id === item?.item_id)
            ?.name,
          [`currency_unit_id${index}`]: item?.currency_unit_id,
          [`quantity${index}`]: +item?.quantity,
          [`price${index}`]: +item?.price,
          [`date_of_manufacture${index}`]: moment(item?.date_of_manufacture),
        });
      });

      form.setFieldsValue(fields);
      setItems(import_order_details || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importItemTicket.importItemTicketDetail]);

  useEffect(() => {
    setBreadcrumbs([
      {
        route: ROUTES.IMPORT_ITEM_TICKET.PATH,
        title: ROUTES.IMPORT_ITEM_TICKET.TITLE,
      },
      {
        title:
          mode === MODAL_MODE.CREATE
            ? ROUTES.IMPORT_ITEM_TICKET_CREATE.TITLE
            : mode === MODAL_MODE.UPDATE
            ? ROUTES.IMPORT_ITEM_TICKET_UPDATE.TITLE
            : ROUTES.IMPORT_ITEM_TICKET_DETAIL.TITLE,
      },
    ]);

    setTitle(
      mode === MODAL_MODE.CREATE
        ? t('importItemTicket.createImportItemTicket')
        : mode === MODAL_MODE.UPDATE
        ? t('importItemTicket.editImportItemTicket')
        : t('importItemTicket.detailImportItemTicket'),
    );
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    document.title = title;
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const getDetail = () => {
    if (mode !== MODAL_MODE.CREATE) props.getImportItemTicketDetail(id);
  };

  const onFinish = (values) => {
    const { name, code, description, shop_id } = values;

    const params = {
      name,
      code,
      description,
      shop_id,
      import_order_details: items.map((item) => ({
        item_id: item.item_id,
        quantity: +item.quantity,
        price: +item.price,
        currency_unit_id: item.currency_unit_id,
        date_of_manufacture: item.date_of_manufacture,
        total_in_coin:
          (+item.quantity * +item.price) /
          conversionRateList.find(
            (rate) =>
              rate.conversion_rates_type ===
                CONVERSION_RATE_TYPE.MONEY_TO_COIN &&
              rate.currency_unit_id === item.currency_unit_id,
          )?.price,
        price_in_coin:
          +item.price /
          conversionRateList.find(
            (rate) =>
              rate.conversion_rates_type ===
                CONVERSION_RATE_TYPE.MONEY_TO_COIN &&
              rate.currency_unit_id === item.currency_unit_id,
          )?.price,
        item_sell_price: itemList.find((i) => i.id === item?.item_id)?.price,
      })),
    };

    if (mode === MODAL_MODE.CREATE)
      props.createImportItemTicket(params, () => {
        backToList();
      });
    else if (mode === MODAL_MODE.UPDATE) {
      params.id = id;
      props.updateImportItemTicket(params, () => {
        backToList();
      });
    }
  };

  const backToList = () => {
    redirectRouter(ROUTES.IMPORT_ITEM_TICKET.PATH);
  };

  const onCancel = () => {
    if (mode === MODAL_MODE.CREATE) resetForm();
    else if (mode === MODAL_MODE.UPDATE) getDetail();
  };

  const resetForm = () => {
    form?.resetFields();
    setItems([{ ...DEFAULT_ITEM }]);
  };

  const renderActionButtons = () => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return (
          <div id="group-button-submit">
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="importItemTicket-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.create')}
            </Button>
          </div>
        );
      case MODAL_MODE.UPDATE:
        return (
          <div id="group-button-submit">
            <Button onClick={() => backToList()} className="close-button">
              {t('common.close')}
            </Button>
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="importItemTicket-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.save')}
            </Button>
          </div>
        );
      case MODAL_MODE.DETAIL:
        switch (status) {
          // PENDING
          case IMPORT_ITEM_TICKET_STATUS.ACTIVE:
          case IMPORT_ITEM_TICKET_STATUS.UNCONFIRM:
            return (
              <div id="group-button-submit">
                <Button onClick={backToList} className="close-button">
                  {t('common.close')}
                </Button>
              </div>
            );
          default:
            return;
        }
      default:
        break;
    }
  };

  const isView = mode === MODAL_MODE.DETAIL;

  return (
    <div className="importItemTicket-form">
      <Spin spinning={importItemTicket?.isLoading}>
        <TopContent
          title={title}
          breadcrumbs={breadcrumbs}
          backToList={backToList}
        />
        <Card className="body-content">
          <Form
            {...layout}
            name="importItemTicket-form"
            id="importItemTicket-form"
            className="required-mark-after"
            form={form}
            initialValues={{
              date_of_manufacture0: moment(),
            }}
            onFinish={onFinish}
          >
            <div className="block-1">
              {isView && (
                <Row>
                  <Col sm={10} xs={24}>
                    <Form.Item
                      name="status"
                      label={t('importItemTicket.status')}
                    >
                      <div
                        className={
                          [IMPORT_ITEM_TICKET_STATUS.ACTIVE].includes(status)
                            ? 'activeStatusBox boxStatus'
                            : [IMPORT_ITEM_TICKET_STATUS.UNCONFIRM].includes(
                                status,
                              )
                            ? 'unconfirmedStatusBox boxStatus'
                            : 'inActiveStatusBox boxStatus'
                        }
                      >
                        {t(IMPORT_ITEM_TICKET_STATUS_MAP[status])}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={10} xs={24}>
                  <Form.Item
                    name="code"
                    label={t('importItemTicket.code')}
                    rules={[
                      {
                        required: true,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.LENGTH_20.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.LENGTH_20.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('importItemTicket.placeholder.code')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('importItemTicket.description')}
                    name="description"
                    rules={[
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input.TextArea
                      disabled={isView}
                      autoSize={{
                        minRows: isView ? 1 : DESCRIPTION_ROWS.MIN,
                        maxRows: DESCRIPTION_ROWS.MAX,
                      }}
                      placeholder={t(
                        'importItemTicket.placeholder.description',
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col sm={10} xs={24}>
                  <Form.Item
                    name="name"
                    label={t('importItemTicket.name')}
                    rules={[
                      {
                        required: true,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('importItemTicket.placeholder.name')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('item.store')}
                    name="shop_id"
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                    ]}
                  >
                    {isView ? (
                      <div className="ant-input ant-input-disabled">
                        {form.getFieldValue('shop_id')}
                      </div>
                    ) : (
                      <Select placeholder={t('item.placeholder.store')}>
                        {storeList
                          .filter(
                            (store) =>
                              store.user_id ===
                              +window.localStorage.getItem('userId'),
                          )
                          .map((store) => (
                            <Select.Option key={store.id} value={store.id}>
                              {store.name}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <ItemsSettingTable
              items={items}
              mode={mode}
              setItems={setItems}
              form={form}
              itemList={itemList}
            />
          </Form>
        </Card>
        <Card style={{ marginTop: '32px' }}>
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderActionButtons()}
              </div>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
}

const mapDispatchToProps = {
  updateImportItemTicket,
  createImportItemTicket,
  getImportItemTicketDetail,
  searchStore,
  searchItem,
  getCurrencyUnit,
  searchConversionRate,
};

const mapStateToProps = (state) => ({
  importItemTicket: state.importItemTicket,
  storeList: state.store.storeList,
  itemList: state.item.itemList,
  conversionRateList: state.conversionRate.conversionRateList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ImportItemTicketForm),
);
