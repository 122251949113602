import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  updateItemFailed,
  updateItemSuccess,
  UPDATE_ITEM_START,
} from 'stores/item/item.action';

/**
 * Update item API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateItemApi = (params) => {
  const uri = `/${API_URL.ITEM}/${params.id}`;
  return api.put(uri, params.data);
};

/**
 * Handle update request and response
 * @param {object} action
 */
function* doUpdateItem(action) {
  try {
    const response = yield call(updateItemApi, action?.payload);

    if (response?.status === 200) {
      yield put(updateItemSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'item.updateItemSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(updateItemFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch update item
 */
export default function* watchUpdateItem() {
  yield takeLatest(UPDATE_ITEM_START, doUpdateItem);
}
