import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { scrollToBottom, filterAutoComplete, formatDateTimeUtc } from 'utils';
import { MODAL_MODE, PATTERN, QUANTITY_MAX_LENGTH } from 'common/constant';
import CustomTable from 'components/common/custom-table';
import { Input, Row, Form, AutoComplete, Button, Checkbox } from 'antd';
import { MinusCircleFilled, MinusCircleOutlined } from '@ant-design/icons';
import './index.scss';
import PlusBox from 'assets/images/plus-box.svg';

function ItemSettingTable(props) {
  const {
    t,
    mode,
    items,
    setItems,
    form,
    inventoriesList,
    storeList,
    filterCategory,
  } = props;
  const isView = mode === MODAL_MODE.DETAIL;

  /**
   * Get columns
   */
  const getColumns = () => {
    return [
      {
        dataIndex: 'id',
        title: t('inventoriesCheck.item.orderNumber'),
        align: 'center',
        width: '15px',
        render: (params, row) => {
          return row?.id + 1;
        },
      },
      {
        dataIndex: 'name',
        width: '18vw',
        title: t('inventoriesCheck.item.name'),
        render: (params, row) => {
          const { id, inventory_id } = row;
          const storeIdList = storeList.map((store) => store.id);
          const filteredInventoriesList = inventoriesList?.filter(
            (inventory) =>
              storeIdList.includes(inventory.shop.id) &&
              (inventory.item.item_group_id === filterCategory ||
                !filterCategory),
          );

          return isView ? (
            <>{getInventoryObject(inventory_id)?.item?.name}</>
          ) : (
            <div className="itemsetting">
              <Form.Item
                name={`item_id${id}`}
                className="itemsetting-form-item"
                rules={[
                  {
                    validator: (rule, value, cb) => {
                      if (!value && items[id].filterValue)
                        cb(t('form.itemNotFound'));
                      else if (!value) cb(t('form.itemNotFound'));
                      cb();
                    },
                  },
                ]}
              >
                <AutoComplete
                  onChange={(event, value) =>
                    onChangeItem(id, 'inventory_id', value?.value)
                  }
                  filterOption={(inputValue, option) => {
                    items[id].filterValue = inputValue;
                    setItems(items);
                    return filterAutoComplete(inputValue, option);
                  }}
                  style={{ width: '100%' }}
                >
                  {filteredInventoriesList.map((inventory) => {
                    if (
                      items.find(
                        (it) =>
                          it?.item_id === inventory.item?.id &&
                          it.inventory_id === inventory.id,
                      )
                    )
                      return (
                        <AutoComplete.Option
                          disabled
                          key={inventory.id}
                          value={inventory.id}
                        >
                          {inventory.item.name}
                        </AutoComplete.Option>
                      );

                    return (
                      <AutoComplete.Option
                        key={inventory.id}
                        value={inventory.id}
                      >
                        {inventory.item.name}
                      </AutoComplete.Option>
                    );
                  })}
                </AutoComplete>
              </Form.Item>
            </div>
          );
        },
      },
      {
        dataIndex: 'shop',
        title: t('inventoriesCheck.item.shop'),
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        render: (params, row) => {
          const { inventory_id } = row;
          return <>{getInventoryObject(inventory_id)?.shop?.name}</>;
        },
      },
      {
        dataIndex: 'expiration_date',
        title: t('inventoriesCheck.item.expiredDate'),
        align: 'center',
        render: (params, row) => {
          const { inventory_id } = row;
          const expiredDate = getInventoryObject(inventory_id)?.expiration_date;
          return <>{expiredDate ? formatDateTimeUtc(expiredDate) : null}</>;
        },
      },
      {
        dataIndex: 'actual_quantity',
        title: t('inventoriesCheck.item.actualQuantity'),
        width: '7vw',
        sortable: false,
        filterable: false,
        align: 'center',
        headerAlign: 'center',
        render: (params, row) => {
          const { id, is_update_quantity } = row;
          return (
            <>
              {isView ? (
                params
              ) : (
                <Form.Item
                  name={`quantity${id}`}
                  rules={
                    is_update_quantity
                      ? [
                          {
                            pattern: PATTERN.non_negative_number,
                            message: t('form.greaterThanOrEqualsZero'),
                          },
                          {
                            validator: (rule, value, cb) => {
                              if (value && value?.length > QUANTITY_MAX_LENGTH)
                                cb(
                                  t('form.maxLength', {
                                    max: QUANTITY_MAX_LENGTH,
                                  }),
                                );
                              cb();
                            },
                          },
                        ]
                      : []
                  }
                >
                  <div style={{ width: '80%', margin: '0 auto' }}>
                    <Input
                      value={params}
                      disabled={isView}
                      type="number"
                      defaultValue={1}
                      onChange={(event) =>
                        onChangeItem(id, 'actual_quantity', event.target.value)
                      }
                    />
                  </div>
                </Form.Item>
              )}
            </>
          );
        },
      },
      {
        dataIndex: 'quantity',
        align: 'center',
        title: t('inventoriesCheck.item.quantity'),
        render: (params) => {
          return <>{parseInt(params)}</>;
        },
      },
      {
        dataIndex: 'differentQuantity',
        title: t('inventoriesCheck.item.differentQuantity'),
        align: 'center',
        render: (params, row) => {
          const { actual_quantity, quantity } = row;
          return quantity ? Math.abs(actual_quantity - quantity) : 0;
        },
      },
      {
        dataIndex: 'is_update_quantity',
        align: 'center',
        title: t('inventoriesCheck.item.synchro'),
        width: '3vw',
        render: (params, row) => {
          const { id, is_update_quantity } = row;
          return (
            <>
              <Checkbox
                checked={is_update_quantity}
                disabled={isView}
                onClick={(event) =>
                  onChangeItem(id, 'is_update_quantity', event.target.checked)
                }
              />
            </>
          );
        },
      },
      {
        dataIndex: 'note',
        title: t('inventoriesCheck.item.note'),
        render: (params, row) => {
          const { id, note } = row;
          return (
            <>
              <Input
                value={note}
                disabled={isView}
                onChange={(event) =>
                  onChangeItem(id, 'note', event.target.value)
                }
              />
            </>
          );
        },
      },
      {
        key: 'remove',
        dataIndex: 'remove',
        title: '',
        visible: mode !== MODAL_MODE.DETAIL,
        render: (params, row) => {
          const { id } = row;
          const hide = mode === MODAL_MODE.DETAIL;
          return hide ? null : (
            <div className="remove-button">
              <Button
                type="text"
                shape="circle"
                onClick={() => onRemoveProduct(id)}
                disabled={items?.length === 1}
                icon={
                  items?.length === 1 ? (
                    <MinusCircleOutlined style={{ fontSize: '15px' }} />
                  ) : (
                    <MinusCircleFilled
                      style={{ color: 'red', fontSize: '15px' }}
                    />
                  )
                }
              />
            </div>
          );
        },
      },
    ];
  };

  const getInventoryObject = (inventoryId) => {
    return inventoriesList.find((inventory) => inventory.id === inventoryId);
  };

  /**
   * Add item
   */
  const onAddItem = () => {
    const newId = items.length;
    setItems([
      ...items,
      {
        id: newId,
        item_id: null,
        shop_id: null,
        actual_quantity: 0,
        quantity: 0,
        is_update_quantity: true,
        note: '',
        inventory_id: '',
      },
    ]);

    scrollToBottom();
  };

  /**
   * Remove product
   */
  const onRemoveProduct = (id) => {
    const oldFields = [];
    items.forEach((item, index) => {
      oldFields.push(`item_id${index}`);
      oldFields.push(`quantity${index}`);
    });

    form.resetFields(oldFields);

    const newItems = [...items]
      .filter((item) => item.id !== id)
      .map((item, index) => ({ ...item, id: index }));

    let fields = {};
    newItems.forEach((item, index) => {
      Object.assign(fields, {
        [`item_id${index}`]: getInventoryObject(item.inventory_id)?.item?.name,
        [`quantity${index}`]: +item?.quantity,
      });
    });

    form.setFieldsValue(fields);
    setItems(newItems);
  };

  /**
   *
   * @param {string} key
   * @param {*} value
   */
  const onChangeItem = (index, key, value) => {
    const newItems = [...items];
    const itemToChange = newItems[index];

    if (key === 'inventory_id') {
      const inventory = inventoriesList.find(
        (inventory) => inventory.id === value,
      );

      itemToChange['quantity'] = inventory?.quantity;
      itemToChange['item_id'] = inventory?.item?.id;
      itemToChange['shop_id'] = inventory?.shop?.id;
      form.setFieldsValue({
        [`item_id${index}`]: inventory?.item?.name,
      });
    } else if (key === 'is_update_quantity') {
      form?.validateFields([`quantity${index}`]);
    }

    itemToChange[key] = value;
    newItems[index] = itemToChange;

    setItems(newItems);
  };

  return (
    <>
      <Row className="title-import-item-ticket-item">
        {!isView && (
          <div id="import-item-ticket-create-button">
            <Button
              className="create-button mr-0 mini-shop-button"
              type="primary"
              onClick={onAddItem}
              size="large"
              icon={
                <img style={{ margin: '5px' }} src={PlusBox} alt="plus-box" />
              }
            >
              {t('common.addItem')}
            </Button>
          </div>
        )}
      </Row>
      <Row className="inventory-check-table-container">
        <CustomTable
          className="import-item-ticket-table"
          rows={items}
          pageSize={20}
          page={1}
          columns={getColumns()}
          total={items.length}
          topInfo={false}
          noPagination={true}
          hideFooter
        />
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  inventoriesList: state.common.inventoriesList,
  storeList: state.store.storeList,
});

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ItemSettingTable),
);
