import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  createStoreFailed,
  createStoreSuccess,
  CREATE_STORE_START,
} from 'stores/store/store.action';

/**
 * Create store API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const createStoreApi = (params) => {
  const uri = `/${API_URL.STORE}`;
  return api.post(uri, params);
};

/**
 * Handle create request and response
 * @param {object} action
 */
function* doCreateStore(action) {
  try {
    const response = yield call(createStoreApi, action?.payload);

    if (response?.status === 200) {
      yield put(createStoreSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'store.createStoreSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(createStoreFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch create store
 */
export default function* watchCreateStore() {
  yield takeLatest(CREATE_STORE_START, doCreateStore);
}
