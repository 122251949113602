import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Row, Col, Card, Form, Button, Input, Spin } from 'antd';
import ArrowLeft from 'assets/images/arrow-left.svg';
import './index.scss';
import { verifyCode, generateCode } from 'stores/user/user.action';
import { COOLDOWN_TIME } from 'common/constant';

function VerifyEmail(props) {
  const { t, toggleChangePassword, returnToLogin, phone, user } = props;
  const [successVerify, setSuccessVerify] = useState(false);
  const [coolDown, setCoolDown] = useState(0);

  const submitVerificationCode = (values) => {
    const { otp } = values;

    const params = {
      otp,
      phone,
    };
    props.verifyCode(params, () => {
      setSuccessVerify(true);
      setTimeout(() => {
        returnToLogin();
      }, 5000);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (coolDown > 0) setCoolDown(coolDown - 1);
    }, 1000);
  }, [coolDown]);

  const resendCode = () => {
    props.generateCode(phone, () => {
      setCoolDown(COOLDOWN_TIME);
    });
  };

  return (
    <Spin spinning={user?.isLoading}>
      <Card className="login-card">
        <Row>
          <Col span={6}>
            {!successVerify && (
              <Button
                className="btn-back"
                onClick={() => toggleChangePassword(false)}
              >
                <img src={ArrowLeft} alt="setting" />
              </Button>
            )}
          </Col>
          <Col span={successVerify ? 22 : 15}>
            <center>
              <h2 className="login-title">
                {successVerify
                  ? t('auth.successVerifyOtp')
                  : t('auth.enterVerficationCode')}
              </h2>
            </center>
          </Col>
        </Row>
        {!successVerify ? (
          <>
            <div className="login-card-content">
              <br />
              <div className="width-100">
                <Form onFinish={submitVerificationCode} size="large">
                  <Row>
                    <div>
                      <label className="login-label">
                        {t('auth.verificationHint', {
                          email: user?.recoverEmail,
                        })}
                      </label>
                    </div>
                  </Row>
                  <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: t('form.required'),
                      },
                      {
                        min: 6,
                        message: t('auth.validVerificationCode'),
                      },
                    ]}
                  >
                    <Input maxLength={6} className="otp-input" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      className="btn-login mini-shop-button"
                    >
                      {t('auth.continue')}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
            <Row justify="center">
              <p>
                {coolDown > 0 ? (
                  <span className="cool-down">
                    {t('auth.codeResent', {
                      time: coolDown,
                    })}
                  </span>
                ) : (
                  <>
                    {t('auth.notReceived')}
                    <Button type="link" onClick={resendCode}>
                      {t('auth.resend')}
                    </Button>
                  </>
                )}
              </p>
            </Row>
          </>
        ) : (
          <Row>
            <Button
              type="primary"
              className="btn-login mini-shop-button"
              onClick={() => returnToLogin()}
            >
              {t('common.backToDashboard')}
            </Button>
          </Row>
        )}
      </Card>
    </Spin>
  );
}

const mapDispatchToProps = {
  verifyCode,
  generateCode,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(VerifyEmail),
);
