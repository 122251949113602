import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TopContent from 'components/common/top-content';
import CustomTable from 'components/common/custom-table';
import { Button, Modal, Spin } from 'antd';
import { EyeFilled, EditFilled, DeleteFilled } from '@ant-design/icons';
import ReactAntSwitch from 'components/common/switch';
import './index.scss';
import { redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import {
  searchStore,
  deleteStore,
  confirmStore,
} from 'stores/store/store.action';
import { searchUser } from 'stores/user/user.action';
import { ADMIN_PHONE, STORE_STATUS } from 'common/constant';
import { CheckSquareFilled } from '@ant-design/icons';

const breadcrumbs = [
  {
    route: '/store',
    title: 'store',
  },
];

function Store(props) {
  const { t, store, userList } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState(null);
  const [keyword, setKeyWord] = useState('');
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmId, setConfirmId] = useState(null);
  const isAdmin =
    JSON.parse(window.localStorage.getItem('user'))?.phone === ADMIN_PHONE;

  useEffect(() => {
    document.title = t('store.title');
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, page, pageSize, filter, sort]);

  useEffect(() => {
    props.searchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('store.id'),
    },
    {
      key: 'code',
      dataIndex: 'code',
      title: t('store.code'),
      filterable: true,
      sorter: true,
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: t('store.name'),
      filterable: true,
      sorter: true,
    },
    {
      key: 'user_id',
      dataIndex: 'user_id',
      title: t('store.owner'),
      render: (params) => {
        return userList?.find((user) => user.id === params)?.full_name;
      },
    },
    {
      key: 'coin',
      dataIndex: 'coin',
      title: t('user.coin'),
      sorter: true,
      render: (params) => {
        return +params;
      },
    },
    {
      key: 'action',
      dataIndex: 'id',
      title: t('common.action'),
      disableClickEventBubbling: true,
      sorter: false,
      align: 'center',
      headerAlign: 'center',
      render: (params, row) => {
        const { status } = row;
        const isConfirmed = status !== STORE_STATUS.UNCONFIRM;
        const isNotDisabled = status !== STORE_STATUS.INACTIVE;
        return (
          <div className="action-button">
            <Button
              type="text"
              shape="circle"
              icon={<EyeFilled />}
              onClick={() => onClickViewDetails(params)}
            />
            {isNotDisabled && (
              <Button
                type="text"
                shape="circle"
                icon={<EditFilled />}
                onClick={() => onClickEdit(params)}
              />
            )}
            {!isConfirmed && isAdmin && (
              <Button
                type="text"
                shape="circle"
                icon={<DeleteFilled />}
                onClick={() => onClickDelete(params)}
              />
            )}
            {!isConfirmed && isAdmin && (
              <Button
                type="text"
                shape="circle"
                icon={<CheckSquareFilled style={{ color: 'green' }} />}
                onClick={() => onClickConfirm(params)}
              />
            )}
            {isConfirmed && isAdmin && (
              <ReactAntSwitch
                onClick={() =>
                  changeStatusStore(
                    params,
                    status === STORE_STATUS.ACTIVE
                      ? STORE_STATUS.INACTIVE
                      : STORE_STATUS.ACTIVE,
                  )
                }
                value={status === STORE_STATUS.ACTIVE}
              />
            )}
          </div>
        );
      },
    },
  ];

  const changeStatusStore = (id, status) => {
    const params = new FormData();
    params.append('status', status);
    props.confirmStore(params, id, () => {
      refreshData();
      onCancelConfirm();
    });
  };

  const handleCreate = () => {
    redirectRouter(ROUTES.STORE_CREATE.PATH);
  };

  const refreshData = () => {
    if (!isAdmin)
      filter.push({
        column: 'user_id',
        value: window.localStorage.getItem('userId'),
      });
    props.searchStore({
      keyword: keyword.trim(),
      offset: (page - 1) * pageSize,
      limit: pageSize,
      filter: JSON.stringify(filter),
      sort,
    });
  };

  const handleSearch = () => {
    refreshData();
  };

  const updateKeyword = (value) => {
    setKeyWord(value);
  };

  const onPageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
  };

  const onPageChange = ({ page }) => {
    setPage(page);
  };

  const onChangeFilter = (filter) => {
    setFilter(filter);
    setPage(1);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSort({
      column: sorter.field,
      value: sorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  const onClickViewDetails = (id) => {
    redirectRouter(ROUTES.STORE_DETAIL.PATH.replace('/:id', `/${id}`));
  };

  const onClickEdit = (id) => {
    redirectRouter(ROUTES.STORE_UPDATE.PATH.replace('/:id', `/${id}`));
  };

  const onClickDelete = (id) => {
    setDeletingId(id);
    setOpenConfirmDeleteModal(true);
  };

  const onCancelDelete = () => {
    setDeletingId(null);
    setOpenConfirmDeleteModal(false);
  };

  const onSubmitDelete = () => {
    props.deleteStore(deletingId, () => {
      onCancelDelete();
      refreshData();
    });
  };

  const onClickConfirm = (id) => {
    setConfirmId(id);
    setOpenConfirmModal(true);
  };

  const onCancelConfirm = () => {
    setConfirmId(null);
    setOpenConfirmModal(false);
  };

  const onSubmitConfirm = () => {
    changeStatusStore(confirmId, STORE_STATUS.ACTIVE);
  };

  return (
    <Spin spinning={store?.isLoading}>
      <TopContent
        title={t('store.title')}
        breadcrumbs={breadcrumbs}
        displayButton={isAdmin ? ['create'] : []}
        createButton={t('store.createButton')}
        handleCreateOpenModal={handleCreate}
        updateKeyword={updateKeyword}
        handleSearch={handleSearch}
      />
      <Modal
        visible={openConfirmDeleteModal}
        title={t('store.deleteModalTitle')}
        size="sm"
        onOk={onSubmitDelete}
        onCancel={onCancelDelete}
        okText={t('common.delete')}
        cancelText={t('common.cancel')}
        hideCancel
        width={719}
        okButtonProps={{
          id: 'delete-button',
        }}
        cancelButtonProps={{
          className: 'cancel-button-custom',
        }}
      >
        {t('store.deleteConfirm')}
      </Modal>
      <Modal
        visible={openConfirmModal}
        title={t('store.confirmModalTitle')}
        size="sm"
        onOk={onSubmitConfirm}
        onCancel={onCancelConfirm}
        okText={t('common.confirm')}
        cancelText={t('common.cancel')}
        hideCancel
        width={719}
        okButtonProps={{
          id: 'delete-button',
        }}
        cancelButtonProps={{
          className: 'cancel-button-custom',
        }}
      >
        {t('store.confirmMessage')}
      </Modal>
      <CustomTable
        columns={columns}
        rows={store?.storeList}
        page={page}
        pageSize={pageSize}
        total={store?.storeList?.length}
        topInfo={true}
        onChange={handleTableChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onChangeFilter={onChangeFilter}
      />
    </Spin>
  );
}

const mapDispatchToProps = {
  searchStore,
  deleteStore,
  confirmStore,
  searchUser,
};

const mapStateToProps = (state) => ({
  store: state.store,
  userList: state.user.userList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Store),
);
