export const CREATE_CONVERSION_RATE_START = 'CREATE_CONVERSION_RATE_START';
export const CREATE_CONVERSION_RATE_SUCCESS = 'CREATE_CONVERSION_RATE_SUCCESS';
export const CREATE_CONVERSION_RATE_FAILED = 'CREATE_CONVERSION_RATE_FAILED';

export const UPDATE_CONVERSION_RATE_START = 'UPDATE_CONVERSION_RATE_START';
export const UPDATE_CONVERSION_RATE_SUCCESS = 'UPDATE_CONVERSION_RATE_SUCCESS';
export const UPDATE_CONVERSION_RATE_FAILED = 'UPDATE_CONVERSION_RATE_FAILED';

export const DELETE_CONVERSION_RATE_START = 'DELETE_CONVERSION_RATE_START';
export const DELETE_CONVERSION_RATE_SUCCESS = 'DELETE_CONVERSION_RATE_SUCCESS';
export const DELETE_CONVERSION_RATE_FAILED = 'DELETE_CONVERSION_RATE_FAILED';

export const SEARCH_CONVERSION_RATE_START = 'SEARCH_CONVERSION_RATE_START';
export const SEARCH_CONVERSION_RATE_SUCCESS = 'SEARCH_CONVERSION_RATE_SUCCESS';
export const SEARCH_CONVERSION_RATE_FAILED = 'SEARCH_CONVERSION_RATE_FAILED';

export const GET_CONVERSION_RATE_DETAIL_START = 'GET_CONVERSION_RATE_DETAIL_START';
export const GET_CONVERSION_RATE_DETAIL_SUCCESS = 'GET_CONVERSION_RATE_DETAIL_SUCCESS';
export const GET_CONVERSION_RATE_DETAIL_FAILED = 'GET_CONVERSION_RATE_DETAIL_FAILED';

/**
 * Create conversion-rate
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createConversionRate(payload, onSuccess, onError) {
  return {
    type: CREATE_CONVERSION_RATE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create conversion-rate success action
 * @param {*} payload
 * @returns {object}
 */
export function createConversionRateSuccess(payload) {
  return {
    type: CREATE_CONVERSION_RATE_SUCCESS,
    payload: payload,
  };
}

/**
 * Create conversion-rate failed action
 * @returns {object}
 */
export function createConversionRateFailed() {
  return {
    type: CREATE_CONVERSION_RATE_FAILED,
  };
}

/**
 * Update conversion-rate
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateConversionRate(payload, onSuccess, onError) {
  return {
    type: UPDATE_CONVERSION_RATE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update conversion-rate success action
 * @param {*} payload
 * @returns {object}
 */
export function updateConversionRateSuccess(payload) {
  return {
    type: UPDATE_CONVERSION_RATE_SUCCESS,
    payload: payload,
  };
}

/**
 * Update conversion-rate failed action
 * @returns {object}
 */
export function updateConversionRateFailed() {
  return {
    type: UPDATE_CONVERSION_RATE_FAILED,
  };
}

/**
 * Delete conversion-rate
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function deleteConversionRate(payload, onSuccess, onError) {
  return {
    type: DELETE_CONVERSION_RATE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Delete conversion-rate success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteConversionRateSuccess(payload) {
  return {
    type: DELETE_CONVERSION_RATE_SUCCESS,
    payload: payload,
  };
}

/**
 * Delete conversion-rate failed action
 * @returns {object}
 */
export function deleteConversionRateFailed() {
  return {
    type: DELETE_CONVERSION_RATE_FAILED,
  };
}

/**
 * Search conversion-rate
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function searchConversionRate(payload, onSuccess, onError) {
  return {
    type: SEARCH_CONVERSION_RATE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Search conversion-rate success action
 * @param {*} payload
 * @returns {object}
 */
export function searchConversionRateSuccess(payload) {
  return {
    type: SEARCH_CONVERSION_RATE_SUCCESS,
    payload: payload,
  };
}

/**
 * Search conversion-rate failed action
 * @returns {object}
 */
export function searchConversionRateFailed() {
  return {
    type: SEARCH_CONVERSION_RATE_FAILED,
  };
}

/**
 * Get conversion-rate detail
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function getConversionRateDetail(payload, onSuccess, onError) {
  return {
    type: GET_CONVERSION_RATE_DETAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Get conversion-rate detail success action
 * @param {*} payload
 * @returns {object}
 */
export function getConversionRateDetailSuccess(payload) {
  return {
    type: GET_CONVERSION_RATE_DETAIL_SUCCESS,
    payload: payload,
  };
}

/**
 * Get conversion-rate detail failed action
 * @returns {object}
 */
export function getConversionRateDetailFailed() {
  return {
    type: GET_CONVERSION_RATE_DETAIL_FAILED,
  };
}
