import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  completeStoreTransactionFailed,
  completeStoreTransactionSuccess,
  COMPLETE_STORE_TRANSACTION_START,
} from 'stores/store/store.action';

/**
 * Compelte store transaction API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const completeStoreTransactionApi = (params) => {
  const uri = `${API_URL.TRANSACTIONS}/complete/${params.id}`;
  return api.put(uri, params);
};

/**
 * Handle complete store transaction request and response
 * @param {object} action
 */
function* doCompleteStoreTransaction(action) {
  try {
    const response = yield call(completeStoreTransactionApi, action?.payload);

    if (response?.status === 200) {
      yield put(completeStoreTransactionSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'store.completeStoreTransactionSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(completeStoreTransactionFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch complete store transaction
 */
export default function* watchCompleteStoreTransaction() {
  yield takeLatest(
    COMPLETE_STORE_TRANSACTION_START,
    doCompleteStoreTransaction,
  );
}
