import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from 'firebase/messaging';
import store from 'stores';
import { createDeviceToken } from 'stores/user/user.action';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAE-6UfGNxCtUjLoNg0MCOSx3_iamDOo6w',
  authDomain: 'mini-shop-355603.firebaseapp.com',
  projectId: 'mini-shop-355603',
  storageBucket: 'mini-shop-355603.appspot.com',
  messagingSenderId: '442726207896',
  appId: '1:442726207896:web:4b0c1e2a129109c0069bed',
  measurementId: 'G-QG9JCT7MVP',
};
// các tham số này là phần config lấy ra được từ phần 2. setting firebase nhé
const firebaseApp = initializeApp(firebaseConfig);

const messaging = isSupported() ? getMessaging(firebaseApp) : null;

const createToken = (user_id) => {
  return getToken(messaging, {
    vapidKey:
      'BPE8Tcb-JWEkzEqwHxvWz62bgROKTnLIiGw1fvOwrp5Ss6eX-kQ6LtZ6PQSnKQ7ylJkiF1PhxGa-n40Y8O_0bEs',
  })
    .then((currentToken) => {
      if (currentToken) {
        store.dispatch(
          createDeviceToken({
            device_token: currentToken,
            user_id,
          }),
        );
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          'No registration token available. Request permission to generate one.',
        );
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

const onMessageListener = messaging
  ? (handler) => {
      onMessage(messaging, (payload) => {
        handler(payload);
      });
    }
  : (handler) => {};

export { messaging, createToken, onMessageListener };
