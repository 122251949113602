import { withTranslation } from 'react-i18next';
import BarChartReport from '../barchart';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Row, Card } from 'antd';
import Title from '../title';
import moment from 'moment';
import { FOUR_WEEKS_DAYS, NORMAL_WEEKS, ORDER_STATUS } from 'common/constant';
import DateSelection from '../date-selection';

class PaymentReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupBy: 0,
      startDate: null,
      endDate: null,
      currentYear: moment().year(),
      currentMonth: moment().month() + 1,
      selectYear: moment().year(),
      selectMonth: moment().month() + 1,
      selectWeek: Math.ceil(moment().date() / 7),
      selectDay: moment().date(),
    };
  }

  formatData = (dataList) => {
    const { t, orderList } = this.props;
    const {
      currentYear,
      currentMonth,
      selectYear,
      selectDay,
      selectMonth,
      selectWeek,
    } = this.state;
    const newData = [];
    const numberOfDays = moment(`${selectYear}-${selectMonth}`).daysInMonth();

    const year = selectYear ? selectYear : currentYear;

    if (selectYear) {
      if (selectMonth) {
        if (selectWeek) {
          if (selectDay) {
            // year, month, week, day
            newData.push({
              name: t('common.date', {
                date: selectDay,
              }),
              coin:
                orderList
                  .filter(
                    (order) =>
                      moment(order.updated_at).month() + 1 === selectMonth &&
                      moment(order.updated_at).year() === selectYear &&
                      moment(order.updated_at).date() === selectDay &&
                      order.status === ORDER_STATUS.CONFIRM,
                  )
                  ?.reduce((a, b) => a + parseInt(b.total_price), 0) || 0,
            });
          } else {
            // year, month, week
            const numberOfDays = moment(
              `${selectYear}-${selectMonth}`,
            ).daysInMonth();

            for (
              let i = 1;
              i <=
              (selectWeek > NORMAL_WEEKS ? numberOfDays - FOUR_WEEKS_DAYS : 7);
              i++
            ) {
              const day = (selectWeek - 1) * 7 + i;
              newData.push({
                name: t('common.date', {
                  date: day,
                }),
                coin:
                  orderList
                    .filter(
                      (order) =>
                        moment(order.updated_at).month() + 1 === selectMonth &&
                        moment(order.updated_at).year() === selectYear &&
                        moment(order.updated_at).date() === day &&
                        order.status === ORDER_STATUS.CONFIRM,
                    )
                    ?.reduce((a, b) => a + parseInt(b.total_price), 0) || 0,
              });
            }
          }
        } else {
          if (selectDay) {
            // year, month, day
            newData.push({
              name: t('common.date', {
                date: selectDay,
              }),
              coin:
                orderList
                  .filter(
                    (order) =>
                      moment(order.updated_at).month() + 1 === selectMonth &&
                      moment(order.updated_at).year() === selectYear &&
                      moment(order.updated_at).date() === selectDay &&
                      order.status === ORDER_STATUS.CONFIRM,
                  )
                  ?.reduce((a, b) => a + parseInt(b.total_price), 0) || 0,
            });
          } else {
            // year, month
            for (
              let i = 1;
              i <= (numberOfDays > FOUR_WEEKS_DAYS ? 5 : 4);
              i++
            ) {
              const week = i;
              const daysInWeek = Array.from(
                { length: 7 },
                (v, k) => k + 1 + (week - 1) * 7,
              );

              newData.push({
                name: t('common.week', {
                  week: week,
                }),
                coin:
                  orderList
                    .filter(
                      (order) =>
                        moment(order.updated_at).month() + 1 === selectMonth &&
                        moment(order.updated_at).year() === selectYear &&
                        daysInWeek.includes(moment(order.updated_at).date()) &&
                        order.status === ORDER_STATUS.CONFIRM,
                    )
                    ?.reduce((a, b) => a + parseInt(b.total_price), 0) || 0,
              });
            }
          }
        }
      } else {
        for (let i = 1; i <= currentMonth; i++) {
          newData.push({
            name: t('common.month', {
              month: i,
            }),
            coin:
              orderList
                .filter(
                  (order) =>
                    moment(order.updated_at).month() + 1 === i &&
                    moment(order.updated_at).year() === year &&
                    order.status === ORDER_STATUS.CONFIRM,
                )
                ?.reduce((a, b) => a + parseInt(b.total_price), 0) || 0,
          });
        }
      }
    }

    return newData;
  };

  handleChangeGroupBy = (id) => {
    this.setState({
      groupBy: id,
    });
  };

  handleChangeSelect = (value) => {
    this.setState({
      filterBy: value,
    });
  };

  handleChangeDate = (unit, { start, end }) => {
    this.setState({
      groupBy: unit.value,
      startDate: start.utc().toISOString(),
      endDate: end.utc().toISOString(),
    });
  };

  updateState = (state, value) => {
    const newState = {
      [state]: value,
    };

    if (state === 'selectWeek')
      Object.assign(newState, {
        selectDay: null,
      });
    else if (state === 'selectMonth')
      Object.assign(newState, {
        selectDay: null,
        selectWeek: null,
      });
    this.setState(newState);
  };

  render() {
    const { t } = this.props;
    const { selectYear, selectMonth, selectWeek, selectDay } = this.state;
    const data = this.formatData([]);
    const color = ['#7F9EF4', '#0FA44A'];

    return (
      <>
        <Card>
          <Row className="justify-content-between">
            <Title
              style={{ lineHeight: '40px' }}
              className="mb-0"
              title={t('dashboard.paymentReport.title')}
            />
            <DateSelection
              selectYear={selectYear}
              selectMonth={selectMonth}
              selectWeek={selectWeek}
              selectDay={selectDay}
              updateState={this.updateState}
            />
          </Row>
          <BarChartReport
            data={data}
            xField="name"
            color={color}
            yField="coin"
          />
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orderList: state.common.orderList,
});

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(PaymentReport),
);
