export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED';

export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED';

export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED';

export const SEARCH_CATEGORY_START = 'SEARCH_CATEGORY_START';
export const SEARCH_CATEGORY_SUCCESS = 'SEARCH_CATEGORY_SUCCESS';
export const SEARCH_CATEGORY_FAILED = 'SEARCH_CATEGORY_FAILED';

export const GET_CATEGORY_DETAIL_START = 'GET_CATEGORY_DETAIL_START';
export const GET_CATEGORY_DETAIL_SUCCESS = 'GET_CATEGORY_DETAIL_SUCCESS';
export const GET_CATEGORY_DETAIL_FAILED = 'GET_CATEGORY_DETAIL_FAILED';

/**
 * Create category
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createCategory(payload, onSuccess, onError) {
  return {
    type: CREATE_CATEGORY_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create category success action
 * @param {*} payload
 * @returns {object}
 */
export function createCategorySuccess(payload) {
  return {
    type: CREATE_CATEGORY_SUCCESS,
    payload: payload,
  };
}

/**
 * Create category failed action
 * @returns {object}
 */
export function createCategoryFailed() {
  return {
    type: CREATE_CATEGORY_FAILED,
  };
}

/**
 * Update category
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateCategory(payload, id, onSuccess, onError) {
  return {
    type: UPDATE_CATEGORY_START,
    payload: {
      id,
      data: payload,
    },
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update category success action
 * @param {*} payload
 * @returns {object}
 */
export function updateCategorySuccess(payload) {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload: payload,
  };
}

/**
 * Update category failed action
 * @returns {object}
 */
export function updateCategoryFailed() {
  return {
    type: UPDATE_CATEGORY_FAILED,
  };
}

/**
 * Delete category
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function deleteCategory(payload, onSuccess, onError) {
  return {
    type: DELETE_CATEGORY_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Delete category success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteCategorySuccess(payload) {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    payload: payload,
  };
}

/**
 * Delete category failed action
 * @returns {object}
 */
export function deleteCategoryFailed() {
  return {
    type: DELETE_CATEGORY_FAILED,
  };
}

/**
 * Search category
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function searchCategory(payload, onSuccess, onError) {
  return {
    type: SEARCH_CATEGORY_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Search category success action
 * @param {*} payload
 * @returns {object}
 */
export function searchCategorySuccess(payload) {
  return {
    type: SEARCH_CATEGORY_SUCCESS,
    payload: payload,
  };
}

/**
 * Search category failed action
 * @returns {object}
 */
export function searchCategoryFailed() {
  return {
    type: SEARCH_CATEGORY_FAILED,
  };
}

/**
 * Get category detail
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getCategoryDetail(payload, onSuccess, onError) {
  return {
    type: GET_CATEGORY_DETAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Get category detail success action
 * @param {*} payload
 * @returns {object}
 */
export function getCategoryDetailSuccess(payload) {
  return {
    type: GET_CATEGORY_DETAIL_SUCCESS,
    payload: payload,
  };
}

/**
 * Get category detail failed action
 * @returns {object}
 */
export function getCategoryDetailFailed() {
  return {
    type: GET_CATEGORY_DETAIL_FAILED,
  };
}
