import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Input,
  Spin,
  DatePicker,
  Upload,
  Modal,
} from 'antd';
import ArrowLeft from 'assets/images/arrow-left.svg';
import './index.scss';
import {
  DATE_FORMAT,
  IMAGE_RELATION_TYPE,
  IMAGE_STATUS,
  PATTERN,
  TEXTFIELD_REQUIRED_LENGTH,
  USER_TYPE_ENUM,
} from 'common/constant';
import { createUser } from 'stores/user/user.action';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { formatDateTimeUtc, redirectRouter } from 'utils';
import { login } from 'stores/auth/auth.action';

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

function RegisterGoogleUser(props) {
  const { t, googleUserProfile, setRegisterGoogleUser, user } = props;
  const [image, setImage] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    form?.setFieldsValue({
      full_name: googleUserProfile?.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRegisterGoogleUser = (values) => {
    const { phone, password_digest, full_name, date_of_birth } = values;
    const imageName = image?.name;

    const params = new FormData();
    params.append('phone', phone);
    params.append('full_name', full_name);
    params.append('email', googleUserProfile?.email);
    params.append(
      'date_of_birth',
      formatDateTimeUtc(date_of_birth, DATE_FORMAT),
    );
    params.append('user_type', USER_TYPE_ENUM.GOOGLE_SHOP_OWNER.toString());
    params.append('password_digest', password_digest);
    if (image) {
      params.append(
        'image',
        image?.status === IMAGE_STATUS.DONE
          ? null
          : JSON.stringify({
              ...image,
              name: imageName,
              url: `${IMAGE_RELATION_TYPE.USER}/undefined/`,
            }),
      );
      if (image?.status !== IMAGE_STATUS.DONE)
        params.append('files[]', image?.originFileObj || null);
    }

    props.createUser(params, () => {
      const params = {
        email: googleUserProfile.email,
        user_type: USER_TYPE_ENUM.GOOGLE_SHOP_OWNER,
        remember_password: false,
      };

      props.login(params, () => {
        setImage(null);
        redirectRouter('/');
      });
    });
  };

  const disabledDate = (current) => {
    return moment().isBefore(current);
  };

  const handleChangeImage = ({ fileList: imageList }) => {
    if (image?.status === IMAGE_STATUS.REMOVED) {
      setImage(null);
    } else {
      setImage(imageList[0]);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  return (
    <Spin spinning={user?.isLoading}>
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <img
          alt="preview"
          style={{
            width: '100%',
          }}
          src={image?.url || previewImage}
        />
      </Modal>
      <Card className="login-card">
        <Row>
          <Col span={6}>
            <Button
              className="btn-back"
              onClick={() => setRegisterGoogleUser(false)}
            >
              <img src={ArrowLeft} alt="setting" />
            </Button>
          </Col>
          <Col span={12}>
            <center>
              <h2 className="login-title">{t('auth.registerShopOwner')}</h2>
            </center>
          </Col>
        </Row>
        <div className="login-card-content">
          <br />
          <div className="width-100">
            <Form
              onFinish={onRegisterGoogleUser}
              size="large"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 8 }}
              form={form}
            >
              <center>
                <Form.Item
                  name="imageList"
                  rules={
                    [
                      // {
                      //   required: !isView,
                      //   message: '',
                      // },
                      // {
                      //   validator: (rule, value, cb) => {
                      //     if (!image) cb(t('form.imageRequired'));
                      //     cb();
                      //   },
                      // },
                    ]
                  }
                >
                  <div className="shop-avatar-container">
                    <div className="upload-container">
                      <Upload
                        action="/"
                        listType="picture-card"
                        fileList={image ? [image] : []}
                        beforeUpload={() => {
                          return false;
                        }}
                        maxCount={1}
                        onPreview={handlePreview}
                        onChange={handleChangeImage}
                      >
                        {image ? null : uploadButton}
                      </Upload>
                    </div>
                  </div>
                </Form.Item>
              </center>
              <div className="pb-8">
                <label className="login-label">{t('user.email')}</label>
              </div>
              <Form.Item>{googleUserProfile?.email}</Form.Item>
              <div className="pb-8">
                <label className="login-label">{t('user.fullName')}</label>
              </div>
              <Form.Item
                name="full_name"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: t('form.required'),
                  },
                  {
                    max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                    message: t('form.maxLength', {
                      max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                    }),
                  },
                ]}
              >
                <Input defaultValue={googleUserProfile?.name} />
              </Form.Item>
              <div className="pb-8">
                <label className="login-label">{t('auth.phoneNumber')}</label>
              </div>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t('form.required'),
                  },
                  {
                    pattern: PATTERN.phone,
                    message: t('form.validPhone'),
                  },
                ]}
              >
                <Input
                  name="phoneNumber"
                  placeholder={t('auth.placeholder.phoneNumber')}
                  className="input"
                />
              </Form.Item>
              <div className="pb-8">
                <label className="login-label">{t('user.password')}</label>
              </div>
              <Form.Item
                name="password_digest"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: t('form.required'),
                  },
                  {
                    max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                    message: t('form.maxLength', {
                      max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                    }),
                  },
                  // {
                  //   min: TEXTFIELD_REQUIRED_LENGTH.LENGTH_8.MAX,
                  //   message: t('form.minLength', {
                  //     min: TEXTFIELD_REQUIRED_LENGTH.LENGTH_8.MAX,
                  //   }),
                  // },
                  // {
                  //   pattern: PATTERN.password,
                  //   message: t('form.validPassword'),
                  // },
                ]}
              >
                <Input.Password placeholder={t('user.placeholder.password')} />
              </Form.Item>
              <div className="pb-8">
                <label className="login-label">{t('user.birthday')}</label>
              </div>
              <Form.Item name="date_of_birth">
                <DatePicker
                  disabledDate={disabledDate}
                  placeholder={t('user.placeholder.birthday')}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="btn-login mini-shop-button"
                >
                  {t('common.confirm')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Card>
    </Spin>
  );
}

const mapDispatchToProps = {
  createUser,
  login,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(RegisterGoogleUser),
);
