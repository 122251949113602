import React, { Fragment, useEffect } from 'react';
import { Row, Select } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  FOUR_WEEKS_DAYS,
  MIN_YEAR,
  MONTH_LIST,
  NORMAL_WEEKS,
  TIME_TYPE_ENUM,
  TIME_TYPE_LIST,
  WEEK_LIST,
} from 'common/constant';

const DateSelection = (props) => {
  const {
    selectYear,
    selectMonth,
    selectWeek,
    selectDay,
    updateState,
    showYear = true,
    showMonth = true,
    showWeek = true,
    showDay = true,
  } = props;
  const currentYear = moment().year();
  const { t } = useTranslation();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      {TIME_TYPE_LIST.map((timeType) => {
        let selectList = [];
        let selectType = '';
        let selectValue = null;
        switch (timeType.id) {
          case TIME_TYPE_ENUM.DAY:
            if (!showDay) return;
            if (selectMonth) {
              const numberOfDays = moment(
                `${selectYear}-${selectMonth}`,
              ).daysInMonth();

              if (selectWeek) {
                selectList = Array.from(
                  {
                    length:
                      selectWeek > NORMAL_WEEKS
                        ? numberOfDays - FOUR_WEEKS_DAYS
                        : 7,
                  },
                  (v, k) => k + 1 + (selectWeek - 1) * 7,
                );
              } else {
                selectList = Array.from(
                  { length: numberOfDays },
                  (v, k) => k + 1,
                );
              }
            }
            selectType = 'selectDay';
            selectValue = selectDay;
            break;
          case TIME_TYPE_ENUM.WEEK:
            if (!showWeek) return;
            const numberOfDays = moment(
              `${selectYear}-${selectMonth}`,
            ).daysInMonth();

            if (selectMonth)
              selectList =
                numberOfDays > FOUR_WEEKS_DAYS
                  ? WEEK_LIST.concat(['5'])
                  : WEEK_LIST;

            selectType = 'selectWeek';
            selectValue = selectWeek;
            break;
          case TIME_TYPE_ENUM.MONTH:
            if (!showMonth) return;
            if (selectYear) {
              selectList = MONTH_LIST;
            }
            selectType = 'selectMonth';
            selectValue = selectMonth;
            break;
          case TIME_TYPE_ENUM.YEAR:
            if (!showYear) return;
            selectList = Array.from(
              { length: currentYear - MIN_YEAR + 1 },
              (v, k) => k + MIN_YEAR,
            );
            selectType = 'selectYear';
            selectValue = selectYear;
            break;
          default:
            break;
        }

        return (
          <Fragment key={timeType?.id}>
            <div style={{ margin: '5px 10px' }}>{t(timeType.text)}</div>
            <Select
              key={timeType?.id}
              value={selectValue}
              onChange={(value) =>
                updateState(selectType, value ? parseInt(value) : value)
              }
              allowClear
              className="dashboard-date-selection"
            >
              {selectList.map((value) => (
                <Select.Option key={value} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Fragment>
        );
      })}
    </Row>
  );
};

export default DateSelection;
