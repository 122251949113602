import {
  GOOGLE_CLIENT_ID,
  NOTIFICATION_TYPE,
  PATTERN,
  POP_UP_CLOSED_ERROR,
  USER_TYPE_ENUM,
} from 'common/constant';
import addNotification from 'common/toast';
import React from 'react';
import GoogleLogin from 'react-google-login';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { login } from 'stores/auth/auth.action';
import { redirectRouter } from 'utils';

const clientId = GOOGLE_CLIENT_ID;

function CustomGoogleLogin(props) {
  const { t, setRegisterGoogleUser, setGoogleUserProfile } = props;
  const onSuccess = (res) => {
    if (!PATTERN.vti_email.test(res.profileObj.email))
      addNotification(t('form.validVtiEmail', NOTIFICATION_TYPE.ERROR));
    const params = {
      email: res.profileObj.email,
      user_type: USER_TYPE_ENUM.GOOGLE_SHOP_OWNER,
      remember_password: false,
    };
    props.login(
      params,
      () => {
        redirectRouter('/');
      },
      () => {
        setGoogleUserProfile(res.profileObj);
        setRegisterGoogleUser(true);
      },
    );
  };

  const onFailure = (res) => {
    if (res.error !== POP_UP_CLOSED_ERROR)
      addNotification(
        t('notification.googleLoginFailed', NOTIFICATION_TYPE.ERROR),
      );
  };

  return (
    <center>
      <div style={{ marginTop: '15px' }}>
        <GoogleLogin
          clientId={clientId}
          buttonText={t('common.googleLoginButton')}
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          isSignedIn={true}
        />
      </div>
    </center>
  );
}

const mapDispatchToProps = {
  login,
};

const mapStateToProps = (state) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CustomGoogleLogin),
);
