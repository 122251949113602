import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TopContent from 'components/common/top-content';
import CustomTable from 'components/common/custom-table';
import { Button, Spin } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import './index.scss';
import { formatDateTimeUtc, redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import { searchInventoriesCheck } from 'stores/inventories-check/inventories-check.action';

const breadcrumbs = [
  {
    route: '/inventories-check',
    title: 'inventoriesCheck',
  },
];

function InventoriesCheck(props) {
  const { t, inventoriesCheck } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState(null);
  const [keyword, setKeyWord] = useState('');

  useEffect(() => {
    document.title = t('inventoriesCheck.title');
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, page, pageSize, filter, sort]);

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('inventoriesCheck.id'),
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: t('inventoriesCheck.name'),
      filterable: true,
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('inventoriesCheck.createdAt'),
      render: (params) => {
        return formatDateTimeUtc(params);
      },
    },
    {
      key: 'action',
      dataIndex: 'id',
      title: t('common.action'),
      disableClickEventBubbling: true,
      sorter: false,
      align: 'center',
      headerAlign: 'center',
      render: (params, row) => {
        return (
          <div className="action-button">
            <Button
              type="text"
              shape="circle"
              icon={<EyeFilled />}
              onClick={() => onClickViewDetails(params)}
            />
          </div>
        );
      },
    },
  ];

  const handleCreate = () => {
    redirectRouter(ROUTES.INVENTORIES_CHECK_CREATE.PATH);
  };

  const refreshData = () => {
    props.searchInventoriesCheck({
      keyword: keyword.trim(),
      offset: (page - 1) * pageSize,
      limit: pageSize,
      filter: JSON.stringify(filter),
      sort,
    });
  };

  const handleSearch = () => {
    refreshData();
  };

  const updateKeyword = (value) => {
    setKeyWord(value);
  };

  const onPageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
  };

  const onPageChange = ({ page }) => {
    setPage(page);
  };

  const onChangeFilter = (filter) => {
    setFilter(filter);
    setPage(1);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSort({
      column: sorter.field,
      value: sorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  const onClickViewDetails = (id) => {
    redirectRouter(
      ROUTES.INVENTORIES_CHECK_DETAIL.PATH.replace('/:id', `/${id}`),
    );
  };

  return (
    <Spin spinning={inventoriesCheck?.isLoading}>
      <TopContent
        title={t('inventoriesCheck.title')}
        breadcrumbs={breadcrumbs}
        displayButton={['create']}
        createButton={t('inventoriesCheck.createButton')}
        handleCreateOpenModal={handleCreate}
        updateKeyword={updateKeyword}
        handleSearch={handleSearch}
      />
      <CustomTable
        columns={columns}
        rows={inventoriesCheck?.inventoriesCheckList}
        page={page}
        pageSize={pageSize}
        total={inventoriesCheck?.total}
        topInfo={true}
        onChange={handleTableChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onChangeFilter={onChangeFilter}
      />
    </Spin>
  );
}

const mapDispatchToProps = {
  searchInventoriesCheck,
};

const mapStateToProps = (state) => ({
  inventoriesCheck: state.inventoriesCheck,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(InventoriesCheck),
);
