import { GOOGLE_CLIENT_ID } from 'common/constant';
import React from 'react';
import { GoogleLogout } from 'react-google-login';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logout } from 'stores/auth/auth.action';
import './index.scss';

const clientId = GOOGLE_CLIENT_ID;

function CustomGoogleLogout(props) {
  const { t } = props;
  const onSuccess = () => {
    props.logout();
  };

  return (
    <div style={{ width: '100%' }}>
      <GoogleLogout
        clientId={clientId}
        buttonText={t('common.googleLogoutButton')}
        onLogoutSuccess={onSuccess}
        className="google-logout-container"
      />
    </div>
  );
}

const mapDispatchToProps = {
  logout,
};

const mapStateToProps = (state) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CustomGoogleLogout),
);
