import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  confirmStoreFailed,
  confirmStoreSuccess,
  CONFIRM_STORE_START,
} from 'stores/store/store.action';

/**
 * Confirm store API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const confirmStoreApi = (params) => {
  const uri = `/${API_URL.STORE}/confirm/${params.id}`;
  return api.put(uri, params.data);
};

/**
 * Handle confirm request and response
 * @param {object} action
 */
function* doConfirmStore(action) {
  try {
    const response = yield call(confirmStoreApi, action?.payload);

    if (response?.status === 200) {
      yield put(confirmStoreSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification('store.confirmStoreSuccess', NOTIFICATION_TYPE.SUCCESS);
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(confirmStoreFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch confirm store
 */
export default function* watchConfirmStore() {
  yield takeLatest(CONFIRM_STORE_START, doConfirmStore);
}
