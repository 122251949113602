import {
  CREATE_BARCODE_START,
  CREATE_BARCODE_SUCCESS,
  CREATE_BARCODE_FAILED,
  DELETE_BARCODE_START,
  DELETE_BARCODE_SUCCESS,
  DELETE_BARCODE_FAILED,
  SEARCH_BARCODE_START,
  SEARCH_BARCODE_SUCCESS,
  SEARCH_BARCODE_FAILED,
} from './barcode.action';

const initialState = {
  isLoading: false,
  barcodeList: [],
  total: 0,
};

/**
 * barcode reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function barcode(state = initialState, action) {
  switch (action.type) {
    case CREATE_BARCODE_START:
    case DELETE_BARCODE_START:
    case SEARCH_BARCODE_START:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_BARCODE_SUCCESS:
    case DELETE_BARCODE_SUCCESS:
    case CREATE_BARCODE_FAILED:
    case DELETE_BARCODE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_BARCODE_SUCCESS:
      return {
        ...state,
        barcodeList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case SEARCH_BARCODE_FAILED:
      return {
        ...state,
        barcodeList: [],
        total: 0,
        isLoading: false,
      };
    default:
      return state;
  }
}
