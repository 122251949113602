import React from 'react';
import FilterImage from 'assets/images/filter.png';
import { Button, Input, Popover, Row } from 'antd';
import { Form, Select, Divider } from 'antd';
import DateRangePicker from 'components/common/date-range-picker';
import './style.scss';
import { DATE_COLUMN_TYPE } from 'common/constant';

export default function FilterDataPanel(props) {
  const { columns, onChangeFilter, t } = props;
  const [filterValue, setFilterValue] = React.useState(null);
  const columnsFilter = columns.filter((item) => item.filterable === true);
  const defaultFilterColumn = columnsFilter[0]?.key;
  const [currentFilterField, setCurrentFilterField] =
    React.useState(defaultFilterColumn);
  const { Option } = Select;
  const onChangeSelectColumn = (event) => {
    setCurrentFilterField(event);
    renderFilterInput();
  };
  const onChangeDateFilterFrom = (date) => {
    const dateFrom = date ? date[0] : null;
    const dateTo = date ? date[1] : null;
    setFilterValue(convertToString(dateFrom, dateTo));
  };
  const convertToString = (dateFrom, dateTo) => {
    dateFrom = new Date(dateFrom);
    const from = dateFrom?.toISOString();
    dateTo = new Date(dateTo);
    const to = dateTo?.toISOString();
    const dates = from + '|' + to;
    return dates;
  };
  const onChangeFilterInput = (event) => {
    const value = event?.target.value;
    setFilterValue(value);
  };
  const onChangeFilterInputOption = (event) => {
    const value = event;
    setFilterValue(value);
  };
  const onEnterFilterInput = (e) => {
    if (e.key === 'Enter') {
      onChangeFilter(currentFilterField, filterValue);
    }
  };
  const onUpdateFilterValue = () => {
    onChangeFilter(currentFilterField, filterValue);
  };
  const onCancelFilterValue = () => {
    setFilterValue('');
    setCurrentFilterField(defaultFilterColumn);
    renderFilterInput();
  };
  const renderFilterInput = () => {
    const column = columns?.find((item) => item.key === currentFilterField);
    if (column?.type === 'date') {
      return (
        <DateRangePicker
          isSubmitForm={true}
          from={null}
          to={null}
          isRequiredFrom={false}
          isRequiredTo={false}
          onChange={(dates) => onChangeDateFilterFrom(dates)}
          className={'mr-2 date-picker-custom'}
        />
      );
    }
    if (column?.type === DATE_COLUMN_TYPE) {
      const { options, getOptions, getOptionValue, getOptionLabel } =
        column?.filterOptions;
      const filterOptions = options || getOptions();
      return (
        <Select
          value={filterValue}
          onChange={onChangeFilterInputOption}
          size="small"
        >
          {filterOptions?.map((item, i) => (
            <Option key={i} value={getOptionValue(item)}>
              {getOptionLabel(item)?.substr(0, 50)}
            </Option>
          ))}
        </Select>
      );
    }
    return (
      <Input
        name="search-key"
        size="small"
        placeholder={t('customTable.inputValue')}
        value={filterValue}
        onChange={onChangeFilterInput}
        onKeyDown={onEnterFilterInput}
      />
    );
  };
  const content = (
    <div>
      <Row>
        <label className="marginRow">{t('customTable.title')}</label>
      </Row>
      <Divider />
      <form>
        <Form.Item>
          <Select
            id="currentFilterField"
            value={currentFilterField}
            onChange={(event) => onChangeSelectColumn(event)}
            placeholder={t('customTable.object')}
          >
            {columnsFilter?.map((item) => (
              <Option key={item.key} value={item.key}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div>
          <Form.Item>{renderFilterInput()}</Form.Item>
        </div>
        <div className="filterButton">
          <Button
            onClick={onUpdateFilterValue}
            className="mini-shop-button"
            type="primary"
          >
            {t('customTable.btnFilter')}
          </Button>
          <Button
            danger
            onClick={onCancelFilterValue}
          >
            {t('customTable.btnCancel')}
          </Button>
        </div>
      </form>
    </div>
  );
  return (
    <div>
      <Popover
        overlayStyle={{
          width: '306px',
        }}
        placement="bottom"
        content={content}
        trigger="click"
      >
        <Button className="btn-filter">
          <img src={FilterImage} alt="filter" />
        </Button>
      </Popover>
    </div>
  );
}
