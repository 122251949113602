import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  getItemDetailFailed,
  getItemDetailSuccess,
  GET_ITEM_DETAIL_START,
} from 'stores/item/item.action';

/**
 * Get item detail API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getItemDetailApi = (params) => {
  const uri = `/${API_URL.ITEM}/${params}`;
  return api.get(uri);
};

/**
 * Handle get detail request and response
 * @param {object} action
 */
function* doGetItemDetail(action) {
  try {
    const response = yield call(getItemDetailApi, action?.payload);

    if (response?.status === 200) {
      yield put(getItemDetailSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(getItemDetailFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch update item
 */
export default function* watchGetItemDetail() {
  yield takeLatest(GET_ITEM_DETAIL_START, doGetItemDetail);
}
