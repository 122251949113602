export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const SEARCH_USER_START = 'SEARCH_USER_START';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILED = 'SEARCH_USER_FAILED';

export const GET_USER_DETAIL_START = 'GET_USER_DETAIL_START';
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS';
export const GET_USER_DETAIL_FAILED = 'GET_USER_DETAIL_FAILED';

export const CHANGE_PASSWORD_ADMIN_START = 'CHANGE_PASSWORD_ADMIN_START';
export const CHANGE_PASSWORD_ADMIN_SUCCESS = 'CHANGE_PASSWORD_ADMIN_SUCCESS';
export const CHANGE_PASSWORD_ADMIN_FAILED = 'CHANGE_PASSWORD_ADMIN_FAILED';

export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const TRANSFER_COIN_START = 'TRANSFER_COIN_START';
export const TRANSFER_COIN_SUCCESS = 'TRANSFER_COIN_SUCCESS';
export const TRANSFER_COIN_FAILED = 'TRANSFER_COIN_FAILED';

export const FORGET_PASSWORD_START = 'FORGET_PASSWORD_START';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

export const GENERATE_CODE_START = 'GENERATE_CODE_START';
export const GENERATE_CODE_SUCCESS = 'GENERATE_CODE_SUCCESS';
export const GENERATE_CODE_FAILED = 'GENERATE_CODE_FAILED';

export const VERIFY_CODE_START = 'VERIFY_CODE_START';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_FAILED = 'VERIFY_CODE_FAILED';

export const CREATE_USER_BANK_START = 'CREATE_USER_BANK_START';
export const CREATE_USER_BANK_SUCCESS = 'CREATE_USER_BANK_SUCCESS';
export const CREATE_USER_BANK_FAILED = 'CREATE_USER_BANK_FAILED';

export const UPDATE_USER_BANK_START = 'UPDATE_USER_BANK_START';
export const UPDATE_USER_BANK_SUCCESS = 'UPDATE_USER_BANK_SUCCESS';
export const UPDATE_USER_BANK_FAILED = 'UPDATE_USER_BANK_FAILED';

export const CREATE_DEVICE_TOKEN_START = 'CREATE_DEVICE_TOKEN_START';
export const CREATE_DEVICE_TOKEN_SUCCESS = 'CREATE_DEVICE_TOKEN_SUCCESS';
export const CREATE_DEVICE_TOKEN_FAILED = 'CREATE_DEVICE_TOKEN_FAILED';

/**
 * Create user
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createUser(payload, onSuccess, onError) {
  return {
    type: CREATE_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create user success action
 * @param {*} payload
 * @returns {object}
 */
export function createUserSuccess(payload) {
  return {
    type: CREATE_USER_SUCCESS,
    payload: payload,
  };
}

/**
 * Create user failed action
 * @returns {object}
 */
export function createUserFailed() {
  return {
    type: CREATE_USER_FAILED,
  };
}

/**
 * Update user
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateUser(payload, id, onSuccess, onError) {
  return {
    type: UPDATE_USER_START,
    payload: {
      id,
      data: payload,
    },
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update user success action
 * @param {*} payload
 * @returns {object}
 */
export function updateUserSuccess(payload) {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: payload,
  };
}

/**
 * Update user failed action
 * @returns {object}
 */
export function updateUserFailed() {
  return {
    type: UPDATE_USER_FAILED,
  };
}

/**
 * Delete user
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function deleteUser(payload, onSuccess, onError) {
  return {
    type: DELETE_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Delete user success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteUserSuccess(payload) {
  return {
    type: DELETE_USER_SUCCESS,
    payload: payload,
  };
}

/**
 * Delete user failed action
 * @returns {object}
 */
export function deleteUserFailed() {
  return {
    type: DELETE_USER_FAILED,
  };
}

/**
 * Search user
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function searchUser(payload, onSuccess, onError) {
  return {
    type: SEARCH_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Search user success action
 * @param {*} payload
 * @returns {object}
 */
export function searchUserSuccess(payload) {
  return {
    type: SEARCH_USER_SUCCESS,
    payload: payload,
  };
}

/**
 * Search user failed action
 * @returns {object}
 */
export function searchUserFailed() {
  return {
    type: SEARCH_USER_FAILED,
  };
}

/**
 * Get user detail
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getUserDetail(payload, onSuccess, onError) {
  return {
    type: GET_USER_DETAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Get user detail success action
 * @param {*} payload
 * @returns {object}
 */
export function getUserDetailSuccess(payload) {
  return {
    type: GET_USER_DETAIL_SUCCESS,
    payload: payload,
  };
}

/**
 * Get user detail failed action
 * @returns {object}
 */
export function getUserDetailFailed() {
  return {
    type: GET_USER_DETAIL_FAILED,
  };
}

/**
 * Change password admin
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function changePasswordAdmin(payload, onSuccess, onError) {
  return {
    type: CHANGE_PASSWORD_ADMIN_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Change password admin success action
 * @param {*} payload
 * @returns {object}
 */
export function changePasswordAdminSuccess(payload) {
  return {
    type: CHANGE_PASSWORD_ADMIN_SUCCESS,
    payload: payload,
  };
}

/**
 * Change password admin failed action
 * @returns {object}
 */
export function changePasswordAdminFailed() {
  return {
    type: CHANGE_PASSWORD_ADMIN_FAILED,
  };
}

/**
 * Change password
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function changePassword(payload, onSuccess, onError) {
  return {
    type: CHANGE_PASSWORD_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Change password success action
 * @param {*} payload
 * @returns {object}
 */
export function changePasswordSuccess(payload) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: payload,
  };
}

/**
 * Change password failed action
 * @returns {object}
 */
export function changePasswordFailed() {
  return {
    type: CHANGE_PASSWORD_FAILED,
  };
}

/**
 * Transfer coin
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function transferCoin(payload, onSuccess, onError) {
  return {
    type: TRANSFER_COIN_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Transfer coin success action
 * @param {*} payload
 * @returns {object}
 */
export function transferCoinSuccess(payload) {
  return {
    type: TRANSFER_COIN_SUCCESS,
    payload: payload,
  };
}

/**
 * Transfer coin failed action
 * @returns {object}
 */
export function transferCoinFailed() {
  return {
    type: TRANSFER_COIN_FAILED,
  };
}

/**
 * Forget password
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function forgetPassword(payload, onSuccess, onError) {
  return {
    type: FORGET_PASSWORD_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Forget password success action
 * @param {*} payload
 * @returns {object}
 */
export function forgetPasswordSuccess(payload) {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: payload,
  };
}

/**
 * Forget password failed action
 * @returns {object}
 */
export function forgetPasswordFailed() {
  return {
    type: FORGET_PASSWORD_FAILED,
  };
}

/**
 * Generate code
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function generateCode(payload, onSuccess, onError) {
  return {
    type: GENERATE_CODE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Generate code success action
 * @param {*} payload
 * @returns {object}
 */
export function generateCodeSuccess(payload) {
  return {
    type: GENERATE_CODE_SUCCESS,
    payload: payload,
  };
}

/**
 * Generate code failed action
 * @returns {object}
 */
export function generateCodeFailed() {
  return {
    type: GENERATE_CODE_FAILED,
  };
}

/**
 * Verify code
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function verifyCode(payload, onSuccess, onError) {
  return {
    type: VERIFY_CODE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Verify code success action
 * @param {*} payload
 * @returns {object}
 */
export function verifyCodeSuccess(payload) {
  return {
    type: VERIFY_CODE_SUCCESS,
    payload: payload,
  };
}

/**
 * Verify code failed action
 * @returns {object}
 */
export function verifyCodeFailed() {
  return {
    type: VERIFY_CODE_FAILED,
  };
}

/**
 * Create user bank
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function createUserBank(payload, onSuccess, onError) {
  return {
    type: CREATE_USER_BANK_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create user bank success action
 * @param {*} payload
 * @returns {object}
 */
export function createUserBankSuccess(payload) {
  return {
    type: CREATE_USER_BANK_SUCCESS,
    payload: payload,
  };
}

/**
 * Create user bank failed action
 * @returns {object}
 */
export function createUserBankFailed() {
  return {
    type: CREATE_USER_BANK_FAILED,
  };
}

/**
 * Update user bank
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateUserBank(payload, onSuccess, onError) {
  return {
    type: UPDATE_USER_BANK_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update user bank success action
 * @param {*} payload
 * @returns {object}
 */
export function updateUserBankSuccess(payload) {
  return {
    type: UPDATE_USER_BANK_SUCCESS,
    payload: payload,
  };
}

/**
 * Update user bank failed action
 * @returns {object}
 */
export function updateUserBankFailed() {
  return {
    type: UPDATE_USER_BANK_FAILED,
  };
}

/**
 * Create device token
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function createDeviceToken(payload, onSuccess, onError) {
  return {
    type: CREATE_DEVICE_TOKEN_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create device token success action
 * @param {*} payload
 * @returns {object}
 */
export function createDeviceTokenSuccess(payload) {
  return {
    type: CREATE_DEVICE_TOKEN_SUCCESS,
    payload: payload,
  };
}

/**
 * Create device token failed action
 * @returns {object}
 */
export function createDeviceTokenFailed() {
  return {
    type: CREATE_DEVICE_TOKEN_FAILED,
  };
}
