import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, Divider, Row, Avatar } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import routes, { ROUTES } from 'common/routes';
import { Link, useLocation } from 'react-router-dom';
import Collapse from 'assets/images/collapse.png';
import logo from 'assets/images/logo.png';
import { LogoutOutlined } from '@ant-design/icons';
import { logout } from 'stores/auth/auth.action';
import './style.scss';
import {
  DEFAULT_USER_AVARTAR,
  IMAGE_RELATION_TYPE,
  DEVICE_MIN_WIDTH,
  NULL_STRING,
  USER_TYPE_ENUM,
} from 'common/constant';
import { getImageUrl, redirectRouter } from 'utils';
import CustomGoogleLogout from 'components/common/google-logout';
const { Sider } = Layout;

const Sidebar = (props) => {
  const { t, user } = props;
  const location = useLocation();
  const deviceWidth = window.innerWidth;
  let { pathname } = location;
  const [openKeys, setOpenKeys] = useState([]);
  const [collapse, setColappse] = useState(deviceWidth <= DEVICE_MIN_WIDTH);
  const [currentUser, setCurrentUser] = useState(null);
  const userType = JSON.parse(window.localStorage.getItem('user'))?.user_type;
  const menuRoutes = routes.filter(
    (item) =>
      item.isInSidebar &&
      ((userType === USER_TYPE_ENUM.ADMIN && !item.shopOwnerOnly) ||
        ([USER_TYPE_ENUM.SHOPOWNER, USER_TYPE_ENUM.GOOGLE_SHOP_OWNER].includes(
          userType,
        ) &&
          !item.adminOnly)),
  );
  let openKey = menuRoutes.findIndex(
    (item) =>
      item.subMenu && item.subMenu.map((sub) => sub.path).includes(pathname),
  );
  if (openKey === -1 && pathname !== '/') {
    openKey = menuRoutes.findIndex((item) => item.subMenu);
  }
  const isGoogleLogin = window.localStorage.getItem('googleLogin') === 'true';

  useEffect(() => {
    setOpenKeys(openKey ? [openKey.toString()] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openKey]);

  useEffect(() => {
    setCurrentUser(JSON.parse(window.localStorage.getItem('user')));
  }, [user?.userDetail]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey?.substring(0, 5) === 'child' || openKeys.length > 1)
      setOpenKeys(keys);
    else setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };
  const onChangeCollapse = () => {
    setColappse(!collapse);
  };

  const redirectToProfile = () => {
    redirectRouter(ROUTES.SHOP_OWNER_PROFILE.PATH);
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-menu">
        <Sider
          trigger={null}
          collapsible
          collapsed={collapse}
          width={deviceWidth <= DEVICE_MIN_WIDTH ? deviceWidth : 272}
        >
          <div className={collapse ? 'group-logo-collapsed' : 'group-logo'}>
            <a
              style={{ marginLeft: '2vw' }}
              className={collapse ? 'logo-collapsed' : ''}
              href="/"
            >
              <img src={logo} alt="logo" width="150" height="100" />
            </a>
            <Button
              className={`btn-collpase ${collapse ? 'btn-collpase-done' : ''}`}
              onClick={onChangeCollapse}
            >
              <img src={Collapse} alt="collpase" />
            </Button>
          </div>
          <div className="user-info" onClick={() => redirectToProfile()}>
            <div className="avatar-container">
              <Avatar
                src={
                  window.localStorage.getItem('userImage') !== NULL_STRING
                    ? getImageUrl(
                        `${
                          IMAGE_RELATION_TYPE.USER
                        }/${window.localStorage.getItem(
                          'userId',
                        )}/${window.localStorage.getItem('userImage')}`,
                      )
                    : DEFAULT_USER_AVARTAR
                }
                alt="user-avatar"
                size={50}
              />
            </div>
            {!collapse && (
              <div>
                <Row className="full-name">{currentUser?.full_name}</Row>
                <Row className="user-mail">{currentUser?.email}</Row>
              </div>
            )}
          </div>
          <div className="menu">
            <Menu
              mode="inline"
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              selectedKeys={[pathname]}
              className="tab-menu"
            >
              {routes
                .filter(
                  (item) =>
                    item.isInSidebar &&
                    ((userType === USER_TYPE_ENUM.ADMIN &&
                      !item.shopOwnerOnly) ||
                      ([
                        USER_TYPE_ENUM.SHOPOWNER,
                        USER_TYPE_ENUM.GOOGLE_SHOP_OWNER,
                      ].includes(userType) &&
                        !item.adminOnly)),
                )
                .map((route, key) => {
                  return (
                    <Menu.Item
                      key={`${route.path}${key}`}
                      icon={route.icon}
                      className={
                        pathname === route.path ||
                        (pathname.includes(route.path) &&
                          route.path !== '/' &&
                          pathname.length > route.path.length &&
                          pathname[route.path.length] === '/')
                          ? 'box-active'
                          : ''
                      }
                    >
                      <Link to={route.path}>{t(`menu.${route.name}`)}</Link>
                    </Menu.Item>
                  );
                })}
            </Menu>
          </div>
        </Sider>
      </div>
      <Divider />
      <div className="sidebar-action-button">
        {isGoogleLogin ? (
          <CustomGoogleLogout />
        ) : (
          <Button
            type="text"
            icon={<LogoutOutlined />}
            onClick={() => props.logout()}
          >
            {t('auth.logout')}
          </Button>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  logout,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar),
);
