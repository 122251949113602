import {
  CHANGE_LANGUAGE,
  GET_CURRENCY_UNIT_START,
  GET_CURRENCY_UNIT_SUCCESS,
  GET_CURRENCY_UNIT_FAILED,
  SEARCH_INVENTORIES_START,
  SEARCH_INVENTORIES_SUCCESS,
  SEARCH_INVENTORIES_FAILED,
  SEARCH_ORDERS_START,
  SEARCH_ORDERS_SUCCESS,
  SEARCH_ORDERS_FAILED,
  SEARCH_SHOP_OWNER_ORDERS_START,
  SEARCH_SHOP_OWNER_ORDERS_SUCCESS,
  SEARCH_SHOP_OWNER_ORDERS_FAILED,
  GET_ORDER_DETAIL_START,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_DETAIL_FAILED,
  SEARCH_SHOP_OWNER_BEST_SELLER_START,
  SEARCH_SHOP_OWNER_BEST_SELLER_SUCCESS,
  SEARCH_SHOP_OWNER_BEST_SELLER_FAILED,
  SEARCH_ADMIN_TRANSACTIONS_START,
  SEARCH_ADMIN_TRANSACTIONS_SUCCESS,
  SEARCH_ADMIN_TRANSACTIONS_FAILED,
  SEARCH_TRANSACTIONS_START,
  SEARCH_TRANSACTIONS_SUCCESS,
  SEARCH_TRANSACTIONS_FAILED,
  CONFIRM_ORDER_START,
  CONFIRM_ORDER_SUCCESS,
  CONFIRM_ORDER_FAILED,
} from './common.action';

const initialState = {
  lang: null,
  isLoading: false,
  currencyUnitList: [],
  inventoriesList: [],
  orderList: [],
  orderDetail: {},
  total: 0,
  bestSeller: [],
  transactionList: [],
  transactionTotal: 0,
};

/**
 * auth reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function common(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        lang: action.payload,
      };
    case GET_CURRENCY_UNIT_START:
    case SEARCH_INVENTORIES_START:
    case SEARCH_ORDERS_START:
    case SEARCH_SHOP_OWNER_ORDERS_START:
    case GET_ORDER_DETAIL_START:
    case SEARCH_SHOP_OWNER_BEST_SELLER_START:
    case SEARCH_ADMIN_TRANSACTIONS_START:
    case SEARCH_TRANSACTIONS_START:
    case CONFIRM_ORDER_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CURRENCY_UNIT_SUCCESS:
      return {
        ...state,
        currencyUnitList: action.payload,
        isLoading: false,
      };
    case SEARCH_INVENTORIES_SUCCESS:
      return {
        ...state,
        inventoriesList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case SEARCH_ORDERS_SUCCESS:
    case SEARCH_SHOP_OWNER_ORDERS_SUCCESS:
      return {
        ...state,
        orderList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderDetail: action.payload,
        isLoading: false,
      };
    case SEARCH_SHOP_OWNER_BEST_SELLER_SUCCESS:
      return {
        ...state,
        bestSeller: action.payload,
        isLoading: false,
      };
    case SEARCH_ADMIN_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionList: action.payload,
        isLoading: false,
      };
    case SEARCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionList: action.payload.list,
        transactionTotal: action.payload.total,
        isLoading: false,
      };
    case GET_CURRENCY_UNIT_FAILED:
      return {
        ...state,
        currencyUnitList: [],
        isLoading: false,
      };
    case SEARCH_INVENTORIES_FAILED:
      return {
        ...state,
        inventoriesList: [],
        total: 0,
        isLoading: false,
      };
    case SEARCH_ORDERS_FAILED:
    case SEARCH_SHOP_OWNER_ORDERS_FAILED:
      return {
        ...state,
        orderList: [],
        total: 0,
        isLoading: false,
      };
    case GET_ORDER_DETAIL_FAILED:
      return {
        ...state,
        orderDetail: {},
        isLoading: false,
      };
    case SEARCH_SHOP_OWNER_BEST_SELLER_FAILED:
      return {
        ...state,
        bestSeller: [],
        isLoading: false,
      };
    case SEARCH_ADMIN_TRANSACTIONS_FAILED:
      return {
        ...state,
        transactionList: [],
        isLoading: false,
      };
    case SEARCH_TRANSACTIONS_FAILED:
      return {
        ...state,
        transactionList: [],
        transactionTotal: 0,
        isLoading: false,
      };
    case CONFIRM_ORDER_SUCCESS:
    case CONFIRM_ORDER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
