import { all } from 'redux-saga/effects';

// common
import watchGetCurrencyUnit from './common/get-currency-unit.saga';
import watchSearchInventories from './common/search-inventories.saga';
import watchSearchOrders from './common/search-orders.saga';
import watchGetOrderDetail from './common/get-order-detail.saga';
import watchRedirectErrorPage from './common/redirect-to-error-page.saga';
import watchSearchShopOwnerOrders from './common/search_shop_owner_orders.saga';
import watchSearchShopOwnerBestSeller from './common/search-shop-owner-best-seller.saga';
import watchSearchAdminTransactions from './common/search-admin-transaction.saga';
import watchSearchTransactions from './common/search-transaction-list.saga';
import watchConfirmOrder from './common/confirm-order.saga';

// auth
import watchLogin from './auth/login.saga';
import watchLogout from './auth/logout.saga';
import watchRefreshToken from './auth/refresh_token.saga';

// category
import watchCreateCategory from './category/create-category.saga';
import watchUpdateCategory from './category/update-category.saga';
import watchDeleteCategory from './category/delete-category.saga';
import watchGetCategoryDetail from './category/get-category-detail.saga';
import watchSearchCategory from './category/search-category.saga';

// item
import watchCreateItem from './item/create-item.saga';
import watchUpdateItem from './item/update-item.saga';
import watchDeleteItem from './item/delete-item.saga';
import watchGetItemDetail from './item/get-item-detail';
import watchSearchItem from './item/search-item.saga';
import watchImportItem from './item/import-item.saga';

// item-unit
import watchCreateItemUnit from './item-unit/create-item-unit.saga';
import watchUpdateItemUnit from './item-unit/update-item-unit.saga';
import watchDeleteItemUnit from './item-unit/delete-item-unit.saga';
import watchGetItemUnitDetail from './item-unit/get-item-unit-detail.saga';
import watchSearchItemUnit from './item-unit/search-item-unit.saga';

// store
import watchCreateStore from './store/create-store.saga';
import watchUpdateStore from './store/update-store.saga';
import watchDeleteStore from './store/delete-store.saga';
import watchGetStoreDetail from './store/get-store-detail.saga';
import watchSearchStore from './store/search-store.saga';
import watchConfirmStore from './store/confirm-store.saga';
import watchCreateStoreTransaction from './store/create-store-transaction.saga';
import watchCompleteStoreTransaction from './store/confirm-store-transaction.saga';
import watchRejectStoreTransaction from './store/reject-store-transaction.saga';

// user
import watchCreateUser from './user/create-user.saga';
import watchUpdateUser from './user/update-user.saga';
import watchDeleteUser from './user/delete-user.saga';
import watchGetUserDetail from './user/get-user-detail.saga';
import watchSearchUser from './user/search-user.saga';
import watchChangePasswordAdmin from './user/change-password-admin.saga';
import watchChangePassword from './user/change-password.saga';
import watchTransferCoin from './user/transfer-coin.saga';
import watchForgetPassword from './user/forget-password.saga';
import watchGenerateCode from './user/generate-code.saga';
import watchVerifyCode from './user/verify-code.saga';
import watchCreateUserBank from './user/create_user_bank.saga';
import watchUpdateUserBank from './user/update_user_bank.saga';
import watchCreateDeviceToken from './user/create-device-token.saga';

// import-item-ticket
import watchCreateImportItemTicket from './import-item-ticket/create-import-item-ticket.saga';
import watchUpdateImportItemTicket from './import-item-ticket/update-import-item-ticket.saga';
import watchDeleteImportItemTicket from './import-item-ticket/delete-import-item-ticket.saga';
import watchSearchImportItemTicket from './import-item-ticket/search-import-item-ticket.saga';
import watchGetImportItemTicketDetail from './import-item-ticket/get-import-item-ticket-detail.saga';
import watchConfirmImportItemTicket from './import-item-ticket/confirm-import-item-ticket.saga';

// barcode
import watchCreateBarcode from './barcode/create-barcode.saga';
import watchDeleteBarcode from './barcode/delete-barcode.saga';
import watchSearchBarcode from './barcode/search-barcode.saga';

// currency-unit
import watchCreateCurrencyUnit from './currency-unit/create-currency-unit.saga';
import watchUpdateCurrencyUnit from './currency-unit/update-currency-unit.saga';
import watchDeleteCurrencyUnit from './currency-unit/delete-currency-unit.saga';
import watchGetCurrencyUnitDetail from './currency-unit/get-currency-unit-detail.saga';
import watchSearchCurrencyUnit from './currency-unit/search-currency-unit.saga';

// conversion-rate
import watchCreateConversionRate from './conversion-rate/create-conversion-rate.saga';
import watchUpdateConversionRate from './conversion-rate/update-conversion-rate.saga';
import watchDeleteConversionRate from './conversion-rate/delete-conversion-rate.saga';
import watchGetConversionRateDetail from './conversion-rate/get-conversion-rate-detail.saga';
import watchSearchConversionRate from './conversion-rate/search-conversion-rate.saga';

// payment-type
import watchCreatePaymentType from './payment-type/create-payment-type.saga';
import watchUpdatePaymentType from './payment-type/update-payment-type.saga';
import watchDeletePaymentType from './payment-type/delete-payment-type.saga';
import watchGetPaymentTypeDetail from './payment-type/get-payment-type.saga';
import watchSearchPaymentType from './payment-type/search-payment-type.saga';

// inventories-check
import watchCreateInventoriesCheck from './inventories-check/create-inventories-check.saga';
import watchUpdateInventoriesCheck from './inventories-check/update-inventories-check.saga';
import watchDeleteInventoriesCheck from './inventories-check/delete-inventories-check.saga';
import watchGetInventoriesCheckDetail from './inventories-check/get-inventories-check-detail.saga';
import watchSearchInventoriesCheck from './inventories-check/search-inventories-check.saga';
import watchConfirmInventoriesCheck from './inventories-check/confirm-inventories-check.saga';

// notification
import watchGetNotificationList from './notification/get-notification-list.saga';
import watchGetNotificationSettingsList from './notification/get-notification-settings-list.saga';
import watchUpdateAllNotificationStatus from './notification/update-all-notification-status.saga';
import watchUpdateNotificationStatus from './notification/update-notification-status.saga';
import watchUpdateNotificationSettingStatus from './notification/update-notification-setting-status.saga';

export default function* rootSaga() {
  yield all([
    // common
    watchGetCurrencyUnit(),
    watchSearchInventories(),
    watchSearchOrders(),
    watchGetOrderDetail(),
    watchRedirectErrorPage(),
    watchSearchShopOwnerOrders(),
    watchSearchShopOwnerBestSeller(),
    watchSearchAdminTransactions(),
    watchSearchTransactions(),
    watchConfirmOrder(),
    // auth
    watchLogin(),
    watchLogout(),
    watchRefreshToken(),
    // category
    watchCreateCategory(),
    watchUpdateCategory(),
    watchDeleteCategory(),
    watchGetCategoryDetail(),
    watchSearchCategory(),
    // item
    watchCreateItem(),
    watchUpdateItem(),
    watchDeleteItem(),
    watchGetItemDetail(),
    watchSearchItem(),
    watchImportItem(),
    // item-unit
    watchCreateItemUnit(),
    watchUpdateItemUnit(),
    watchDeleteItemUnit(),
    watchGetItemUnitDetail(),
    watchSearchItemUnit(),
    // store
    watchCreateStore(),
    watchUpdateStore(),
    watchDeleteStore(),
    watchGetStoreDetail(),
    watchSearchStore(),
    watchConfirmStore(),
    watchCreateStoreTransaction(),
    watchCompleteStoreTransaction(),
    watchRejectStoreTransaction(),
    // user
    watchCreateUser(),
    watchUpdateUser(),
    watchDeleteUser(),
    watchGetUserDetail(),
    watchSearchUser(),
    watchChangePasswordAdmin(),
    watchChangePassword(),
    watchTransferCoin(),
    watchForgetPassword(),
    watchGenerateCode(),
    watchVerifyCode(),
    watchCreateUserBank(),
    watchUpdateUserBank(),
    watchCreateDeviceToken(),
    // import-item-ticket
    watchCreateImportItemTicket(),
    watchUpdateImportItemTicket(),
    watchDeleteImportItemTicket(),
    watchSearchImportItemTicket(),
    watchGetImportItemTicketDetail(),
    watchConfirmImportItemTicket(),
    // barcode
    watchCreateBarcode(),
    watchDeleteBarcode(),
    watchSearchBarcode(),
    // currency-unit
    watchCreateCurrencyUnit(),
    watchUpdateCurrencyUnit(),
    watchDeleteCurrencyUnit(),
    watchGetCurrencyUnitDetail(),
    watchSearchCurrencyUnit(),
    // conversion-rate
    watchCreateConversionRate(),
    watchUpdateConversionRate(),
    watchDeleteConversionRate(),
    watchGetConversionRateDetail(),
    watchSearchConversionRate(),
    // payment-type
    watchCreatePaymentType(),
    watchUpdatePaymentType(),
    watchDeletePaymentType(),
    watchGetPaymentTypeDetail(),
    watchSearchPaymentType(),
    // inventories-check
    watchCreateInventoriesCheck(),
    watchUpdateInventoriesCheck(),
    watchDeleteInventoriesCheck(),
    watchGetInventoriesCheckDetail(),
    watchSearchInventoriesCheck(),
    watchConfirmInventoriesCheck(),
    // notification
    watchGetNotificationList(),
    watchGetNotificationSettingsList(),
    watchUpdateAllNotificationStatus(),
    watchUpdateNotificationStatus(),
    watchUpdateNotificationSettingStatus(),
  ]);
}
