import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import {
  Form,
  Spin,
  Card,
  Row,
  Col,
  Input,
  DatePicker,
  Button,
  Modal,
  Select,
  Upload,
} from 'antd';
import TopContent from 'components/common/top-content';
import {
  DATE_FORMAT,
  DEFAULT_PASSWORD,
  IMAGE_RELATION_TYPE,
  IMAGE_STATUS,
  MODAL_MODE,
  PATTERN,
  TEXTFIELD_REQUIRED_LENGTH,
  USER_STATUS,
  USER_STATUS_MAP,
  USER_TYPE,
  USER_TYPE_ENUM,
} from 'common/constant';
import moment from 'moment';
import { formatDateTimeUtc, getImageUrl, redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import {
  createUser,
  updateUser,
  changePasswordAdmin,
  getUserDetail,
} from 'stores/user/user.action';
import { PlusOutlined } from '@ant-design/icons';
import Coin from 'assets/images/coin.png';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const { Option } = Select;

function UserForm(props) {
  const { t, user } = props;
  const [form] = Form.useForm();
  const [mode, setMode] = useState(MODAL_MODE.CREATE);
  const [status, setStatus] = useState(0);
  const [title, setTitle] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      route: ROUTES.USER.PATH,
      title: ROUTES.USER.TITLE,
    },
    {
      title: ROUTES.USER_CREATE.TITLE,
    },
  ]);
  const [openChangePasswordAdmin, setOpenChangePasswordAdmin] = useState(false);
  const [image, setImage] = useState(null);
  const [userType, setUserType] = useState(null);
  const path = props.match.path;
  const { id } = props.match.params;

  useEffect(() => {
    setMode(
      path.includes('/update/')
        ? MODAL_MODE.UPDATE
        : path.includes('/detail/')
        ? MODAL_MODE.DETAIL
        : MODAL_MODE.CREATE,
    );
  }, [path]);

  useEffect(() => {
    if (mode !== MODAL_MODE.CREATE) {
      const {
        phone,
        email,
        full_name,
        date_of_birth,
        user_type,
        status,
        image,
        coin,
      } = user.userDetail;

      if (phone) {
        form.setFieldsValue({
          phone,
          email,
          full_name,
          date_of_birth: date_of_birth ? moment(date_of_birth) : null,
          user_type,
          imageList: image ? [image] : [],
          coin: +coin,
        });

        setUserType(user_type);
        setStatus(status);

        if (image)
          setImage({
            uid: 1,
            name: image,
            status: IMAGE_STATUS.DONE,
            url: getImageUrl(`${IMAGE_RELATION_TYPE.USER}/${id}/${image}`),
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, form]);

  useEffect(() => {
    document.title = title;
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    setBreadcrumbs([
      {
        route: ROUTES.USER.PATH,
        title: ROUTES.USER.TITLE,
      },
      {
        title:
          mode === MODAL_MODE.CREATE
            ? ROUTES.USER_CREATE.TITLE
            : mode === MODAL_MODE.UPDATE
            ? ROUTES.USER_UPDATE.TITLE
            : ROUTES.USER_DETAIL.TITLE,
      },
    ]);

    setTitle(
      mode === MODAL_MODE.CREATE
        ? t('user.createUser')
        : mode === MODAL_MODE.UPDATE
        ? t('user.editUser')
        : t('user.detailUser'),
    );
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const onFinish = (values) => {
    const {
      phone,
      password_digest,
      full_name,
      email,
      date_of_birth,
      user_type,
    } = values;
    const imageName = image?.name;

    const params = new FormData();
    params.append('phone', phone);
    params.append('full_name', full_name);
    params.append('email', email);
    params.append(
      'date_of_birth',
      formatDateTimeUtc(date_of_birth, DATE_FORMAT),
    );
    params.append('user_type', user_type);
    if (image) {
      params.append(
        'image',
        image?.status === IMAGE_STATUS.DONE
          ? null
          : JSON.stringify({
              ...image,
              name: imageName,
              url: `${IMAGE_RELATION_TYPE.ITEM_GROUP}/${id}/`,
            }),
      );
      if (image?.status !== IMAGE_STATUS.DONE)
        params.append('files[]', image?.originFileObj || null);
    }

    if (mode === MODAL_MODE.CREATE) {
      params.append('password_digest', password_digest);
      props.createUser(params, () => {
        backToList();
        setImage(null);
      });
    } else if (mode === MODAL_MODE.UPDATE) {
      props.updateUser(params, id, () => {
        backToList();
        setImage(null);
      });
    }
  };

  const submitChangePasswordAdmin = () => {
    form?.validateFields();
    const new_password = form.getFieldValue('new_password');
    if (!new_password) return;

    const params = {
      user_id: +props.match.params.id,
      new_password,
    };

    props.changePasswordAdmin(params, () => {
      backToList();
    });
  };

  const disabledDate = (current) => {
    return moment().isBefore(current);
  };

  const backToList = () => {
    redirectRouter(ROUTES.USER.PATH);
    setImage(null);
  };

  const getDetail = () => {
    const id = props.match.params.id;
    if (mode !== MODAL_MODE.CREATE) props.getUserDetail(id);
  };

  const onCancel = () => {
    if (mode === MODAL_MODE.CREATE) resetForm();
    else if (mode === MODAL_MODE.UPDATE) getDetail();
  };

  const resetForm = () => {
    form?.resetFields();
    setImage(null);
  };

  const openChangePasswordAdminModal = () => {
    setOpenChangePasswordAdmin(true);
  };

  const closeChangePasswordAdminModal = () => {
    form.resetFields(['new_password']);
    setOpenChangePasswordAdmin(false);
  };

  const renderActionButtons = () => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return (
          <div id="group-button-submit">
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="user-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.create')}
            </Button>
          </div>
        );
      case MODAL_MODE.UPDATE:
        return (
          <div id="group-button-submit">
            <Button onClick={() => backToList()} className="close-button">
              {t('common.close')}
            </Button>
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="user-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.save')}
            </Button>
          </div>
        );
      case MODAL_MODE.DETAIL:
        switch (status) {
          // PENDING
          case USER_STATUS.ACTIVE:
            return (
              <div id="group-button-submit">
                <Button onClick={backToList} className="close-button">
                  {t('common.close')}
                </Button>
                {true && (
                  <Button
                    onClick={openChangePasswordAdminModal}
                    className="mini-shop-button"
                    type="primary"
                  >
                    {t('user.changePassword')}
                  </Button>
                )}
              </div>
            );
          // INACTIVE
          case USER_STATUS.INACTIVE:
            return (
              <Button onClick={() => backToList()} className="close-button">
                {t('common.close')}
              </Button>
            );
          default:
            return;
        }
      default:
        break;
    }
  };

  const handleChangeImage = ({ fileList: imageList }) => {
    if (image?.status === IMAGE_STATUS.REMOVED) {
      setImage(null);
    } else {
      setImage(imageList[0]);
    }
  };

  const isView = mode === MODAL_MODE.DETAIL;
  const isCreate = mode === MODAL_MODE.CREATE;

  return (
    <div className="user-form">
      <Modal
        visible={openChangePasswordAdmin}
        title={t('user.changePassword')}
        width={720}
        cancelText={t('common.close')}
        okText={t('common.confirm')}
        onCancel={closeChangePasswordAdminModal}
        onOk={submitChangePasswordAdmin}
      >
        <Card>
          <Form
            {...layout}
            name="change-password-form"
            id="change-password-form"
            className="required-mark-after"
            form={form}
          >
            <Form.Item label={t('user.phoneNumber')} name="phone">
              <Input disabled placeholder={t('user.placeholder.phoneNumber')} />
            </Form.Item>
            <Form.Item label={t('user.fullName')} name="full_name">
              <Input disabled placeholder={t('user.placeholder.fullName')} />
            </Form.Item>
            <Form.Item
              label={t('user.newPassword')}
              name="new_password"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t('form.required'),
                },
                {
                  max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  message: t('form.maxLength', {
                    max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                  }),
                },
                // {
                //   min: TEXTFIELD_REQUIRED_LENGTH.LENGTH_8.MAX,
                //   message: t('form.minLength', {
                //     min: TEXTFIELD_REQUIRED_LENGTH.LENGTH_8.MAX,
                //   }),
                // },
                // {
                //   pattern: PATTERN.password,
                //   message: t('form.validPassword'),
                // },
              ]}
            >
              <Input.Password placeholder={t('user.placeholder.newPassword')} />
            </Form.Item>
          </Form>
        </Card>
      </Modal>
      <Spin spinning={user?.isLoading}>
        <TopContent
          title={title}
          breadcrumbs={breadcrumbs}
          backToList={backToList}
        />
        <Card className="body-content">
          <Form
            {...layout}
            name="user-form"
            id="user-form"
            className="required-mark-after"
            initialValues={{
              password_digest: DEFAULT_PASSWORD,
            }}
            form={form}
            onFinish={onFinish}
          >
            <div className="block-1">
              {isView && (
                <Row>
                  <Col sm={10} xs={24}>
                    <Form.Item name="status" label={t('user.status')}>
                      <div
                        className={
                          [USER_STATUS.ACTIVE].includes(status)
                            ? 'activeStatusBox boxStatus'
                            : 'inActiveStatusBox boxStatus'
                        }
                      >
                        {t(USER_STATUS_MAP[status])}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={10} xs={24}>
                  <Form.Item
                    name="phone"
                    label={t('user.phoneNumber')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.LENGTH_10.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.LENGTH_10.MAX,
                        }),
                      },
                      {
                        pattern: PATTERN.phone,
                        message: t('form.validPhone'),
                      },
                    ]}
                  >
                    <Input
                      disabled={!isCreate}
                      placeholder={t('user.placeholder.phoneNumber')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('user.fullName')}
                    name="full_name"
                    rules={[
                      {
                        whitespace: true,
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('user.placeholder.fullName')}
                    />
                  </Form.Item>
                  <Form.Item label={t('user.birthday')} name="date_of_birth">
                    {isView ? (
                      <>
                        <div className="ant-input-disabled">
                          {formatDateTimeUtc(
                            form.getFieldValue('date_of_birth'),
                          )}
                        </div>
                      </>
                    ) : (
                      <DatePicker
                        disabled={isView}
                        disabledDate={disabledDate}
                        placeholder={t('user.placeholder.birthday')}
                      />
                    )}
                  </Form.Item>
                  {isView && (
                    <Form.Item name="coin" label={t('user.coin')}>
                      <Input
                        disabled
                        prefix={
                          <img
                            src={Coin}
                            width="15px"
                            height="15px"
                            alt="coin-icon"
                          />
                        }
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="imageList"
                    label={t('user.image')}
                    rules={
                      [
                        // {
                        //   required: !isView,
                        //   message: '',
                        // },
                        // {
                        //   validator: (rule, value, cb) => {
                        //     if (!image) cb(t('form.imageRequired'));
                        //     cb();
                        //   },
                        // },
                      ]
                    }
                  >
                    {isView ? (
                      image && (
                        <img
                          width="100"
                          height="100"
                          className="image"
                          src={image?.url}
                          alt="img"
                        />
                      )
                    ) : (
                      <Upload
                        action="/"
                        listType="picture-card"
                        fileList={image ? [image] : []}
                        beforeUpload={() => {
                          return false;
                        }}
                        maxCount={1}
                        onChange={handleChangeImage}
                      >
                        {image ? null : uploadButton}
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={10} xs={24}>
                  {isCreate && (
                    <Form.Item
                      name="password_digest"
                      label={t('user.password')}
                      rules={[
                        {
                          whitespace: true,
                          required: true,
                          message: t('form.required'),
                        },
                        {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                          message: t('form.maxLength', {
                            max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                          }),
                        },
                        // {
                        //   min: TEXTFIELD_REQUIRED_LENGTH.LENGTH_8.MAX,
                        //   message: t('form.minLength', {
                        //     min: TEXTFIELD_REQUIRED_LENGTH.LENGTH_8.MAX,
                        //   }),
                        // },
                        // {
                        //   pattern: PATTERN.password,
                        //   message: t('form.validPassword'),
                        // },
                      ]}
                    >
                      <Input.Password
                        disabled={isView}
                        placeholder={t('user.placeholder.password')}
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    label={t('user.email')}
                    name="email"
                    rules={[
                      {
                        whitespace: true,
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                      // {
                      //   pattern: PATTERN.vti_email,
                      //   message: t('form.validVtiEmail'),
                      // },
                      {
                        pattern: PATTERN.email,
                        message: t('form.validEmail'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('user.placeholder.email')}
                    />
                  </Form.Item>
                  <Form.Item
                    name={`user_type`}
                    label={t('user.userType')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                    ]}
                  >
                    {isView ? (
                      <div className="ant-input ant-input-disabled">
                        {t(
                          `userType.${
                            USER_TYPE.find((type) => type.value === userType)
                              ?.key
                          }`,
                        )}
                      </div>
                    ) : (
                      <Select
                        disabled={isView}
                        placeholder={t('user.placeholder.userType')}
                        allowClear
                      >
                        {USER_TYPE.map(
                          (type) =>
                            ![
                              USER_TYPE_ENUM.ADMIN,
                              USER_TYPE_ENUM.GOOGLE_SHOP_OWNER,
                              USER_TYPE_ENUM.GOOGLE_USER,
                            ].includes(type.value) && (
                              <Option key={type.key} value={type.value}>
                                {t(`userType.${type.key}`)}
                              </Option>
                            ),
                        )}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
        <Card style={{ marginTop: '32px' }}>
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderActionButtons()}
              </div>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
}

const mapDispatchToProps = {
  createUser,
  updateUser,
  changePasswordAdmin,
  getUserDetail,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(UserForm),
);
