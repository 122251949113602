import { Switch } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './style.scss';

const CLASS_NAME = 'react-ant-switch';

export default class ReactAntSwitch extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.bool,
    defaultValue: PropTypes.bool,
    onChange: PropTypes.func,
  };

  render() {
    const { className, value, defaultValue, ...props } = this.props;
    return (
      <div className={CLASS_NAME}>
        <Switch
          data-component={CLASS_NAME}
          className={classNames(className)}
          checked={value}
          defaultChecked={defaultValue}
          {...props}
        />
      </div>
    );
  }
}
