import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  updateUserBankFailed,
  updateUserBankSuccess,
  UPDATE_USER_BANK_START,
} from 'stores/user/user.action';

/**
 * Update user bank API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateUserBankApi = (params) => {
  const { id } = params;
  delete params.id;
  const uri = `/${API_URL.USER_BANK}/${id}`;
  return api.put(uri, params.data);
};

/**
 * Handle update request and response
 * @param {object} action
 */
function* doUpdateUserBank(action) {
  try {
    const response = yield call(updateUserBankApi, action?.payload);

    if (response?.status === 200) {
      yield put(updateUserBankSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification('user.updateUserBankSuccess', NOTIFICATION_TYPE.SUCCESS);
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(updateUserBankFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch update user bank
 */
export default function* watchUpdateUserBank() {
  yield takeLatest(UPDATE_USER_BANK_START, doUpdateUserBank);
}
