import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  forgetPasswordFailed,
  forgetPasswordSuccess,
  FORGET_PASSWORD_START,
} from 'stores/user/user.action';

/**
 * Change user password admin API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const forgetPasswordApi = (params) => {
  const uri = `/${API_URL.USER}/change_password`;
  return api.put(uri, params);
};

/**
 * Handle change password admin request and response
 * @param {object} action
 */
function* doForgetPassword(action) {
  try {
    const response = yield call(forgetPasswordApi, action?.payload);

    if (response?.status === 200) {
      yield put(forgetPasswordSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'user.forgetPasswordSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(forgetPasswordFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch change password
 */
export default function* watchForgetPassword() {
  yield takeLatest(FORGET_PASSWORD_START, doForgetPassword);
}
