import {
  CREATE_IMPORT_ITEM_TICKET_START,
  CREATE_IMPORT_ITEM_TICKET_SUCCESS,
  CREATE_IMPORT_ITEM_TICKET_FAILED,
  UPDATE_IMPORT_ITEM_TICKET_START,
  UPDATE_IMPORT_ITEM_TICKET_SUCCESS,
  UPDATE_IMPORT_ITEM_TICKET_FAILED,
  DELETE_IMPORT_ITEM_TICKET_START,
  DELETE_IMPORT_ITEM_TICKET_SUCCESS,
  DELETE_IMPORT_ITEM_TICKET_FAILED,
  SEARCH_IMPORT_ITEM_TICKET_START,
  SEARCH_IMPORT_ITEM_TICKET_SUCCESS,
  SEARCH_IMPORT_ITEM_TICKET_FAILED,
  GET_IMPORT_ITEM_TICKET_DETAIL_START,
  GET_IMPORT_ITEM_TICKET_DETAIL_SUCCESS,
  GET_IMPORT_ITEM_TICKET_DETAIL_FAILED,
  CONFIRM_IMPORT_ITEM_TICKET_START,
  CONFIRM_IMPORT_ITEM_TICKET_SUCCESS,
  CONFIRM_IMPORT_ITEM_TICKET_FAILED,
} from './import-item-ticket.action';

const initialState = {
  isLoading: false,
  importItemTicketList: [],
  importItemTicketDetail: {},
  total: 0,
};

/**
 * importItemTicket reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function importItemTicket(state = initialState, action) {
  switch (action.type) {
    case CREATE_IMPORT_ITEM_TICKET_START:
    case UPDATE_IMPORT_ITEM_TICKET_START:
    case DELETE_IMPORT_ITEM_TICKET_START:
    case SEARCH_IMPORT_ITEM_TICKET_START:
    case GET_IMPORT_ITEM_TICKET_DETAIL_START:
    case CONFIRM_IMPORT_ITEM_TICKET_START:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_IMPORT_ITEM_TICKET_SUCCESS:
    case UPDATE_IMPORT_ITEM_TICKET_SUCCESS:
    case DELETE_IMPORT_ITEM_TICKET_SUCCESS:
    case CREATE_IMPORT_ITEM_TICKET_FAILED:
    case CONFIRM_IMPORT_ITEM_TICKET_SUCCESS:
    case UPDATE_IMPORT_ITEM_TICKET_FAILED:
    case DELETE_IMPORT_ITEM_TICKET_FAILED:
    case CONFIRM_IMPORT_ITEM_TICKET_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_IMPORT_ITEM_TICKET_SUCCESS:
      return {
        ...state,
        importItemTicketList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case SEARCH_IMPORT_ITEM_TICKET_FAILED:
      return {
        ...state,
        importItemTicketList: [],
        total: 0,
        isLoading: false,
      };
    case GET_IMPORT_ITEM_TICKET_DETAIL_SUCCESS:
      return {
        ...state,
        importItemTicketDetail: action.payload,
        isLoading: false,
      };
    case GET_IMPORT_ITEM_TICKET_DETAIL_FAILED:
      return {
        ...state,
        importItemTicketDetail: {},
        isLoading: false,
      };
    default:
      return state;
  }
}
