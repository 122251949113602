export const CREATE_PAYMENT_TYPE_START = 'CREATE_PAYMENT_TYPE_START';
export const CREATE_PAYMENT_TYPE_SUCCESS = 'CREATE_PAYMENT_TYPE_SUCCESS';
export const CREATE_PAYMENT_TYPE_FAILED = 'CREATE_PAYMENT_TYPE_FAILED';

export const UPDATE_PAYMENT_TYPE_START = 'UPDATE_PAYMENT_TYPE_START';
export const UPDATE_PAYMENT_TYPE_SUCCESS = 'UPDATE_PAYMENT_TYPE_SUCCESS';
export const UPDATE_PAYMENT_TYPE_FAILED = 'UPDATE_PAYMENT_TYPE_FAILED';

export const DELETE_PAYMENT_TYPE_START = 'DELETE_PAYMENT_TYPE_START';
export const DELETE_PAYMENT_TYPE_SUCCESS = 'DELETE_PAYMENT_TYPE_SUCCESS';
export const DELETE_PAYMENT_TYPE_FAILED = 'DELETE_PAYMENT_TYPE_FAILED';

export const SEARCH_PAYMENT_TYPE_START = 'SEARCH_PAYMENT_TYPE_START';
export const SEARCH_PAYMENT_TYPE_SUCCESS = 'SEARCH_PAYMENT_TYPE_SUCCESS';
export const SEARCH_PAYMENT_TYPE_FAILED = 'SEARCH_PAYMENT_TYPE_FAILED';

export const GET_PAYMENT_TYPE_DETAIL_START = 'GET_PAYMENT_TYPE_DETAIL_START';
export const GET_PAYMENT_TYPE_DETAIL_SUCCESS = 'GET_PAYMENT_TYPE_DETAIL_SUCCESS';
export const GET_PAYMENT_TYPE_DETAIL_FAILED = 'GET_PAYMENT_TYPE_DETAIL_FAILED';

/**
 * Create currency-unit
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createPaymentType(payload, onSuccess, onError) {
  return {
    type: CREATE_PAYMENT_TYPE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create currency-unit success action
 * @param {*} payload
 * @returns {object}
 */
export function createPaymentTypeSuccess(payload) {
  return {
    type: CREATE_PAYMENT_TYPE_SUCCESS,
    payload: payload,
  };
}

/**
 * Create currency-unit failed action
 * @returns {object}
 */
export function createPaymentTypeFailed() {
  return {
    type: CREATE_PAYMENT_TYPE_FAILED,
  };
}

/**
 * Update currency-unit
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updatePaymentType(payload, onSuccess, onError) {
  return {
    type: UPDATE_PAYMENT_TYPE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update currency-unit success action
 * @param {*} payload
 * @returns {object}
 */
export function updatePaymentTypeSuccess(payload) {
  return {
    type: UPDATE_PAYMENT_TYPE_SUCCESS,
    payload: payload,
  };
}

/**
 * Update currency-unit failed action
 * @returns {object}
 */
export function updatePaymentTypeFailed() {
  return {
    type: UPDATE_PAYMENT_TYPE_FAILED,
  };
}

/**
 * Delete currency-unit
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function deletePaymentType(payload, onSuccess, onError) {
  return {
    type: DELETE_PAYMENT_TYPE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Delete currency-unit success action
 * @param {*} payload
 * @returns {object}
 */
export function deletePaymentTypeSuccess(payload) {
  return {
    type: DELETE_PAYMENT_TYPE_SUCCESS,
    payload: payload,
  };
}

/**
 * Delete currency-unit failed action
 * @returns {object}
 */
export function deletePaymentTypeFailed() {
  return {
    type: DELETE_PAYMENT_TYPE_FAILED,
  };
}

/**
 * Search currency-unit
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function searchPaymentType(payload, onSuccess, onError) {
  return {
    type: SEARCH_PAYMENT_TYPE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Search currency-unit success action
 * @param {*} payload
 * @returns {object}
 */
export function searchPaymentTypeSuccess(payload) {
  return {
    type: SEARCH_PAYMENT_TYPE_SUCCESS,
    payload: payload,
  };
}

/**
 * Search currency-unit failed action
 * @returns {object}
 */
export function searchPaymentTypeFailed() {
  return {
    type: SEARCH_PAYMENT_TYPE_FAILED,
  };
}

/**
 * Get currency-unit detail
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function getPaymentTypeDetail(payload, onSuccess, onError) {
  return {
    type: GET_PAYMENT_TYPE_DETAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Get currency-unit detail success action
 * @param {*} payload
 * @returns {object}
 */
export function getPaymentTypeDetailSuccess(payload) {
  return {
    type: GET_PAYMENT_TYPE_DETAIL_SUCCESS,
    payload: payload,
  };
}

/**
 * Get currency-unit detail failed action
 * @returns {object}
 */
export function getPaymentTypeDetailFailed() {
  return {
    type: GET_PAYMENT_TYPE_DETAIL_FAILED,
  };
}
