import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  updateConversionRateFailed,
  updateConversionRateSuccess,
  UPDATE_CONVERSION_RATE_START,
} from 'stores/conversion-rate/conversion-rate.action';

/**
 * Update conversion-rate API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateConversionRateApi = (params) => {
  const id = params.id;
  delete params.id;
  const uri = `${API_URL.CONVERSION_RATE}/${id}`;
  return api.put(uri, params);
};

/**
 * Handle update request and response
 * @param {object} action
 */
function* doUpdateConversionRate(action) {
  try {
    const response = yield call(updateConversionRateApi, action?.payload);

    if (response?.status === 200) {
      yield put(updateConversionRateSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'conversionRate.updateConversionRateSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(updateConversionRateFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch update conversion-rate
 */
export default function* watchUpdateConversionRate() {
  yield takeLatest(UPDATE_CONVERSION_RATE_START, doUpdateConversionRate);
}
