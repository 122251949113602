import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import { Card, Row, Spin, Form, Col, Input } from 'antd';
import CustomTable from 'components/common/custom-table';
import { searchItem } from 'stores/item/item.action';
import { getOrderDetail } from 'stores/common/common.action';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

function TransactionForm(props) {
  const { t, id, common, userList } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    props.searchItem({
      filter: JSON.stringify([
        {
          column: 'user_id',
          value: window.localStorage.getItem('userId'),
        },
      ]),
    });

    props.getOrderDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const { user_id, receiving_address } = common.orderDetail;
    form.setFieldsValue({
      user: userList.find((user) => user.id === user_id)?.full_name,
      receivingAdress: receiving_address,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [common.orderDetail]);

  const getColumns = () => {
    return [
      {
        key: 'id',
        dataIndex: 'id',
        title: t('transaction.id'),
        align: 'center',
        render: (params, row) => {
          return row?.id + 1;
        },
      },
      {
        key: 'item',
        dataIndex: 'item',
        title: t('transaction.itemName'),
        render: (params) => {
          return params?.name;
        },
      },
      {
        key: 'shop',
        dataIndex: 'shop',
        title: t('transaction.shopName'),
        render: (params) => {
          return params?.name;
        },
      },
      {
        key: 'price',
        dataIndex: 'price',
        title: t('transaction.price'),
      },
      {
        key: 'quantity',
        dataIndex: 'quantity',
        title: t('transaction.quantity'),
      },
      {
        key: 'total_money',
        dataIndex: 'total_money',
        title: t('transaction.total'),
        render: (params, row) => {
          const { price, quantity } = row;
          return price * quantity;
        },
      },
    ];
  };

  return (
    <Spin spinning={common?.isLoading}>
      <Card className="transaction-form-card" bordered={false}>
        <Form {...layout} form={form} className="required-mark-after">
          <Row>
            <Col span={10}>
              <Form.Item name="user" label={t('transaction.userName')}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={10} offset={3}>
              <Form.Item
                name="receivingAdress"
                label={t('transaction.receivingAddress')}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row>
          <CustomTable
            className="transaction-table"
            rows={common?.orderDetail?.order_detail || []}
            pageSize={20}
            page={1}
            columns={getColumns()}
            total={common?.orderDetail?.order_detail?.length || 0}
            topInfo={false}
            noPagination={true}
            hideFooter
          />
        </Row>
      </Card>
    </Spin>
  );
}

const mapDispatchToProps = {
  searchItem,
  getOrderDetail,
};

const mapStateToProps = (state) => ({
  common: state.common,
  itemList: state.item.itemList,
  userList: state.user.userList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TransactionForm),
);
