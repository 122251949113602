import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { List, Switch, Row, Button } from 'antd';
import { NOTIFICATION_SETTING_STATUS } from 'common/constant';
import { updateNotificationSettingStatus } from 'stores/notification/notification.action';

function NotificationSettings(props) {
  const {
    t,
    notificationSettingList,
    setRefreshSettings,
    refreshSettings,
    changeList,
    setChangeList,
  } = props;

  useEffect(() => {
    setChangeList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationSettingList]);

  const handleChangeNotificationSettingStatus = (id, value) => {
    const copyChangeList = [...changeList];
    const checkExist = copyChangeList.find((setting) => setting.id === id);
    if (checkExist) checkExist['status'] = value ? 1 : 0;
    else
      copyChangeList.push({
        id,
        status: value ? 1 : 0,
      });
    setChangeList(copyChangeList);
  };

  const saveSettings = () => {
    const params = {
      notification_settings: changeList,
    };
    props.updateNotificationSettingStatus(params, () => {
      setRefreshSettings(!refreshSettings);
    });
  };

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={notificationSettingList}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Switch
                checked={
                  changeList.find((setting) => setting.id === item.id)
                    ? changeList.find((setting) => setting.id === item.id)
                        ?.status === NOTIFICATION_SETTING_STATUS.ACTIVE
                    : item.status === NOTIFICATION_SETTING_STATUS.ACTIVE
                }
                onClick={(checked) =>
                  handleChangeNotificationSettingStatus(item.id, checked)
                }
              />,
            ]}
          >
            <List.Item.Meta title={item.name} />
          </List.Item>
        )}
      />
      <Row>
        <div style={{ width: '100%' }}>
          <Button
            style={{ float: 'right', margin: '7px' }}
            className="mini-shop-button"
            onClick={() => saveSettings()}
          >
            {t('common.save')}
          </Button>
        </div>
      </Row>
    </>
  );
}

const mapDispatchToProps = {
  updateNotificationSettingStatus,
};

const mapStateToProps = (state) => ({
  notificationSettingList: state.notification.notificationSettingList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(NotificationSettings),
);
