import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  searchImportItemTicketFailed,
  searchImportItemTicketSuccess,
  SEARCH_IMPORT_ITEM_TICKET_START,
} from 'stores/import-item-ticket/import-item-ticket.action';

/**
 * Search import-item-ticket API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const searchImportItemTicketApi = (params) => {
  const uri = `/${API_URL.IMPORT_ITEM_TICKET}`;
  return api.get(uri, params);
};

/**
 * Handle update request and response
 * @param {object} action
 */
function* doSearchImportItemTicket(action) {
  try {
    const response = yield call(searchImportItemTicketApi, action?.payload);

    if (response?.status === 200) {
      const payload = {
        list: response.data.items,
        total: response.data.total,
      };
      yield put(searchImportItemTicketSuccess(payload));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(searchImportItemTicketFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch search import-item-ticket
 */
export default function* watchSearchImportItemTicket() {
  yield takeLatest(SEARCH_IMPORT_ITEM_TICKET_START, doSearchImportItemTicket);
}
