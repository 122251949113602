import React from 'react';
import { Avatar } from 'antd';
import { getImageUrl } from 'utils';
import './style.scss';

function ShopAvatar(props) {
  const { shop } = props;

  return (
    <div>
      <div className="shop-avatar">
        <div>
          <Avatar
            size={100}
            icon={<img src={getImageUrl(shop?.url + shop?.image)} alt="shop" />}
          />
        </div>
        <div>{shop?.name}</div>
      </div>
    </div>
  );
}

export default ShopAvatar;
