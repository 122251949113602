import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  deleteCurrencyUnitFailed,
  deleteCurrencyUnitSuccess,
  DELETE_CURRENCY_UNIT_START,
} from 'stores/currency-unit/currency-unit.action';

/**
 * Delete currency-unit API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const deleteCurrencyUnitApi = (params) => {
  const uri = `${API_URL.CURRENCY_UNIT}/${params}`;
  return api.delete(uri);
};

/**
 * Handle delete request and response
 * @param {object} action
 */
function* doDeleteCurrencyUnit(action) {
  try {
    const response = yield call(deleteCurrencyUnitApi, action?.payload);

    if (response?.status === 200) {
      yield put(deleteCurrencyUnitSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'currencyUnit.deleteCurrencyUnitSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(deleteCurrencyUnitFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch delete currency-unit
 */
export default function* watchDeleteCurrencyUnit() {
  yield takeLatest(DELETE_CURRENCY_UNIT_START, doDeleteCurrencyUnit);
}
