import { USER_TYPE_ENUM } from 'common/constant';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  refreshTokenFailed,
  refreshTokenSuccess,
  REFRESH_TOKEN_START,
} from 'stores/auth/auth.action';
import i18n from 'common/i18n';
import Cookies from 'universal-cookie';
import { logout } from 'stores/auth/auth.action';
import store from 'stores';
const cookies = new Cookies();

/**
 * RefreshToken
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const refreshTokenApi = (params) => {
  const uri = `/users/refresh_token`;
  return api.put(uri, params);
};

/**
 * Handle refresh token request and response
 * @param {object} action
 */
function* doRefreshToken(action) {
  try {
    const response = yield call(refreshTokenApi, action?.payload);
    if (response?.status === 200) {
      const { data } = response;

      if (
        [USER_TYPE_ENUM.USER, USER_TYPE_ENUM.GOOGLE_USER].includes(
          data.user.user_type,
        )
      )
        throw new Error(i18n.t('auth.notPermitted'));
      // Save token info to cookies
      cookies.set('token', data.access_token);
      cookies.set('userId', data?.user?.id);

      // Save token to local storage
      localStorage.setItem('token', 'Bearer ' + data.access_token);
      // Save refresh to ken to local storage
      // Save user infomation to local storage
      localStorage.setItem('user', JSON.stringify(data?.user));
      localStorage.setItem('userId', data?.user?.id);
      localStorage.setItem('userImage', data?.user?.image);
      yield put(refreshTokenSuccess(response.data.user));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }
    } else {
      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(refreshTokenFailed(error?.message));
    store.dispatch(logout());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch refreshToken
 */
export default function* watchRefreshToken() {
  yield takeLatest(REFRESH_TOKEN_START, doRefreshToken);
}
