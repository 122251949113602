import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { List } from 'antd';
import { formatDateTimeUtc, redirectRouter } from 'utils';
import './index.scss';
import { NOTIFICATION_STATUS, ORDER_RECEIVE_TYPE } from 'common/constant';
import { ROUTES } from 'common/routes';
import { updateNotificationStatus } from 'stores/notification/notification.action';

function NotificationList(props) {
  const { t, notificationList, setRefreshSettings, refreshSettings } = props;

  const redirectToPage = (order_id, notification_id) => {
    props.updateNotificationStatus(notification_id);
    redirectRouter(ROUTES.TRANSACTION_STATISTICS.PATH + `?id=${order_id}`);
    setRefreshSettings(!refreshSettings);
  };

  return (
    <>
      <List
        dataSource={notificationList}
        className="notification-list"
        renderItem={(item) => (
          <List.Item
            className={
              (item?.status === NOTIFICATION_STATUS.READ
                ? 'noti-read'
                : 'noti-unread') +
              (item?.order?.receive_type === ORDER_RECEIVE_TYPE.SHIP
                ? ' ship-order'
                : '')
            }
            onClick={() => redirectToPage(item?.order_id, item?.id)}
          >
            <List.Item.Meta
              avatar={<div className="colored-circle"></div>}
              title={t('notification.title') + ` ${item.id}`}
              description={
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<span class="${
                      [
                        ORDER_RECEIVE_TYPE.SHIP,
                        ORDER_RECEIVE_TYPE.PRE_ORDER,
                      ].includes(item?.order?.receive_type)
                        ? 'red prefix'
                        : 'prefix'
                    }">
                    ${
                      item?.order?.receive_type === ORDER_RECEIVE_TYPE.PRE_ORDER
                        ? t('notification.preOrder')
                        : t('notification.notificationPrefix', {
                            addition:
                              item?.order?.receive_type ===
                              ORDER_RECEIVE_TYPE.SHIP
                                ? t('notification.ship')
                                : '',
                          })
                    }
                  </span> ${item?.message}
                  ${t('notification.at', {
                    time: formatDateTimeUtc(item?.created_at),
                  })} `,
                  }}
                ></div>
              }
            ></List.Item.Meta>
          </List.Item>
        )}
      />
    </>
  );
}

const mapDispatchToProps = {
  updateNotificationStatus,
};

const mapStateToProps = (state) => ({
  notificationList: state.notification.notificationList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(NotificationList),
);
