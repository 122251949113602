import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TopContent from 'components/common/top-content';
import CustomTable from 'components/common/custom-table';
import { Button, Modal, Spin } from 'antd';
import { EyeFilled, EditFilled, /*DeleteFilled*/ } from '@ant-design/icons';
import ReactAntSwitch from 'components/common/switch';
import './index.scss';
import { formatDateTimeUtc, redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import {
  CONVERSION_RATE_STATUS,
  CURRENCY_UNIT_STATUS,
  USER_STATUS,
  USER_TYPE,
} from 'common/constant';
import { searchUser, deleteUser, updateUser } from 'stores/user/user.action';
import { searchConversionRate } from 'stores/conversion-rate/conversion-rate.action';
import { searchCurrencyUnit } from 'stores/currency-unit/currency-unit.action';
import TransferCoinModal from './transfer-coin-modal';
import Coin from 'assets/images/coin.png';

const breadcrumbs = [
  {
    route: '/user',
    title: 'user',
  },
];

function User(props) {
  const { t, user, currencyUnitList, conversionRateList } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState(null);
  const [keyword, setKeyWord] = useState('');
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [openTransferCoinModal, setOpenTransferCoinModal] = useState(false);
  const [transferUserId, setTransferUserId] = useState(null);
  const [deletingId, setDeletingId] = useState(null);

  const refreshData = () => {
    props.searchUser({
      keyword: keyword.trim(),
      offset: (page - 1) * pageSize,
      limit: pageSize,
      filter: JSON.stringify(filter),
      sort,
    });
  };

  useEffect(() => {
    props.searchConversionRate({
      filter: JSON.stringify([
        {
          column: 'status',
          value: CONVERSION_RATE_STATUS.ACTIVE,
        },
      ]),
    });

    props.searchCurrencyUnit({
      filter: JSON.stringify([
        {
          column: 'status',
          value: CURRENCY_UNIT_STATUS.ACTIVE,
        },
      ]),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = t('user.title');
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, page, pageSize, filter, sort]);

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('user.id'),
    },
    {
      key: 'full_name',
      dataIndex: 'full_name',
      title: t('user.fullName'),
      filterable: true,
      sorter: true,
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: t('user.email'),
      filterable: true,
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: t('user.phoneNumber'),
      filterable: true,
    },
    {
      key: 'user_type',
      dataIndex: 'user_type',
      title: t('user.userType'),
      render: (params) => {
        return t(
          `userType.${USER_TYPE.find((type) => type.value === params)?.key}`,
        );
      },
    },
    {
      key: 'coin',
      dataIndex: 'coin',
      title: t('user.coin'),
      sorter: true,
      render: (params) => {
        return +params;
      },
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('user.createdAt'),
      render: (params) => {
        return formatDateTimeUtc(params);
      },
    },
    {
      key: 'action',
      dataIndex: 'id',
      title: t('common.action'),
      disableClickEventBubbling: true,
      sorter: false,
      align: 'center',
      headerAlign: 'center',
      render: (params, row) => {
        const { status } = row;
        return (
          <div className="action-button">
            <Button
              type="text"
              shape="circle"
              icon={<EyeFilled />}
              onClick={() => onClickViewDetails(params)}
            />
            <Button
              type="text"
              shape="circle"
              icon={<EditFilled />}
              onClick={() => onClickEdit(params)}
            />
            {/* <Button
              type="text"
              shape="circle"
              icon={<DeleteFilled />}
              onClick={() => onClickDelete(params)}
            /> */}
            {status !== USER_STATUS.INACTIVE && (
              <Button
                type="text"
                shape="circle"
                icon={<img src={Coin} width="20" height="20" alt="coin" />}
                onClick={() => onClickOpenTransfer(params)}
              />
            )}
            <ReactAntSwitch
              onClick={() => toggleUser(params, status)}
              value={status === USER_STATUS.ACTIVE}
            />
          </div>
        );
      },
    },
  ];

  const onClickOpenTransfer = (id) => {
    setTransferUserId(id);
    setOpenTransferCoinModal(true);
  };

  const toggleUser = (id, status) => {
    const params = new FormData();
    params.append(
      'status',
      status === USER_STATUS.ACTIVE ? USER_STATUS.INACTIVE : USER_STATUS.ACTIVE,
    );

    props.updateUser(params, id, () => {
      refreshData();
    });
  };

  const handleCreate = () => {
    redirectRouter(ROUTES.USER_CREATE.PATH);
  };

  const handleSearch = () => {
    refreshData();
  };

  const updateKeyword = (value) => {
    setKeyWord(value);
  };

  const onPageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
  };

  const onPageChange = ({ page }) => {
    setPage(page);
  };

  const onChangeFilter = (filter) => {
    setFilter(filter);
    setPage(1);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSort({
      column: sorter.field,
      value: sorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  const onClickViewDetails = (id) => {
    redirectRouter(ROUTES.USER_DETAIL.PATH.replace('/:id', `/${id}`));
  };

  const onClickEdit = (id) => {
    redirectRouter(ROUTES.USER_UPDATE.PATH.replace('/:id', `/${id}`));
  };

  // const onClickDelete = (id) => {
  //   setDeletingId(id);
  //   setOpenConfirmDeleteModal(true);
  // };

  const onCancelDelete = () => {
    setDeletingId(null);
    setOpenConfirmDeleteModal(false);
  };

  const onSubmitDelete = () => {
    props.deleteUser(deletingId, () => {
      onCancelDelete();
      refreshData();
    });
  };

  const onCloseTransfer = () => {
    setTransferUserId(null);
    setOpenTransferCoinModal(false);
    refreshData();
  };

  return (
    <Spin spinning={user?.isLoading}>
      <TopContent
        title={t('user.title')}
        breadcrumbs={breadcrumbs}
        displayButton={['create']}
        createButton={t('user.createButton')}
        handleCreateOpenModal={handleCreate}
        handleSearch={handleSearch}
        updateKeyword={updateKeyword}
      />
      <Modal
        visible={openConfirmDeleteModal}
        title={t('user.deleteModalTitle')}
        size="sm"
        onOk={onSubmitDelete}
        onCancel={onCancelDelete}
        okText={t('common.delete')}
        cancelText={t('common.cancel')}
        hideCancel
        width={719}
        okButtonProps={{
          id: 'delete-button',
        }}
        cancelButtonProps={{
          className: 'cancel-button-custom',
        }}
      >
        {t('user.deleteConfirm')}
      </Modal>
      {openTransferCoinModal && (
        <TransferCoinModal
          openTransferCoinModal={openTransferCoinModal}
          transferUserId={transferUserId}
          onCloseTransfer={onCloseTransfer}
          user={user.userList.find((user) => user.id === transferUserId)}
          currencyUnitList={currencyUnitList}
          conversionRateList={conversionRateList}
        />
      )}
      <CustomTable
        columns={columns}
        rows={user.userList}
        page={page}
        pageSize={pageSize}
        total={user?.total}
        topInfo={true}
        onChange={handleTableChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onChangeFilter={onChangeFilter}
      />
    </Spin>
  );
}

const mapDispatchToProps = {
  searchUser,
  deleteUser,
  updateUser,
  searchConversionRate,
  searchCurrencyUnit,
};

const mapStateToProps = (state) => ({
  user: state.user,
  conversionRateList: state.conversionRate.conversionRateList,
  currencyUnitList: state.currencyUnit.currencyUnitList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(User),
);
