import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import {
  Form,
  Spin,
  Card,
  Row,
  Col,
  Input,
  Button,
  AutoComplete,
  Modal,
} from 'antd';
import { filterAutoComplete } from 'utils';
import TopContent from 'components/common/top-content';
import CustomTable from 'components/common/custom-table';
import PlusBox from 'assets/images/plus-box.svg';
import { redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import { createBarcode } from 'stores/barcode/barcode.action';
import { searchItem } from 'stores/item/item.action';
import {
  MinusCircleOutlined,
  MinusCircleFilled,
  BarcodeOutlined,
} from '@ant-design/icons';
import { NOTIFICATION_TYPE, TEXTFIELD_REQUIRED_LENGTH } from 'common/constant';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import addNotification from 'common/toast';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const DEFAULT_ITEM = {
  id: 0,
  item_id: null,
  code: null,
  filterValue: '',
};

function BarcodeForm(props) {
  const { t, barcode, itemList } = props;
  const title = t('barcode.createBarcode');
  const [form] = Form.useForm();
  const [items, setItems] = useState([{ ...DEFAULT_ITEM }]);
  const [openScanBarcode, setOpenScanBarcode] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      route: ROUTES.BARCODE.PATH,
      title: ROUTES.BARCODE.TITLE,
    },
    {
      title: ROUTES.BARCODE_CREATE.TITLE,
    },
  ]);

  useEffect(() => {
    document.title = title;
    setBreadcrumbs([
      {
        route: ROUTES.BARCODE.PATH,
        title: ROUTES.BARCODE.TITLE,
      },
      {
        title: ROUTES.BARCODE_CREATE.TITLE,
      },
    ]);

    props.searchItem({
      filter: JSON.stringify([
        {
          column: 'user_id',
          value: window.localStorage.getItem('userId'),
        },
      ]),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = () => {
    const params = {
      item_barcodes: items.map((item) => ({
        item_id: item?.item_id,
        barcode: item?.code,
      })),
    };

    props.createBarcode(params, () => {
      backToList();
    });
  };

  const backToList = () => {
    redirectRouter(ROUTES.BARCODE.PATH);
  };

  const resetForm = () => {
    form?.resetFields();
    setItems([{ ...DEFAULT_ITEM }]);
  };

  const renderActionButtons = () => {
    return (
      <div id="group-button-submit">
        <Button onClick={resetForm}>{t('common.cancel')}</Button>
        <Button
          form="barcode-form"
          key="submit"
          htmlType="submit"
          className="mini-shop-button"
          type="primary"
        >
          {t('common.create')}
        </Button>
      </div>
    );
  };

  const getColumns = () => {
    return [
      {
        dataIndex: 'id',
        title: t('barcode.id'),
        align: 'center',
        width: '5vw',
        render: (params, row) => {
          return row?.id + 1;
        },
      },
      {
        dataIndex: 'item',
        title: t('barcode.item'),
        align: 'center',
        width: '30vw',
        render: (params, row) => {
          const { id } = row;
          return (
            <div className="itemsetting">
              <Form.Item
                name={`item_id${id}`}
                className="itemsetting-form-item"
                rules={[
                  {
                    validator: (rule, value, cb) => {
                      if (!value && items[id].filterValue)
                        cb(t('form.itemNotFound'));
                      else if (!value) cb(t('form.required'));
                      cb();
                    },
                  },
                ]}
              >
                <AutoComplete
                  onChange={(event, value) =>
                    onChangeItem(id, 'item_id', +value?.value)
                  }
                  filterOption={(inputValue, option) => {
                    items[id].filterValue = inputValue;
                    setItems(items);
                    return filterAutoComplete(inputValue, option);
                  }}
                  style={{ width: '100%' }}
                >
                  {itemList.map((item) => {
                    if (items.find((it) => it?.item_id === item?.id))
                      return (
                        <AutoComplete.Option
                          disabled
                          key={item.id}
                          value={item.id.toString()}
                        >
                          {item.name}
                        </AutoComplete.Option>
                      );

                    return (
                      <AutoComplete.Option
                        key={item.id}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </AutoComplete.Option>
                    );
                  })}
                </AutoComplete>
              </Form.Item>
            </div>
          );
        },
      },
      {
        dataIndex: 'code',
        title: t('barcode.code'),
        align: 'center',
        render: (params, row) => {
          const { id } = row;
          return (
            <div className="itemsetting">
              <Form.Item
                name={`code${id}`}
                className="itemsetting-form-item"
                rules={[
                  {
                    required: true,
                    message: t('form.required'),
                  },
                  {
                    max: TEXTFIELD_REQUIRED_LENGTH.BARCODE.MAX,
                    message: t('form.maxLength', {
                      max: TEXTFIELD_REQUIRED_LENGTH.BARCODE.MAX,
                    }),
                  },
                ]}
              >
                <Input
                  onChange={(event) =>
                    onChangeItem(id, 'code', event.target.value)
                  }
                />
              </Form.Item>
            </div>
          );
        },
      },
      {
        key: 'remove',
        dataIndex: 'remove',
        title: '',
        width: '5vw',
        render: (params, row) => {
          const { id } = row;
          return (
            <div className="remove-button">
              <Button
                type="text"
                shape="circle"
                onClick={() => onRemoveItem(id)}
                disabled={items?.length === 1}
                icon={
                  items?.length === 1 ? (
                    <MinusCircleOutlined style={{ fontSize: '15px' }} />
                  ) : (
                    <MinusCircleFilled
                      style={{ color: 'red', fontSize: '15px' }}
                    />
                  )
                }
              />
            </div>
          );
        },
      },
    ];
  };

  /**
   * Add item
   */
  const onAddItem = () => {
    setItems([
      ...items,
      {
        id: items.length,
        item_id: null,
        code: null,
        filterValue: '',
      },
    ]);
  };

  /**
   * Remove product
   */
  const onRemoveItem = (id) => {
    const oldFields = [];
    items.forEach((item, index) => {
      oldFields.push(`item_id${index}`);
      oldFields.push(`code${index}`);
    });

    form.resetFields(oldFields);

    const newItems = [...items]
      .filter((item) => item.id !== id)
      .map((item, index) => ({ ...item, id: index }));

    let fields = {};
    newItems.forEach((item, index) => {
      Object.assign(fields, {
        [`item_id${index}`]: getItemObject(item?.item_id)?.name,
        [`code${index}`]: item?.code,
      });
    });

    form.setFieldsValue(fields);
    setItems(newItems);
  };

  /**
   * Get item object with code, name...
   * @returns
   */
  const getItemObject = (id) => {
    return itemList?.find((item) => item?.id === id);
  };

  /**
   *
   * @param {string} key
   * @param {*} value
   */
  const onChangeItem = (index, key, value) => {
    const newItems = [...items];
    const itemToChange = newItems[index];

    if (key === 'item_id') {
      itemToChange['price'] = +itemList.find((item) => item.id === value)
        ?.price;
      form.setFieldsValue({
        [`item_id${index}`]: getItemObject(value)?.name,
      });
    }

    itemToChange[key] = value;
    newItems[index] = itemToChange;

    setItems(newItems);
  };

  const handleOpenScanBarcode = () => {
    setOpenScanBarcode(true);
  };

  const handleCloseScanBarcode = () => {
    setOpenScanBarcode(false);
  };

  const handleScanBarcode = (result) => {
    if (result) {
      const newCode = result['text'];
      let check = true;
      for (let i = 0; i < items.length; i++) {
        if (items[i].code === newCode) {
          check = false;
          break;
        }
      }

      if (!check)
        addNotification(t('message.barcodeExist'), NOTIFICATION_TYPE.ERROR);
      else {
        items[items.length - 1]['code'] = newCode;
        form.setFieldsValue({
          [`code${items.length - 1}`]: newCode,
        });
        setItems(items);
      }

      handleCloseScanBarcode();
    }
  };

  return (
    <div className="barcode-form">
      <Spin spinning={barcode?.isLoading}>
        <TopContent
          title={title}
          breadcrumbs={breadcrumbs}
          backToList={backToList}
        />
        {openScanBarcode && (
          <Modal
            visible={openScanBarcode}
            width={750}
            onCancel={handleCloseScanBarcode}
            footer={[]}
          >
            <BarcodeScannerComponent
              width="700"
              height="700"
              torch={true}
              onUpdate={(err, result) => handleScanBarcode(result)}
            />
          </Modal>
        )}
        <Card className="body-content">
          <Form
            {...layout}
            name="barcode-form"
            id="barcode-form"
            className="required-mark-after"
            form={form}
            onFinish={onFinish}
          >
            <Row className="title-barcode-item">
              <div>
                <Button
                  prefix={<BarcodeOutlined />}
                  className="create-button mr-0 mini-shop-button"
                  type="primary"
                  onClick={() => handleOpenScanBarcode()}
                >
                  {t('common.scanBarcode')}
                </Button>
              </div>
              <div id="barcode-create-button">
                <Button
                  className="create-button mr-0 mini-shop-button"
                  type="primary"
                  onClick={onAddItem}
                  size="large"
                  icon={
                    <img
                      style={{ margin: '5px' }}
                      src={PlusBox}
                      alt="plus-box"
                    />
                  }
                >
                  {t('common.addItem')}
                </Button>
              </div>
            </Row>
            <Row>
              <CustomTable
                className="barcode-table"
                rows={items}
                pageSize={20}
                page={1}
                columns={getColumns()}
                total={items.length}
                topInfo={false}
                noPagination={true}
                hideFooter
              />
            </Row>
          </Form>
        </Card>
        <Card style={{ marginTop: '32px' }}>
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderActionButtons()}
              </div>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
}

const mapDispatchToProps = {
  createBarcode,
  searchItem,
};

const mapStateToProps = (state) => ({
  barcode: state.barcode,
  itemList: state.item.itemList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(BarcodeForm),
);
