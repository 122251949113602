import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Card, Row, Col } from 'antd';
import {
  ShopOutlined,
  UserOutlined,
  AppstoreOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  CONVERSION_RATE_TYPE,
  IMPORT_ITEM_TICKET_STATUS,
  INVENTORY_REPORT_TYPE,
  ORDER_STATUS,
} from 'common/constant';
import DateSelection from '../date-selection';
import { searchInventoriesCheck } from 'stores/inventories-check/inventories-check.action';

function Summary(props) {
  const {
    t,
    totalUser,
    totalItem,
    totalStore,
    orderList,
    importItemTicketList,
    conversionRateList,
    storeList,
    isAdmin,
    inventoriesList,
    inventoriesCheckList,
  } = props;
  const storeIds = storeList.map((store) => store.id);
  const [selectMonth, setSelectMonth] = useState(moment().month() + 1);
  const [selectYear, setSelectYear] = useState(moment().year());

  useEffect(() => {
    props.searchInventoriesCheck(
      isAdmin
        ? {}
        : {
            filter: JSON.stringify([
              {
                column: 'user_id',
                value: window.localStorage.getItem('userId'),
              },
            ]),
          },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalIncome =
    orderList
      .filter(
        (order) =>
          order.status === ORDER_STATUS.CONFIRM &&
          (moment(order.updated_at).month() + 1 === selectMonth ||
            !selectMonth) &&
          (moment(order.updated_at).year() === selectYear || !selectYear),
      )
      ?.reduce((a, b) => a + parseInt(b.total_price), 0) || 0;

  const totalFee =
    importItemTicketList
      .filter(
        (ticket) =>
          ticket.status === IMPORT_ITEM_TICKET_STATUS.ACTIVE &&
          (moment(ticket.updated_at).month() + 1 === selectMonth ||
            !selectMonth) &&
          (moment(ticket.updated_at).year() === selectYear || !selectYear) &&
          storeIds.includes(ticket.shop_id),
      )
      ?.reduce(
        (a, b) =>
          a +
          parseInt(
            b.details?.reduce(
              (c, d) =>
                c +
                (d['price'] * d['quantity']) /
                  conversionRateList.find(
                    (rate) =>
                      rate.conversion_rates_type ===
                        CONVERSION_RATE_TYPE.MONEY_TO_COIN &&
                      rate.currency_unit_id === d['currency_unit_id'],
                  )?.price,
              0,
            ),
          ),
        0,
      ) || 0;
  const totalProfit = totalIncome - totalFee;

  const filteredInventoriesList = inventoriesList?.filter(
    (inventory) =>
      storeIds?.includes(inventory.shop.id) &&
      inventory.status === INVENTORY_REPORT_TYPE.UNEXPIRED &&
      (moment(inventory.updated_at).month() + 1 === selectMonth ||
        !selectMonth) &&
      (moment(inventory.updated_at).year() === selectYear || !selectYear) &&
      parseInt(inventory?.quantity) !== 0,
  );

  const totalInventoriesLeftSellPrice =
    filteredInventoriesList.length > 0
      ? filteredInventoriesList?.reduce(
          (a, b) => a + b.item.price * parseInt(b.quantity),
          0,
        )
      : 0;

  const totalInventoriesBuyPrice = filteredInventoriesList?.reduce(
    (a, b) => a + b.import_price_per_item * parseInt(b.quantity),
    0,
  );

  const totalInventoriesPrice = `${totalInventoriesLeftSellPrice} (${totalInventoriesBuyPrice})`;

  const inventoriesCheckDetails = inventoriesCheckList.reduce(
    (a, b) =>
      a.concat(
        b.details.filter(
          (detail) =>
            (moment(detail.inventory_created_at).month() + 1 === selectMonth ||
              !selectMonth) &&
            (moment(detail.inventory_created_at).year() === selectYear ||
              !selectYear) &&
            detail.is_update_quantity,
        ),
      ),
    [],
  );

  const totalLost =
    inventoriesCheckDetails.length > 0
      ? inventoriesCheckDetails.reduce(
          (c, d) =>
            c +
            (d.actual_quantity < d.quantity
              ? d.price * (parseInt(d.quantity) - parseInt(d.actual_quantity))
              : 0),
          0,
        )
      : 0;

  const totalInventoriesSellPrice =
    importItemTicketList
      .filter(
        (ticket) =>
          ticket.status === IMPORT_ITEM_TICKET_STATUS.ACTIVE &&
          (moment(ticket.updated_at).month() + 1 === selectMonth ||
            !selectMonth) &&
          (moment(ticket.updated_at).year() === selectYear || !selectYear) &&
          storeIds.includes(ticket.shop_id),
      )
      ?.reduce(
        (a, b) =>
          a +
          parseInt(
            b.details?.reduce((c, d) => c + d['item_price'] * d['quantity'], 0),
          ),
        0,
      ) || 0;

  const totalProfitPromise = totalInventoriesSellPrice - totalFee;
  const totalProfitActual = totalProfitPromise - totalLost;

  const totalProfitInSoldProduct =
    orderList
      .filter(
        (order) =>
          order.status === ORDER_STATUS.CONFIRM &&
          (moment(order.updated_at).month() + 1 === selectMonth ||
            !selectMonth) &&
          (moment(order.updated_at).year() === selectYear || !selectYear),
      )
      ?.reduce(
        (a, b) =>
          a +
          parseInt(b.order_details.reduce((c, d) => c + parseInt(d.profit), 0)),
        0,
      ) || 0;

  const updateState = (type, value) => {
    switch (type) {
      case 'selectMonth':
        setSelectMonth(value);
        break;
      case 'selectYear':
        if (!value) setSelectMonth(null);
        setSelectYear(value);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Row className="justify-content-between">
        <DateSelection
          showDay={false}
          showWeek={false}
          selectMonth={selectMonth}
          selectYear={selectYear}
          updateState={updateState}
        />
      </Row>
      <Row>
        <Col span={24} className="d-flex">
          <Col xs={24} sm={8} className="card-styling">
            <Card className="db-card">
              <div className="icon-box">
                <DollarOutlined
                  style={{ borderColor: 'yellow' }}
                  className="dashboard-icon"
                />
              </div>
              <div className="card-right">
                <p className="card-num">{totalIncome}</p>
                <p className="card-txt">
                  {t('dashboard.itemReport.totalIncome', {
                    sub: selectYear
                      ? selectMonth
                        ? t('dashboard.itemReport.month', {
                            month: selectMonth,
                            sub: t('dashboard.itemReport.year', {
                              year: selectYear,
                            }),
                          })
                        : t('dashboard.itemReport.year', {
                            year: selectYear,
                          })
                      : '',
                  })}
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={8} className="card-styling">
            <Card className="db-card">
              <div className="icon-box">
                <DollarOutlined className="dashboard-icon" />
              </div>
              <div className="card-right">
                <p className="card-num">{totalFee}</p>
                <p className="card-txt">
                  {t('dashboard.itemReport.totalCost', {
                    sub: selectYear
                      ? selectMonth
                        ? t('dashboard.itemReport.month', {
                            month: selectMonth,
                            sub: t('dashboard.itemReport.year', {
                              year: selectYear,
                            }),
                          })
                        : t('dashboard.itemReport.year', {
                            year: selectYear,
                          })
                      : '',
                  })}
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={8} className="card-styling">
            <Card className="db-card">
              <div className="icon-box">
                <DollarOutlined className="dashboard-icon" />
              </div>
              <div className="card-right">
                <p className="card-num">{totalProfit}</p>
                <p className="card-txt">
                  {t('dashboard.itemReport.totalProfit', {
                    sub: selectYear
                      ? selectMonth
                        ? t('dashboard.itemReport.month', {
                            month: selectMonth,
                            sub: t('dashboard.itemReport.year', {
                              year: selectYear,
                            }),
                          })
                        : t('dashboard.itemReport.year', {
                            year: selectYear,
                          })
                      : '',
                  })}
                </p>
              </div>
            </Card>
          </Col>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24} className="d-flex">
          <Col xs={24} sm={8} className="card-styling">
            <Card className="db-card">
              <div className="icon-box">
                <ShopOutlined className="dashboard-icon" />
              </div>
              <div className="card-right">
                <p className="card-num">{totalStore}</p>
                <p className="card-txt">
                  {t('dashboard.itemReport.totalStores')}
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={8} className="card-styling">
            <Card className="db-card">
              <div className="icon-box">
                <AppstoreOutlined className="dashboard-icon" />
              </div>
              <div className="card-right">
                <p className="card-num">{totalItem}</p>
                <p className="card-txt">
                  {t('dashboard.itemReport.totalItems')}
                </p>
              </div>
            </Card>
          </Col>
          {isAdmin ? (
            <Col xs={24} sm={8} className="card-styling">
              <Card className="db-card">
                <div className="icon-box">
                  <UserOutlined className="dashboard-icon" />
                </div>
                <div className="card-right">
                  <p className="card-num">{totalUser}</p>
                  <p className="card-txt">
                    {t('dashboard.itemReport.totalUsers')}
                  </p>
                </div>
              </Card>
            </Col>
          ) : (
            <Col xs={24} sm={8} className="card-styling">
              <Card className="db-card">
                <div className="icon-box">
                  <DollarOutlined className="dashboard-icon" />
                </div>
                <div className="card-right">
                  <p className="card-num">{totalInventoriesPrice}</p>
                  <p className="card-txt">
                    {t('dashboard.itemReport.inventoriesPrice', {
                      sub: selectYear
                        ? selectMonth
                          ? t('dashboard.itemReport.month', {
                              month: selectMonth,
                              sub: t('dashboard.itemReport.year', {
                                year: selectYear,
                              }),
                            })
                          : t('dashboard.itemReport.year', {
                              year: selectYear,
                            })
                        : '',
                    })}
                  </p>
                </div>
              </Card>
            </Col>
          )}
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24} className="d-flex">
          <Col xs={24} sm={8} className="card-styling">
            <Card className="db-card">
              <div className="icon-box">
                <DollarOutlined className="dashboard-icon" />
              </div>
              <div className="card-right">
                <p className="card-num">{totalLost}</p>
                <p className="card-txt">
                  {t('dashboard.itemReport.totalLost', {
                    sub: selectYear
                      ? selectMonth
                        ? t('dashboard.itemReport.month', {
                            month: selectMonth,
                            sub: t('dashboard.itemReport.year', {
                              year: selectYear,
                            }),
                          })
                        : t('dashboard.itemReport.year', {
                            year: selectYear,
                          })
                      : '',
                  })}
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={8} className="card-styling">
            <Card className="db-card">
              <div className="icon-box">
                <DollarOutlined className="dashboard-icon" />
              </div>
              <div className="card-right">
                <p className="card-num">{totalProfitPromise}</p>
                <p className="card-txt">
                  {t('dashboard.itemReport.totalProfitPromise', {
                    sub: selectYear
                      ? selectMonth
                        ? t('dashboard.itemReport.month', {
                            month: selectMonth,
                            sub: t('dashboard.itemReport.year', {
                              year: selectYear,
                            }),
                          })
                        : t('dashboard.itemReport.year', {
                            year: selectYear,
                          })
                      : '',
                  })}
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={8} className="card-styling">
            <Card className="db-card">
              <div className="icon-box">
                <DollarOutlined className="dashboard-icon" />
              </div>
              <div className="card-right">
                <p className="card-num">{totalProfitActual}</p>
                <p className="card-txt">
                  {t('dashboard.itemReport.totalProfitActual', {
                    sub: selectYear
                      ? selectMonth
                        ? t('dashboard.itemReport.month', {
                            month: selectMonth,
                            sub: t('dashboard.itemReport.year', {
                              year: selectYear,
                            }),
                          })
                        : t('dashboard.itemReport.year', {
                            year: selectYear,
                          })
                      : '',
                  })}
                </p>
              </div>
            </Card>
          </Col>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24} className="d-flex">
          <Col xs={24} sm={8} className="card-styling"></Col>
          <Col xs={24} sm={8} className="card-styling"></Col>
          <Col xs={24} sm={8} className="card-styling">
            <Card className="db-card">
              <div className="icon-box">
                <DollarOutlined className="dashboard-icon" />
              </div>
              <div className="card-right">
                <p className="card-num">{totalProfitInSoldProduct}</p>
                <p className="card-txt">
                  {t('dashboard.itemReport.totalProfitInSoldProduct', {
                    sub: selectYear
                      ? selectMonth
                        ? t('dashboard.itemReport.month', {
                            month: selectMonth,
                            sub: t('dashboard.itemReport.year', {
                              year: selectYear,
                            }),
                          })
                        : t('dashboard.itemReport.year', {
                            year: selectYear,
                          })
                      : '',
                  })}
                </p>
              </div>
            </Card>
          </Col>
        </Col>
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  totalUser: state.user.total,
  totalItem: state.item.total,
  totalStore: state.store.total,
  orderList: state.common.orderList,
  inventoriesList: state.common.inventoriesList,
  importItemTicketList: state.importItemTicket.importItemTicketList,
  conversionRateList: state.conversionRate.conversionRateList,
  storeList: state.store.storeList,
  inventoriesCheckList: state.inventoriesCheck.inventoriesCheckList,
});

const mapDispatchToProps = {
  searchInventoriesCheck,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Summary),
);
