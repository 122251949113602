import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  searchInventoriesFailed,
  searchInventoriesSuccess,
  SEARCH_INVENTORIES_START,
} from 'stores/common/common.action';

/**
 * Search inventories API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const searchInventoriesApi = (params) => {
  const uri = API_URL.INVENTORIES;
  return api.get(uri, params);
};

/**
 * Handle update request and response
 * @param {object} action
 */
function* doSearchInventories(action) {
  try {
    const response = yield call(searchInventoriesApi, action?.payload);

    if (response?.status === 200) {
      const payload = {
        list: response.data.items,
        total: response.data.total,
      };
      yield put(searchInventoriesSuccess(payload));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(searchInventoriesFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch search inventories
 */
export default function* watchSearchInventories() {
  yield takeLatest(SEARCH_INVENTORIES_START, doSearchInventories);
}
