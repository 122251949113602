export const CREATE_STORE_START = 'CREATE_STORE_START';
export const CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS';
export const CREATE_STORE_FAILED = 'CREATE_STORE_FAILED';

export const UPDATE_STORE_START = 'UPDATE_STORE_START';
export const UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS';
export const UPDATE_STORE_FAILED = 'UPDATE_STORE_FAILED';

export const DELETE_STORE_START = 'DELETE_STORE_START';
export const DELETE_STORE_SUCCESS = 'DELETE_STORE_SUCCESS';
export const DELETE_STORE_FAILED = 'DELETE_STORE_FAILED';

export const SEARCH_STORE_START = 'SEARCH_STORE_START';
export const SEARCH_STORE_SUCCESS = 'SEARCH_STORE_SUCCESS';
export const SEARCH_STORE_FAILED = 'SEARCH_STORE_FAILED';

export const GET_STORE_DETAIL_START = 'GET_STORE_DETAIL_START';
export const GET_STORE_DETAIL_SUCCESS = 'GET_STORE_DETAIL_SUCCESS';
export const GET_STORE_DETAIL_FAILED = 'GET_STORE_DETAIL_FAILED';

export const CONFIRM_STORE_START = 'CONFIRM_STORE_START';
export const CONFIRM_STORE_SUCCESS = 'CONFIRM_STORE_SUCCESS';
export const CONFIRM_STORE_FAILED = 'CONFIRM_STORE_FAILED';

export const CREATE_STORE_TRANSACTION_START = 'CREATE_STORE_TRANSACTION_START';
export const CREATE_STORE_TRANSACTION_SUCCESS = 'CREATE_STORE_TRANSACTION_SUCCESS';
export const CREATE_STORE_TRANSACTION_FAILED = 'CREATE_STORE_TRANSACTION_FAILED';

export const COMPLETE_STORE_TRANSACTION_START = 'COMPLETE_STORE_TRANSACTION_START';
export const COMPLETE_STORE_TRANSACTION_SUCCESS = 'COMPLETE_STORE_TRANSACTION_SUCCESS';
export const COMPLETE_STORE_TRANSACTION_FAILED = 'COMPLETE_STORE_TRANSACTION_FAILED';

export const REJECT_STORE_TRANSACTION_START = 'REJECT_STORE_TRANSACTION_START';
export const REJECT_STORE_TRANSACTION_SUCCESS = 'REJECT_STORE_TRANSACTION_SUCCESS';
export const REJECT_STORE_TRANSACTION_FAILED = 'REJECT_STORE_TRANSACTION_FAILED';

/**
 * Create store
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createStore(payload, onSuccess, onError) {
  return {
    type: CREATE_STORE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create store success action
 * @param {*} payload
 * @returns {object}
 */
export function createStoreSuccess(payload) {
  return {
    type: CREATE_STORE_SUCCESS,
    payload: payload,
  };
}

/**
 * Create store failed action
 * @returns {object}
 */
export function createStoreFailed() {
  return {
    type: CREATE_STORE_FAILED,
  };
}

/**
 * Update store
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateStore(payload, id, onSuccess, onError) {
  return {
    type: UPDATE_STORE_START,
    payload: {
      id,
      data: payload,
    },
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update store success action
 * @param {*} payload
 * @returns {object}
 */
export function updateStoreSuccess(payload) {
  return {
    type: UPDATE_STORE_SUCCESS,
    payload: payload,
  };
}

/**
 * Update store failed action
 * @returns {object}
 */
export function updateStoreFailed() {
  return {
    type: UPDATE_STORE_FAILED,
  };
}

/**
 * Delete store
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function deleteStore(payload, onSuccess, onError) {
  return {
    type: DELETE_STORE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Delete store success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteStoreSuccess(payload) {
  return {
    type: DELETE_STORE_SUCCESS,
    payload: payload,
  };
}

/**
 * Delete store failed action
 * @returns {object}
 */
export function deleteStoreFailed() {
  return {
    type: DELETE_STORE_FAILED,
  };
}

/**
 * Search store
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function searchStore(payload, onSuccess, onError) {
  return {
    type: SEARCH_STORE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Search store success action
 * @param {*} payload
 * @returns {object}
 */
export function searchStoreSuccess(payload) {
  return {
    type: SEARCH_STORE_SUCCESS,
    payload: payload,
  };
}

/**
 * Search store failed action
 * @returns {object}
 */
export function searchStoreFailed() {
  return {
    type: SEARCH_STORE_FAILED,
  };
}

/**
 * Get store detail
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getStoreDetail(payload, onSuccess, onError) {
  return {
    type: GET_STORE_DETAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Get store detail success action
 * @param {*} payload
 * @returns {object}
 */
export function getStoreDetailSuccess(payload) {
  return {
    type: GET_STORE_DETAIL_SUCCESS,
    payload: payload,
  };
}

/**
 * Get store detail failed action
 * @returns {object}
 */
export function getStoreDetailFailed() {
  return {
    type: GET_STORE_DETAIL_FAILED,
  };
}

/**
 * Confirm store
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function confirmStore(payload, id, onSuccess, onError) {
  return {
    type: CONFIRM_STORE_START,
    payload: {
      id,
      data: payload,
    },
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Confirm store success action
 * @param {*} payload
 * @returns {object}
 */
export function confirmStoreSuccess(payload) {
  return {
    type: CONFIRM_STORE_SUCCESS,
    payload: payload,
  };
}

/**
 * Confirm store failed action
 * @returns {object}
 */
export function confirmStoreFailed() {
  return {
    type: CONFIRM_STORE_FAILED,
  };
}

/**
 * Create store transaction
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function createStoreTransaction(payload, onSuccess, onError) {
  return {
    type: CREATE_STORE_TRANSACTION_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create store transaction success action
 * @param {*} payload
 * @returns {object}
 */
export function createStoreTransactionSuccess(payload) {
  return {
    type: CREATE_STORE_TRANSACTION_SUCCESS,
    payload: payload,
  };
}

/**
 * Create store transaction failed action
 * @returns {object}
 */
export function createStoreTransactionFailed() {
  return {
    type: CREATE_STORE_TRANSACTION_FAILED,
  };
}

/**
 * Complete store transaction
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function completeStoreTransaction(payload, onSuccess, onError) {
  return {
    type: COMPLETE_STORE_TRANSACTION_START,
    payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Complete store transaction success action
 * @param {*} payload
 * @returns {object}
 */
export function completeStoreTransactionSuccess(payload) {
  return {
    type: COMPLETE_STORE_TRANSACTION_SUCCESS,
    payload: payload,
  };
}

/**
 * Complete store transaction failed action
 * @returns {object}
 */
export function completeStoreTransactionFailed() {
  return {
    type: COMPLETE_STORE_TRANSACTION_FAILED,
  };
}

/**
 * Reject store transaction
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function rejectStoreTransaction(payload, onSuccess, onError) {
  return {
    type: REJECT_STORE_TRANSACTION_START,
    payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Reject store transaction success action
 * @param {*} payload
 * @returns {object}
 */
export function rejectStoreTransactionSuccess(payload) {
  return {
    type: REJECT_STORE_TRANSACTION_SUCCESS,
    payload: payload,
  };
}

/**
 * Reject store transaction failed action
 * @returns {object}
 */
export function rejectStoreTransactionFailed() {
  return {
    type: REJECT_STORE_TRANSACTION_FAILED,
  };
}
