import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import { Form, Spin, Card, Row, Col, Input, Button } from 'antd';
import TopContent from 'components/common/top-content';
import {
  MODAL_MODE,
  TEXTFIELD_REQUIRED_LENGTH,
  CURRENCY_UNIT_STATUS,
  CURRENCY_UNIT_STATUS_MAP,
  DEVICE_MIN_WIDTH,
} from 'common/constant';
import { formatDateTimeUtc, redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import {
  getCurrencyUnitDetail,
  createCurrencyUnit,
  updateCurrencyUnit,
} from 'stores/currency-unit/currency-unit.action';
import moment from 'moment';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

function CurrencyUnitForm(props) {
  const { t, currencyUnit } = props;
  const [form] = Form.useForm();
  const [mode, setMode] = useState(MODAL_MODE.CREATE);
  const [status, setStatus] = useState(0);
  const [title, setTitle] = useState('');
  const [createdAt, setCreatedAt] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      route: ROUTES.CURRENCY_UNIT.PATH,
      title: ROUTES.CURRENCY_UNIT.TITLE,
    },
    {
      title: ROUTES.CURRENCY_UNIT_CREATE.TITLE,
    },
  ]);
  const path = props.match.path;

  useEffect(() => {
    setMode(
      path.includes('/update/')
        ? MODAL_MODE.UPDATE
        : path.includes('/detail/')
        ? MODAL_MODE.DETAIL
        : MODAL_MODE.CREATE,
    );
  }, [path]);

  useEffect(() => {
    if (mode !== MODAL_MODE.CREATE) {
      const { name, created_at, status } = currencyUnit.currencyUnitDetail;
      setStatus(status);
      setCreatedAt(moment(created_at));
      form.setFieldsValue({
        name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyUnit, form]);

  useEffect(() => {
    document.title = title;
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    setBreadcrumbs([
      {
        route: ROUTES.CURRENCY_UNIT.PATH,
        title: ROUTES.CURRENCY_UNIT.TITLE,
      },
      {
        title:
          mode === MODAL_MODE.CREATE
            ? ROUTES.CURRENCY_UNIT_CREATE.TITLE
            : mode === MODAL_MODE.UPDATE
            ? ROUTES.CURRENCY_UNIT_UPDATE.TITLE
            : ROUTES.CURRENCY_UNIT_DETAIL.TITLE,
      },
    ]);

    setTitle(
      mode === MODAL_MODE.CREATE
        ? t('currencyUnit.createCurrencyUnit')
        : mode === MODAL_MODE.UPDATE
        ? t('currencyUnit.editCurrencyUnit')
        : t('currencyUnit.detailCurrencyUnit'),
    );
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const onFinish = (values) => {
    const { name } = values;
    const id = props.match.params.id;
    const params = {
      name,
    };
    if (mode === MODAL_MODE.CREATE)
      props.createCurrencyUnit(params, () => {
        backToList();
      });
    else if (mode === MODAL_MODE.UPDATE) {
      params.id = id;
      props.updateCurrencyUnit(params, () => {
        backToList();
      });
    }
  };

  const backToList = () => {
    redirectRouter(ROUTES.CURRENCY_UNIT.PATH);
  };

  const getDetail = () => {
    const id = props.match.params.id;
    if (mode !== MODAL_MODE.CREATE) props.getCurrencyUnitDetail(id);
  };

  const onCancel = () => {
    if (mode === MODAL_MODE.CREATE) resetForm();
    else if (mode === MODAL_MODE.UPDATE) getDetail();
  };

  const resetForm = () => {
    form?.resetFields();
  };

  const renderActionButtons = () => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return (
          <div id="group-button-submit">
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="currency-unit-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.create')}
            </Button>
          </div>
        );
      case MODAL_MODE.UPDATE:
        return (
          <div id="group-button-submit">
            <Button onClick={() => backToList()} className="close-button">
              {t('common.close')}
            </Button>
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="currency-unit-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.save')}
            </Button>
          </div>
        );
      case MODAL_MODE.DETAIL:
        switch (status) {
          // PENDING
          case CURRENCY_UNIT_STATUS.UNCONFIRM:
          case CURRENCY_UNIT_STATUS.ACTIVE:
            return (
              <div id="group-button-submit">
                <Button onClick={backToList} className="close-button">
                  {t('common.close')}
                </Button>
              </div>
            );
          // INACTIVE
          case CURRENCY_UNIT_STATUS.INACTIVE:
            return (
              <Button onClick={() => backToList()} className="close-button">
                {t('common.close')}
              </Button>
            );
          default:
            return;
        }
      default:
        break;
    }
  };

  const isView = mode === MODAL_MODE.DETAIL;
  return (
    <div className="currency-unit-form">
      <Spin spinning={currencyUnit?.isLoading}>
        <TopContent
          title={title}
          breadcrumbs={breadcrumbs}
          backToList={backToList}
        />
        <Card className="body-content">
          <Form
            {...layout}
            name="currency-unit-form"
            id="currency-unit-form"
            className="required-mark-after"
            form={form}
            onFinish={onFinish}
          >
            <div className="block-1">
              {isView && (
                <Row>
                  <Col
                    sm={12}
                    xs={24}
                    offset={window.innerWidth > DEVICE_MIN_WIDTH ? 4 : 0}
                  >
                    <Form.Item name="status" label={t('currencyUnit.status')}>
                      <div
                        className={
                          [CURRENCY_UNIT_STATUS.ACTIVE].includes(status)
                            ? 'activeStatusBox boxStatus'
                            : [CURRENCY_UNIT_STATUS.UNCONFIRM].includes(status)
                            ? 'unconfirmedStatusBox boxStatus'
                            : 'inActiveStatusBox boxStatus'
                        }
                      >
                        {t(CURRENCY_UNIT_STATUS_MAP[status])}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col
                  sm={12}
                  xs={24}
                  offset={window.innerWidth > DEVICE_MIN_WIDTH ? 4 : 0}
                >
                  <Form.Item
                    name="name"
                    label={t('currencyUnit.name')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('currencyUnit.placeholder.name')}
                    />
                  </Form.Item>
                  {isView && (
                    <Form.Item label={t('currencyUnit.createdAt')}>
                      <div className="ant-input-disabled">
                        {formatDateTimeUtc(createdAt)}
                      </div>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
        <Card style={{ marginTop: '32px' }}>
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderActionButtons()}
              </div>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
}

const mapDispatchToProps = {
  getCurrencyUnitDetail,
  createCurrencyUnit,
  updateCurrencyUnit,
};

const mapStateToProps = (state) => ({
  currencyUnit: state.currencyUnit,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CurrencyUnitForm),
);
