import {
  CREATE_STORE_START,
  CREATE_STORE_SUCCESS,
  CREATE_STORE_FAILED,
  UPDATE_STORE_START,
  UPDATE_STORE_SUCCESS,
  UPDATE_STORE_FAILED,
  DELETE_STORE_START,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_FAILED,
  SEARCH_STORE_START,
  SEARCH_STORE_SUCCESS,
  SEARCH_STORE_FAILED,
  GET_STORE_DETAIL_START,
  GET_STORE_DETAIL_SUCCESS,
  GET_STORE_DETAIL_FAILED,
  CONFIRM_STORE_START,
  CONFIRM_STORE_SUCCESS,
  CONFIRM_STORE_FAILED,
  CREATE_STORE_TRANSACTION_START,
  CREATE_STORE_TRANSACTION_SUCCESS,
  CREATE_STORE_TRANSACTION_FAILED,
  COMPLETE_STORE_TRANSACTION_START,
  COMPLETE_STORE_TRANSACTION_SUCCESS,
  COMPLETE_STORE_TRANSACTION_FAILED,
  REJECT_STORE_TRANSACTION_START,
  REJECT_STORE_TRANSACTION_SUCCESS,
  REJECT_STORE_TRANSACTION_FAILED,
} from './store.action';

const initialState = {
  isLoading: false,
  storeList: [],
  storeDetail: {},
  total: 0,
};

/**
 * store reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function store(state = initialState, action) {
  switch (action.type) {
    case CREATE_STORE_START:
    case UPDATE_STORE_START:
    case DELETE_STORE_START:
    case CONFIRM_STORE_START:
    case GET_STORE_DETAIL_START:
    case CREATE_STORE_TRANSACTION_START:
    case COMPLETE_STORE_TRANSACTION_START:
    case REJECT_STORE_TRANSACTION_START:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_STORE_START:
      return {
        ...state,
        storeDetail: {},
        isLoading: true,
      };
    case CREATE_STORE_SUCCESS:
    case UPDATE_STORE_SUCCESS:
    case DELETE_STORE_SUCCESS:
    case CONFIRM_STORE_SUCCESS:
    case CREATE_STORE_TRANSACTION_SUCCESS:
    case COMPLETE_STORE_TRANSACTION_SUCCESS:
    case REJECT_STORE_TRANSACTION_SUCCESS:
    case CREATE_STORE_FAILED:
    case UPDATE_STORE_FAILED:
    case DELETE_STORE_FAILED:
    case CONFIRM_STORE_FAILED:
    case CREATE_STORE_TRANSACTION_FAILED:
    case COMPLETE_STORE_TRANSACTION_FAILED:
    case REJECT_STORE_TRANSACTION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_STORE_SUCCESS:
      return {
        ...state,
        storeList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case SEARCH_STORE_FAILED:
      return {
        ...state,
        storeList: [],
        total: 0,
        isLoading: false,
      };
    case GET_STORE_DETAIL_SUCCESS:
      return {
        ...state,
        storeDetail: action.payload,
        isLoading: false,
      };
    case GET_STORE_DETAIL_FAILED:
      return {
        ...state,
        storeDetail: {},
        isLoading: false,
      };
    default:
      return state;
  }
}
