import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  deleteImportItemTicketFailed,
  deleteImportItemTicketSuccess,
  DELETE_IMPORT_ITEM_TICKET_START,
} from 'stores/import-item-ticket/import-item-ticket.action';

/**
 * Delete import-item-ticket API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const deleteImportItemTicketApi = (params) => {
  const uri = `/${API_URL.IMPORT_ITEM_TICKET}/${params}`;
  return api.delete(uri);
};

/**
 * Handle delete request and response
 * @param {object} action
 */
function* doDeleteImportItemTicket(action) {
  try {
    const response = yield call(deleteImportItemTicketApi, action?.payload);

    if (response?.status === 200) {
      yield put(deleteImportItemTicketSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'importItemTicket.deleteImportItemTicketSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(deleteImportItemTicketFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch delete import-item-ticket
 */
export default function* watchDeleteImportItemTicket() {
  yield takeLatest(DELETE_IMPORT_ITEM_TICKET_START, doDeleteImportItemTicket);
}
