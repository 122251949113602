import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  createConversionRateFailed,
  createConversionRateSuccess,
  CREATE_CONVERSION_RATE_START,
} from 'stores/conversion-rate/conversion-rate.action';

/**
 * Create conversion-rate API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const createConversionRateApi = (params) => {
  const uri = `${API_URL.CONVERSION_RATE}`;
  return api.post(uri, params);
};

/**
 * Handle create request and response
 * @param {object} action
 */
function* doCreateConversionRate(action) {
  try {
    const response = yield call(createConversionRateApi, action?.payload);

    if (response?.status === 200) {
      yield put(createConversionRateSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'conversionRate.createConversionRateSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(createConversionRateFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch create conversion-rate
 */
export default function* watchCreateConversionRate() {
  yield takeLatest(CREATE_CONVERSION_RATE_START, doCreateConversionRate);
}
