import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  changePasswordAdminFailed,
  changePasswordAdminSuccess,
  CHANGE_PASSWORD_ADMIN_START,
} from 'stores/user/user.action';

/**
 * Change user password admin API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const changePasswordAdminApi = (params) => {
  const {user_id} = params;
  delete params.user_id;
  const uri = `/${API_URL.USER}/${user_id}/change_password`;
  return api.put(uri, params);
};

/**
 * Handle change password admin request and response
 * @param {object} action
 */
function* dochangePassword(action) {
  try {
    const response = yield call(changePasswordAdminApi, action?.payload);

    if (response?.status === 200) {
      yield put(changePasswordAdminSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'user.changePasswordSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(changePasswordAdminFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch change password
 */
export default function* watchChangePasswordAdmin() {
  yield takeLatest(CHANGE_PASSWORD_ADMIN_START, dochangePassword);
}
