import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  searchItemUnitFailed,
  searchItemUnitSuccess,
  SEARCH_ITEM_UNIT_START,
} from 'stores/item-unit/item-unit.action';

/**
 * Search item-unit API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const searchItemUnitApi = (params) => {
  const uri = `/${API_URL.ITEM_UNIT}`;
  return api.get(uri, params);
};

/**
 * Handle update request and response
 * @param {object} action
 */
function* doSearchItemUnit(action) {
  try {
    const response = yield call(searchItemUnitApi, action?.payload);

    if (response?.status === 200) {
      const payload = {
        list: response.data.items,
        total: response.data.total,
      }
      yield put(searchItemUnitSuccess(payload));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(searchItemUnitFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch search item-unit
 */
export default function* watchSearchItemUnit() {
  yield takeLatest(SEARCH_ITEM_UNIT_START, doSearchItemUnit);
}
