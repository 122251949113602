import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TopContent from 'components/common/top-content';
import CustomTable from 'components/common/custom-table';
import { Button, Modal, Spin } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import './index.scss';
import { redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import { searchBarcode, deleteBarcode } from 'stores/barcode/barcode.action';

const breadcrumbs = [
  {
    route: '/barcode',
    title: 'barcode',
  },
];

function Barcode(props) {
  const { t, barcode } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState(null);
  const [keyword, setKeyWord] = useState('');
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  useEffect(() => {
    document.title = t('barcode.title');
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, page, pageSize, filter, sort]);

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('barcode.id'),
    },
    {
      key: 'code',
      dataIndex: 'barcode',
      title: t('barcode.code'),
      filterable: true,
      sorter: true,
    },
    {
      key: 'item_name',
      dataIndex: 'item_name',
      title: t('barcode.item'),
      filterable: true,
    },
    {
      key: 'action',
      dataIndex: 'id',
      title: t('common.action'),
      disableClickEventBubbling: true,
      sorter: false,
      align: 'center',
      headerAlign: 'center',
      render: (params) => {
        return (
          <div className="action-button">
            <Button
              type="text"
              shape="circle"
              icon={<DeleteFilled />}
              onClick={() => onClickDelete(params)}
            />
          </div>
        );
      },
    },
  ];

  const handleCreate = () => {
    redirectRouter(ROUTES.BARCODE_CREATE.PATH);
  };

  const refreshData = () => {
    props.searchBarcode({
      keyword: keyword.trim(),
      offset: (page - 1) * pageSize,
      limit: pageSize,
      filter: JSON.stringify(
        filter.concat([
          {
            column: 'user_id',
            value: window.localStorage.getItem('userId'),
          },
        ]),
      ),
      sort,
    });
  };

  const handleSearch = () => {
    refreshData();
  };

  const updateKeyword = (value) => {
    setKeyWord(value);
  };

  const onPageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
  };

  const onPageChange = ({ page }) => {
    setPage(page);
  };

  const onChangeFilter = (filter) => {
    setFilter(filter);
    setPage(1);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSort({
      column: sorter.field,
      value: sorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  const onClickDelete = (id) => {
    setDeletingId(id);
    setOpenConfirmDeleteModal(true);
  };

  const onCancelDelete = () => {
    setDeletingId(null);
    setOpenConfirmDeleteModal(false);
  };

  const onSubmitDelete = () => {
    props.deleteBarcode(deletingId, () => {
      onCancelDelete();
      refreshData();
    });
  };

  return (
    <Spin spinning={barcode?.isLoading}>
      <TopContent
        title={t('barcode.title')}
        breadcrumbs={breadcrumbs}
        displayButton={['create']}
        createButton={t('barcode.createButton')}
        handleCreateOpenModal={handleCreate}
        updateKeyword={updateKeyword}
        handleSearch={handleSearch}
      />
      <Modal
        visible={openConfirmDeleteModal}
        title={t('barcode.deleteModalTitle')}
        size="sm"
        onOk={onSubmitDelete}
        onCancel={onCancelDelete}
        okText={t('common.delete')}
        cancelText={t('common.cancel')}
        hideCancel
        width={719}
        okButtonProps={{
          id: 'delete-button',
        }}
        cancelButtonProps={{
          className: 'cancel-button-custom',
        }}
      >
        {t('barcode.deleteConfirm')}
      </Modal>
      <CustomTable
        columns={columns}
        rows={barcode.barcodeList}
        page={page}
        pageSize={pageSize}
        total={barcode?.total}
        topInfo={true}
        onChange={handleTableChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onChangeFilter={onChangeFilter}
      />
    </Spin>
  );
}

const mapDispatchToProps = {
  searchBarcode,
  deleteBarcode,
};

const mapStateToProps = (state) => ({
  barcode: state.barcode,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Barcode),
);
