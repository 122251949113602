import {
  CREATE_CATEGORY_START,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILED,
  UPDATE_CATEGORY_START,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILED,
  DELETE_CATEGORY_START,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILED,
  SEARCH_CATEGORY_START,
  SEARCH_CATEGORY_SUCCESS,
  SEARCH_CATEGORY_FAILED,
  GET_CATEGORY_DETAIL_START,
  GET_CATEGORY_DETAIL_SUCCESS,
  GET_CATEGORY_DETAIL_FAILED,
} from './category.action';

const initialState = {
  isLoading: false,
  categoryList: [],
  categoryDetail: {},
  total: 0,
};

/**
 * category reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function category(state = initialState, action) {
  switch (action.type) {
    case CREATE_CATEGORY_START:
    case UPDATE_CATEGORY_START:
    case DELETE_CATEGORY_START:
    case GET_CATEGORY_DETAIL_START:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_CATEGORY_START:
      return {
        ...state,
        categoryDetail: {},
        isLoading: true,
      };
    case CREATE_CATEGORY_SUCCESS:
    case UPDATE_CATEGORY_SUCCESS:
    case DELETE_CATEGORY_SUCCESS:
    case CREATE_CATEGORY_FAILED:
    case UPDATE_CATEGORY_FAILED:
    case DELETE_CATEGORY_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case SEARCH_CATEGORY_FAILED:
      return {
        ...state,
        categoryList: [],
        total: 0,
        isLoading: false,
      };
    case GET_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        categoryDetail: action.payload,
        isLoading: false,
      };
    case GET_CATEGORY_DETAIL_FAILED:
      return {
        ...state,
        categoryDetail: {},
        isLoading: false,
      };
    default:
      return state;
  }
}
