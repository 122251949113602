import {
  GET_NOTIFICATION_LIST_START,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAILED,
  GET_NOTIFICATION_SETTINGS_LIST_START,
  GET_NOTIFICATION_SETTINGS_LIST_SUCCESS,
  GET_NOTIFICATION_SETTINGS_LIST_FAILED,
  UPDATE_ALL_NOTIFICATION_STATUS_START,
  UPDATE_ALL_NOTIFICATION_STATUS_SUCCESS,
  UPDATE_ALL_NOTIFICATION_STATUS_FAILED,
  UPDATE_NOTIFICATION_SETTING_STATUS_START,
  UPDATE_NOTIFICATION_SETTING_STATUS_SUCCESS,
  UPDATE_NOTIFICATION_SETTING_STATUS_FAILED,
  UPDATE_NOTIFICATION_STATUS_START,
  UPDATE_NOTIFICATION_STATUS_SUCCESS,
  UPDATE_NOTIFICATION_STATUS_FAILED,
} from './notification.action';

const initialState = {
  isLoading: false,
  notificationSettingList: [],
  notificationList: [],
};

export default function notification(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATION_SETTINGS_LIST_START:
    case GET_NOTIFICATION_LIST_START:
    case UPDATE_NOTIFICATION_STATUS_START:
    case UPDATE_NOTIFICATION_SETTING_STATUS_START:
    case UPDATE_ALL_NOTIFICATION_STATUS_START:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_ALL_NOTIFICATION_STATUS_SUCCESS:
    case UPDATE_NOTIFICATION_SETTING_STATUS_SUCCESS:
    case UPDATE_NOTIFICATION_STATUS_SUCCESS:
    case UPDATE_NOTIFICATION_STATUS_FAILED:
    case UPDATE_NOTIFICATION_SETTING_STATUS_FAILED:
    case UPDATE_ALL_NOTIFICATION_STATUS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notificationList: action.payload,
      };
    case GET_NOTIFICATION_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        notificationList: [],
      };
    case GET_NOTIFICATION_SETTINGS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notificationSettingList: action.payload,
      };
    case GET_NOTIFICATION_SETTINGS_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        notificationSettingList: [],
      };
    default:
      return state;
  }
}
