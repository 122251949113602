import { API_URL, NOTIFICATION_TYPE } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  getNotificationListFailed,
  getNotificationListSuccess,
  GET_NOTIFICATION_LIST_START,
} from 'stores/notification/notification.action';

/**
 * Get notification API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getNotificationListApi = (params) => {
  const uri = `/${API_URL.NOTIFICATION}/user/${params}`;
  return api.get(uri);
};

/**
 * Handle get notification request and response
 * @param {object} action
 */
function* doGetNotificationList(action) {
  try {
    const response = yield call(getNotificationListApi, action?.payload);

    if (response?.status === 200) {
      const payload = response.data;
      yield put(getNotificationListSuccess(payload));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(getNotificationListFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch get notification list
 */
export default function* watchGetNotificationList() {
  yield takeLatest(GET_NOTIFICATION_LIST_START, doGetNotificationList);
}
