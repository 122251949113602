import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import { Form, Row, Col, Input, Modal, Select, Button } from 'antd';
import {
  CONVERSION_RATE_TYPE,
  DEFAULT_ADMIN_PAYMENT_TYPE_ID,
  PATTERN,
  TRANSACTION_TYPE,
} from 'common/constant';
import { transferCoin } from 'stores/user/user.action';
import { DoubleRightOutlined } from '@ant-design/icons';
import Coin from 'assets/images/coin.png';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

function TransferCoinModal(props) {
  const {
    t,
    openTransferCoinModal,
    transferUserId,
    user,
    currencyUnitList,
    conversionRateList,
  } = props;
  const [coin, setCoin] = useState(0);
  const [conversionRateId, setConversionRateId] = useState(null);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { conversion_rate_id, money } = values;
    const params = {
      receiver_id: transferUserId,
      price: coin,
      money: +money,
      transaction_type: TRANSACTION_TYPE.ADMIN,
      conversion_rate_id,
      payment_type_id: DEFAULT_ADMIN_PAYMENT_TYPE_ID,
    };

    props.transferCoin(params, () => {
      props.onCloseTransfer();
    });
  };

  const onCancelTransfer = () => {
    form.resetFields();
  };

  const validCurrencyUnitList = currencyUnitList.filter((unit) =>
    conversionRateList
      .filter(
        (rate) =>
          rate.conversion_rates_type === CONVERSION_RATE_TYPE.MONEY_TO_COIN,
      )
      .map((rate) => rate.currency_unit_id)
      .includes(unit.id),
  );

  const handleChangeMoneyValue = (value) => {
    const conversion_rate_id = form.getFieldValue('conversion_rate_id');

    if (conversion_rate_id) {
      setCoin(
        Math.round(
          value /
            conversionRateList.find((rate) => rate.id === +conversion_rate_id)
              ?.price,
        ),
      );
    }
  };

  const handleChangeCurrencyUnit = (value) => {
    const money = form.getFieldValue('money');

    setConversionRateId(value);
    setCoin(
      money
        ? Math.round(
            money /
              conversionRateList.find((rate) => rate.id === +value)?.price,
          )
        : 0,
    );

    if (money) form.validateFields();
  };

  return (
    <Modal
      visible={openTransferCoinModal}
      title={t('user.transferCoinTitle')}
      size="sm"
      onCancel={props.onCloseTransfer}
      width={700}
      okButtonProps={{
        id: 'delete-button',
      }}
      cancelButtonProps={{
        className: 'cancel-button-custom',
      }}
      footer={[
        <Button
          key="cancel"
          onClick={onCancelTransfer}
          className="cancel-button-custom"
        >
          {t('common.cancel')}
        </Button>,
        <Button
          form="transfer-coin-form"
          key="submit"
          htmlType="submit"
          className="update-button-custom"
        >
          {t('common.confirm')}
        </Button>,
      ]}
    >
      <Form
        {...layout}
        name="transfer-coin-form"
        id="transfer-coin-form"
        className="required-mark-after ant-card-body"
        form={form}
        onFinish={onFinish}
      >
        <div className="block-1">
          <Row>
            <Col span={18} offset={3}>
              <Form.Item label={t('user.fullName')}>
                <div className="ant-input ant-input-disabled">
                  {user?.full_name}
                </div>
              </Form.Item>
              <Form.Item
                label={t('user.currencyUnit')}
                name="conversion_rate_id"
                rules={[
                  {
                    required: true,
                    message: t('form.required'),
                  },
                ]}
              >
                <Select
                  placeholder={t('user.placeholder.currencyUnit')}
                  onChange={(value) => handleChangeCurrencyUnit(value)}
                >
                  {validCurrencyUnitList
                    .filter((unit) => unit.name !== 'COIN')
                    .map((unit) => {
                      const value = conversionRateList.find(
                        (rate) =>
                          rate.currency_unit_id === unit.id &&
                          rate.conversion_rates_type ===
                            CONVERSION_RATE_TYPE.MONEY_TO_COIN,
                      )?.id;
                      return (
                        <Select.Option key={value} value={value}>
                          {t(unit.name)}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item label={t('conversionRate.price')}>
                {`${
                  conversionRateList.find(
                    (rate) =>
                      rate.id === +form.getFieldValue('conversion_rate_id'),
                  )?.price || ''
                } : 1`}
              </Form.Item>
              <Form.Item
                label={t('user.transferValue')}
                name="money"
                rules={[
                  {
                    required: true,
                    message: t('form.required'),
                  },
                  {
                    pattern: PATTERN.positive_number,
                    message: t('form.greaterThanZero'),
                  },
                  {
                    validator: (rule, value, cb) => {
                      const min = conversionRateList.find(
                        (rate) => rate.id === conversionRateId,
                      )?.price;
                      if (min && value < +min)
                        cb(
                          t('form.min', {
                            min: +min,
                          }),
                        );
                      cb();
                    },
                  },
                ]}
              >
                <Input
                  placeholder={t('user.placeholder.transferValue')}
                  type="number"
                  onChange={(event) =>
                    handleChangeMoneyValue(event.target.value)
                  }
                  suffix={
                    <>
                      <DoubleRightOutlined /> &nbsp; {+coin} &nbsp;
                      <img
                        src={Coin}
                        width="15px"
                        height="15px"
                        alt="coin-icon"
                      />
                    </>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
}

const mapDispatchToProps = {
  transferCoin,
};

const mapStateToProps = (state) => ({
  itemUnit: state.itemUnit,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TransferCoinModal),
);
