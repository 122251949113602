import React, { Component } from 'react';
import { Table, Select, Pagination, Row, Col, Statistic } from 'antd';
import { withTranslation } from 'react-i18next';
import './index.scss';
import FilterDataPanel from './components/filter-data-panel';
import { connect } from 'react-redux';

const ROWS_PER_PAGE_OPTIONS = [20, 50, 100];

/**
 * Data Table
 */
class CustomTable extends Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      orderBy: null,
      selected: [],
      page: props.page || 0,
      pageSize: props.pageSize || 20,
      indexCol: props.indexCol || 'id',
      filters: props.filters || [],
      truncatedValues: [],
      selectedRowKeys: [],
      confirmMessage: '',
    };
  }

  /**
   * Handle change order
   * @param {*} param
   */
  onChangeSort = ({ order, orderBy }) => {
    this.clearTruncateValues();
    this.setState({ order, orderBy });
    this.props.onChangeSort({ order, orderBy });
  };

  /**
   * Handle select all
   * @param {*} event
   */
  handleSelectAllClick = (event) => {
    this.clearTruncateValues();
    const { rows } = this.props;
    const { indexCol, selected } = this.state;
    if (event.target.checked) {
      const concatSelected = [...selected, ...rows];
      const uniqueIndexValues = [
        ...new Set(concatSelected.map((item) => item[indexCol])),
      ];
      const newSelected = uniqueIndexValues.map((indexValue) =>
        concatSelected.find((item) => item[indexCol] === indexValue),
      );
      this.setState({ selected: newSelected });
      this.props.onChangeSelectedRows &&
        this.props.onChangeSelectedRows(newSelected);
    } else {
      const newSelected = selected.filter(
        (item) => !rows.find((e) => e[indexCol] === item[indexCol]),
      );
      this.setState({ selected: newSelected });
      this.props.onChangeSelectedRows &&
        this.props.onChangeSelectedRows(newSelected);
    }
  };

  /**
   * Handle select or deselect row
   * @param {*} event
   * @param {*} indexValue
   * @returns
   */
  handleSelectOrDeselectRow = (event, indexValue) => {
    this.clearTruncateValues();
    const { checkboxSelection, rows } = this.props;
    if (!checkboxSelection) return;
    const { selected, indexCol } = this.state;
    const selectedIndex = selected.findIndex(
      (item) => item[indexCol] === indexValue,
    );
    let newSelected = [];

    const newValueData = rows.find((item) => item[indexCol] === indexValue);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, newValueData);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
    this.props.onChangeSelectedRows &&
      this.props.onChangeSelectedRows(newSelected);
  };

  onChangePage = (page) => {
    this.clearTruncateValues();
    if (this.props.checkboxSelection) this.props.onChangeSelectedRows([]);
    page && this.setState({ page, selectedRowKeys: [] });
    this.props.onPageChange && page && this.props.onPageChange({ page });
  };

  onChangePageSize = (index, { children: pageSize }) => {
    this.clearTruncateValues();
    if (this.props.checkboxSelection) this.props.onChangeSelectedRows([]);
    pageSize &&
      this.setState({ pageSize: pageSize, page: 1, selectedRowKeys: [] });
    this.props.onPageSizeChange &&
      pageSize &&
      this.props.onPageSizeChange({ pageSize: pageSize });
    this.props.onPageChange({ page: 1 });
  };

  /**
   * Check if row is selected
   * @param {*} indexColValue
   * @returns
   */
  isSelected = (indexColValue) => {
    const { selected, indexCol } = this.state;
    return (
      selected.findIndex((item) => item[indexCol] === indexColValue) !== -1
    );
  };

  /**
   * Handle change filter event
   * @param {string} field
   * @param {string} value
   */
  onChangeFilter = (field, value) => {
    if (value === null) {
      let filters = [];
      // Call props handler
      const { onChangeFilter } = this.props;
      onChangeFilter && onChangeFilter(filters);
    } else {
      this.clearTruncateValues();
      let filters = [...this.state.filters];
      const index = filters.findIndex((item) => item.field === field);

      // if field is in filters
      if (index >= 0) {
        filters[index].value =
          typeof value === 'string' ? value?.trim() : value;
      } else {
        // if field is not in filters => reset null and push new field
        filters = [];
        filters.push({
          column: field,
          value: typeof value === 'string' ? value?.trim() : value,
        });
      }

      filters = filters.filter((item) => item.value !== '');

      if (this.props.checkboxSelection) this.props.onChangeSelectedRows([]);
      this.setState({ filters, selectedRowKeys: [] });

      // Call props handler
      const { onChangeFilter } = this.props;
      onChangeFilter && onChangeFilter(filters);
    }
  };

  /**
   *
   * @param {{colIndexValue: *, field: string}} truncateData
   */
  onTruncateValue = (truncateData) => {
    const { truncatedValues } = this.state;
    truncatedValues.push(truncateData);

    this.setState({
      truncatedValues: truncatedValues.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.colIndexValue === thing.colIndexValue &&
              t.field === thing.field,
          ),
      ),
    });
  };

  /**
   * clearTruncateValues
   */
  clearTruncateValues = () => {
    this.setState({
      truncatedValues: [],
    });
  };

  /**
   * onSelectChange
   */
  onSelectChange = (selectedRowKeys) => {
    const dataSelect = [];
    selectedRowKeys.forEach((value) => {
      dataSelect.push(this.props.rows[value]);
    });
    this.setState({ selectedRowKeys });
    this.props.onChangeSelectedRows(dataSelect);
  };

  /**
   * Render
   * @returns {JSX.Element}
   */
  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const {
      rows,
      className,
      checkboxSelection,
      columns,
      total,
      t,
      topInfo,
      noPagination,
      title,
      selectedRows,
      ...props
    } = this.props;

    const { page } = this.state;
    for (let i = 0; i < rows.length; i++) {
      rows[i]['key'] = i;
    }

    return (
      <>
        {topInfo && (
          <div className="filterSelectColumn">
            <FilterDataPanel
              t={t}
              onChangeFilter={this.onChangeFilter}
              columns={columns}
            />
          </div>
        )}
        {this.props.totalStat && (
          <Row justify="end">
            <Col span={3}>
              <Row justify="end">
                <Statistic
                  title={t('inventoryStatistics.totalStock')}
                  value={this.props.totalStock}
                />
              </Row>
            </Col>
            <Col span={3}>
              <Row justify="end">
                <Statistic
                  title={t('inventoryStatistics.totalCost')}
                  value={this.props.totalCost}
                />
              </Row>
            </Col>
          </Row>
        )}
        <Table
          rowSelection={checkboxSelection ? rowSelection : undefined}
          className={className + ' customTable'}
          columns={columns}
          dataSource={rows}
          title={
            title
              ? () => (
                  <h2>
                    <b>{title}</b>
                  </h2>
                )
              : null
          }
          {...props}
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={false}
          showSorterTooltip={false}
        />
        {!noPagination && (
          <div className="custom-paging">
            <div className="text">
              <span className="text_sub">{t('pagination.rowsPerPage')}</span>
              <Select
                defaultValue={ROWS_PER_PAGE_OPTIONS[0]}
                onChange={this.onChangePageSize}
                size="small"
                className="select-paging"
              >
                {ROWS_PER_PAGE_OPTIONS.map((rows, index) => (
                  <Select.Option key={index}>{rows}</Select.Option>
                ))}
              </Select>
            </div>
            <div>
              <Pagination
                defaultCurrent={+page}
                current={+page}
                total={total}
                onChange={this.onChangePage}
                pageSize={this.state.pageSize}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CustomTable),
);
