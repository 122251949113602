import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TopContent from 'components/common/top-content';
import CustomTable from 'components/common/custom-table';
import ShopAvatar from 'components/common/shop-avatar';
import { Spin, List, Card } from 'antd';
import './index.scss';
import { searchInventories } from 'stores/common/common.action';
import { searchStore } from 'stores/store/store.action';
import { formatDateTimeUtc } from 'utils';
import { DEVICE_MIN_WIDTH, INVENTORY_STATUS } from 'common/constant';

const breadcrumbs = [
  {
    route: '/outdated-statistics',
    title: 'outdatedStatistics',
  },
];

function OutdatedStatistics(props) {
  const { t, common, storeList } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState([
    {
      column: 'status',
      value: INVENTORY_STATUS.EXPIRED,
    },
  ]);
  const [sort, setSort] = useState(null);
  const [keyword] = useState('');
  const [shopId, setShopId] = useState(null);
  const [shopName, setShopName] = useState('');
  const deviceWidth = window.innerWidth;

  useEffect(() => {
    document.title = t('inventories.title');
    props.searchStore({
      filter: JSON.stringify([
        {
          column: 'user_id',
          value: window.localStorage.getItem('userId'),
        },
      ]),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, page, pageSize, filter, sort]);

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('inventories.id'),
    },
    {
      key: 'item_id',
      dataIndex: 'item_id',
      title: t('inventories.itemName'),
      sorter: true,
      render: (params, row) => {
        const { item } = row;
        return item?.name;
      },
    },
    {
      key: 'expiration_date',
      dataIndex: 'expiration_date',
      title: t('inventories.expirationDate'),
      render: (params) => {
        return formatDateTimeUtc(params);
      },
    },
  ];

  const refreshData = () => {
    props.searchInventories({
      keyword,
      offset: (page - 1) * pageSize,
      limit: pageSize,
      filter: JSON.stringify(filter),
      sort,
    });
  };

  const onPageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
  };

  const onPageChange = ({ page }) => {
    setPage(page);
  };

  const onChangeFilter = (filter) => {
    setFilter(filter);
    setPage(1);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSort({
      column: sorter.field,
      value: sorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  const backToList = () => {
    setShopId(null);
  };

  const handleSelectShop = (shop) => {
    setShopName(shop?.name);
    setShopId(shop?.id);
  };

  return (
    <Spin spinning={common?.isLoading}>
      {shopId ? (
        <>
          <TopContent
            title={`${t('outdatedInventories.title')} ${shopName}`}
            breadcrumbs={breadcrumbs}
            backToList={backToList}
          />
          <CustomTable
            columns={columns}
            rows={common.inventoriesList?.filter(
              (inventory) =>
                inventory?.shop?.id === shopId &&
                inventory.status === INVENTORY_STATUS.EXPIRED &&
                parseInt(inventory?.quantity) !== 0,
            )}
            page={page}
            pageSize={pageSize}
            total={common?.total}
            topInfo={true}
            onChange={handleTableChange}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onChangeFilter={onChangeFilter}
          />
        </>
      ) : (
        <center>
          <Card className="shop-list">
            <center>
              {deviceWidth > DEVICE_MIN_WIDTH ? (
                <h2>
                  <b>{t('common.shopList')}</b>
                </h2>
              ) : (
                <h3>
                  <b>{t('common.shopList')}</b>
                </h3>
              )}
            </center>
            <List
              grid={{
                gutter: 16,
                column: deviceWidth > DEVICE_MIN_WIDTH ? 3 : 1,
              }}
              dataSource={storeList}
              renderItem={(shop) => (
                <List.Item onClick={() => handleSelectShop(shop)}>
                  <ShopAvatar shop={shop} />
                </List.Item>
              )}
            />
          </Card>
        </center>
      )}
    </Spin>
  );
}

const mapDispatchToProps = {
  searchInventories,
  searchStore,
};

const mapStateToProps = (state) => ({
  common: state.common,
  storeList: state.store.storeList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(OutdatedStatistics),
);
