import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  searchAdminTransactionsFailed,
  searchAdminTransactionsSuccess,
  SEARCH_ADMIN_TRANSACTIONS_START,
} from 'stores/common/common.action';

/**
 * Search admin transactions API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const searchAdminTransactionsApi = (params) => {
  const uri = `${API_URL.TRANSACTIONS}/admin`;
  return api.get(uri);
};

/**
 * Handle get admin transaction request and response
 * @param {object} action
 */
function* doSearchAdminTransactions(action) {
  try {
    const response = yield call(searchAdminTransactionsApi, action?.payload);

    if (response?.status === 200) {
      yield put(searchAdminTransactionsSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(searchAdminTransactionsFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch search adminTransactions
 */
export default function* watchSearchAdminTransactions() {
  yield takeLatest(SEARCH_ADMIN_TRANSACTIONS_START, doSearchAdminTransactions);
}
