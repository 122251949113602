import {
  CREATE_CONVERSION_RATE_START,
  CREATE_CONVERSION_RATE_SUCCESS,
  CREATE_CONVERSION_RATE_FAILED,
  UPDATE_CONVERSION_RATE_START,
  UPDATE_CONVERSION_RATE_SUCCESS,
  UPDATE_CONVERSION_RATE_FAILED,
  DELETE_CONVERSION_RATE_START,
  DELETE_CONVERSION_RATE_SUCCESS,
  DELETE_CONVERSION_RATE_FAILED,
  SEARCH_CONVERSION_RATE_START,
  SEARCH_CONVERSION_RATE_SUCCESS,
  SEARCH_CONVERSION_RATE_FAILED,
  GET_CONVERSION_RATE_DETAIL_START,
  GET_CONVERSION_RATE_DETAIL_SUCCESS,
  GET_CONVERSION_RATE_DETAIL_FAILED,
} from './conversion-rate.action';

const initialState = {
  isLoading: false,
  conversionRateList: [],
  conversionRateDetail: {},
  total: 0,
};

/**
 * conversion-rate reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function conversionRate(state = initialState, action) {
  switch (action.type) {
    case CREATE_CONVERSION_RATE_START:
    case UPDATE_CONVERSION_RATE_START:
    case DELETE_CONVERSION_RATE_START:
    case GET_CONVERSION_RATE_DETAIL_START:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_CONVERSION_RATE_START:
      return {
        ...state,
        conversionRateDetail: {},
        isLoading: true,
      };
    case CREATE_CONVERSION_RATE_SUCCESS:
    case UPDATE_CONVERSION_RATE_SUCCESS:
    case DELETE_CONVERSION_RATE_SUCCESS:
    case CREATE_CONVERSION_RATE_FAILED:
    case UPDATE_CONVERSION_RATE_FAILED:
    case DELETE_CONVERSION_RATE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_CONVERSION_RATE_SUCCESS:
      return {
        ...state,
        conversionRateList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case SEARCH_CONVERSION_RATE_FAILED:
      return {
        ...state,
        conversionRateList: [],
        total: 0,
        isLoading: false,
      };
    case GET_CONVERSION_RATE_DETAIL_SUCCESS:
      return {
        ...state,
        conversionRateDetail: action.payload,
        isLoading: false,
      };
    case GET_CONVERSION_RATE_DETAIL_FAILED:
      return {
        ...state,
        conversionRateDetail: {},
        isLoading: false,
      };
    default:
      return state;
  }
}
