import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TopContent from 'components/common/top-content';
import CustomTable from 'components/common/custom-table';
import { Button, Modal, Spin } from 'antd';
import {
  EyeFilled,
  EditFilled,
  DeleteFilled,
  CheckSquareFilled,
} from '@ant-design/icons';
import './index.scss';
import { formatDateTimeUtc, redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import {
  searchImportItemTicket,
  confirmImportItemTicket,
  deleteImportItemTicket
} from 'stores/import-item-ticket/import-item-ticket.action';
import {
  IMPORT_ITEM_TICKET_STATUS,
  IMPORT_ITEM_TICKET_STATUS_LIST,
  IMPORT_ITEM_TICKET_STATUS_MAP,
} from 'common/constant';

const breadcrumbs = [
  {
    route: '/import-item-ticket',
    title: 'importItemTicket',
  },
];

function ImportItemTicket(props) {
  const { t, importItemTicket } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState(null);
  const [keyword, setKeyWord] = useState('');
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmId, setConfirmId] = useState(null);

  useEffect(() => {
    document.title = t('importItemTicket.title');
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, page, pageSize, filter, sort]);

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('importItemTicket.id'),
    },
    {
      key: 'code',
      dataIndex: 'code',
      title: t('importItemTicket.code'),
      filterable: true,
      sorter: true,
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: t('importItemTicket.name'),
      filterable: true,
      sorter: true,
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('importItemTicket.createdAt'),
      render: (params) => {
        return formatDateTimeUtc(params);
      },
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('importItemTicket.status'),
      filterable: true,
      type: 'categorical',
      filterOptions: {
        options: IMPORT_ITEM_TICKET_STATUS_LIST,
        getOptionValue: (option) => option?.value,
        getOptionLabel: (option) => t(option?.text),
      },
      render: (params) => {
        return t(IMPORT_ITEM_TICKET_STATUS_MAP[params]);
      },
    },
    {
      key: 'action',
      dataIndex: 'id',
      title: t('common.action'),
      disableClickEventBubbling: true,
      sorter: false,
      align: 'center',
      headerAlign: 'center',
      render: (params, row) => {
        const { status } = row;
        const isConfirmed = status !== IMPORT_ITEM_TICKET_STATUS.UNCONFIRM;
        return (
          <div className="action-button">
            <Button
              type="text"
              shape="circle"
              icon={<EyeFilled />}
              onClick={() => onClickViewDetails(params)}
            />
            {!isConfirmed && (
              <Button
                type="text"
                shape="circle"
                icon={<EditFilled />}
                onClick={() => onClickEdit(params)}
              />
            )}
            {!isConfirmed && (
              <Button
                type="text"
                shape="circle"
                icon={<DeleteFilled />}
                onClick={() => onClickDelete(params)}
              />
            )}
            {!isConfirmed && (
              <Button
                type="text"
                shape="circle"
                icon={<CheckSquareFilled style={{ color: 'green' }} />}
                onClick={() => onClickConfirm(params)}
              />
            )}
          </div>
        );
      },
    },
  ];

  const changeImportItemTicketStatus = () => {
    props.confirmImportItemTicket(confirmId, () => {
      refreshData();
      onCancelConfirm();
    });
  };

  const handleCreate = () => {
    redirectRouter(ROUTES.IMPORT_ITEM_TICKET_CREATE.PATH);
  };

  const refreshData = () => {
    props.searchImportItemTicket({
      keyword: keyword.trim(),
      offset: (page - 1) * pageSize,
      limit: pageSize,
      filter: JSON.stringify(filter),
      sort,
    });
  };

  const handleSearch = () => {
    refreshData();
  };

  const updateKeyword = (value) => {
    setKeyWord(value);
  };

  const onPageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
  };

  const onPageChange = ({ page }) => {
    setPage(page);
  };

  const onChangeFilter = (filter) => {
    setFilter(filter);
    setPage(1);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSort({
      column: sorter.field,
      value: sorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  const onClickViewDetails = (id) => {
    redirectRouter(
      ROUTES.IMPORT_ITEM_TICKET_DETAIL.PATH.replace('/:id', `/${id}`),
    );
  };

  const onClickEdit = (id) => {
    redirectRouter(
      ROUTES.IMPORT_ITEM_TICKET_UPDATE.PATH.replace('/:id', `/${id}`),
    );
  };

  const onClickDelete = (id) => {
    setDeletingId(id);
    setOpenConfirmDeleteModal(true);
  };

  const onCancelDelete = () => {
    setDeletingId(null);
    setOpenConfirmDeleteModal(false);
  };

  const onSubmitDelete = () => {
    props.deleteImportItemTicket(deletingId, () => {
      onCancelDelete();
      refreshData();
    });
  };

  const onClickConfirm = (id) => {
    setConfirmId(id);
    setOpenConfirmModal(true);
  };

  const onCancelConfirm = () => {
    setConfirmId(null);
    setOpenConfirmModal(false);
  };

  const onSubmitConfirm = () => {
    changeImportItemTicketStatus();
  };

  return (
    <Spin spinning={importItemTicket?.isLoading}>
      <TopContent
        title={t('importItemTicket.title')}
        breadcrumbs={breadcrumbs}
        displayButton={['create']}
        createButton={t('importItemTicket.createButton')}
        handleCreateOpenModal={handleCreate}
        updateKeyword={updateKeyword}
        handleSearch={handleSearch}
      />
      <Modal
        visible={openConfirmDeleteModal}
        title={t('importItemTicket.deleteModalTitle')}
        size="sm"
        onOk={onSubmitDelete}
        onCancel={onCancelDelete}
        okText={t('common.delete')}
        cancelText={t('common.cancel')}
        hideCancel
        width={719}
        okButtonProps={{
          id: 'delete-button',
        }}
        cancelButtonProps={{
          className: 'cancel-button-custom',
        }}
      >
        {t('importItemTicket.deleteConfirm')}
      </Modal>
      <Modal
        visible={openConfirmModal}
        title={t('importItemTicket.confirmModalTitle')}
        size="sm"
        onOk={onSubmitConfirm}
        onCancel={onCancelConfirm}
        okText={t('common.confirm')}
        cancelText={t('common.cancel')}
        hideCancel
        width={719}
        okButtonProps={{
          id: 'delete-button',
        }}
        cancelButtonProps={{
          className: 'cancel-button-custom',
        }}
      >
        {t('importItemTicket.confirmMessage')}
      </Modal>
      <CustomTable
        columns={columns}
        rows={importItemTicket?.importItemTicketList}
        page={page}
        pageSize={pageSize}
        total={0}
        topInfo={true}
        onChange={handleTableChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onChangeFilter={onChangeFilter}
      />
    </Spin>
  );
}

const mapDispatchToProps = {
  searchImportItemTicket,
  confirmImportItemTicket,
  deleteImportItemTicket
};

const mapStateToProps = (state) => ({
  importItemTicket: state.importItemTicket,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ImportItemTicket),
);
