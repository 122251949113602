import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  importItemFailed,
  importItemSuccess,
  IMPORT_ITEM_START,
} from 'stores/item/item.action';
import { API_URL, NOTIFICATION_TYPE } from 'common/constant';
import addNotification from 'common/toast';

/**
 * import item
 * @param {any} body Params will be sent to server
 * @returns {Promise}
 */
const importItemApi = (body) => {
  const uri = `/${API_URL.ITEM}/import`;
  let formData = new FormData();
  formData.append('file', body.originFileObj);
  return api.postMultiplePart(uri, formData);
};

/**
 * Handle import data request and response
 * @param {object} action
 */
function* doImportItem(action) {
  try {
    const response = yield call(importItemApi, action?.payload);

    if (response?.status === 200) {
      yield put(importItemSuccess(response));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      } else {
        throw new Error(response?.message);
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(importItemFailed());

    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch import item group
 */
export default function* watchImportItem() {
  yield takeLatest(IMPORT_ITEM_START, doImportItem);
}
