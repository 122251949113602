import { put, takeLatest } from 'redux-saga/effects';
import {} from 'stores/auth/auth.action';
import { redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import {
  redirectErrorPageSuccess,
  redirectErrorPageFailed,
  REDIRECT_ERROR_PAGE_START,
} from 'stores/common/common.action';

/**
 * Handle logout request and response
 * @param {object} action
 */
function* doRedirect(action) {
  try {
    // Redirect to home
    setTimeout(() => {
      redirectRouter(ROUTES.ERROR_PAGE.PATH);
    }, 500);
    yield put(redirectErrorPageSuccess());
  } catch (error) {
    yield put(redirectErrorPageFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch search users
 */
export default function* watchRedirectErrorPage() {
  yield takeLatest(REDIRECT_ERROR_PAGE_START, doRedirect);
}
