import {
  CREATE_ITEM_START,
  CREATE_ITEM_SUCCESS,
  CREATE_ITEM_FAILED,
  UPDATE_ITEM_START,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILED,
  DELETE_ITEM_START,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILED,
  SEARCH_ITEM_START,
  SEARCH_ITEM_SUCCESS,
  SEARCH_ITEM_FAILED,
  GET_ITEM_DETAIL_START,
  GET_ITEM_DETAIL_SUCCESS,
  GET_ITEM_DETAIL_FAILED,
  IMPORT_ITEM_START,
  IMPORT_ITEM_SUCCESS,
  IMPORT_ITEM_FAILED,
} from './item.action';

const initialState = {
  isLoading: false,
  itemList: [],
  itemDetail: {},
  total: 0,
};

/**
 * item reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function item(state = initialState, action) {
  switch (action.type) {
    case CREATE_ITEM_START:
    case UPDATE_ITEM_START:
    case DELETE_ITEM_START:
    case SEARCH_ITEM_START:
    case GET_ITEM_DETAIL_START:
    case IMPORT_ITEM_START:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_ITEM_SUCCESS:
    case UPDATE_ITEM_SUCCESS:
    case DELETE_ITEM_SUCCESS:
    case IMPORT_ITEM_SUCCESS:
    case CREATE_ITEM_FAILED:
    case UPDATE_ITEM_FAILED:
    case DELETE_ITEM_FAILED:
    case IMPORT_ITEM_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_ITEM_SUCCESS:
      return {
        ...state,
        itemList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case SEARCH_ITEM_FAILED:
      return {
        ...state,
        itemList: [],
        total: 0,
        isLoading: false,
      };
    case GET_ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        itemDetail: action.payload,
        isLoading: false,
      };
    case GET_ITEM_DETAIL_FAILED:
      return {
        ...state,
        itemDetail: {},
        isLoading: false,
      };
    default:
      return state;
  }
}
