import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  confirmImportItemTicketFailed,
  confirmImportItemTicketSuccess,
  CONFIRM_IMPORT_ITEM_TICKET_START,
} from 'stores/import-item-ticket/import-item-ticket.action';

/**
 * Confirm import-item-ticket API
 * @returns {Promise}
 */
const confirmImportItemTicketApi = (id) => {
  const uri = `/${API_URL.IMPORT_ITEM_TICKET}/${id}/confirm`;
  return api.put(uri);
};

/**
 * Handle confirm request and response
 * @param {object} action
 */
function* doConfirmImportItemTicket(action) {
  try {
    const response = yield call(confirmImportItemTicketApi, action?.payload);

    if (response?.status === 200) {
      yield put(confirmImportItemTicketSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'importItemTicket.confirmImportItemTicketSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(confirmImportItemTicketFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch confirm import-item-ticket
 */
export default function* watchConfirmImportItemTicket() {
  yield takeLatest(CONFIRM_IMPORT_ITEM_TICKET_START, doConfirmImportItemTicket);
}
