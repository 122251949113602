import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  getImportItemTicketDetailFailed,
  getImportItemTicketDetailSuccess,
  GET_IMPORT_ITEM_TICKET_DETAIL_START,
} from 'stores/import-item-ticket/import-item-ticket.action';

/**
 * Get import-item-ticket detail API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getImportItemTicketDetailApi = (params) => {
  const uri = `/${API_URL.IMPORT_ITEM_TICKET}/${params}`;
  return api.get(uri);
};

/**
 * Handle get detail request and response
 * @param {object} action
 */
function* doGetImportItemTicketDetail(action) {
  try {
    const response = yield call(getImportItemTicketDetailApi, action?.payload);

    if (response?.status === 200) {
      yield put(getImportItemTicketDetailSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(getImportItemTicketDetailFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch update import-item-ticket
 */
export default function* watchGetImportItemTicketDetail() {
  yield takeLatest(GET_IMPORT_ITEM_TICKET_DETAIL_START, doGetImportItemTicketDetail);
}
