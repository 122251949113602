import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  updateNotificationSettingStatusFailed,
  updateNotificationSettingStatusSuccess,
  UPDATE_NOTIFICATION_SETTING_STATUS_START,
} from 'stores/notification/notification.action';

/**
 * Update notification API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const updateNotificationSettingStatusApi = (params) => {
  const uri = `${API_URL.NOTIFICATION_SETTING}`;
  return api.put(uri, params);
};

/**
 * Handle update settings request and response
 * @param {object} action
 */
function* doUpdateNotificationSettingStatus(action) {
  try {
    const response = yield call(updateNotificationSettingStatusApi, action?.payload);

    if (response?.status === 200) {
      yield put(updateNotificationSettingStatusSuccess(response.data));

      addNotification(
        ('notification.updateNotificationSettingsSuccess'),
        NOTIFICATION_TYPE.SUCCESS
      )

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(updateNotificationSettingStatusFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch update settings notification
 */
export default function* watchUpdateNotificationSettingStatus() {
  yield takeLatest(UPDATE_NOTIFICATION_SETTING_STATUS_START, doUpdateNotificationSettingStatus);
}
