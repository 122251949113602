export const GET_NOTIFICATION_SETTINGS_LIST_START = 'GET_NOTIFICATION_SETTINGS_LIST_START';
export const GET_NOTIFICATION_SETTINGS_LIST_SUCCESS = 'GET_NOTIFICATION_SETTINGS_LIST_SUCCESS';
export const GET_NOTIFICATION_SETTINGS_LIST_FAILED = 'GET_NOTIFICATION_SETTINGS_LIST_FAILED';

export const GET_NOTIFICATION_LIST_START = 'GET_NOTIFICATION_LIST_START';
export const GET_NOTIFICATION_LIST_SUCCESS = 'GET_NOTIFICATION_LIST_SUCCESS';
export const GET_NOTIFICATION_LIST_FAILED = 'GET_NOTIFICATION_LIST_FAILED';

export const UPDATE_ALL_NOTIFICATION_STATUS_START = 'UPDATE_ALL_NOTIFICATION_STATUS_START';
export const UPDATE_ALL_NOTIFICATION_STATUS_SUCCESS = 'UPDATE_ALL_NOTIFICATION_STATUS_SUCCESS';
export const UPDATE_ALL_NOTIFICATION_STATUS_FAILED = 'UPDATE_ALL_NOTIFICATION_STATUS_FAILED';

export const UPDATE_NOTIFICATION_STATUS_START = 'UPDATE_NOTIFICATION_STATUS_START';
export const UPDATE_NOTIFICATION_STATUS_SUCCESS = 'UPDATE_NOTIFICATION_STATUS_SUCCESS';
export const UPDATE_NOTIFICATION_STATUS_FAILED = 'UPDATE_NOTIFICATION_STATUS_FAILED';

export const UPDATE_NOTIFICATION_SETTING_STATUS_START = 'UPDATE_NOTIFICATION_SETTING_STATUS_START';
export const UPDATE_NOTIFICATION_SETTING_STATUS_SUCCESS = 'UPDATE_NOTIFICATION_SETTING_STATUS_SUCCESS';
export const UPDATE_NOTIFICATION_SETTING_STATUS_FAILED = 'UPDATE_NOTIFICATION_SETTING_STATUS_FAILED';

/**
 * Get notification settings list
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getNotificationSettingsList(payload, onSuccess, onError) {
  return {
    type: GET_NOTIFICATION_SETTINGS_LIST_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Get notification settings list success action
 * @param {*} payload
 * @returns {object}
 */
export function getNotificationSettingsListSuccess(payload) {
  return {
    type: GET_NOTIFICATION_SETTINGS_LIST_SUCCESS,
    payload: payload,
  };
}

/**
 * Get notification settings list failed action
 * @returns {object}
 */
export function getNotificationSettingsListFailed() {
  return {
    type: GET_NOTIFICATION_SETTINGS_LIST_FAILED,
  };
}

/**
 * Get notification list
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getNotificationList(payload, onSuccess, onError) {
  return {
    type: GET_NOTIFICATION_LIST_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Get notification list success action
 * @param {*} payload
 * @returns {object}
 */
export function getNotificationListSuccess(payload) {
  return {
    type: GET_NOTIFICATION_LIST_SUCCESS,
    payload: payload,
  };
}

/**
 * Get notification list failed action
 * @returns {object}
 */
export function getNotificationListFailed() {
  return {
    type: GET_NOTIFICATION_LIST_FAILED,
  };
}

/**
 * Update all notification status
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function updateAllNotificationStatus(payload, onSuccess, onError) {
  return {
    type: UPDATE_ALL_NOTIFICATION_STATUS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update all notification status success action
 * @param {*} payload
 * @returns {object}
 */
export function updateAllNotificationStatusSuccess(payload) {
  return {
    type: UPDATE_ALL_NOTIFICATION_STATUS_SUCCESS,
    payload: payload,
  };
}

/**
 * Update all notification status failed action
 * @returns {object}
 */
export function updateAllNotificationStatusFailed() {
  return {
    type: UPDATE_ALL_NOTIFICATION_STATUS_FAILED,
  };
}

/**
 * Update notification status
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function updateNotificationStatus(payload, onSuccess, onError) {
  return {
    type: UPDATE_NOTIFICATION_STATUS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update notification status success action
 * @param {*} payload
 * @returns {object}
 */
export function updateNotificationStatusSuccess(payload) {
  return {
    type: UPDATE_NOTIFICATION_STATUS_SUCCESS,
    payload: payload,
  };
}

/**
 * Update notification status failed action
 * @returns {object}
 */
export function updateNotificationStatusFailed() {
  return {
    type: UPDATE_NOTIFICATION_STATUS_FAILED,
  };
}

/**
 * Update notification setting status
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function updateNotificationSettingStatus(payload, onSuccess, onError) {
  return {
    type: UPDATE_NOTIFICATION_SETTING_STATUS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update notification setting status success action
 * @param {*} payload
 * @returns {object}
 */
export function updateNotificationSettingStatusSuccess(payload) {
  return {
    type: UPDATE_NOTIFICATION_SETTING_STATUS_SUCCESS,
    payload: payload,
  };
}

/**
 * Update notification setting status failed action
 * @returns {object}
 */
export function updateNotificationSettingStatusFailed() {
  return {
    type: UPDATE_NOTIFICATION_SETTING_STATUS_FAILED,
  };
}
