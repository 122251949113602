import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { login } from 'stores/auth/auth.action';
import {
  Row,
  Col,
  Spin,
  // Carousel,
  Card,
  Form,
  Input,
  Checkbox,
  Button,
} from 'antd';
import CustomGoogleLogin from 'components/common/google-login';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.png';
// import LoginBanner1 from 'assets/images/LoginBanner1.jpg';
// import LoginBanner2 from 'assets/images/LoginBanner2.PNG';
// import LoginBanner3 from 'assets/images/LoginBanner3.PNG';
import VerifyEmail from './verify-email';
import ForgotPassword from './forgot-password';
import RegisterGoogleUser from './register-google-user';
// import NewPassword from './new-password';
import './index.scss';
import { redirectRouter } from 'utils';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

function Login(props) {
  const { t, auth } = props;
  const [error, setError] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [checkRememberPass, setCheckRememberPass] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [registerGoogleUser, setRegisterGoogleUser] = useState(false);
  const [googleUserProfile, setGoogleUserProfile] = useState(null);
  // const [createNewPassword, setCreateNewPassword] = useState(false);
  const [recoverPhone, setRecoverPhone] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    document.title = t('auth.title');
  }, [t]);

  useEffect(() => {
    setError(auth.message);
  }, [auth.message]);

  const toggleChangePassword = (value) => {
    setChangePassword(value);
    setVerifyEmail(false);
    // setCreateNewPassword(false);
  };

  const openVerifyEmail = () => {
    setChangePassword(false);
    setVerifyEmail(true);
  };

  // const openCreateNewPassword = () => {
  //   setCreateNewPassword(true);
  //   setVerifyEmail(false);
  // };

  const returnToLogin = () => {
    // setCreateNewPassword(false);
    setChangePassword(false);
    setVerifyEmail(false);
  };

  const onFinish = (values) => {
    props.login(values, () => {
      const callbackUrl = new URLSearchParams(props.location.search).get(
        'callbackUrl',
      );
      // Redirect to home
      callbackUrl ? redirectRouter(callbackUrl) : redirectRouter('/');
    });
  };

  return (
    <>
      <Spin spinning={auth.isLoading}>
        <div className="login-container">
          <Row className="height-100">
            {/* <Col lg={12} sm={24}>
              <Carousel autoplay dots={false}>
                <div>
                  <div className="login-banner-content-style">
                    <img src={LoginBanner1} alt="banner1" />
                  </div>
                </div>
                <div>
                  <div className="login-banner-content-style">
                    <img src={LoginBanner2} alt="banner2" />
                  </div>
                </div>
                <div>
                  <div className="login-banner-content-style">
                    <img src={LoginBanner3} alt="banner3" />
                  </div>
                </div>
              </Carousel>
            </Col> */}
            <Col lg={24} sm={24}>
              <Row className="right-div" justify="center" align="middle">
                <Col sm={8} xs={24} >
                  <div>
                    {changePassword ? (
                      <ForgotPassword
                        toggleChangePassword={toggleChangePassword}
                        openVerifyEmail={openVerifyEmail}
                        setRecoverPhone={setRecoverPhone}
                      />
                    ) : verifyEmail ? (
                      <VerifyEmail
                        toggleChangePassword={toggleChangePassword}
                        returnToLogin={returnToLogin}
                        phone={recoverPhone}
                      />
                    ) : registerGoogleUser ? (
                      <RegisterGoogleUser
                        googleUserProfile={googleUserProfile}
                        setRegisterGoogleUser={setRegisterGoogleUser}
                      />
                    ) : (
                      // ) : createNewPassword ? (
                      //   <NewPassword
                      //     toggleChangePassword={toggleChangePassword}
                      //     returnToLogin={returnToLogin}
                      //     verificationCode={code}
                      //     phone={recoverPhone}
                      //   />
                      <Card className="login-card">
                        <div className="login-card-content">
                          <div>
                            <img src={logo} alt="logo" width={200} />
                          </div>
                          <Col span={24} className="width-100">
                            <Form
                              className="login-form"
                              {...layout}
                              form={form}
                              name="control-ref"
                              initialValues={{
                                remember_password: false,
                              }}
                              onFinish={onFinish}
                              scrollToFirstError
                              size="large"
                              wrapperCol={{ span: 24 }}
                              labelCol={{ span: 8 }}
                            >
                              <div className="pb-8">
                                <label className="login-label">
                                  {t('auth.username')}
                                </label>
                              </div>
                              <Form.Item
                                name="phone"
                                rules={[
                                  {
                                    required: true,
                                    message: t('form.required'),
                                  },
                                ]}
                              >
                                <Input
                                  name="phone"
                                  value={phone}
                                  onChange={(event) =>
                                    setPhone(event.target.value)
                                  }
                                  placeholder={t('auth.phoneNumber')}
                                  className="input-control"
                                />
                              </Form.Item>
                              <div className="pb-8">
                                <label className="login-label">
                                  {t('auth.password')}
                                </label>
                              </div>
                              <Form.Item
                                name="password"
                                rules={[
                                  {
                                    required: true,
                                    message: t('form.required'),
                                  },
                                ]}
                              >
                                <Input.Password
                                  value={password}
                                  onChange={(event) =>
                                    setPassword(event.target.value)
                                  }
                                  placeholder={t('auth.placeholder.password')}
                                  className="input-control "
                                />
                              </Form.Item>
                              {error && (
                                <span className="error-span">{error}</span>
                              )}
                              <Form.Item
                                name="remember_password"
                                valuePropName="checked"
                                className="remember-pass"
                              >
                                <div className="remember-password">
                                  <Checkbox
                                    onChange={() =>
                                      setCheckRememberPass(!checkRememberPass)
                                    }
                                  >
                                    {t('auth.savePassword')}
                                  </Checkbox>
                                  <Link
                                    to="/login"
                                    className="color-blue-standard"
                                    onClick={() => toggleChangePassword(true)}
                                  >
                                    {t('auth.forgotPassword')}
                                  </Link>
                                </div>
                              </Form.Item>
                              <Form.Item>
                                <Button
                                  htmlType="submit"
                                  type="primary"
                                  className="btn-login mini-shop-button"
                                >
                                  {t('auth.login')}
                                </Button>
                              </Form.Item>
                            </Form>
                          </Col>
                        </div>
                        <Row>
                          <Col span={24}>
                            <CustomGoogleLogin
                              setRegisterGoogleUser={setRegisterGoogleUser}
                              setGoogleUserProfile={setGoogleUserProfile}
                            />
                          </Col>
                        </Row>
                      </Card>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
}

const mapDispatchToProps = {
  login,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Login),
);
