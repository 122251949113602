import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { redirectRouter } from 'utils';
import NotFoundImg from 'assets/images/not_found.png';
import './index.scss';

function NotFoundPage(props) {
  const { t } = props;

  useEffect(() => {
    document.title = t('404.title');
  });

  const backToDashboard = () => {
    redirectRouter('/');
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <div>
        <img src={NotFoundImg} alt={t('404.image')} width="1000" />
        <div style={{ margin: '20px auto' }}>
          <Button
            size="large"
            className="mini-shop-button"
            type="primary"
            onClick={() => backToDashboard()}
          >
            {t('common.backToDashboard')}
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = () => ({});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(NotFoundPage),
);
