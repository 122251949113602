export const CREATE_INVENTORIES_CHECK_START = 'CREATE_INVENTORIES_CHECK_START';
export const CREATE_INVENTORIES_CHECK_SUCCESS = 'CREATE_INVENTORIES_CHECK_SUCCESS';
export const CREATE_INVENTORIES_CHECK_FAILED = 'CREATE_INVENTORIES_CHECK_FAILED';

export const UPDATE_INVENTORIES_CHECK_START = 'UPDATE_INVENTORIES_CHECK_START';
export const UPDATE_INVENTORIES_CHECK_SUCCESS = 'UPDATE_INVENTORIES_CHECK_SUCCESS';
export const UPDATE_INVENTORIES_CHECK_FAILED = 'UPDATE_INVENTORIES_CHECK_FAILED';

export const DELETE_INVENTORIES_CHECK_START = 'DELETE_INVENTORIES_CHECK_START';
export const DELETE_INVENTORIES_CHECK_SUCCESS = 'DELETE_INVENTORIES_CHECK_SUCCESS';
export const DELETE_INVENTORIES_CHECK_FAILED = 'DELETE_INVENTORIES_CHECK_FAILED';

export const SEARCH_INVENTORIES_CHECK_START = 'SEARCH_INVENTORIES_CHECK_START';
export const SEARCH_INVENTORIES_CHECK_SUCCESS = 'SEARCH_INVENTORIES_CHECK_SUCCESS';
export const SEARCH_INVENTORIES_CHECK_FAILED = 'SEARCH_INVENTORIES_CHECK_FAILED';

export const GET_INVENTORIES_CHECK_DETAIL_START = 'GET_INVENTORIES_CHECK_DETAIL_START';
export const GET_INVENTORIES_CHECK_DETAIL_SUCCESS = 'GET_INVENTORIES_CHECK_DETAIL_SUCCESS';
export const GET_INVENTORIES_CHECK_DETAIL_FAILED = 'GET_INVENTORIES_CHECK_DETAIL_FAILED';

export const CONFIRM_INVENTORIES_CHECK_START = 'CONFIRM_INVENTORIES_CHECK_START';
export const CONFIRM_INVENTORIES_CHECK_SUCCESS = 'CONFIRM_INVENTORIES_CHECK_SUCCESS';
export const CONFIRM_INVENTORIES_CHECK_FAILED = 'CONFIRM_INVENTORIES_CHECK_FAILED';


/**
 * Create inventories check
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createInventoriesCheck(payload, onSuccess, onError) {
  return {
    type: CREATE_INVENTORIES_CHECK_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create inventories check success action
 * @param {*} payload
 * @returns {object}
 */
export function createInventoriesCheckSuccess(payload) {
  return {
    type: CREATE_INVENTORIES_CHECK_SUCCESS,
    payload: payload,
  };
}

/**
 * Create inventories check failed action
 * @returns {object}
 */
export function createInventoriesCheckFailed() {
  return {
    type: CREATE_INVENTORIES_CHECK_FAILED,
  };
}

/**
 * Update inventories check
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateInventoriesCheck(payload, onSuccess, onError) {
  return {
    type: UPDATE_INVENTORIES_CHECK_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update inventories check success action
 * @param {*} payload
 * @returns {object}
 */
export function updateInventoriesCheckSuccess(payload) {
  return {
    type: UPDATE_INVENTORIES_CHECK_SUCCESS,
    payload: payload,
  };
}

/**
 * Update inventories check failed action
 * @returns {object}
 */
export function updateInventoriesCheckFailed() {
  return {
    type: UPDATE_INVENTORIES_CHECK_FAILED,
  };
}

/**
 * Delete inventories check
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function deleteInventoriesCheck(payload, onSuccess, onError) {
  return {
    type: DELETE_INVENTORIES_CHECK_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Delete inventories check success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteInventoriesCheckSuccess(payload) {
  return {
    type: DELETE_INVENTORIES_CHECK_SUCCESS,
    payload: payload,
  };
}

/**
 * Delete inventories check failed action
 * @returns {object}
 */
export function deleteInventoriesCheckFailed() {
  return {
    type: DELETE_INVENTORIES_CHECK_FAILED,
  };
}

/**
 * Search inventories check
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function searchInventoriesCheck(payload, onSuccess, onError) {
  return {
    type: SEARCH_INVENTORIES_CHECK_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Search inventories check success action
 * @param {*} payload
 * @returns {object}
 */
export function searchInventoriesCheckSuccess(payload) {
  return {
    type: SEARCH_INVENTORIES_CHECK_SUCCESS,
    payload: payload,
  };
}

/**
 * Search inventories check failed action
 * @returns {object}
 */
export function searchInventoriesCheckFailed() {
  return {
    type: SEARCH_INVENTORIES_CHECK_FAILED,
  };
}

/**
 * Get inventories check detail
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function getInventoriesCheckDetail(payload, onSuccess, onError) {
  return {
    type: GET_INVENTORIES_CHECK_DETAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Get inventories check detail success action
 * @param {*} payload
 * @returns {object}
 */
export function getInventoriesCheckDetailSuccess(payload) {
  return {
    type: GET_INVENTORIES_CHECK_DETAIL_SUCCESS,
    payload: payload,
  };
}

/**
 * Get inventories check detail failed action
 * @returns {object}
 */
export function getInventoriesCheckDetailFailed() {
  return {
    type: GET_INVENTORIES_CHECK_DETAIL_FAILED,
  };
}

/**
 * Confirm inventories check
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function confirmInventoriesCheck(payload, onSuccess, onError) {
  return {
    type: CONFIRM_INVENTORIES_CHECK_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Confirm inventories check success action
 * @param {*} payload
 * @returns {object}
 */
export function confirmInventoriesCheckSuccess(payload) {
  return {
    type: CONFIRM_INVENTORIES_CHECK_SUCCESS,
    payload: payload,
  };
}

/**
 * Confirm inventories check failed action
 * @returns {object}
 */
export function confirmInventoriesCheckFailed() {
  return {
    type: CONFIRM_INVENTORIES_CHECK_FAILED,
  };
}
