import { Component } from 'react';
import { Column } from '@ant-design/charts';
import { CHART_MAX_COLUMN_WIDTH } from 'common/constant';

class BarChartReport extends Component {
  render() {
    const { data, xField, yField, color } = this.props;
    var config = {
      data: data,
      isGroup: true,
      xField: xField,
      yField: yField,
      label: {
        position: 'middle',
        layout: [],
      },
      scrollbar: {
        type: 'horizontal',
      },
      maxColumnWidth: CHART_MAX_COLUMN_WIDTH,
      color: color,
      legend: {
        layout: 'horizontal',
        position: 'bottom',
      },
      // conversionTag: showConversion ? {} : null,
    };
    return <Column {...config} />;
  }
}
export default BarChartReport;
