import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { scrollToBottom, filterAutoComplete } from 'utils';
import {
  CURRENCY_UNIT_STATUS,
  DEFAULT_CURRENCY_UNIT_NAME,
  MODAL_MODE,
  PATTERN,
  PRICE_MAX_LENGTH,
  QUANTITY_MAX_LENGTH,
} from 'common/constant';
import CustomTable from 'components/common/custom-table';
import {
  Input,
  Row,
  Form,
  AutoComplete,
  Button,
  Select,
  DatePicker,
} from 'antd';
import { MinusCircleFilled, MinusCircleOutlined } from '@ant-design/icons';
import './style.scss';
import PlusBox from 'assets/images/plus-box.svg';
import moment from 'moment';

function ItemSettingTable(props) {
  const { t, mode, items, itemList, setItems, form, currencyUnitList } = props;
  const isView = mode === MODAL_MODE.DETAIL;

  const disabledDate = (current) => {
    return moment().isBefore(current);
  };

  /**
   * Get columns
   */
  const getColumns = () => {
    return [
      {
        dataIndex: 'id',
        title: t('importItemTicket.item.orderNumber'),
        align: 'center',
        render: (params, row) => {
          return row?.id + 1;
        },
      },
      {
        dataIndex: 'name',
        title: t('importItemTicket.item.name'),
        width: '18vw',
        render: (params, row) => {
          const { item_id, id } = row;
          const shopItemList = form.getFieldValue('shop_id')
            ? itemList.filter((item) =>
                item.shop_id.includes(form.getFieldValue('shop_id')),
              )
            : [];
          return isView ? (
            <>{getItemObject(item_id)?.name || ''}</>
          ) : (
            <div className="itemsetting">
              <Form.Item
                name={`item_id${id}`}
                className="itemsetting-form-item"
                rules={[
                  {
                    validator: (rule, value, cb) => {
                      if (!value && items[id].filterValue)
                        cb(t('form.itemNotFound'));
                      else if (!value) cb(t('form.itemNotFound'));
                      cb();
                    },
                  },
                ]}
              >
                <AutoComplete
                  onChange={(event, value) =>
                    onChangeItem(id, 'item_id', value?.value)
                  }
                  filterOption={(inputValue, option) => {
                    items[id].filterValue = inputValue;
                    setItems(items);
                    return filterAutoComplete(inputValue, option);
                  }}
                  style={{ width: '100%' }}
                >
                  {shopItemList.map((item) => {
                    if (items.find((it) => it?.item_id === item?.id))
                      return (
                        <AutoComplete.Option
                          disabled
                          key={item.id}
                          value={item.id}
                        >
                          {item.name}
                        </AutoComplete.Option>
                      );

                    return (
                      <AutoComplete.Option key={item.id} value={item.id}>
                        {item.name}
                      </AutoComplete.Option>
                    );
                  })}
                </AutoComplete>
              </Form.Item>
            </div>
          );
        },
      },
      {
        dataIndex: 'code',
        title: t('importItemTicket.item.code'),
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        render: (params, row) => {
          const { item_id } = row;
          return <>{getItemObject(item_id)?.code || ''}</>;
        },
      },
      {
        dataIndex: 'itemUnit',
        title: t('importItemTicket.item.unit'),
        width: '5vw',
        render: (params, row) => {
          const { item_id } = row;
          return <>{getItemObject(item_id)?.item_unit?.name || ''}</>;
        },
      },
      {
        dataIndex: 'quantity',
        title: t('importItemTicket.item.quantity'),
        sortable: false,
        filterable: false,
        align: 'center',
        width: '8vw',
        render: (params, row) => {
          const { id } = row;
          return (
            <>
              {isView ? (
                params
              ) : (
                <Form.Item
                  name={`quantity${id}`}
                  rules={[
                    {
                      pattern: PATTERN.positive_number,
                      message: t('form.greaterThanZero'),
                    },
                    {
                      validator: (rule, value, cb) => {
                        if (value && value?.length > QUANTITY_MAX_LENGTH)
                          cb(
                            t('form.maxLength', {
                              max: QUANTITY_MAX_LENGTH,
                            }),
                          );
                        cb();
                      },
                    },
                  ]}
                >
                  <div style={{ width: '80%', margin: '0 auto' }}>
                    <Input
                      value={params}
                      disabled={isView}
                      type="number"
                      defaultValue={1}
                      onChange={(event) =>
                        onChangeItem(id, 'quantity', event.target.value)
                      }
                    />
                  </div>
                </Form.Item>
              )}{' '}
            </>
          );
        },
      },
      {
        dataIndex: 'price',
        title: t('importItemTicket.item.price'),
        align: 'center',
        width: '7vw',
        render: (params, row) => {
          const { id } = row;
          return (
            <>
              {isView ? (
                params
              ) : (
                <Form.Item
                  name={`price${id}`}
                  rules={[
                    {
                      pattern: PATTERN.positive_number,
                      message: t('form.greaterThanZero'),
                    },
                    {
                      validator: (rule, value, cb) => {
                        if (value && value?.length > PRICE_MAX_LENGTH)
                          cb(
                            t('form.maxLength', {
                              max: PRICE_MAX_LENGTH,
                            }),
                          );
                        cb();
                      },
                    },
                  ]}
                >
                  <Input
                    disabled={isView}
                    type="number"
                    defaultValue={0}
                    onChange={(event) =>
                      onChangeItem(id, 'price', event.target.value)
                    }
                  />
                </Form.Item>
              )}
            </>
          );
        },
      },
      {
        dataIndex: 'total',
        title: t('importItemTicket.item.total'),
        align: 'center',
        width: '6vw',
        render: (params, row) => {
          const { price, quantity } = row;
          return price * quantity;
        },
      },
      {
        dataIndex: 'currency_unit_id',
        title: t('importItemTicket.item.currencyUnit'),
        sortable: false,
        filterable: false,
        render: (params, row) => {
          const { id } = row;
          return (
            <Form.Item
              name={`currency_unit_id${id}`}
              rules={[
                {
                  required: true,
                  message: t('form.required'),
                },
              ]}
            >
              <Select
                onChange={(value) =>
                  onChangeItem(id, 'currency_unit_id', value)
                }
                defaultValue={
                  id !== 0
                    ? currencyUnitList.find((unit) =>
                        DEFAULT_CURRENCY_UNIT_NAME.includes(unit.name),
                      )?.name
                    : ''
                }
                disabled={isView}
              >
                {currencyUnitList
                  ?.filter(
                    (unit) =>
                      unit.name !== 'COIN' &&
                      unit?.status === CURRENCY_UNIT_STATUS.ACTIVE,
                  )
                  ?.map((unit) => (
                    <Select.Option key={unit.id} value={unit.id}>
                      {unit.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          );
        },
      },
      {
        dataIndex: 'date_of_manufacture',
        title: t('importItemTicket.item.productionDate'),
        sortable: false,
        filterable: false,
        render: (params, row) => {
          const { id } = row;
          return (
            <Form.Item
              name={`date_of_manufacture${id}`}
              rules={[
                {
                  required: true,
                  message: t('form.required'),
                },
              ]}
            >
              <DatePicker
                disabled={isView}
                disabledDate={disabledDate}
                placeholder={t('importItemTicket.placeholder.productionDate')}
                defaultValue={moment()}
                onChange={(date) =>
                  onChangeItem(id, 'date_of_manufacture', date)
                }
              />
            </Form.Item>
          );
        },
      },
      {
        key: 'remove',
        dataIndex: 'remove',
        title: '',
        visible: mode !== MODAL_MODE.DETAIL,
        render: (params, row) => {
          const { id } = row;
          const hide = mode === MODAL_MODE.DETAIL;
          return hide ? null : (
            <div className="remove-button">
              <Button
                type="text"
                shape="circle"
                onClick={() => onRemoveProduct(id)}
                disabled={items?.length === 1}
                icon={
                  items?.length === 1 ? (
                    <MinusCircleOutlined style={{ fontSize: '15px' }} />
                  ) : (
                    <MinusCircleFilled
                      style={{ color: 'red', fontSize: '15px' }}
                    />
                  )
                }
              />
            </div>
          );
        },
      },
    ];
  };

  /**
   * Add item
   */
  const onAddItem = () => {
    const newId = items.length;
    const defaultCurrencyId = currencyUnitList.find((unit) =>
      DEFAULT_CURRENCY_UNIT_NAME.includes(unit.name),
    )?.id;
    setItems([
      ...items,
      {
        id: newId,
        item_id: null,
        currency_unit_id: defaultCurrencyId,
        price: 0,
        quantity: 1,
        date_of_manufacture: moment(),
        filterValue: '',
      },
    ]);

    form.setFieldsValue({
      [`currency_unit_id${newId}`]: defaultCurrencyId,
      [`date_of_manufacture${newId}`]: moment(),
    });
    scrollToBottom();
  };

  /**
   * Remove product
   */
  const onRemoveProduct = (id) => {
    const oldFields = [];
    items.forEach((item, index) => {
      oldFields.push(`item_id${index}`);
      oldFields.push(`quantity${index}`);
      oldFields.push(`currency_unit_id${index}`);
      oldFields.push(`price${index}`);
      oldFields.push(`date_of_manufacture${index}`);
    });

    form.resetFields(oldFields);

    const newItems = [...items]
      .filter((item) => item.id !== id)
      .map((item, index) => ({ ...item, id: index }));

    let fields = {};
    newItems.forEach((item, index) => {
      Object.assign(fields, {
        [`item_id${index}`]: getItemObject(item?.item_id)?.name,
        [`quantity${index}`]: +item?.quantity,
        [`currency_unit_id${index}`]: item?.currency_unit_id,
        [`price${index}`]: +item?.price,
        [`date_of_manufacture${index}`]: item?.date_of_manufacture,
      });
    });

    form.setFieldsValue(fields);
    setItems(newItems);
  };

  /**
   * Get item object with code, name...
   * @returns
   */
  const getItemObject = (id) => {
    return itemList?.find((item) => item?.id === id);
  };

  /**
   *
   * @param {string} key
   * @param {*} value
   */
  const onChangeItem = (index, key, value) => {
    const newItems = [...items];
    const itemToChange = newItems[index];

    if (key === 'item_id') {
      const defaultPrice = +itemList.find((item) => item.id === value)?.price;
      itemToChange['price'] = defaultPrice;
      form.setFieldsValue({
        [`item_id${index}`]: getItemObject(value)?.name,
        [`price${index}`]: defaultPrice,
      });
    }

    itemToChange[key] = value;
    newItems[index] = itemToChange;

    setItems(newItems);
  };

  return (
    <>
      <Row className="title-import-item-ticket-item">
        {!isView && (
          <div id="import-item-ticket-create-button">
            <Button
              className="create-button mr-0 mini-shop-button"
              type="primary"
              onClick={onAddItem}
              size="large"
              icon={
                <img style={{ margin: '5px' }} src={PlusBox} alt="plus-box" />
              }
            >
              {t('common.addItem')}
            </Button>
          </div>
        )}
      </Row>
      <Row>
        <CustomTable
          className="import-item-ticket-table"
          rows={items}
          pageSize={20}
          page={1}
          columns={getColumns()}
          total={items.length}
          topInfo={false}
          noPagination={true}
          hideFooter
        />
      </Row>
    </>
  );
}

const mapStateToProps = (state) => ({
  currencyUnitList: state.common.currencyUnitList,
});

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ItemSettingTable),
);
