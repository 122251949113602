import {
  CREATE_PAYMENT_TYPE_START,
  CREATE_PAYMENT_TYPE_SUCCESS,
  CREATE_PAYMENT_TYPE_FAILED,
  UPDATE_PAYMENT_TYPE_START,
  UPDATE_PAYMENT_TYPE_SUCCESS,
  UPDATE_PAYMENT_TYPE_FAILED,
  DELETE_PAYMENT_TYPE_START,
  DELETE_PAYMENT_TYPE_SUCCESS,
  DELETE_PAYMENT_TYPE_FAILED,
  SEARCH_PAYMENT_TYPE_START,
  SEARCH_PAYMENT_TYPE_SUCCESS,
  SEARCH_PAYMENT_TYPE_FAILED,
  GET_PAYMENT_TYPE_DETAIL_START,
  GET_PAYMENT_TYPE_DETAIL_SUCCESS,
  GET_PAYMENT_TYPE_DETAIL_FAILED,
} from './payment-type.action';

const initialState = {
  isLoading: false,
  paymentTypeList: [],
  paymentTypeDetail: {},
  total: 0,
};

/**
 * payment-type reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function paymentType(state = initialState, action) {
  switch (action.type) {
    case CREATE_PAYMENT_TYPE_START:
    case UPDATE_PAYMENT_TYPE_START:
    case DELETE_PAYMENT_TYPE_START:
    case GET_PAYMENT_TYPE_DETAIL_START:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_PAYMENT_TYPE_START:
      return {
        ...state,
        paymentTypeDetail: {},
        isLoading: true,
      };
    case CREATE_PAYMENT_TYPE_SUCCESS:
    case UPDATE_PAYMENT_TYPE_SUCCESS:
    case DELETE_PAYMENT_TYPE_SUCCESS:
    case CREATE_PAYMENT_TYPE_FAILED:
    case UPDATE_PAYMENT_TYPE_FAILED:
    case DELETE_PAYMENT_TYPE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_PAYMENT_TYPE_SUCCESS:
      return {
        ...state,
        paymentTypeList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case SEARCH_PAYMENT_TYPE_FAILED:
      return {
        ...state,
        paymentTypeList: [],
        total: 0,
        isLoading: false,
      };
    case GET_PAYMENT_TYPE_DETAIL_SUCCESS:
      return {
        ...state,
        paymentTypeDetail: action.payload,
        isLoading: false,
      };
    case GET_PAYMENT_TYPE_DETAIL_FAILED:
      return {
        ...state,
        paymentTypeDetail: {},
        isLoading: false,
      };
    default:
      return state;
  }
}
