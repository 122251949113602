import React from 'react';
import { DatePicker } from 'antd';
import { withTranslation } from 'react-i18next';
import 'moment/locale/vi';
import locale from 'antd/es/date-picker/locale/vi_VN';
import calendarIcon from 'assets/images/Calendar.png';

function DateRangePicker(props) {
  const { isDisabled, from, to, onChange, size, t, format, className } = props;
  return (
    <DatePicker.RangePicker
      placeholder={[t('common.fromLabel'), t('common.toLabel')]}
      format={format}
      disabled={isDisabled}
      size={size}
      defaultValue={[from, to]}
      onCalendarChange={onChange}
      suffixIcon={<img style={{ opacity: 0.3 }} src={calendarIcon} alt="" />}
      locale={locale}
      className={className}
    />
  );
}
export default withTranslation()(DateRangePicker);
