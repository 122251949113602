import React from 'react';
import { Row, Col, Button, Input, Select, Typography, Upload } from 'antd';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import PlusBox from 'assets/images/plus-box.svg';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ArrowLeft from 'assets/images/arrow-left.svg';
import Notification from './notification';
import VIFlag from 'assets/images/VI.png';
import ENFlag from 'assets/images/EN.png';
import {
  ACCEPT_UPLOAD_TYPE,
  DEFAULT_LANGUAGE,
  DEVICE_MIN_WIDTH,
  NOTIFICATION_TYPE,
} from 'common/constant';
import { handleChangeLanguage } from 'stores/common/common.action';
import Breadcrumb from '../breadcum';
import './index.scss';
import addNotification from 'common/toast';

function TopContent(props) {
  const {
    t,
    showSearch = true,
    i18n,
    language,
    breadcrumbs,
    displayButton,
    createButton,
    title,
    backToList,
    importApi,
    refreshData,
  } = props;
  const deviceWidth = window.innerWidth;

  const changeSearch = (e) => {
    props.updateKeyword(e.target.value);
  };

  const handleUploadFile = ({ fileList }) => {
    if (!ACCEPT_UPLOAD_TYPE.includes(fileList[0]?.type)) {
      addNotification(t('common.invalidFile'), NOTIFICATION_TYPE.ERROR);
      return;
    }
    importApi(fileList[0], () => {
      refreshData();
    });
  };

  return (
    <>
      <Row>
        <Col span={20}>
          {backToList ? (
            <div>
              <Button className="btn-back" onClick={backToList}>
                <img src={ArrowLeft} alt="setting" />
              </Button>
            </div>
          ) : (
            <>
              {showSearch && deviceWidth > DEVICE_MIN_WIDTH ? (
                <div className="top-search">
                  <Input
                    className="search-bar"
                    name="keyword"
                    placeholder={t('common.searchPlaceholder')}
                    onChange={changeSearch}
                    onPressEnter={props.handleSearch}
                    prefix={<SearchOutlined />}
                  />
                  <Button
                    className="mini-shop-button"
                    type="primary"
                    onClick={props.handleSearch}
                  >
                    {t('common.search')}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </Col>
        <Col span={3} offset={1}>
          <div className="setting-group-icon">
            <Notification />
            {deviceWidth > DEVICE_MIN_WIDTH && (
              <Select
                onChange={(value) => {
                  i18n.changeLanguage(value);
                  window.localStorage.setItem('language', value);
                  props.handleChangeLanguage(value);
                }}
                defaultValue={language || i18n.languages[DEFAULT_LANGUAGE]}
              >
                {i18n.languages.map((language, index) => {
                  const flagList = {
                    vi: VIFlag,
                    en: ENFlag,
                  };
                  return (
                    <Select.Option value={language} key={index}>
                      <div style={{ width: '22px', display: 'inline-block' }}>
                        {language.toUpperCase()}
                      </div>
                      &nbsp;
                      <img
                        src={flagList[language]}
                        width="26px"
                        height="18px"
                        alt="#"
                      />
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </div>
        </Col>
      </Row>
      <br />
      <div className="top-content">
        <div>
          <Typography.Title level={ deviceWidth > DEVICE_MIN_WIDTH ? 3 : 4}>{title}</Typography.Title>
          <Breadcrumb breadcrumbs={breadcrumbs} />
        </div>
        {displayButton && (
          <div className="button-group">
            {importApi && (
              <Upload
                action="/"
                accept={ACCEPT_UPLOAD_TYPE.join(',')}
                maxCount={1}
                beforeUpload={() => {
                  return false;
                }}
                showUploadList={false}
                onChange={handleUploadFile}
              >
                <Button size="large" icon={<UploadOutlined />}>
                  {t('common.uploadItem')}
                </Button>
              </Upload>
            )}
            {displayButton.includes('create') && (
              <Button
                className="mini-shop-button"
                type="primary"
                onClick={props.handleCreateOpenModal}
                size="large"
                icon={
                  <img style={{ margin: '5px' }} src={PlusBox} alt="plus-box" />
                }
              >
                {createButton}
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
}

const mapDispatchToProps = {
  handleChangeLanguage,
};

const mapStateToProps = (state) => ({
  language: state.common.lang,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TopContent),
);
