import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import BarChartReport from '../barchart';
import React, { Component } from 'react';
import { Row, Card } from 'antd';
import Title from '../title';
import { USER_TYPE_ENUM } from 'common/constant';

class UserReport extends Component {
  constructor(props) {
    super(props);
  }

  formatData = (dataList) => {
    const newData = [
      {
        name: 'Người dùng',
        value: this.props.userList.filter((user) =>
          [USER_TYPE_ENUM.USER, USER_TYPE_ENUM.GOOGLE_USER].includes(
            user.user_type,
          ),
        )?.length,
      },
      {
        name: 'Chủ cửa hàng',
        value: this.props.userList.filter((user) =>
          [USER_TYPE_ENUM.SHOPOWNER, USER_TYPE_ENUM.GOOGLE_SHOP_OWNER].includes(
            user.user_type,
          ),
        )?.length,
      },
    ];

    return newData;
  };

  handleChangeSelect = (value) => {
    this.setState({
      filterBy: value,
    });
  };

  render() {
    const { t } = this.props;
    const data = this.formatData([]);

    return (
      <Card>
        <Row className="justify-content-between">
          <Title
            style={{ lineHeight: '40px' }}
            className="mb-0"
            title={t('dashboard.userReport.title')}
          />
        </Row>
        <BarChartReport data={data} xField="name" yField="value" />
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userList: state.user.userList,
});

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(UserReport),
);
