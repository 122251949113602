import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import {
  Form,
  Spin,
  Card,
  Row,
  Col,
  Input,
  Button,
  Select,
  Upload,
  Modal,
  Switch,
} from 'antd';
import TopContent from 'components/common/top-content';
import {
  ITEM_PRICE_LENGTH,
  MODAL_MODE,
  TEXTFIELD_REQUIRED_LENGTH,
  ITEM_STATUS,
  ITEM_STATUS_MAP,
  IMAGE_STATUS,
  IMAGE_RELATION_TYPE,
  STORE_STATUS,
  ITEM_UNIT_STATUS,
  CATEGORY_STATUS,
  DESCRIPTION_ROWS,
  ITEM_TYPE_MAP,
  ITEM_TYPE_ENUM,
  CAN_PRE_ORDER_ITEM,
} from 'common/constant';
import { getImageUrl, redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import { createItem, updateItem, getItemDetail } from 'stores/item/item.action';
import { PlusOutlined, QrcodeOutlined } from '@ant-design/icons';
import { searchStore } from 'stores/store/store.action';
import { searchItemUnit } from 'stores/item-unit/item-unit.action';
import { searchCategory } from 'stores/category/category.action';
import Coin from 'assets/images/coin.png';
import QRCode from 'qrcode.react';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

function ItemForm(props) {
  const { t, item, storeList, itemUnitList, categoryList } = props;
  const [form] = Form.useForm();
  const [mode, setMode] = useState(MODAL_MODE.CREATE);
  const [status, setStatus] = useState(0);
  const [title, setTitle] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      route: ROUTES.ITEM.PATH,
      title: ROUTES.ITEM.TITLE,
    },
    {
      title: ROUTES.ITEM_CREATE.TITLE,
    },
  ]);
  const [newImageList, setNewImageList] = useState([]);
  const [oldImageList, setOldImageList] = useState([]);
  const [canPreOrder, setCanPreOrder] = useState(false);
  const path = props.match.path;
  const { id } = props.match.params;

  useEffect(() => {
    let tempMode = path.includes('/update/')
      ? MODAL_MODE.UPDATE
      : path.includes('/detail/')
      ? MODAL_MODE.DETAIL
      : MODAL_MODE.CREATE;
    setMode(tempMode);

    if (tempMode !== MODAL_MODE.DETAIL) {
      props.searchStore({
        filter: JSON.stringify([
          {
            column: 'status',
            value: STORE_STATUS.ACTIVE,
          },
        ]),
      });
      props.searchItemUnit({
        filter: JSON.stringify([
          {
            column: 'status',
            value: ITEM_UNIT_STATUS.ACTIVE,
          },
        ]),
      });
      props.searchCategory({
        filter: JSON.stringify([
          {
            column: 'status',
            value: CATEGORY_STATUS.ACTIVE,
          },
        ]),
      });
    }

    if (tempMode === MODAL_MODE.CREATE) form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    document.title = title;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    setBreadcrumbs([
      {
        route: ROUTES.ITEM.PATH,
        title: ROUTES.ITEM.TITLE,
      },
      {
        title:
          mode === MODAL_MODE.CREATE
            ? ROUTES.ITEM_CREATE.TITLE
            : mode === MODAL_MODE.UPDATE
            ? ROUTES.ITEM_UPDATE.TITLE
            : ROUTES.ITEM_DETAIL.TITLE,
      },
    ]);

    setTitle(
      mode === MODAL_MODE.CREATE
        ? t('item.createItem')
        : mode === MODAL_MODE.UPDATE
        ? t('item.editItem')
        : t('item.detailItem'),
    );
    getDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (mode !== MODAL_MODE.CREATE) {
      const {
        code,
        name,
        item_group_id,
        item_unit_id,
        price,
        expiration_date,
        description,
        status,
        shop_id,
        shops,
        item_group,
        item_unit,
        images,
        item_type,
        can_pre_order,
      } = item.itemDetail;
      setStatus(status);
      setCanPreOrder(can_pre_order === CAN_PRE_ORDER_ITEM.TRUE);

      const imageList = [];
      if (images) {
        images?.forEach((image) => {
          imageList.push({
            uid: imageList.length,
            name: image.name,
            status: IMAGE_STATUS.DONE,
            url: getImageUrl(`${IMAGE_RELATION_TYPE.ITEM}/${id}/${image.name}`),
          });
        });

        setOldImageList(imageList);
      }

      form.setFieldsValue({
        code,
        name,
        item_type,
        item_group_id:
          mode === MODAL_MODE.DETAIL ? item_group?.name : item_group_id,
        item_unit_id:
          mode === MODAL_MODE.DETAIL ? item_unit?.name : item_unit_id,
        price,
        expiration_date,
        description,
        shop_id:
          mode === MODAL_MODE.DETAIL
            ? shops?.map((shop) => shop.name).join(',')
            : shop_id,
        imageList:
          images?.length > 0 ? images : oldImageList.concat(newImageList),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.itemDetail]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const onFinish = (values) => {
    const {
      code,
      name,
      item_group_id,
      item_unit_id,
      price,
      expiration_date,
      description,
      shop_id,
      item_type,
    } = values;

    const params = new FormData();
    params.append('code', code);
    params.append('name', name);
    params.append('description', description || '');
    params.append('price', price);
    params.append('expiration_date', expiration_date);
    params.append('item_group_id', item_group_id);
    params.append('item_unit_id', item_unit_id);
    params.append('user_id', window.localStorage.getItem('userId'));
    params.append('item_type', item_type || ITEM_TYPE_ENUM.REGULAR);
    params.append(
      'can_pre_order',
      canPreOrder
        ? CAN_PRE_ORDER_ITEM.TRUE.toString()
        : CAN_PRE_ORDER_ITEM.FALSE.toString(),
    );
    shop_id.forEach((id) => {
      params.append('shop_id[]', id);
    });

    newImageList.forEach((image) => {
      const imageName = image.name;
      params.append(
        'images[]',
        JSON.stringify({
          ...image,
          name: imageName,
          url: `${IMAGE_RELATION_TYPE.ITEM}/${id}/`,
        }),
      );
      params.append('files[]', image.originFileObj || null);
    });

    if (mode === MODAL_MODE.CREATE)
      props.createItem(params, () => {
        backToList();
      });
    else if (mode === MODAL_MODE.UPDATE) {
      oldImageList.forEach((image) => {
        params.append('old_images[]', image.name);
      });
      props.updateItem(params, props.match.params.id, () => {
        backToList();
      });
    }
  };

  const backToList = () => {
    redirectRouter(ROUTES.ITEM.PATH);
    setNewImageList([]);
    setOldImageList([]);
  };

  const getDetail = () => {
    const id = props.match.params.id;
    if (mode !== MODAL_MODE.CREATE) props.getItemDetail(id);
  };

  const onCancel = () => {
    if (mode === MODAL_MODE.CREATE) resetForm();
    else if (mode === MODAL_MODE.UPDATE) getDetail();
    setNewImageList([]);
  };

  const resetForm = () => {
    form?.resetFields();
  };

  const renderActionButtons = () => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return (
          <div id="group-button-submit">
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="item-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.create')}
            </Button>
          </div>
        );
      case MODAL_MODE.UPDATE:
        return (
          <div id="group-button-submit">
            <Button onClick={() => backToList()} className="close-button">
              {t('common.close')}
            </Button>
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="item-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.save')}
            </Button>
          </div>
        );
      case MODAL_MODE.DETAIL:
        switch (status) {
          // PENDING
          case ITEM_STATUS.UNCONFIRM:
          case ITEM_STATUS.ACTIVE:
            return (
              <div id="group-button-submit">
                <Button onClick={() => backToList()} className="close-button">
                  {t('common.close')}
                </Button>
              </div>
            );
          // INACTIVE
          case ITEM_STATUS.INACTIVE:
            return (
              <Button onClick={() => backToList()} className="close-button">
                {t('common.close')}
              </Button>
            );
          default:
            return;
        }
      default:
        break;
    }
  };

  const handleChangeImage = ({ fileList: imageList, file: changedImage }) => {
    const copyNewImageList = [];
    if (changedImage?.status === IMAGE_STATUS.REMOVED) {
      const imageToRemove = oldImageList.find(
        (img) => img.url === changedImage?.url,
      );
      if (imageToRemove)
        oldImageList.splice(oldImageList.indexOf(imageToRemove), 1);
    }

    imageList.forEach((image) => {
      if (image?.status === IMAGE_STATUS.DONE) {
      } else {
        copyNewImageList.push(image);
      }
    });

    setOldImageList([...oldImageList]);
    setNewImageList([...copyNewImageList]);
  };

  const printQR = () => {
    const qrCodeURL = document
      .getElementById('qrCodeEl')
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = 'QR_Code.png';
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const isView = mode === MODAL_MODE.DETAIL;
  const isCreate = mode === MODAL_MODE.CREATE;

  return (
    <div className="item-form">
      <Spin spinning={item?.isLoading}>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={() => {
            setPreviewVisible(false);
            setPreviewImage(null);
          }}
        >
          <img
            alt="preview"
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>
        <TopContent
          title={title}
          breadcrumbs={breadcrumbs}
          backToList={backToList}
        />
        <QRCode
          style={{ display: 'none' }}
          id="qrCodeEl"
          size={300}
          value={id}
        />
        {isView && (
          <Button
            className="mini-shop-button"
            style={{ marginBottom: '10px' }}
            type="primary"
            onClick={() => printQR()}
            icon={<QrcodeOutlined />}
          >
            {t('common.printQr')}
          </Button>
        )}
        <Card className="body-content">
          <Form
            {...layout}
            name="item-form"
            id="item-form"
            className="required-mark-after"
            form={form}
            onFinish={onFinish}
            initialValues={{
              item_type: ITEM_TYPE_ENUM.REGULAR,
            }}
          >
            <div className="block-1">
              {isView && (
                <Row>
                  <Col sm={10} xs={24}>
                    <Form.Item label={t('item.status')}>
                      <div
                        className={
                          [ITEM_STATUS.ACTIVE].includes(status)
                            ? 'activeStatusBox boxStatus'
                            : [ITEM_STATUS.UNCONFIRM].includes(status)
                            ? 'unconfirmedStatusBox boxStatus'
                            : 'inActiveStatusBox boxStatus'
                        }
                      >
                        {t(ITEM_STATUS_MAP[status])}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={10} xs={24}>
                  <Form.Item
                    name="code"
                    label={t('item.code')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.LENGTH_20.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.LENGTH_20.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input
                      disabled={!isCreate}
                      placeholder={t('item.placeholder.code')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('item.itemCategory')}
                    name="item_group_id"
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                    ]}
                  >
                    {isView ? (
                      <div className="ant-input ant-input-disabled">
                        {form.getFieldValue('item_group_id')}
                      </div>
                    ) : (
                      <Select placeholder={t('item.placeholder.itemCategory')}>
                        {categoryList.map((category) => (
                          <Select.Option key={category.id} value={category.id}>
                            {category.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item
                    label={t('item.itemUnit')}
                    name="item_unit_id"
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                    ]}
                  >
                    {isView ? (
                      <div className="ant-input ant-input-disabled">
                        {form.getFieldValue('item_unit_id')}
                      </div>
                    ) : (
                      <Select placeholder={t('item.placeholder.itemUnit')}>
                        {itemUnitList.map((unit) => (
                          <Select.Option key={unit.id} value={unit.id}>
                            {unit.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item
                    label={t('item.expireDate')}
                    name="expiration_date"
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                    ]}
                  >
                    {isView ? (
                      <div className="ant-input ant-input-disabled">
                        {`${form.getFieldValue('expiration_date')} ${t(
                          'common.day',
                        )}`}
                      </div>
                    ) : (
                      <Input
                        disabled={isView}
                        placeholder={t('item.placeholder.expireDate')}
                        type="number"
                        suffix={t('common.day')}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    label={t('item.description')}
                    name="description"
                    rules={[
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input.TextArea
                      disabled={isView}
                      autoSize={{
                        minRows: isView ? 1 : DESCRIPTION_ROWS.MIN,
                        maxRows: DESCRIPTION_ROWS.MAX,
                      }}
                      placeholder={t('item.placeholder.description')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="imageList"
                    label={t('item.image')}
                    rules={[
                      {
                        required: !isView,
                        message: '',
                      },
                      {
                        validator: (rule, value, cb) => {
                          if (oldImageList.concat(newImageList).length === 0)
                            cb(t('form.imageRequired'));
                          cb();
                        },
                      },
                    ]}
                  >
                    {isView ? (
                      oldImageList.length > 0 && (
                        <>
                          {oldImageList.map((image) => (
                            <img
                              key={image?.url}
                              width="100"
                              height="100"
                              className="image"
                              src={image?.url}
                              alt="img"
                            />
                          ))}
                        </>
                      )
                    ) : (
                      <Upload
                        action="/"
                        listType="picture-card"
                        fileList={oldImageList.concat(newImageList)}
                        beforeUpload={() => {
                          return false;
                        }}
                        onPreview={handlePreview}
                        onChange={handleChangeImage}
                      >
                        {uploadButton}
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={10} xs={24}>
                  <Form.Item
                    name="name"
                    label={t('item.name')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('item.placeholder.name')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="price"
                    label={t('item.price')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: ITEM_PRICE_LENGTH,
                        message: t('form.maxLength', {
                          max: ITEM_PRICE_LENGTH,
                        }),
                      },
                      {
                        validator: (rule, value, cb) => {
                          const numberSplit = value?.split('.');
                          if (value && numberSplit.length > 1)
                            if (value.split('.')[1].length > 3)
                              cb(
                                t('form.numberAfterDot', {
                                  max: 3,
                                }),
                              );
                          cb();
                        },
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('item.placeholder.price')}
                      type="number"
                      prefix={
                        <img
                          src={Coin}
                          width="15px"
                          height="15px"
                          alt="coin-icon"
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('item.store')}
                    name="shop_id"
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                    ]}
                  >
                    {isView ? (
                      <div className="ant-input ant-input-disabled">
                        {form.getFieldValue('shop_id')}
                      </div>
                    ) : (
                      <Select
                        placeholder={t('item.placeholder.store')}
                        mode="multiple"
                      >
                        {storeList
                          .filter(
                            (store) =>
                              store.user_id ===
                              +window.localStorage.getItem('userId'),
                          )
                          .map((store) => (
                            <Select.Option key={store.id} value={store.id}>
                              {store.name}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item
                    label={t('item.itemType')}
                    name="item_type"
                    rules={[]}
                  >
                    {isView ? (
                      <div className="ant-input ant-input-disabled">
                        {t(
                          ITEM_TYPE_MAP.find(
                            (type) =>
                              type.value === form.getFieldValue('item_type'),
                          )?.text,
                        )}
                      </div>
                    ) : (
                      <Select placeholder={t('item.placeholder.itemType')}>
                        {ITEM_TYPE_MAP.map((type) => (
                          <Select.Option key={type.value} value={type.value}>
                            {t(type.text)}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label={t('item.preOrder')}>
                    <Switch
                      checked={canPreOrder}
                      disabled={isView}
                      onClick={(checked) => setCanPreOrder(checked)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
        <Card style={{ marginTop: '32px' }}>
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderActionButtons()}
              </div>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
}

const mapDispatchToProps = {
  createItem,
  updateItem,
  getItemDetail,
  searchStore,
  searchCategory,
  searchItemUnit,
};

const mapStateToProps = (state) => ({
  item: state.item,
  storeList: state.store.storeList,
  itemUnitList: state.itemUnit.itemUnitList,
  categoryList: state.category.categoryList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ItemForm),
);
