import {
  CREATE_CURRENCY_UNIT_START,
  CREATE_CURRENCY_UNIT_SUCCESS,
  CREATE_CURRENCY_UNIT_FAILED,
  UPDATE_CURRENCY_UNIT_START,
  UPDATE_CURRENCY_UNIT_SUCCESS,
  UPDATE_CURRENCY_UNIT_FAILED,
  DELETE_CURRENCY_UNIT_START,
  DELETE_CURRENCY_UNIT_SUCCESS,
  DELETE_CURRENCY_UNIT_FAILED,
  SEARCH_CURRENCY_UNIT_START,
  SEARCH_CURRENCY_UNIT_SUCCESS,
  SEARCH_CURRENCY_UNIT_FAILED,
  GET_CURRENCY_UNIT_DETAIL_START,
  GET_CURRENCY_UNIT_DETAIL_SUCCESS,
  GET_CURRENCY_UNIT_DETAIL_FAILED,
} from './currency-unit.action';

const initialState = {
  isLoading: false,
  currencyUnitList: [],
  currencyUnitDetail: {},
  total: 0,
};

/**
 * currency-unit reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function currencyUnit(state = initialState, action) {
  switch (action.type) {
    case CREATE_CURRENCY_UNIT_START:
    case UPDATE_CURRENCY_UNIT_START:
    case DELETE_CURRENCY_UNIT_START:
    case GET_CURRENCY_UNIT_DETAIL_START:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_CURRENCY_UNIT_START:
      return {
        ...state,
        currencyUnitDetail: {},
        isLoading: true,
      };
    case CREATE_CURRENCY_UNIT_SUCCESS:
    case UPDATE_CURRENCY_UNIT_SUCCESS:
    case DELETE_CURRENCY_UNIT_SUCCESS:
    case CREATE_CURRENCY_UNIT_FAILED:
    case UPDATE_CURRENCY_UNIT_FAILED:
    case DELETE_CURRENCY_UNIT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_CURRENCY_UNIT_SUCCESS:
      return {
        ...state,
        currencyUnitList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case SEARCH_CURRENCY_UNIT_FAILED:
      return {
        ...state,
        currencyUnitList: [],
        total: 0,
        isLoading: false,
      };
    case GET_CURRENCY_UNIT_DETAIL_SUCCESS:
      return {
        ...state,
        currencyUnitDetail: action.payload,
        isLoading: false,
      };
    case GET_CURRENCY_UNIT_DETAIL_FAILED:
      return {
        ...state,
        currencyUnitDetail: {},
        isLoading: false,
      };
    default:
      return state;
  }
}
