import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  createDeviceTokenFailed,
  createDeviceTokenSuccess,
  CREATE_DEVICE_TOKEN_START,
} from 'stores/user/user.action';

/**
 * Create device token API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const createDeviceTokenApi = (params) => {
  const uri = `/${API_URL.USER}/device_tokens`;
  return api.post(uri, params);
};

/**
 * Handle create request and response
 * @param {object} action
 */
function* doCreateDeviceToken(action) {
  try {
    const response = yield call(createDeviceTokenApi, action?.payload);

    if (response?.status === 200) {
      yield put(createDeviceTokenSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(createDeviceTokenFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch create device token
 */
export default function* watchCreateDeviceToken() {
  yield takeLatest(CREATE_DEVICE_TOKEN_START, doCreateDeviceToken);
}
