import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Summary from './components/summary';
import PaymentReport from './components/payment-report';
import BillReport from './components/bill-report';
import InventoriesReport from './components/inventories-report';
import { Col, Row, Spin } from 'antd';
import UserReport from './components/user-report';
import TopContent from 'components/common/top-content';
import BestSeller from './components/best-seller';
import './index.scss';
import { searchUser } from 'stores/user/user.action';
import { searchStore } from 'stores/store/store.action';
import { searchItem } from 'stores/item/item.action';
import {
  searchShopOwnerOrders,
  searchInventories,
  searchOrders,
  searchShopOwnerBestSeller,
} from 'stores/common/common.action';
import { searchImportItemTicket } from 'stores/import-item-ticket/import-item-ticket.action';
import {
  ADMIN_PHONE,
  IMPORT_ITEM_TICKET_STATUS,
  INVENTORY_REPORT_TYPE,
} from 'common/constant';
import { searchConversionRate } from 'stores/conversion-rate/conversion-rate.action';

function Dashboard(props) {
  const { t, common, user, item, store } = props;
  const isAdmin =
    JSON.parse(window.localStorage.getItem('user'))?.phone === ADMIN_PHONE;
  useEffect(() => {
    document.title = t('dashboard.title');
    const userId = window.localStorage.getItem('userId');
    const params = isAdmin
      ? {}
      : { filter: JSON.stringify([{ column: 'user_id', value: userId }]) };
    props.searchStore(params);
    props.searchItem(params);
    props.searchImportItemTicket({
      filter: JSON.stringify([
        {
          column: 'status',
          value: IMPORT_ITEM_TICKET_STATUS.ACTIVE,
        },
      ]),
    });
    props.searchConversionRate();
    props.searchInventories();

    if (!isAdmin) {
      const shop_owner_id = window.localStorage.getItem('userId');
      props.searchShopOwnerOrders({
        user_id: shop_owner_id,
      });
      props.searchShopOwnerBestSeller(shop_owner_id);
    } else {
      props.searchUser();
      props.searchOrders();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin
      spinning={
        common?.isLoading ||
        user?.isLoading ||
        item?.isLoading ||
        store?.isLoading
      }
    >
      <TopContent showSearch={false} />
      <Summary isAdmin={isAdmin} />
      <Row>
        <Col span={24} xl={{ span: 12 }} className="chart-styling">
          <PaymentReport />
        </Col>
        <Col span={24} xl={{ span: 12 }} className="chart-styling">
          <BillReport />
        </Col>
      </Row>
      {!isAdmin && (
        <>
          <Row>
            <Col span={24} className="chart-styling">
              <InventoriesReport type={INVENTORY_REPORT_TYPE.UNEXPIRED} />
            </Col>
          </Row>
          <Row>
            <Col span={24} className="chart-styling">
              <InventoriesReport type={INVENTORY_REPORT_TYPE.EXPIRED} />
            </Col>
          </Row>
        </>
      )}
      <Row>
        {isAdmin ? (
          <Col span={24} xl={{ span: 12 }} className="chart-styling">
            <UserReport />
          </Col>
        ) : (
          <>
            <Col span={24} xl={{ span: 24 }} className="chart-styling">
              <BestSeller />
            </Col>
          </>
        )}
      </Row>
    </Spin>
  );
}

const mapDispatchToProps = {
  searchUser,
  searchStore,
  searchItem,
  searchShopOwnerOrders,
  searchImportItemTicket,
  searchInventories,
  searchConversionRate,
  searchOrders,
  searchShopOwnerBestSeller,
};

const mapStateToProps = (state) => ({
  common: state.common,
  item: state.item,
  store: state.store,
  user: state.user,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard),
);
