import React from 'react';
import { Router } from 'react-router-dom';
import history from 'services/history';
import Layout from 'components/layout-router';
import { onMessageListener } from 'firebase-config';
import addNotification from 'common/toast';
import store from 'stores';
import { getNotificationList } from 'stores/notification/notification.action';

/**
 *
 * @returns {*}
 */

export default function AppContainer() {
  const notification_handler = (payload) => {
    store.dispatch(getNotificationList(window.localStorage.getItem('userId')));
    addNotification(payload.data.message);
  };

  onMessageListener(notification_handler);

  return (
    <div className={'root'}>
      <Router history={history}>
        <Layout />
      </Router>
    </div>
  );
}
