export const CREATE_IMPORT_ITEM_TICKET_START = 'CREATE_IMPORT_ITEM_TICKET_START';
export const CREATE_IMPORT_ITEM_TICKET_SUCCESS = 'CREATE_IMPORT_ITEM_TICKET_SUCCESS';
export const CREATE_IMPORT_ITEM_TICKET_FAILED = 'CREATE_IMPORT_ITEM_TICKET_FAILED';

export const UPDATE_IMPORT_ITEM_TICKET_START = 'UPDATE_IMPORT_ITEM_TICKET_START';
export const UPDATE_IMPORT_ITEM_TICKET_SUCCESS = 'UPDATE_IMPORT_ITEM_TICKET_SUCCESS';
export const UPDATE_IMPORT_ITEM_TICKET_FAILED = 'UPDATE_IMPORT_ITEM_TICKET_FAILED';

export const DELETE_IMPORT_ITEM_TICKET_START = 'DELETE_IMPORT_ITEM_TICKET_START';
export const DELETE_IMPORT_ITEM_TICKET_SUCCESS = 'DELETE_IMPORT_ITEM_TICKET_SUCCESS';
export const DELETE_IMPORT_ITEM_TICKET_FAILED = 'DELETE_IMPORT_ITEM_TICKET_FAILED';

export const SEARCH_IMPORT_ITEM_TICKET_START = 'SEARCH_IMPORT_ITEM_TICKET_START';
export const SEARCH_IMPORT_ITEM_TICKET_SUCCESS = 'SEARCH_IMPORT_ITEM_TICKET_SUCCESS';
export const SEARCH_IMPORT_ITEM_TICKET_FAILED = 'SEARCH_IMPORT_ITEM_TICKET_FAILED';

export const GET_IMPORT_ITEM_TICKET_DETAIL_START = 'GET_IMPORT_ITEM_TICKET_DETAIL_START';
export const GET_IMPORT_ITEM_TICKET_DETAIL_SUCCESS = 'GET_IMPORT_ITEM_TICKET_DETAIL_SUCCESS';
export const GET_IMPORT_ITEM_TICKET_DETAIL_FAILED = 'GET_IMPORT_ITEM_TICKET_DETAIL_FAILED';

export const CONFIRM_IMPORT_ITEM_TICKET_START = 'CONFIRM_IMPORT_ITEM_TICKET_START'
export const CONFIRM_IMPORT_ITEM_TICKET_SUCCESS = 'CONFIRM_IMPORT_ITEM_TICKET_SUCCESS'
export const CONFIRM_IMPORT_ITEM_TICKET_FAILED = 'CONFIRM_IMPORT_ITEM_TICKET_FAILED'

/**
 * Create import item ticket
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createImportItemTicket(payload, onSuccess, onError) {
  return {
    type: CREATE_IMPORT_ITEM_TICKET_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create import item ticket success action
 * @param {*} payload
 * @returns {object}
 */
export function createImportItemTicketSuccess(payload) {
  return {
    type: CREATE_IMPORT_ITEM_TICKET_SUCCESS,
    payload: payload,
  };
}

/**
 * Create import item ticket failed action
 * @returns {object}
 */
export function createImportItemTicketFailed() {
  return {
    type: CREATE_IMPORT_ITEM_TICKET_FAILED,
  };
}

/**
 * Update import item ticket
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateImportItemTicket(payload, onSuccess, onError) {
  return {
    type: UPDATE_IMPORT_ITEM_TICKET_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update import item ticket success action
 * @param {*} payload
 * @returns {object}
 */
export function updateImportItemTicketSuccess(payload) {
  return {
    type: UPDATE_IMPORT_ITEM_TICKET_SUCCESS,
    payload: payload,
  };
}

/**
 * Update import item ticket failed action
 * @returns {object}
 */
export function updateImportItemTicketFailed() {
  return {
    type: UPDATE_IMPORT_ITEM_TICKET_FAILED,
  };
}

/**
 * Delete import item ticket
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function deleteImportItemTicket(payload, onSuccess, onError) {
  return {
    type: DELETE_IMPORT_ITEM_TICKET_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Delete import item ticket success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteImportItemTicketSuccess(payload) {
  return {
    type: DELETE_IMPORT_ITEM_TICKET_SUCCESS,
    payload: payload,
  };
}

/**
 * Delete import item ticket failed action
 * @returns {object}
 */
export function deleteImportItemTicketFailed() {
  return {
    type: DELETE_IMPORT_ITEM_TICKET_FAILED,
  };
}

/**
 * Search import item ticket
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function searchImportItemTicket(payload, onSuccess, onError) {
  return {
    type: SEARCH_IMPORT_ITEM_TICKET_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Search import item ticket success action
 * @param {*} payload
 * @returns {object}
 */
export function searchImportItemTicketSuccess(payload) {
  return {
    type: SEARCH_IMPORT_ITEM_TICKET_SUCCESS,
    payload: payload,
  };
}

/**
 * Search import item ticket failed action
 * @returns {object}
 */
export function searchImportItemTicketFailed() {
  return {
    type: SEARCH_IMPORT_ITEM_TICKET_FAILED,
  };
}

/**
 * Get import item ticket detail
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function getImportItemTicketDetail(payload, onSuccess, onError) {
  return {
    type: GET_IMPORT_ITEM_TICKET_DETAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Get import item ticket detail success action
 * @param {*} payload
 * @returns {object}
 */
export function getImportItemTicketDetailSuccess(payload) {
  return {
    type: GET_IMPORT_ITEM_TICKET_DETAIL_SUCCESS,
    payload: payload,
  };
}

/**
 * Get import item ticket detail failed action
 * @returns {object}
 */
export function getImportItemTicketDetailFailed() {
  return {
    type: GET_IMPORT_ITEM_TICKET_DETAIL_FAILED,
  };
}

/**
 * import import-item-ticket
 * @param {function=} onSuccess Callback function on success
 * @param {function=} onError Callback function on error
 * @returns {object}
 */
 export function confirmImportItemTicket(payload, onSuccess, onError) {
  return {
    type: CONFIRM_IMPORT_ITEM_TICKET_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * import import-item-ticket success
 * @param {*} payload
 * @returns {object}
 */
export function confirmImportItemTicketSuccess(payload) {
  return {
    type: CONFIRM_IMPORT_ITEM_TICKET_SUCCESS,
    payload: payload,
  };
}

/**
 * import import-item-ticket failed
 * @returns {object}
 */
export function confirmImportItemTicketFailed() {
  return {
    type: CONFIRM_IMPORT_ITEM_TICKET_FAILED,
  };
}

