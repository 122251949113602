import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import {
  Form,
  Spin,
  Card,
  Row,
  Col,
  Input,
  Button,
  Select,
  Upload,
  Modal,
} from 'antd';
import TopContent from 'components/common/top-content';
import {
  MODAL_MODE,
  PATTERN,
  TEXTFIELD_REQUIRED_LENGTH,
  STORE_STATUS,
  STORE_STATUS_MAP,
  USER_TYPE_ENUM,
  IMAGE_STATUS,
  IMAGE_RELATION_TYPE,
  ADMIN_PHONE,
  USER_STATUS,
  DESCRIPTION_ROWS,
  TRANSACTION_TYPE,
} from 'common/constant';
import { formatDateTimeUtc, getImageUrl, redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import {
  getStoreDetail,
  createStore,
  updateStore,
  createStoreTransaction,
} from 'stores/store/store.action';
import { searchUser } from 'stores/user/user.action';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import Coin from 'assets/images/coin.png';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Upload
    </div>
  </div>
);

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

function StoreForm(props) {
  const { t, store, userList } = props;
  const [form] = Form.useForm();
  const [mode, setMode] = useState(MODAL_MODE.CREATE);
  const [status, setStatus] = useState(0);
  const [title, setTitle] = useState('');
  const [createdAt, setCreatedAt] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      route: ROUTES.STORE.PATH,
      title: ROUTES.STORE.TITLE,
    },
    {
      title: ROUTES.STORE_CREATE.TITLE,
    },
  ]);
  const [shopwOwner, setShopOwner] = useState(null);
  const [image, setImage] = useState(null);
  const path = props.match.path;
  const { id } = props.match.params;
  const isAdmin =
    JSON.parse(window.localStorage.getItem('user'))?.phone === ADMIN_PHONE;

  useEffect(() => {
    props.searchUser();
    setMode(
      path.includes('/update/')
        ? MODAL_MODE.UPDATE
        : path.includes('/detail/')
        ? MODAL_MODE.DETAIL
        : MODAL_MODE.CREATE,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (mode !== MODAL_MODE.CREATE) {
      const {
        code,
        name,
        address,
        email,
        phone,
        description,
        user_id,
        image,
        status,
        created_at,
        coin,
      } = store.storeDetail;

      if (code) {
        setStatus(status);
        setCreatedAt(moment(created_at));
        if (image)
          setImage({
            uid: 1,
            name: image,
            status: IMAGE_STATUS.DONE,
            url: getImageUrl(`${IMAGE_RELATION_TYPE.SHOP}/${id}/${image}`),
          });

        form.setFieldsValue({
          code,
          name,
          address,
          phone,
          email,
          description,
          user_id,
          imageList: image ? [image] : [],
          coin: +coin,
        });

        setShopOwner(
          userList
            .filter((user) =>
              [
                USER_TYPE_ENUM.SHOPOWNER,
                USER_TYPE_ENUM.GOOGLE_SHOP_OWNER,
              ].includes(user.user_type),
            )
            .find((user) => user.id === form.getFieldValue('user_id')),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, form]);

  useEffect(() => {
    document.title = title;
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    setBreadcrumbs([
      {
        route: ROUTES.STORE.PATH,
        title: ROUTES.STORE.TITLE,
      },
      {
        title:
          mode === MODAL_MODE.CREATE
            ? ROUTES.STORE_CREATE.TITLE
            : mode === MODAL_MODE.UPDATE
            ? ROUTES.STORE_UPDATE.TITLE
            : ROUTES.STORE_DETAIL.TITLE,
      },
    ]);

    setTitle(
      mode === MODAL_MODE.CREATE
        ? t('store.createStore')
        : mode === MODAL_MODE.UPDATE
        ? t('store.editStore')
        : t('store.detailStore'),
    );
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const onFinish = (values) => {
    const { code, name, address, email, phone, description, user_id } = values;
    const id = props.match.params.id;
    const imageName = image?.name;

    const params = new FormData();
    params.append('code', code);
    params.append('name', name);
    params.append('address', address);
    params.append('logo', '');
    params.append('email', email);
    params.append('phone', phone);
    params.append('description', description || '');
    params.append(
      'user_id',
      isAdmin ? user_id : window.localStorage.getItem('userId'),
    );
    if (image) {
      params.append(
        'image',
        image?.status === IMAGE_STATUS.DONE
          ? null
          : JSON.stringify({
              ...image,
              name: imageName,
              url: `${IMAGE_RELATION_TYPE.SHOP}/${id}/`,
            }),
      );
      if (image?.status !== IMAGE_STATUS.DONE)
        params.append('files[]', image.originFileObj || null);
    }

    if (mode === MODAL_MODE.CREATE)
      props.createStore(params, () => {
        backToList();
      });
    else if (mode === MODAL_MODE.UPDATE) {
      props.updateStore(params, id, () => {
        backToList();
      });
    }

    setImage(null);
  };

  const backToList = () => {
    redirectRouter(ROUTES.STORE.PATH);
    setImage(null);
  };

  const getDetail = () => {
    const id = props.match.params.id;
    if (mode !== MODAL_MODE.CREATE) props.getStoreDetail(id);
    else if (mode === MODAL_MODE.UPDATE) getDetail();
  };

  const onCancel = () => {
    if (mode === MODAL_MODE.CREATE) resetForm();
    else if (mode === MODAL_MODE.UPDATE) getDetail();
  };

  const resetForm = () => {
    form?.resetFields();
    setImage(null);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const renderActionButtons = () => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return (
          <div id="group-button-submit">
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="store-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.create')}
            </Button>
          </div>
        );
      case MODAL_MODE.UPDATE:
        return (
          <div id="group-button-submit">
            <Button onClick={() => backToList()} className="close-button">
              {t('common.close')}
            </Button>
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="store-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('common.save')}
            </Button>
          </div>
        );
      case MODAL_MODE.DETAIL:
        switch (status) {
          // PENDING
          case STORE_STATUS.UNCONFIRM:
          case STORE_STATUS.ACTIVE:
            return (
              <div id="group-button-submit">
                <Button
                  onClick={() => setOpenWithdrawModal(true)}
                  className="mini-shop-button"
                  type="primary"
                >
                  {t('store.withdrawCoin')}
                </Button>
                <Button onClick={backToList} className="close-button">
                  {t('common.close')}
                </Button>
              </div>
            );
          // INACTIVE
          case STORE_STATUS.INACTIVE:
            return (
              <Button onClick={() => backToList()} className="close-button">
                {t('common.close')}
              </Button>
            );
          default:
            return;
        }
      default:
        break;
    }
  };

  const handleChangeImage = ({ fileList: imageList }) => {
    if (image?.status === IMAGE_STATUS.REMOVED) {
      setImage(null);
    } else {
      setImage(imageList[0]);
    }
  };

  const closeWithdrawModal = () => {
    setOpenWithdrawModal(false);
  };

  const submitWithdraw = () => {
    form.validateFields();
    const maxCoin = form.getFieldValue('coin');
    const price = +form.getFieldValue('price');
    if (!price || price > maxCoin) return;

    const params = {
      user_id: window.localStorage.getItem('userId'),
      receiver_id: null,
      transaction_type: TRANSACTION_TYPE.SHOP,
      money: price,
      price,
      shop_id: id,
      conversion_rate_id: null,
      payment_type_id: null,
    };

    props.createStoreTransaction(params, () => {
      setOpenWithdrawModal(false);
      getDetail();
    });
  };

  const isView = mode === MODAL_MODE.DETAIL;
  const isCreate = mode === MODAL_MODE.CREATE;
  return (
    <div className="store-form">
      <Spin spinning={store?.isLoading}>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={() => {
            setPreviewVisible(false);
            setPreviewImage(null);
          }}
        >
          <img
            alt="preview"
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>
        {openWithdrawModal && (
          <Modal
            className="ant-card-body"
            visible={openWithdrawModal}
            title={t('store.withdrawCoin')}
            width={720}
            cancelText={t('common.close')}
            okText={t('common.confirm')}
            onCancel={closeWithdrawModal}
            onOk={submitWithdraw}
          >
            <Form
              {...layout}
              id="withdraw-form"
              className="required-mark-after"
              form={form}
            >
              <Form.Item
                label={t('user.withdrawAmount')}
                name="price"
                rules={[
                  {
                    required: true,
                    message: t('form.required'),
                  },
                  {
                    validator: (rule, value, cb) => {
                      const maxCoin = form.getFieldValue('coin');
                      if (value && value > maxCoin)
                        cb(
                          t('store.maxCoinWithdraw', {
                            max: maxCoin,
                          }),
                        );
                      cb();
                    },
                  },
                ]}
              >
                <Input
                  prefix={
                    <img
                      src={Coin}
                      width="15px"
                      height="15px"
                      alt="coin-icon"
                    />
                  }
                  type="number"
                  placeholder={t('user.placeholder.withdrawAmount')}
                />
              </Form.Item>
            </Form>
          </Modal>
        )}
        <TopContent
          title={title}
          breadcrumbs={breadcrumbs}
          backToList={backToList}
        />
        <Card className="body-content">
          <Form
            {...layout}
            name="store-form"
            id="store-form"
            className="required-mark-after"
            form={form}
            onFinish={onFinish}
          >
            <div className="block-1">
              {isView && (
                <Row>
                  <Col sm={10} xs={24}>
                    <Form.Item name="status" label={t('store.status')}>
                      <div
                        className={
                          [STORE_STATUS.ACTIVE].includes(status)
                            ? 'activeStatusBox boxStatus'
                            : [STORE_STATUS.UNCONFIRM].includes(status)
                            ? 'unconfirmedStatusBox boxStatus'
                            : 'inActiveStatusBox boxStatus'
                        }
                      >
                        {t(STORE_STATUS_MAP[status])}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={10} xs={24}>
                  <Form.Item
                    name="code"
                    label={t('store.code')}
                    rules={[
                      {
                        required: isCreate,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.LENGTH_20.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.LENGTH_20.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input
                      disabled={!isCreate}
                      placeholder={t('store.placeholder.code')}
                    />
                  </Form.Item>
                  {isAdmin && (
                    <Form.Item
                      label={t('store.owner')}
                      name="user_id"
                      rules={[
                        {
                          required: !isView,
                          message: t('form.required'),
                        },
                      ]}
                    >
                      {isView ? (
                        <div className="ant-input ant-input-disabled">
                          {shopwOwner?.full_name}
                        </div>
                      ) : (
                        <Select
                          placeholder={t('store.placeholder.owner')}
                          disabled={isView}
                        >
                          {userList
                            .filter(
                              (user) =>
                                [
                                  USER_TYPE_ENUM.SHOPOWNER,
                                  USER_TYPE_ENUM.GOOGLE_SHOP_OWNER,
                                ].includes(user.user_type) &&
                                user.status === USER_STATUS.ACTIVE,
                            )
                            .map((user) => (
                              <Select.Option key={user.id} value={user.id}>
                                {user.full_name}
                              </Select.Option>
                            ))}
                        </Select>
                      )}
                    </Form.Item>
                  )}
                  <Form.Item
                    name="phone"
                    label={t('store.phoneNumber')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.LENGTH_10.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.LENGTH_10.MAX,
                        }),
                      },
                      {
                        pattern: PATTERN.phone,
                        message: t('form.validPhone'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('store.placeholder.phoneNumber')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('store.description')}
                    name="description"
                    rules={[
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input.TextArea
                      disabled={isView}
                      autoSize={{
                        minRows: isView ? 1 : DESCRIPTION_ROWS.MIN,
                        maxRows: DESCRIPTION_ROWS.MAX,
                      }}
                      placeholder={t('store.placeholder.description')}
                    />
                  </Form.Item>
                  {isView && (
                    <Form.Item name="coin" label={t('user.coin')}>
                      <Input
                        disabled
                        prefix={
                          <img
                            src={Coin}
                            width="15px"
                            height="15px"
                            alt="coin-icon"
                          />
                        }
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="imageList"
                    label={t('store.image')}
                    rules={[]}
                  >
                    {isView ? (
                      image && (
                        <img
                          width="100"
                          height="100"
                          className="image"
                          src={image?.url}
                          alt="img"
                        />
                      )
                    ) : (
                      <Upload
                        action="/"
                        listType="picture-card"
                        fileList={image ? [image] : []}
                        beforeUpload={() => {
                          return false;
                        }}
                        onPreview={handlePreview}
                        maxCount={1}
                        onChange={handleChangeImage}
                      >
                        {image ? null : uploadButton}
                      </Upload>
                    )}
                  </Form.Item>
                  {isView && (
                    <Form.Item label={t('itemUnit.createdAt')}>
                      <div className="ant-input-disabled">
                        {formatDateTimeUtc(createdAt)}
                      </div>
                    </Form.Item>
                  )}
                </Col>
                <Col sm={10} xs={24}>
                  <Form.Item
                    name="name"
                    label={t('store.name')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('store.placeholder.name')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('store.address')}
                    name="address"
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('store.placeholder.address')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('store.email')}
                    name="email"
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                      // {
                      //   pattern: PATTERN.vti_email,
                      //   message: t('form.validVtiEmail'),
                      // },
                      {
                        pattern: PATTERN.email,
                        message: t('form.validEmail'),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('store.placeholder.email')}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
        <Card style={{ marginTop: '32px' }}>
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderActionButtons()}
              </div>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
}

const mapDispatchToProps = {
  getStoreDetail,
  createStore,
  updateStore,
  searchUser,
  createStoreTransaction,
};

const mapStateToProps = (state) => ({
  store: state.store,
  userList: state.user.userList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(StoreForm),
);
