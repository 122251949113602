export const CREATE_BARCODE_START = 'CREATE_BARCODE_START';
export const CREATE_BARCODE_SUCCESS = 'CREATE_BARCODE_SUCCESS';
export const CREATE_BARCODE_FAILED = 'CREATE_BARCODE_FAILED';

export const DELETE_BARCODE_START = 'DELETE_BARCODE_START';
export const DELETE_BARCODE_SUCCESS = 'DELETE_BARCODE_SUCCESS';
export const DELETE_BARCODE_FAILED = 'DELETE_BARCODE_FAILED';

export const SEARCH_BARCODE_START = 'SEARCH_BARCODE_START';
export const SEARCH_BARCODE_SUCCESS = 'SEARCH_BARCODE_SUCCESS';
export const SEARCH_BARCODE_FAILED = 'SEARCH_BARCODE_FAILED';

/**
 * Create barcode
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createBarcode(payload, onSuccess, onError) {
  return {
    type: CREATE_BARCODE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create barcode success action
 * @param {*} payload
 * @returns {object}
 */
export function createBarcodeSuccess(payload) {
  return {
    type: CREATE_BARCODE_SUCCESS,
    payload: payload,
  };
}

/**
 * Create barcode failed action
 * @returns {object}
 */
export function createBarcodeFailed() {
  return {
    type: CREATE_BARCODE_FAILED,
  };
}

/**
 * Delete barcode
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function deleteBarcode(payload, onSuccess, onError) {
  return {
    type: DELETE_BARCODE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Delete barcode success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteBarcodeSuccess(payload) {
  return {
    type: DELETE_BARCODE_SUCCESS,
    payload: payload,
  };
}

/**
 * Delete barcode failed action
 * @returns {object}
 */
export function deleteBarcodeFailed() {
  return {
    type: DELETE_BARCODE_FAILED,
  };
}

/**
 * Search barcode
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function searchBarcode(payload, onSuccess, onError) {
  return {
    type: SEARCH_BARCODE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Search barcode success action
 * @param {*} payload
 * @returns {object}
 */
export function searchBarcodeSuccess(payload) {
  return {
    type: SEARCH_BARCODE_SUCCESS,
    payload: payload,
  };
}

/**
 * Search barcode failed action
 * @returns {object}
 */
export function searchBarcodeFailed() {
  return {
    type: SEARCH_BARCODE_FAILED,
  };
}
