import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import ReactNotification from 'react-notifications-component';
import AppContainer from 'containers/app';
import i18n from 'common/i18n';
import store from 'stores';
import 'antd/dist/antd.min.css';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Suspense>
    <ReactNotification />
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <AppContainer />
      </I18nextProvider>
    </Provider>
  </Suspense>,
);
