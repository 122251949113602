import {
  CREATE_ITEM_UNIT_START,
  CREATE_ITEM_UNIT_SUCCESS,
  CREATE_ITEM_UNIT_FAILED,
  UPDATE_ITEM_UNIT_START,
  UPDATE_ITEM_UNIT_SUCCESS,
  UPDATE_ITEM_UNIT_FAILED,
  DELETE_ITEM_UNIT_START,
  DELETE_ITEM_UNIT_SUCCESS,
  DELETE_ITEM_UNIT_FAILED,
  SEARCH_ITEM_UNIT_START,
  SEARCH_ITEM_UNIT_SUCCESS,
  SEARCH_ITEM_UNIT_FAILED,
  GET_ITEM_UNIT_DETAIL_START,
  GET_ITEM_UNIT_DETAIL_SUCCESS,
  GET_ITEM_UNIT_DETAIL_FAILED,
} from './item-unit.action';

const initialState = {
  isLoading: false,
  itemUnitList: [],
  itemUnitDetail: {},
  total: 0,
};

/**
 * item-unit reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function itemUnit(state = initialState, action) {
  switch (action.type) {
    case CREATE_ITEM_UNIT_START:
    case UPDATE_ITEM_UNIT_START:
    case DELETE_ITEM_UNIT_START:
    case SEARCH_ITEM_UNIT_START:
    case GET_ITEM_UNIT_DETAIL_START:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_ITEM_UNIT_SUCCESS:
    case UPDATE_ITEM_UNIT_SUCCESS:
    case DELETE_ITEM_UNIT_SUCCESS:
    case CREATE_ITEM_UNIT_FAILED:
    case UPDATE_ITEM_UNIT_FAILED:
    case DELETE_ITEM_UNIT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case SEARCH_ITEM_UNIT_SUCCESS:
      return {
        ...state,
        itemUnitList: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case SEARCH_ITEM_UNIT_FAILED:
      return {
        ...state,
        itemUnitList: [],
        total: 0,
        isLoading: false,
      };
    case GET_ITEM_UNIT_DETAIL_SUCCESS:
      return {
        ...state,
        itemUnitDetail: action.payload,
        isLoading: false,
      };
    case GET_ITEM_UNIT_DETAIL_FAILED:
      return {
        ...state,
        itemUnitDetail: {},
        isLoading: false,
      };
    default:
      return state;
  }
}
