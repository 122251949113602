import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import { Form, Spin, Card, Row, Col, Input, Button, Select } from 'antd';
import TopContent from 'components/common/top-content';
import {
  DATE_FORMAT,
  DEVICE_MIN_WIDTH,
  MODAL_MODE,
  NOTIFICATION_TYPE,
  TEXTFIELD_REQUIRED_LENGTH,
} from 'common/constant';
import { formatDateTimeUtc, redirectRouter } from 'utils';
import { ROUTES } from 'common/routes';
import {
  getInventoriesCheckDetail,
  createInventoriesCheck,
} from 'stores/inventories-check/inventories-check.action';
import { searchInventories } from 'stores/common/common.action';
import { searchStore } from 'stores/store/store.action';
import { searchCategory } from 'stores/category/category.action';
import moment from 'moment';
import ItemsSettingTable from './items-setting-table';
import addNotification from 'common/toast';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

function InventoriesCheckForm(props) {
  const { t, inventoriesCheck, inventoriesList, categoryList } = props;
  const [form] = Form.useForm();
  const [mode, setMode] = useState(MODAL_MODE.CREATE);
  const [title, setTitle] = useState('');
  const [createdAt, setCreatedAt] = useState(null);
  const [createdBy, setCreatedBy] = useState('');
  const [items, setItems] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      route: ROUTES.INVENTORIES_CHECK.PATH,
      title: ROUTES.INVENTORIES_CHECK.TITLE,
    },
    {
      title: ROUTES.INVENTORIES_CHECK_CREATE.TITLE,
    },
  ]);
  const [filterCategory, setFilterCategory] = useState(null);
  const path = props.match.path;

  useEffect(() => {
    setMode(path.includes('/detail/') ? MODAL_MODE.DETAIL : MODAL_MODE.CREATE);
    props.searchInventories();
    const filteredParams = {
      filter: JSON.stringify([
        {
          column: 'user_id',
          value: window.localStorage.getItem('userId'),
        },
      ]),
    };
    props.searchStore(filteredParams);
    props.searchCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    if (mode !== MODAL_MODE.CREATE) {
      const { name, created_at, inventories_check_details, user_name } =
        inventoriesCheck.inventoriesCheckDetail;
      setCreatedAt(moment(created_at));
      setCreatedBy(user_name);

      let fields = { name: name };
      inventories_check_details?.forEach((item, index) => {
        Object.assign(fields, {
          [`item_id${index}`]: getInventoryObject(item?.inventory_id)?.item
            ?.name,
          [`quantity${index}`]: +item?.quantity,
        });
      });

      form.setFieldsValue(fields);
      setItems(
        inventories_check_details
          ? inventories_check_details.map((inventory_check, index) => ({
              ...inventory_check,
              id: index,
            }))
          : [],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoriesCheck, form]);

  useEffect(() => {
    document.title = title;
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    setBreadcrumbs([
      {
        route: ROUTES.INVENTORIES_CHECK.PATH,
        title: ROUTES.INVENTORIES_CHECK.TITLE,
      },
      {
        title:
          mode === MODAL_MODE.CREATE
            ? ROUTES.INVENTORIES_CHECK_CREATE.TITLE
            : mode === MODAL_MODE.UPDATE
            ? ROUTES.INVENTORIES_CHECK_UPDATE.TITLE
            : ROUTES.INVENTORIES_CHECK_DETAIL.TITLE,
      },
    ]);

    setTitle(
      mode === MODAL_MODE.CREATE
        ? t('inventoriesCheck.createInventoriesCheck')
        : mode === MODAL_MODE.UPDATE
        ? t('inventoriesCheck.editInventoriesCheck')
        : t('inventoriesCheck.detailInventoriesCheck'),
    );
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const getInventoryObject = (inventoryId) => {
    return inventoriesList.find((inventory) => inventory.id === inventoryId);
  };

  const onFinish = (values) => {
    if (items.length === 0) {
      addNotification('inventoriesCheck.cannot_blank', NOTIFICATION_TYPE.ERROR);
      return;
    }
    const { name } = values;
    const params = {
      name,
      user_id: window.localStorage.getItem('userId'),
      inventories_check_details: items.map((item) => ({
        item_id: item.item_id,
        shop_id: item.shop_id,
        quantity: parseInt(item.quantity),
        actual_quantity: parseInt(item.actual_quantity),
        is_update_quantity: item.is_update_quantity,
        note: item.note,
        inventory_id: item.inventory_id,
      })),
    };
    props.createInventoriesCheck(params, () => {
      backToList();
    });
  };

  const backToList = () => {
    redirectRouter(ROUTES.INVENTORIES_CHECK.PATH);
  };

  const getDetail = () => {
    const id = props.match.params.id;
    if (mode !== MODAL_MODE.CREATE) props.getInventoriesCheckDetail(id);
  };

  const onCancel = () => {
    if (mode === MODAL_MODE.CREATE) resetForm();
    else if (mode === MODAL_MODE.UPDATE) getDetail();
  };

  const resetForm = () => {
    form?.resetFields();
    setItems([]);
  };

  const renderActionButtons = () => {
    switch (mode) {
      case MODAL_MODE.CREATE:
        return (
          <div id="group-button-submit">
            <Button onClick={onCancel}>{t('common.cancel')}</Button>
            <Button
              form="inventories-check-form"
              key="submit"
              htmlType="submit"
              className="mini-shop-button"
              type="primary"
            >
              {t('inventoriesCheck.synchronize')}
            </Button>
          </div>
        );
      case MODAL_MODE.DETAIL:
        return (
          <Button onClick={() => backToList()} className="close-button">
            {t('common.close')}
          </Button>
        );
      default:
        break;
    }
  };

  const isView = mode === MODAL_MODE.DETAIL;
  return (
    <div className="inventories-check-form">
      <Spin spinning={inventoriesCheck?.isLoading}>
        <TopContent
          title={title}
          breadcrumbs={breadcrumbs}
          backToList={backToList}
        />
        <Card className="body-content">
          <Form
            {...layout}
            name="inventories-check-form"
            id="inventories-check-form"
            className="required-mark-after"
            form={form}
            onFinish={onFinish}
          >
            <div className="block-1">
              <Row>
                <Col
                  sm={12}
                  xs={24}
                  offset={window.innerWidth > DEVICE_MIN_WIDTH ? 4 : 0}
                >
                  <Form.Item
                    name="name"
                    label={t('inventoriesCheck.name')}
                    rules={[
                      {
                        required: !isView,
                        message: t('form.required'),
                      },
                      {
                        max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        message: t('form.maxLength', {
                          max: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                        }),
                      },
                    ]}
                  >
                    <Input
                      disabled={isView}
                      placeholder={t('inventoriesCheck.placeholder.name')}
                    />
                  </Form.Item>
                  {!isView && (
                    <Form.Item
                      name="filterCategory"
                      label={t('inventoriesCheck.filterCategory')}
                      rules={[]}
                    >
                      <Select
                        allowClear
                        onChange={(value) => setFilterCategory(value)}
                      >
                        {categoryList.map((category) => (
                          <Select.Option key={category.id} value={category.id}>
                            {category.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {isView && (
                    <>
                      <Form.Item label={t('inventoriesCheck.createdBy')}>
                        <Input value={createdBy} disabled={isView} />
                      </Form.Item>
                      <Form.Item label={t('inventoriesCheck.createdAt')}>
                        <Input
                          value={formatDateTimeUtc(createdAt, DATE_FORMAT)}
                          disabled={isView}
                        />
                      </Form.Item>
                    </>
                  )}
                </Col>
              </Row>
            </div>
            <ItemsSettingTable
              items={items}
              mode={mode}
              form={form}
              setItems={setItems}
              filterCategory={filterCategory}
            />
          </Form>
        </Card>
        <Card style={{ marginTop: '15px' }}>
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderActionButtons()}
              </div>
            </Col>
          </Row>
        </Card>
      </Spin>
    </div>
  );
}

const mapDispatchToProps = {
  getInventoriesCheckDetail,
  createInventoriesCheck,
  searchInventories,
  searchStore,
  searchCategory,
};

const mapStateToProps = (state) => ({
  inventoriesCheck: state.inventoriesCheck,
  inventoriesList: state.common.inventoriesList,
  categoryList: state.category.categoryList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(InventoriesCheckForm),
);
