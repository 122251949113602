import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  confirmOrderFailed,
  confirmOrderSuccess,
  CONFIRM_ORDER_START,
} from 'stores/common/common.action';

/**
 * Confirm order API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const confirmOrderApi = (params) => {
  const uri = `${API_URL.ORDERS}/${params}/confirm`;
  return api.put(uri);
};

/**
 * Handle confirm request and response
 * @param {object} action
 */
function* doConfirmOrder(action) {
  try {
    const response = yield call(confirmOrderApi, action?.payload);

    if (response?.status === 200) {
      yield put(confirmOrderSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification('common.confirmOrderSuccess', NOTIFICATION_TYPE.SUCCESS);
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(confirmOrderFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch confirm order
 */
export default function* watchConfirmOrder() {
  yield takeLatest(CONFIRM_ORDER_START, doConfirmOrder);
}
