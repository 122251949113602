import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ORDER_STATUS, PIE_CHART_COLORS } from 'common/constant';
import { Component } from 'react';
import { Pie } from '@ant-design/charts';
import { Card, Row } from 'antd';
import Title from '../title';

class BillReport extends Component {
  constructor(props) {
    super(props);
  }

  renderChartTitle = () => this.props.t('dashboard.billReport.title');

  render() {
    const { t, orderList } = this.props;

    var config = {
      height: 350,
      width: 250,
      appendPadding: 10,
      data: [
        {
          name: t('dashboard.billReport.billCreated'),
          value: orderList.filter(
            (order) => order?.status === ORDER_STATUS.UNCONFIRM,
          ).length,
        },
        {
          name: t('dashboard.billReport.paid'),
          value: orderList.filter(
            (order) => order?.status === ORDER_STATUS.CONFIRM,
          ).length,
        },
        // {
        //   name: t('dashboard.billReport.due'),
        //   value: 300,
        // },
        // {
        //   name: t('dashboard.billReport.overDue'),
        //   value: 400,
        // },
      ],
      angleField: 'value',
      colorField: 'name',
      color: PIE_CHART_COLORS,
      radius: 1,
      label: {
        type: 'outer',
        style: { fontSize: 14, textOverflow: 'unset' },
      },
      statistic: {
        content: {
          style: {
            fontSize: 22,
          },
        },
      },
      innerRadius: 0.6,
      interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    };
    return (
      <Card style={{ paddingBottom: '5px' }}>
        <Row className="justify-content-between">
          <Title
            style={{ lineHeight: '40px' }}
            className="mb-0"
            title={this.renderChartTitle()}
          />
        </Row>
        <Pie {...config} />
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  orderList: state.common.orderList,
});

const mapDispatchToProps = {};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(BillReport),
);
