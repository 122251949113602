export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const REFRESH_TOKEN_START = 'REFRESH_TOKEN_START';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED';

export const EXPIRED_TOKEN = 'EXPIRED_TOKEN'

/**
 * Login
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function login(payload, onSuccess, onError) {
  return {
    type: LOGIN_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Login success action
 * @param {*} payload
 * @returns {object}
 */
export function loginSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    payload: payload,
  };
}

/**
 * Login failed action
 * @returns {object}
 */
export function loginFailed(payload) {
  return {
    payload,
    type: LOGIN_FAILED,
  };
}

/**
 * Logout
 * @param {any} callbackUrl
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function logout(callbackUrl, onSuccess, onError) {
  return {
    type: LOGOUT_START,
    callbackUrl: callbackUrl,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Logout success action
 * @param {*} payload
 * @returns {object}
 */
export function logoutSuccess(payload) {
  return {
    type: LOGOUT_SUCCESS,
    payload: payload,
  };
}

/**
 * Logout failed action
 * @returns {object}
 */
export function logoutFailed() {
  return {
    type: LOGOUT_FAILED,
  };
}

/**
 * RefreshToken
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function refreshToken(payload, onSuccess, onError) {
  return {
    type: REFRESH_TOKEN_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * RefreshToken success action
 * @param {*} payload
 * @returns {object}
 */
export function refreshTokenSuccess(payload) {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload: payload,
  };
}

/**
 * RefreshToken failed action
 * @returns {object}
 */
export function refreshTokenFailed(payload) {
  return {
    payload: payload,
    type: REFRESH_TOKEN_FAILED,
  };
}

/**
 * Expired token action
 * @returns {object}
 */
 export function expiredToken() {
  return {
    type: EXPIRED_TOKEN,
  };
}
