// @ts-nocheck
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import vi from 'assets/locales/vi/translation.json';
import en from 'assets/locales/en/translation.json';

const resources = {
  vi: {
    translation: vi,
  },
  en: {
    translation: en,
  },
};
i18n.use(initReactI18next).init({
  resources: resources,
  lng: window.localStorage.getItem('language') || 'vi',
  fallbackLng: ['vi', 'en'],
  ns: ['translation'],
  defaultNS: ['translation'],
  interpolation: {
    escapeValue: false,
  },
  debug: false,
  react: {
    useSuspense: true,
  },
});

export default i18n;
