import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  deletePaymentTypeFailed,
  deletePaymentTypeSuccess,
  DELETE_PAYMENT_TYPE_START,
} from 'stores/payment-type/payment-type.action';

/**
 * Delete payment-type API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const deletePaymentTypeApi = (params) => {
  const uri = `${API_URL.PAYMENT_TYPE}/${params}`;
  return api.delete(uri);
};

/**
 * Handle delete request and response
 * @param {object} action
 */
function* doDeletePaymentType(action) {
  try {
    const response = yield call(deletePaymentTypeApi, action?.payload);

    if (response?.status === 200) {
      yield put(deletePaymentTypeSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification(
        'paymentType.deletePaymentTypeSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      );
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(deletePaymentTypeFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch delete payment-type
 */
export default function* watchDeletePaymentType() {
  yield takeLatest(DELETE_PAYMENT_TYPE_START, doDeletePaymentType);
}
