export const CREATE_ITEM_UNIT_START = 'CREATE_ITEM_UNIT_START';
export const CREATE_ITEM_UNIT_SUCCESS = 'CREATE_ITEM_UNIT_SUCCESS';
export const CREATE_ITEM_UNIT_FAILED = 'CREATE_ITEM_UNIT_FAILED';

export const UPDATE_ITEM_UNIT_START = 'UPDATE_ITEM_UNIT_START';
export const UPDATE_ITEM_UNIT_SUCCESS = 'UPDATE_ITEM_UNIT_SUCCESS';
export const UPDATE_ITEM_UNIT_FAILED = 'UPDATE_ITEM_UNIT_FAILED';

export const DELETE_ITEM_UNIT_START = 'DELETE_ITEM_UNIT_START';
export const DELETE_ITEM_UNIT_SUCCESS = 'DELETE_ITEM_UNIT_SUCCESS';
export const DELETE_ITEM_UNIT_FAILED = 'DELETE_ITEM_UNIT_FAILED';

export const SEARCH_ITEM_UNIT_START = 'SEARCH_ITEM_UNIT_START';
export const SEARCH_ITEM_UNIT_SUCCESS = 'SEARCH_ITEM_UNIT_SUCCESS';
export const SEARCH_ITEM_UNIT_FAILED = 'SEARCH_ITEM_UNIT_FAILED';

export const GET_ITEM_UNIT_DETAIL_START = 'GET_ITEM_UNIT_DETAIL_START';
export const GET_ITEM_UNIT_DETAIL_SUCCESS = 'GET_ITEM_UNIT_DETAIL_SUCCESS';
export const GET_ITEM_UNIT_DETAIL_FAILED = 'GET_ITEM_UNIT_DETAIL_FAILED';

/**
 * Create item-unit
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createItemUnit(payload, onSuccess, onError) {
  return {
    type: CREATE_ITEM_UNIT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Create item-unit success action
 * @param {*} payload
 * @returns {object}
 */
export function createItemUnitSuccess(payload) {
  return {
    type: CREATE_ITEM_UNIT_SUCCESS,
    payload: payload,
  };
}

/**
 * Create item-unit failed action
 * @returns {object}
 */
export function createItemUnitFailed() {
  return {
    type: CREATE_ITEM_UNIT_FAILED,
  };
}

/**
 * Update item-unit
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateItemUnit(payload, onSuccess, onError) {
  return {
    type: UPDATE_ITEM_UNIT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Update item-unit success action
 * @param {*} payload
 * @returns {object}
 */
export function updateItemUnitSuccess(payload) {
  return {
    type: UPDATE_ITEM_UNIT_SUCCESS,
    payload: payload,
  };
}

/**
 * Update item-unit failed action
 * @returns {object}
 */
export function updateItemUnitFailed() {
  return {
    type: UPDATE_ITEM_UNIT_FAILED,
  };
}

/**
 * Delete item-unit
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function deleteItemUnit(payload, onSuccess, onError) {
  return {
    type: DELETE_ITEM_UNIT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Delete item-unit success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteItemUnitSuccess(payload) {
  return {
    type: DELETE_ITEM_UNIT_SUCCESS,
    payload: payload,
  };
}

/**
 * Delete item-unit failed action
 * @returns {object}
 */
export function deleteItemUnitFailed() {
  return {
    type: DELETE_ITEM_UNIT_FAILED,
  };
}

/**
 * Search item-unit
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function searchItemUnit(payload, onSuccess, onError) {
  return {
    type: SEARCH_ITEM_UNIT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Search item-unit success action
 * @param {*} payload
 * @returns {object}
 */
export function searchItemUnitSuccess(payload) {
  return {
    type: SEARCH_ITEM_UNIT_SUCCESS,
    payload: payload,
  };
}

/**
 * Search item-unit failed action
 * @returns {object}
 */
export function searchItemUnitFailed() {
  return {
    type: SEARCH_ITEM_UNIT_FAILED,
  };
}

/**
 * Get item-unit detail
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
 export function getItemUnitDetail(payload, onSuccess, onError) {
  return {
    type: GET_ITEM_UNIT_DETAIL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  };
}

/**
 * Get item-unit detail success action
 * @param {*} payload
 * @returns {object}
 */
export function getItemUnitDetailSuccess(payload) {
  return {
    type: GET_ITEM_UNIT_DETAIL_SUCCESS,
    payload: payload,
  };
}

/**
 * Get item-unit detail failed action
 * @returns {object}
 */
export function getItemUnitDetailFailed() {
  return {
    type: GET_ITEM_UNIT_DETAIL_FAILED,
  };
}
