import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  searchShopOwnerOrdersFailed,
  searchShopOwnerOrdersSuccess,
  SEARCH_SHOP_OWNER_ORDERS_START,
} from 'stores/common/common.action';

/**
 * Search shop owner orders API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const searchShopOwnerOrdersApi = (params) => {
  const { user_id } = params;
  delete params.user_id;
  const uri = `${API_URL.ORDERS}/shop_owner/${user_id}`;
  return api.get(uri, params);
};

/**
 * Handle search shop owner request and response
 * @param {object} action
 */
function* doSearchShopOwnerOrders(action) {
  try {
    const response = yield call(searchShopOwnerOrdersApi, action?.payload);

    if (response?.status === 200) {
      const payload = {
        list: response.data.items,
        total: response.data.total,
      };

      yield put(searchShopOwnerOrdersSuccess(payload));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess(response.data.items);
      }
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(searchShopOwnerOrdersFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch search shop owner orders
 */
export default function* watchSearchShopOwnerOrders() {
  yield takeLatest(SEARCH_SHOP_OWNER_ORDERS_START, doSearchShopOwnerOrders);
}
