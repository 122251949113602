import { put, takeLatest } from 'redux-saga/effects';
import {
  logoutFailed,
  logoutSuccess,
  LOGOUT_START,
} from 'stores/auth/auth.action';
import { redirectRouter } from 'utils';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

/**
 * Handle logout request and response
 * @param {object} action
 */
function* doLogout(action) {
  try {
    // remove token info from cookies
    cookies.remove('token');
    cookies.remove('refreshToken');
    cookies.remove('userId');

    // Remove token from local storage
    localStorage.removeItem('token');

    // Save refresh token from local storage
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('user');
    localStorage.removeItem('userImage')
    localStorage.removeItem('googleLogin')

    // Redirect to home
    setTimeout(() => {
      redirectRouter('/login');
    }, 500);
    yield put(logoutSuccess());
  } catch (error) {
    yield put(logoutFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch search users
 */
export default function* watchLogout() {
  yield takeLatest(LOGOUT_START, doLogout);
}
