import { NOTIFICATION_TYPE, API_URL } from 'common/constant';
import addNotification from 'common/toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import {
  rejectStoreTransactionFailed,
  rejectStoreTransactionSuccess,
  REJECT_STORE_TRANSACTION_START,
} from 'stores/store/store.action';

/**
 * Reject store transaction API
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const rejectStoreTransactionApi = (params) => {
  const uri = `${API_URL.TRANSACTIONS}/reject/${params}`;
  return api.put(uri);
};

/**
 * Handle reject store transaction request and response
 * @param {object} action
 */
function* doRejectStoreTransaction(action) {
  try {
    const response = yield call(rejectStoreTransactionApi, action?.payload);

    if (response?.status === 200) {
      yield put(rejectStoreTransactionSuccess(response.data));

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess();
      }

      addNotification('store.rejectStoreTransactionSuccess', NOTIFICATION_TYPE.SUCCESS);
    } else {
      addNotification(
        response?.message || response?.statusText,
        NOTIFICATION_TYPE.ERROR,
      );

      throw new Error(response?.message);
    }
  } catch (error) {
    yield put(rejectStoreTransactionFailed());
    // Call callback action if provided
    if (action.onError) {
      yield action.onError();
    }
  }
}

/**
 * Watch reject store transaction
 */
export default function* watchRejectStoreTransaction() {
  yield takeLatest(REJECT_STORE_TRANSACTION_START, doRejectStoreTransaction);
}
