import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TopContent from 'components/common/top-content';
import CustomTable from 'components/common/custom-table';
import TransactionForm from 'containers/transaction-statistics/form';
import { Spin, Button, Modal } from 'antd';
import './index.scss';
import { searchTransactions } from 'stores/common/common.action';
import { searchUser } from 'stores/user/user.action';
import {
  searchStore,
  completeStoreTransaction,
  rejectStoreTransaction,
} from 'stores/store/store.action';
import {
  DATE_FORMAT,
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_LIST,
  TRANSACTION_STATUS_MAP,
  TRANSACTION_TYPE,
} from 'common/constant';
import { CheckSquareFilled, MinusCircleFilled } from '@ant-design/icons';
import { formatDateTimeUtc } from 'utils';

const breadcrumbs = [
  {
    route: '/withdraw-coin',
    title: 'withdrawCoin',
  },
];

function WithdrawCoin(props) {
  const { t, common, userList, storeList } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filter, setFilter] = useState([
    {
      column: 'transaction_type',
      value: TRANSACTION_TYPE.SHOP,
    },
  ]);
  const [sort, setSort] = useState(null);
  const [openTransactionDetail, setOpenTransactionDetail] = useState(false);
  const [transactionId, setTransactionId] = useState(null);

  useEffect(() => {
    document.title = t('withdrawCoin.title');
    props.searchUser();
    props.searchStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, page, pageSize, filter, sort]);

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('withdrawCoin.id'),
    },
    {
      key: 'user_id',
      dataIndex: 'user_id',
      title: t('withdrawCoin.user'),
      render: (params) => {
        return userList.find((user) => user.id === params)?.full_name;
      },
    },
    {
      key: 'shop_id',
      dataIndex: 'shop_id',
      title: t('withdrawCoin.shop'),
      render: (params) => {
        return storeList.find((store) => store.id === params)?.name;
      },
    },
    {
      key: 'price',
      dataIndex: 'price',
      title: t('withdrawCoin.coin'),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('withdrawCoin.status'),
      filterable: true,
      type: 'categorical',
      filterOptions: {
        options: TRANSACTION_STATUS_LIST,
        getOptionValue: (option) => option?.id,
        getOptionLabel: (option) => t(option?.text),
      },
      render: (params) => {
        return t(TRANSACTION_STATUS_MAP[params]);
      },
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('withdrawCoin.createdAt'),
      render: (params) => {
        return formatDateTimeUtc(params, DATE_FORMAT);
      },
    },
    {
      key: 'action',
      dataIndex: 'id',
      title: t('common.action'),
      disableClickEventBubbling: true,
      sorter: false,
      align: 'center',
      headerAlign: 'center',
      render: (params, row) => {
        const { status } = row;
        const canAction = status === TRANSACTION_STATUS.UNCONFIRM;
        return (
          <div className="action-button">
            {canAction && (
              <>
                <Button
                  type="text"
                  shape="circle"
                  icon={<CheckSquareFilled style={{ color: 'green' }} />}
                  onClick={() =>
                    props.completeStoreTransaction(row, () => {
                      refreshData();
                    })
                  }
                />
                <Button
                  type="text"
                  shape="circle"
                  icon={<MinusCircleFilled style={{ color: 'red' }} />}
                  onClick={() =>
                    props.rejectStoreTransaction(params, () => {
                      refreshData();
                    })
                  }
                />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const refreshData = () => {
    props.searchTransactions({
      user_id: window.localStorage.getItem('userId'),
      offset: (page - 1) * pageSize,
      limit: pageSize,
      filter: JSON.stringify(filter),
      sort,
    });
  };

  const onPageSizeChange = ({ pageSize }) => {
    setPageSize(pageSize);
  };

  const onPageChange = ({ page }) => {
    setPage(page);
  };

  const onChangeFilter = (filter) => {
    setFilter(filter);
    setPage(1);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setSort({
      column: sorter.field,
      value: sorter.order === 'ascend' ? 'asc' : 'desc',
    });
  };

  const onCloseDetailModal = () => {
    setTransactionId(null);
    setOpenTransactionDetail(false);
    refreshData();
  };

  return (
    <Spin spinning={common?.isLoading}>
      <TopContent
        title={`${t('withdrawCoin.title')}`}
        breadcrumbs={breadcrumbs}
        showSearch={false}
      />
      <CustomTable
        columns={columns}
        rows={common?.transactionList}
        page={page}
        pageSize={pageSize}
        total={common?.transactionTotal}
        topInfo={true}
        onChange={handleTableChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onChangeFilter={onChangeFilter}
      />
      {openTransactionDetail && (
        <Modal
          visible={openTransactionDetail}
          title={t('withdrawCoin.detailModalTitle')}
          onCancel={onCloseDetailModal}
          width={1000}
          footer={[
            <Button
              key="close"
              onClick={onCloseDetailModal}
              className="close-button-custom"
            >
              {t('common.close')}
            </Button>,
          ]}
        >
          <TransactionForm id={transactionId} />
        </Modal>
      )}
    </Spin>
  );
}

const mapDispatchToProps = {
  searchTransactions,
  searchUser,
  searchStore,
  completeStoreTransaction,
  rejectStoreTransaction,
};

const mapStateToProps = (state) => ({
  common: state.common,
  userList: state.user.userList,
  storeList: state.store.storeList,
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(WithdrawCoin),
);
